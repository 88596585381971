import React from 'react';
import { CenteredLoader } from '@ui/Loader';
import { BotTabs } from '@utils/Routing';
import { MiniOnboardingOverlay } from './MiniOnboardingOverlay/MiniOnboardingOverlay';
import { useBotLivelinessStatus } from '@utils/Data/Bot';
import css from './MiniOnbordingTab.css';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { BotLivelinessStatus } from '@globals';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';

interface MiniOnbordingTabProps {
  page: BotTabs.liveChat | BotTabs.people | BotTabs.reEngage;
}

export const MiniOnbordingTab: React.FC<MiniOnbordingTabProps> = ({
  children,
  page,
}) => {
  const { status, loading } = useBotLivelinessStatus();
  const { isWhatsappEnabled, loading: whatsappStatusLoading } =
    useWhatsappEnabled();
  const { isLiveChatWebView } = useIsLiveChatWebView();

  if (loading || whatsappStatusLoading)
    return <CenteredLoader className={css.loader} />;

  const onBoardingOverlayShown =
    status === BotLivelinessStatus.OFF_WITHOUT_INGRESS_CONTROLLERS &&
    !isWhatsappEnabled &&
    !isLiveChatWebView;

  return onBoardingOverlayShown ? (
    <MiniOnboardingOverlay page={page} />
  ) : (
    <>{children}</>
  );
};
