import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment sendToMessengerEntryPointFragment on SendToMessengerEntryPoint {
    ...commonPluginFragment
    config {
      domains
      color
      size
      cta_text
      block_id
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
