import React, { useCallback, useMemo } from 'react';
import * as PopperJS from 'popper.js';
import { omit } from 'ramda';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ControllerStateAndHelpers } from 'downshift';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { ComboboxFilterType, Item, SimpleCombobox } from '@ui/SimpleCombobox';
import { BotHeaderMenuItem } from '@ui/Menu';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Input, InputColorWay } from '@ui/Input';
import { useAdminId } from '@utils/Admin/useAdminId';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useWindowSize } from '@utils/DOM/useWindowSize';
import { isBotCopying } from '@utils/Bot/isBotCopying';
import { MIN_BOTS_AMOUNT } from '../../consts';
import {
  ComboboxAllBotsType,
  ComboboxItemType,
  ComboboxType,
} from '../../types';
import * as css from './BotHeader.css';
import { BotPicture } from '../BotPicture/BotPicture';
import { BotNameInput } from '../BotNameInput/BotNameInput';
import { BotOptionsMenu } from '@components/BotList/components/BotOptionsMenu';
import { useBotOptionsHandler } from '@components/BotList/components/BotOptionsHandler/BotOptionsHandler';
import { BotTitle } from '../BotTitle';
import { BotSwitcherNavItemContainer } from '@components/LeftNavigationPanel/components/BotSwitcher/BotSwitcherNavItem';
import { getDeviceRelatedProps } from '../../utils';
import { Footer } from '../ComboboxItems';
import { TopPanel } from './components/TopPanel';
import cn from 'classnames';
import { useTopPanel } from './hooks/useTopPanel';
import { Platform } from '@globals';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';

/**
 * Не добавлять мемоизацию, может произойти баг, что меню начнет рисоваться за рамками экрана
 * кажется причина в том что както конфликтуют модифаеры попера хз как, инстансы то ращные,
 * возможно кудато надо добавить scheduleUpdate из попера
 *
 * тред https://chatfuel.slack.com/archives/CHEP7CEV7/p1660821763830129
 */
const getPopperModifiers = (): PopperJS.Modifiers => ({
  offset: {
    offset: '0, 0',
  },
  preventOverflow: {
    padding: 0,
    enabled: true,
    boundariesElement: document.getElementById('page-content') || 'viewport',
    escapeWithReference: false,
  },
});

type SpecifiedProps =
  | 'filter'
  | 'onAddBotButtonClick'
  | 'comboboxItems'
  | 'botsItemsLength';

export interface BotHeaderProps
  extends Omit<BotHeaderBaseProps, SpecifiedProps> {
  loading: boolean;
}

interface BotHeaderBaseProps {
  onChangeBot(item: ComboboxItemType | null): void;
  onAllBotsSelected(): void;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  onButtonClick?: () => void;
  renderCustomItem?: (
    item: Item<ComboboxItemType>,
    props: any,
  ) => React.ReactNode;
  onAddBotButtonClick: (platforms?: Platform[]) => void;
  loading: boolean;
  botsItemsLength: number;
  comboboxItems: Item<ComboboxItemType>[];
  filter: ComboboxFilterType<ComboboxItemType>;
  menuDisabled?: boolean;
}

export const BotHeaderBase = React.memo<BotHeaderBaseProps>(
  ({
    onChangeBot,
    onAllBotsSelected,
    onMenuClose,
    onMenuOpen,
    onButtonClick,
    onAddBotButtonClick,
    renderCustomItem,
    comboboxItems,
    botsItemsLength,
    loading,
    filter,
    menuDisabled,
  }) => {
    const { t } = useSafeTranslation();
    const { height: pageHeight } = useWindowSize();
    const isWhiteLabel = isWhiteLabelDomain();
    const { id } = useAdminId();
    const {
      handleOptionSelect,
      isRenameMode,
      setIsRenameMode,
      exitRenameMode,
    } = useBotOptionsHandler({ userId: id! });
    const { isSmallScreenSize } = useDeviceMedia();
    const {
      isShowPlatformSelector,
      isShowTopPanel,
      toggleTopPanelPlatformSelector,
      hideTopPanelPlatformSelector,
    } = useTopPanel();

    const newComboboxItems = useMemo(() => {
      if (!isWhiteLabel && !isShowTopPanel) {
        comboboxItems.push({
          type: ComboboxType.footer,
          id: '__footer',
          title: t('common.BotHeader.allBotsList'),
          fixedToFooter: true,
        } as ComboboxAllBotsType);
      }

      return comboboxItems;
    }, [isWhiteLabel, isShowTopPanel, comboboxItems, t]);

    const handleItemSelect = useCallback(
      (
        item: ComboboxItemType | null,
        { clearSelection }: ControllerStateAndHelpers<ComboboxItemType | null>,
      ) => {
        clearSelection();
        exitRenameMode();
        if (!item) {
          return;
        }
        if (item.type === ComboboxType.item) {
          onChangeBot(item);
        }

        onMenuClose?.();
      },
      [onChangeBot, onMenuClose, exitRenameMode],
    );

    const showSearchField = botsItemsLength > MIN_BOTS_AMOUNT;

    const { fixedSizeListHeight, autoSizerHeight, itemSize } =
      getDeviceRelatedProps({
        mobile: isSmallScreenSize,
        showAllBotsButton: !isWhiteLabel,
        showSearchField,
        pageHeight,
        itemsLength: newComboboxItems.length,
      });
    const { isLiveChatWebView } = useIsLiveChatWebView();

    return (
      <SimpleCombobox<ComboboxItemType>
        menuBoxDataTestId="bot-header__combobox-menu"
        hideFooterSeparator
        position="right"
        modifiers={getPopperModifiers()}
        onStateChange={(change) => {
          if (change.isOpen === undefined) {
            return;
          }

          if (change.isOpen) {
            onMenuOpen?.();
          } else {
            onMenuClose?.();
          }
        }}
        virtualScroll
        autoSizerStyles={{ height: autoSizerHeight }}
        fixedSizeListProps={() => ({
          height: fixedSizeListHeight,
          overscanCount: 5,
          itemSize,
          className: css.darkScroll,
        })}
        menuBoxClassName={cn(isShowTopPanel && css.bottomPadding, css.combobox)}
        filter={filter}
        items={newComboboxItems}
        onChange={handleItemSelect}
        renderMenuHead={({ getInputProps }) => {
          const inputProps = getInputProps();

          // На onBlur downshift закрывает комбобокс, тк на мобилке в этом комбобоксе есть другие инпуты
          // (ренейм бота, удаление, копирование) при фокусировании на них, комбобокс закроется
          const deviceDependentProps = isSmallScreenSize
            ? omit(['onBlur'], inputProps)
            : inputProps;

          return (
            <>
              {isShowTopPanel && !isLiveChatWebView && (
                <TopPanel
                  showPlatformSelector={isShowPlatformSelector}
                  onAddBotButtonClick={toggleTopPanelPlatformSelector}
                  onAddBotButtonOutsideClick={hideTopPanelPlatformSelector}
                  addBotButtonDisabled={loading}
                  onAllBotsButtonClick={onAllBotsSelected}
                  onBotCreateRequest={onAddBotButtonClick}
                />
              )}
              {showSearchField && (
                <div
                  className={cn(
                    css.searchStringBox,
                    isShowPlatformSelector && css.disabled,
                  )}
                >
                  <Input
                    autoFocus
                    renderIcon={() => <Icon icon="search" color="greyDark" />}
                    placeholder={t('common.BotHeader.searchPlaceholder')}
                    colorWay={InputColorWay.darkest}
                    className={css.input}
                    data-testid="bot-list__input"
                    {...deviceDependentProps}
                  />
                </div>
              )}
            </>
          );
        }}
        renderInput={({ getToggleButtonProps, isOpen }) => {
          const { onBlur, ...props } = getToggleButtonProps();

          return (
            <div
              className={cn(
                css.botSwitcherContainer,
                isOpen && isSmallScreenSize && css.hidden,
              )}
            >
              <BotSwitcherNavItemContainer
                loading={loading}
                onButtonClick={onButtonClick}
                menuDisabled={menuDisabled}
                data-testid="bot-switcher__open-bot-list"
                {...props}
              />
            </div>
          );
        }}
        renderItem={({ item, index, highlightedIndex, getItemProps }) => {
          const active = index === highlightedIndex;
          const itemProps = getItemProps({ item, index });
          const commonProps = isSmallScreenSize
            ? {
                key: item.id,
                ...omit(['onMouseDown', 'onMouseMove'], itemProps),
              }
            : {
                key: item.id,
                active,
                ...itemProps,
              };

          const customItem = renderCustomItem?.(item, { itemSize });
          if (customItem) {
            return (
              <div className={cn(isShowPlatformSelector && css.disabled)}>
                {customItem}
              </div>
            );
          }

          if (item.type === ComboboxType.item) {
            const renameMode = isRenameMode(item.bot);

            return (
              <BotHeaderMenuItem
                className={cn(isShowPlatformSelector && css.disabled)}
                title={item.title}
                makeupStyles={{ maxWidth: '100%', overflow: 'hidden' }}
                leftElement={
                  <Flex>
                    <BotPicture
                      imageSrc={item.bot.status?.page_info?.picture}
                    />
                    <Spacer factor={1} horizontalFactor={1} />
                  </Flex>
                }
                titleStyle={
                  isSmallScreenSize ? { overflow: 'visible' } : undefined
                }
                titleElement={
                  renameMode ? (
                    <BotNameInput
                      bot={item.bot}
                      onDone={() => setIsRenameMode(item.bot, false)}
                      eventCategory="header bot"
                      hidePicture={isSmallScreenSize}
                    />
                  ) : (
                    <BotTitle active={active} item={item} />
                  )
                }
                rightElement={
                  !renameMode &&
                  isSmallScreenSize &&
                  !isLiveChatWebView && (
                    <BotOptionsMenu
                      isLast={false}
                      botId={item.bot.id}
                      onActionSelect={(selectedItem) =>
                        handleOptionSelect(item.bot, selectedItem.type)
                      }
                      disabled={isBotCopying(item.bot)}
                    />
                  )
                }
                {...commonProps}
              />
            );
          }

          if (item.type === ComboboxType.footer) {
            return (
              <Footer
                onAddBotButtonClick={onAddBotButtonClick}
                addBotButtonDisabled={loading}
                onAllBotsButtonClick={onAllBotsSelected}
              />
            );
          }

          return null;
        }}
      />
    );
  },
);
