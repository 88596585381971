import React from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Formik, FormikConfig } from 'formik';

import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { Loader } from '@ui/Loader';
import { ScrollBox as ScrollBoxRegular } from '@ui/ScrollBox';
import { ScrollBoxWithShadow } from '@ui/ScrollBox/ScrollBoxWithShadow';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import * as css from './BillingDetailsForm.css';

export interface BillingDetailsFormProps {
  initialValues: BillingDetailsValues;
  onSubmit: FormikConfig<BillingDetailsValues>['onSubmit'];
  state?: FormState;
  withoutShadow?: boolean;
}

export enum FormState {
  pending,
  loading,
  success,
  error,
}

interface BillingDetailsValues {
  company_name: string;
  email: string;
  tax_id: string;
  street: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
}

export const BillingDetailsForm: React.FC<BillingDetailsFormProps> = ({
  initialValues,
  onSubmit,
  state = FormState.pending,
  withoutShadow,
}) => {
  const { t } = useSafeTranslation();
  const readOnly = state === FormState.loading;
  const ScrollBox = withoutShadow ? ScrollBoxRegular : ScrollBoxWithShadow;
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, handleBlur, values }) => (
        <form data-testid="billing-details-form" onSubmit={handleSubmit}>
          <ScrollBox className={css.scrollBox}>
            <Spacer factor={4} />
            <Type size="15px" weight="semibold">
              {t('BillingDetailsForm-JSXText-9504-company')}
            </Type>
            <Input
              className={css.inputField}
              name="company_name"
              placeholder={t('modernComponents.Payments.BillingDetails.name')}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={readOnly}
              value={values.company_name}
            />
            <Input
              className={css.inputField}
              name="email"
              placeholder={t('modernComponents.Payments.BillingDetails.email')}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={readOnly}
              value={values.email}
            />
            <Input
              className={css.inputField}
              name="tax_id"
              placeholder={t('modernComponents.Payments.BillingDetails.taxId')}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={readOnly}
              value={values.tax_id}
            />
            <Spacer factor={4} />
            <Type size="15px" weight="semibold">
              {t('BillingDetailsForm-JSXText--114-address')}
            </Type>
            <Input
              className={css.inputField}
              name="street"
              placeholder={t('modernComponents.Payments.BillingDetails.street')}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={readOnly}
              value={values.street}
            />
            <Input
              className={css.inputField}
              name="city"
              placeholder={t('modernComponents.Payments.BillingDetails.city')}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={readOnly}
              value={values.city}
            />
            <Input
              className={css.inputField}
              name="state"
              placeholder={t('modernComponents.Payments.BillingDetails.state')}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={readOnly}
              value={values.state}
            />
            <Input
              className={css.inputField}
              name="postcode"
              placeholder={t(
                'modernComponents.Payments.BillingDetails.postcode',
              )}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={readOnly}
              value={values.postcode}
            />
            <Input
              className={css.inputField}
              name="country"
              placeholder={t(
                'modernComponents.Payments.BillingDetails.country',
              )}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={readOnly}
              value={values.country}
            />
            <Spacer factor={5} />
          </ScrollBox>
          <div>
            <Flex className={css.submitButton} alignItems="center">
              {readOnly ? (
                <Loader data-testid="billing-details-form_loader" />
              ) : (
                <>
                  {state === FormState.success && (
                    <div className={css.changesSavedText}>
                      {t('BillingDetailsForm-JSXText--183-changes-saved')}
                    </div>
                  )}
                  {state === FormState.error && (
                    <div
                      className={cn(
                        css.changesSavedText,
                        css.changesSavedTextError,
                      )}
                    >
                      {t(
                        'BillingDetailsForm-JSXText-1677-error-please-try-later',
                      )}
                    </div>
                  )}
                  <Button
                    type="submit"
                    intent="primary"
                    className={css.updateButton}
                  >
                    {t('BillingDetailsForm-JSXText-1713-update')}
                  </Button>
                </>
              )}
            </Flex>
          </div>
        </form>
      )}
    </Formik>
  );
};
