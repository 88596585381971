import React, { useCallback, useState } from 'react';
import { StripeInput } from '@ui/StripeInput';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { Flex, FlexItem } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { visuallyHidden } from '@utils/css/styles';
import { usePaymentForm } from '../hooks/usePaymentForm';
import { PaywallPaymentDialog } from './PaywallPaymentDialog';
import { PaywallPaymentLoader } from './PaywallPaymentLoader';
import { StripeElementChangeEvent } from '@stripe/stripe-js';

interface PaywallPaymentFormProps {
  initialPayment?: boolean;
  loading?: boolean;
  submitting?: boolean;
  onSubmit?: () => void;
  onDismiss?: () => void;
}

interface StripeValidation {
  complete: boolean;
  invalid: boolean;
}

type StripeElementType = StripeElementChangeEvent['elementType'];

type StripeElementsValidation = Partial<
  Record<StripeElementType, StripeValidation>
>;

export const PaywallPaymentForm: React.FC<PaywallPaymentFormProps> = ({
  initialPayment = false,
  loading = false,
  submitting = false,
  onSubmit,
  onDismiss,
}) => {
  const { t } = useSafeTranslation();
  const [elementsValidation, setElementValidation] =
    useState<StripeElementsValidation>({});

  const complete =
    (elementsValidation.cardNumber?.complete &&
      elementsValidation.cardCvc?.complete &&
      elementsValidation.cardExpiry?.complete) ??
    false;

  const { cardElementIsReady, handleCardReady, handlePayment } = usePaymentForm(
    { initialPayment, complete, onSubmit },
  );

  const handleChange = useCallback(
    (event: StripeElementChangeEvent) => {
      setElementValidation((elementsValidation) => ({
        ...elementsValidation,
        [event.elementType]: {
          complete: event.complete,
          invalid: !!event.error,
        },
      }));
    },
    [setElementValidation],
  );

  const formStyle = cardElementIsReady ? undefined : visuallyHidden;

  return (
    <PaywallPaymentDialog onDismiss={onDismiss}>
      {(loading || !cardElementIsReady) && <PaywallPaymentLoader />}
      {!loading && (
        <form style={formStyle} onSubmit={handlePayment}>
          <StripeInput
            as={CardNumberElement}
            disabled={submitting}
            placeholder={t(
              'modernComponents.Payments.PaymentDialog.cardNumber',
            )}
            error={elementsValidation.cardNumber?.invalid}
            errorText={
              elementsValidation.cardNumber?.invalid
                ? t(
                    'modernComponents.Payments.PaymentDialog.error.invalidCardNumber',
                  )
                : undefined
            }
            onChange={handleChange}
            onReady={handleCardReady}
          />
          <Spacer factor={5} />
          <Flex>
            <FlexItem equalWidth>
              <StripeInput
                as={CardExpiryElement}
                disabled={submitting}
                placeholder="MM/YY"
                error={elementsValidation.cardExpiry?.invalid}
                errorText={
                  elementsValidation.cardExpiry?.invalid
                    ? t(
                        'modernComponents.Payments.PaymentDialog.error.invalidExpiryDate',
                      )
                    : undefined
                }
                onChange={handleChange}
              />
            </FlexItem>
            <Spacer horizontalFactor={5} />
            <FlexItem equalWidth>
              <StripeInput
                as={CardCvcElement}
                disabled={submitting}
                placeholder="СVV"
                error={elementsValidation.cardCvc?.invalid}
                onChange={handleChange}
              />
            </FlexItem>
          </Flex>
          <Spacer />
          <Flex justifyContent="flex-end">
            <Button
              type="submit"
              intent="primary"
              disabled={!complete}
              loading={submitting}
            >
              {t('modernComponents.Payments.PaymentDialog.paywallSubmit')}
            </Button>
          </Flex>
        </form>
      )}
    </PaywallPaymentDialog>
  );
};
