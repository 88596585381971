import React from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { BotPageRouteParams, BotTabs, getTabLink } from '@utils/Routing';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { TabNavLinkWithRole } from '../TabLink/TabLink';
import { NavItemProps } from './types';
import * as css from '../../LeftNavigationPanel.css';
import * as commonCSS from '../../common.css';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { ShopifySubscriptionStatus } from '@globals';

export const BillingNavSubItems: React.FC<NavItemProps> = ({
  small,
  onClick,
  className,
}) => {
  const { botId } = useParams<BotPageRouteParams>();
  const { t } = useSafeTranslation();
  const { account } = useShopifyAccount();
  const isShopifyHasSubscription = [
    ShopifySubscriptionStatus.active,
    ShopifySubscriptionStatus.cancelled,
  ].includes(account?.subscription?.status as ShopifySubscriptionStatus);

  return (
    <div
      className={cn({
        [css.extendedContent]: !small,
      })}
    >
      <TabNavLinkWithRole
        data-testid="test-nav-to-plans"
        tabUrl={getTabLink(BotTabs.plans, botId)}
        tabEvent="plans"
        domain="pro"
        onClick={onClick}
        className={className}
        small={small}
        activeClassName={commonCSS.activeLinkSubMenu}
      >
        {!small && <Spacer factor={1} horizontalFactor={5} />}
        <Type weight="medium" size="12px" color="grey">
          {t('modernComponents.LeftNavigationPanel.plansSubTab')}
        </Type>
        <Spacer horizontalFactor={2} />
      </TabNavLinkWithRole>

      {(!account || isShopifyHasSubscription) && (
        <TabNavLinkWithRole
          data-testid="test-nav-to-billing-info"
          tabUrl={getTabLink(BotTabs.billing, botId)}
          tabEvent="billing-info"
          domain="pro"
          onClick={onClick}
          className={className}
          small={small}
          activeClassName={commonCSS.activeLinkSubMenu}
        >
          {!small && <Spacer factor={1} horizontalFactor={5} />}
          <Type weight="medium" size="12px" color="grey">
            {t('modernComponents.LeftNavigationPanel.billingSubTab')}
          </Type>
        </TabNavLinkWithRole>
      )}
    </div>
  );
};
