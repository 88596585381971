import Cropper from 'cropperjs';
import { AspectRatioValue } from '../../../ratio';
import {
  ImageMIMEType,
  getExtFromFileName,
  getImageMIMETypeByExt,
} from '../../../../../../utils/fileUtils';

const QUALITY_ARGUMENT = 1;

export const getResizedBlobData = (
  cropper: Cropper,
  mimeType: ImageMIMEType,
): Promise<Blob> =>
  new Promise((resolve, reject) => {
    cropper.getCroppedCanvas().toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject();
        }
      },
      mimeType,
      QUALITY_ARGUMENT,
    );
  });

export const config: Cropper.Options = {
  viewMode: 2,
  autoCropArea: 1,
  background: false,
  movable: false,
  rotatable: false,
  scalable: false,
  center: false,
  minCropBoxWidth: 100,
  minCropBoxHeight: 100,
};

const autoResize = (
  fileBlob: File,
  aspectRatio: AspectRatioValue,
): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const imgURL = URL.createObjectURL(fileBlob);
    const preview = document.createElement('img');
    preview.setAttribute('src', imgURL);
    preview.onload = () => {
      preview.style.display = 'none';
      document.body.appendChild(preview);

      const cropper = new Cropper(preview, {
        ...config,
        autoCrop: true,
        autoCropArea: 1,
        aspectRatio,
        ready() {
          (this as any).cropper.cropper.style.display = 'none';
          cropper.crop();

          const cleanUp = () => {
            cropper.destroy();
            preview.remove();
            URL.revokeObjectURL(imgURL);
          };

          const fileName = getExtFromFileName(fileBlob.name);
          const mimeType =
            (fileName && getImageMIMETypeByExt(fileName)) || ImageMIMEType.jpeg;

          getResizedBlobData(cropper, mimeType)
            .then((blob) => {
              cleanUp();
              resolve(blob);
            })
            .catch(() => {
              cleanUp();
              reject();
            });
        },
      });
    };
  });
};

export const getAutoResizedSquareImage = (imageFile: File) =>
  autoResize(imageFile, AspectRatioValue.square);

export const getAutoResizedHorizontalGalleryImage = (imageFile: File) =>
  autoResize(imageFile, AspectRatioValue.horizontalFacebookGallery);
