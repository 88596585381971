import { PluginType } from '../common/PluginTypes';
import { instagramLinkEntryPointFragment_config as InstagramLinkEntryPointConfig } from './@types/instagramLinkEntryPointFragment';

export const PLUGIN_TYPE = PluginType.instagram_bot_link_entry_point;

export const PLUGIN_DEFAULT_CONFIG: InstagramLinkEntryPointConfig = {
  __typename: 'InstagramLinkEntryPointConfig',
  block_id: null,
  referral: '',
};

export const PLUGIN_INPUT_FIELD = 'instagramLinkEntryPointConfig';
