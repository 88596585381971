import { SimpleCombobox, SimpleComboboxProps } from '@ui/SimpleCombobox';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Input } from '@ui/Input';
import { Spacer } from '@ui/Spacer';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import noop from 'lodash-es/noop';
import React from 'react';
import css from './InviteAdminDialog.css';
import { useCopyText } from '@utils/Copy';
import { MenuItem } from '@ui/Menu';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

export interface InviteAdminDialogProps {
  title: string;
  onClose: VoidFunction;
  onRoleSelect(roleId: string): void;
  inviteLoading: boolean;
  inviteLink?: string;
  items: SimpleComboboxProps<{
    id: string;
    title: string;
    tooltip: string;
  }>['items'];
}

export const InviteAdminDialog: React.FC<InviteAdminDialogProps> = ({
  title,
  onClose,
  onRoleSelect,
  inviteLoading,
  inviteLink,
  items,
}) => {
  const { copy } = useCopyText();
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <DefaultDialog
      onDismiss={onClose}
      mobileAdaptive={isSmallScreenSize}
      header={t('InviteAdminDialog.inviteTeammate')}
      footer={
        <div className={css.footer}>
          <Type size="15px">{t('InviteAdminDialog.copyAndSend')}</Type>
          <Spacer factor={2} />
          <Flex>
            <Input
              containerClassName={css.linkInput}
              value={inviteLink}
              onChange={noop}
              disabled={!inviteLink}
            />
            <Spacer horizontalFactor={3} />
            <Button
              disabled={!inviteLink}
              loading={inviteLoading}
              onClick={() => {
                if (inviteLink) copy(inviteLink);
              }}
              icon={<Icon icon="copy" color="white" />}
            />
          </Flex>
        </div>
      }
    >
      <TextEllipsis>
        <Type size="15px" color="greyDark">
          {title}
        </Type>
      </TextEllipsis>
      <Spacer factor={6} />
      <Type size="15px">{t('InviteAdminDialog.chooseRole')}</Type>
      <Spacer factor={2} />
      <SimpleCombobox
        className={css.combobox}
        items={items}
        onChange={(item) => {
          if (item) onRoleSelect(item.id);
        }}
        renderItem={({ item, getItemProps, highlightedIndex, index }) => (
          <MenuItem
            key={item.id}
            title={item.title}
            tooltip={item.tooltip}
            {...getItemProps({ item })}
            active={index === highlightedIndex}
            tooltipBoundariesElement="viewport"
          />
        )}
        renderInput={({ getToggleButtonProps, selectedItem }) => (
          <Button
            loading={inviteLoading}
            className={css.selectRoleButton}
            intent="secondary"
            {...getToggleButtonProps()}
            iconRight={<Icon icon="triangle" />}
          >
            {selectedItem ? (
              selectedItem.title
            ) : (
              <Type size="15px">{t('InviteAdminDialog.select')}</Type>
            )}
          </Button>
        )}
      />
    </DefaultDialog>
  );
};
