import React, { useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useQuery } from '@apollo/react-hooks';
import { TutorialLinks } from '@ui/Links';
import { getLinkByVideoHash } from '@utils/Youtube/getLinkByVideoHash';
import { useCurrentBotId, useCurrentFlowId } from '@utils/Routing';
import { DefaultDialog } from '@ui/Dialog/DefaultDialog';
import { Button } from '@ui/Button';
import { ReactComponent as DropdownIcon } from '@ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { MenuItem } from '@ui/Menu';
import { Tooltip2 } from '@ui/Tooltip2';
import { sendEvent } from '@utils/Analytics';
import { Autofocus } from '@ui/Autofocus';
import { CenteredLoader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import {
  MessageTagsFlowBlocksQuery,
  MessageTagsFlowBlocksQueryVariables,
} from './@types/MessageTagsFlowBlocksQuery';
import {
  NON_PROMOTIONAL_GROUP_ID,
  NON_PROMOTIONAL_SUBSCRIPTION_ID,
  PROMOTIONAL_GROUP_ID,
  STANDARD_MESSAGING_ID,
  TAG_ID_TO_TAG_MAP,
  TAG_ORDER,
  TAGS_TOOLTIP_LIST,
} from '../BroadcastingTags/TagsData';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { PluginTutorialYoutube } from '../../constants/externalLinks';
import { MESSAGE_TAGS_FLOW_BLOCKS_QUERY } from './queries';
import * as css from './MessageTagModal.css';
import { MessageTagItem } from './types';
import { useNotificationMessagesTopics } from '@utils/Data/NotificationMessages/useNotificationMessagesTopics';

const createTooltip = (text: string, t: TFunction) => {
  return `
  <span>${text} <span style="text-decoration: underline">${t(
    'common.LearnMore',
  )}&nbsp;↗</span></span>
`;
};

const getTagsGroup: (
  data: MessageTagsFlowBlocksQuery,
  tagGroup: string,
  t: TFunction,
) => MessageTagItem[] = (data, tagGroup, t) => {
  const isEuRestricted = data.bot.status?.page_info?.eu_restricted;
  return data.messageTags
    .filter((tag) => TAG_ID_TO_TAG_MAP(t).get(tag.tag)?.groupId === tagGroup)
    .sort((tagA, tagB) => TAG_ORDER[tagA.tag] - TAG_ORDER[tagB.tag])
    .map((tag) => {
      const tagItem = TAG_ID_TO_TAG_MAP(t).get(tag.tag);
      const tooltipConfig = TAGS_TOOLTIP_LIST(t)[tagItem?.id || ''];
      return {
        id: tagItem?.id || '',
        title: tagItem?.leftPartTitle || '',
        type: 'item',
        disabled: !!(
          (
            tag.disabled &&
            (tag.tag !== NON_PROMOTIONAL_SUBSCRIPTION_ID || !isEuRestricted)
          ) // enable for EU admins (https://chatfuel.atlassian.net/browse/CHAT-4471)
        ),
        tagType: 'tag',
        tooltip: {
          html: createTooltip(tooltipConfig.text, t),
          url: tooltipConfig.linkHref,
        },
      };
    });
};

export interface MessageTagModalProps {
  defaultValue?: string | null;
  onChange: (item: MessageTagItem) => void;
  onDismiss?: () => void;
}

export const MessageTagModal: React.FC<MessageTagModalProps> = ({
  onChange,
  onDismiss,
  ...rest
}) => {
  const { t } = useSafeTranslation();
  const [selectedItem, setSelectedItem] = useState<
    MessageTagItem | undefined | null
  >();
  const botId = useCurrentBotId();
  const flowId = useCurrentFlowId();
  const { data, loading: oneTimeTagLoadingLoading } = useQuery<
    MessageTagsFlowBlocksQuery,
    MessageTagsFlowBlocksQueryVariables
  >(MESSAGE_TAGS_FLOW_BLOCKS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      botId: botId || '',
    },
  });

  const { notificationMessagesTopics, notificationMessagesTopicsLoading } =
    useNotificationMessagesTopics(undefined, {
      fetchPolicy: 'network-only',
    });

  const defaultValue = rest.defaultValue ?? STANDARD_MESSAGING_ID;

  const items = useMemo<MessageTagItem[]>(() => {
    if (
      data?.messageTags &&
      !oneTimeTagLoadingLoading &&
      !notificationMessagesTopicsLoading
    ) {
      const promotionalItems = getTagsGroup(data, PROMOTIONAL_GROUP_ID, t);
      const otnItems: MessageTagItem[] =
        data.oneTimeNotifications.length > 0
          ? data.oneTimeNotifications.map((tag) => ({
              id: tag.purpose,
              title: tag.purpose,
              type: 'item',
              tagType: 'otn',
            }))
          : [
              {
                id: 'emptyOtnItem',
                title: t(
                  'MessageTagModal-string--258-none-😬-create-topics-in-otn-plugin',
                ),
                type: 'item',
                disabled: true,
                tooltip: {
                  text: t(
                    'MessageTagModal-string-8088-add-otn-plugin-to-create-a-topic',
                  ),
                },
              },
            ];

      const ntnItems: MessageTagItem[] =
        notificationMessagesTopics &&
        (notificationMessagesTopics?.length || 0) > 0
          ? notificationMessagesTopics.map((tag) => ({
              id: tag.id,
              title: tag.name,
              type: 'item',
              tagType: 'ntn',
            }))
          : [
              {
                id: 'emptyNtnItem',
                title: t('MessageTagModal-string-n-time-notification-empty'),
                type: 'item',
                disabled: true,
                tooltip: {
                  text: t(
                    'MessageTagModal-string-n-time-notification-empty-tooltip',
                  ),
                },
              },
            ];

      const nonPromotionalItems = getTagsGroup(
        data,
        NON_PROMOTIONAL_GROUP_ID,
        t,
      );

      return [
        {
          title: t('MessageTagModal-string--936-promotional-content'),
          id: 'promotionalContent',
          type: 'title',
        },
        ...promotionalItems,
        {
          title: t('MessageTagModal-string-7342-line-1'),
          id: 'line1',
          type: 'divider',
        },
        {
          title: t('MessageTagModal-string-1975-one-time-notification'),
          id: 'oneTimeNotification',
          type: 'title',
        },
        ...otnItems,
        {
          title: t('MessageTagModal-string-7342-line-1'),
          id: 'line2',
          type: 'divider',
        },
        {
          title: t('MessageTagModal-string-n-time-notification'),
          id: 'nTimeNotification',
          type: 'title',
        },
        ...ntnItems,
        {
          title: t('MessageTagModal-string-7342-line-2'),
          id: 'line3',
          type: 'divider',
        },
        {
          title: t('MessageTagModal-string-1793-non-promotional-content'),
          id: 'nonPromotionalContent',
          type: 'title',
        },
        ...nonPromotionalItems,
      ];
    }
    return [];
  }, [
    data,
    oneTimeTagLoadingLoading,
    notificationMessagesTopicsLoading,
    t,
    notificationMessagesTopics,
  ]);

  useEffect(() => {
    setSelectedItem(items.find((item) => item.id === defaultValue));
  }, [items, defaultValue]);

  return (
    <DefaultDialog
      header={t('MessageTagModal-string--131-choose-message-tag')}
      footer={
        <Flex
          justifyContent="space-between"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <TutorialLinks
            variant="default"
            linkItems={[
              {
                text: t('tutorial.FlowTutorial.MessageTagTutorial'),
                href: getLinkByVideoHash(PluginTutorialYoutube.messageTag),
              },
            ]}
          />
          <Spacer />
          <Button
            intent="primary"
            onClick={() => {
              if (selectedItem) {
                sendEvent({
                  category: 'flow',
                  action: 'choose message tag',
                  label: selectedItem.id,
                  propertyBag: {
                    flowId,
                  },
                });
                onChange(selectedItem);
              } else {
                onDismiss?.();
              }
            }}
          >
            {t('MessageTagModal-JSXText-1747-set-up')}
          </Button>
        </Flex>
      }
      onDismiss={() => onDismiss?.()}
    >
      <Type weight="semibold" size="15px_DEPRECATED">
        {t('MessageTagModal-JSXText-9443-message-tag')}
      </Type>
      <Spacer factor={1} />
      {selectedItem ? (
        <SimpleCombobox<MessageTagItem>
          selectedItem={selectedItem}
          renderInput={({ getToggleButtonProps, selectedItem }) => (
            <Autofocus
              render={({ bind }) => (
                <Button
                  {...bind}
                  intent="secondary"
                  {...getToggleButtonProps()}
                  iconRight={<DropdownIcon />}
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  {selectedItem.title}
                </Button>
              )}
            />
          )}
          onChange={(item) => {
            setSelectedItem(item);
          }}
          renderItem={({ getItemProps, item, index, highlightedIndex }) => (
            <Tooltip2
              {...getItemProps({ item })}
              key={item.id}
              content={
                <a
                  href={`${item.tooltip?.url || ''}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={css.tooltipLink}
                  style={
                    item.tooltip?.url ? undefined : { pointerEvents: 'none' }
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    sendEvent({
                      category: 'flow',
                      action: 'tooltip click',
                      label: item.id,
                      propertyBag: {
                        flowId,
                      },
                    });
                  }}
                >
                  {item.tooltip?.html ? (
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.tooltip.html,
                      }}
                    />
                  ) : (
                    item.tooltip?.text
                  )}
                </a>
              }
              placement="right"
              type="small"
              hoverable
              boundariesElement="viewport"
              disabled={!(item?.tooltip?.text || item?.tooltip?.html)}
            >
              {(ref, bind) => (
                <MenuItem
                  {...bind}
                  {...getItemProps({ item })}
                  active={index === highlightedIndex}
                  title={item.title}
                  type={item.type}
                  innerRef={ref}
                  disabled={item.disabled || item.type !== 'item'}
                  style={{ width: '400px' }}
                />
              )}
            </Tooltip2>
          )}
          items={items}
        />
      ) : (
        <div style={{ width: '100%' }}>
          <CenteredLoader />
        </div>
      )}
      <Spacer factor={4} />
    </DefaultDialog>
  );
};
