import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { ImageResize, ImageResizeProps } from './ImageResize';
import { DefaultDialog } from '../../../../../modern-ui/Dialog/DefaultDialog';
import { Button } from '../../../../../modern-ui/Button';
import { LoadingPlaceholder } from '../../../../../modern-ui/LoadingPlaceholder';
import { ServiceMessageType } from '../../../../../modern-ui/ServiceMessage2';
import { toaster, Messages } from '../../../../../services/MessageService';
import * as css from './ImageResize.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

export interface ImageResizeDialogProps
  extends Omit<ImageResizeProps, 'onCrop'> {
  onDismiss(): void;
  onSave(croppedImage: Blob): void;
}

export const ImageResizeDialog: React.FC<ImageResizeDialogProps> = ({
  image,
  ratio,
  onDismiss,
  onSave,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const [loading, setLoading] = useState(false);
  const [croppedImageBlob, setCroppedImageBlob] = useState<Blob | null>(null);

  const handleSave = useCallback(() => {
    setLoading(true);
    if (croppedImageBlob) {
      onSave(croppedImageBlob);
    } else {
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: Messages.somethingWentWrong,
        },
      });
      onDismiss();
    }
  }, [croppedImageBlob, onSave, onDismiss]);

  return (
    <DefaultDialog
      mobileAdaptive={isSmallScreenSize}
      header={window.i18next.t('ImageResizeDialog-string--183-edit-image')}
      footer={
        loading ? (
          <LoadingPlaceholder className={css.buttonPlaceholder} />
        ) : (
          <Button
            className={cn(css.cropButtonMobile)}
            intent="primary"
            onClick={handleSave}
          >
            {window.i18next.t('ImageResizeDialog-JSXText-6104-crop')}
          </Button>
        )
      }
      onDismiss={onDismiss}
      dialogStyle={{ width: isSmallScreenSize ? '100%' : '620px' }}
      contentStyle={{
        height: '480px',
      }}
    >
      <ImageResize image={image} ratio={ratio} onCrop={setCroppedImageBlob} />
    </DefaultDialog>
  );
};
