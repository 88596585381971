import { EntryPointTipsQuery_entryPointTips_sections as Sections } from '@utils/Data/MiniOnboarding/@types/EntryPointTipsQuery';
import isEqual from 'lodash-es/isEqual';
import { log } from 'cf-common/src/logger';
import { useCallback, useEffect, useState } from 'react';
import { useCurrentBotId } from '@utils/Routing';
import {
  ServerStorageItemKeys,
  getServerStorageObservable,
  serverStorageItemGet,
  serverStorageItemSet,
} from '@utils/ServerStorage';
import { getUpdatedSectionsState } from './utils';

export type OnboardingOpenSectionState = Record<
  string,
  Record<string, boolean>
>;

export const useOnboardingOpenSectionState = (
  botId: string,
  initialState?: OnboardingOpenSectionState,
) => {
  const [rawData, setRawData] = useState<OnboardingOpenSectionState>();

  /**
   * По какой-то причине при использовании useServerStorage были нереальные
   * лаги, графкл почему-то обновлял кеши по 0.5 - 1 секунде
   */
  useEffect(() => {
    serverStorageItemGet(ServerStorageItemKeys.onboardingOpenState)
      .then((data) => {
        let newData = data;

        if (!newData?.[botId] && initialState) {
          newData = {
            ...data,
            [botId]: initialState,
          };

          serverStorageItemSet(
            ServerStorageItemKeys.onboardingOpenState,
            newData,
          );
        }

        if (!isEqual(rawData, newData)) {
          setRawData(newData);
        }
      })
      .catch((error) => {
        log.error({ msg: 'Could not fetch onboarding open state', error });
      });
    // eslint-disable-next-line
  }, []);

  // требуется для синхронизации двух инстансов этого хука
  useEffect(() => {
    const subscription = getServerStorageObservable(
      ServerStorageItemKeys.onboardingOpenState,
    ).subscribe((data) => {
      if (!isEqual(rawData, data)) {
        setRawData(data);
      }
    });

    return () => subscription.unsubscribe();
  }, [rawData]);

  const getState = (id: string): boolean => Boolean(rawData?.[botId]?.[id]);
  const setState = (id: string, state: boolean): void => {
    const newData = {
      ...rawData,
      [botId]: {
        ...rawData?.[botId],
        [id]: state,
      },
    };

    setRawData(newData);
    serverStorageItemSet(ServerStorageItemKeys.onboardingOpenState, newData);
  };
  const updateState = (state: OnboardingOpenSectionState['']) => {
    const newData = {
      ...rawData,
      [botId]: state,
    };

    setRawData(newData);
    serverStorageItemSet(ServerStorageItemKeys.onboardingOpenState, newData);
  };
  const toggleState = (id: string) => setState(id, !getState(id));
  const isStateInitialized = (): boolean => Boolean(rawData?.[botId]);

  return {
    getState,
    setState,
    toggleState,
    updateState,
    isStateInitialized,
  };
};

export const useWatchOnboardingSectionCompleted = () => {
  const botId = useCurrentBotId()!;
  const { getState, updateState, isStateInitialized } =
    useOnboardingOpenSectionState(botId);

  const recalculateOpenSectionState = useCallback(
    (sections: Sections[]) => {
      if (!isStateInitialized()) {
        return;
      }

      updateState(getUpdatedSectionsState(sections, getState));
    },
    [getState, isStateInitialized, updateState],
  );

  return { recalculateOpenSectionState };
};
