import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment whatsappTemplatePluginFragment on WhatsappTemplatePlugin {
    ...commonPluginFragment
    config {
      whatsappBusinessAccountId
      managedTemplateId
      managedTemplateActions {
        buttonRedirects {
          counterId
          blockId
        }
      }
      template {
        id
        name
        status
        category
        language
        quality_score {
          score
        }
        components {
          type
          text
          format
          buttons {
            type
            text
            url
          }
        }
      }
      headerParameter {
        type
        text
        document {
          id
          filename
          link
        }
        image {
          id
          link
          filename
        }
        video {
          id
          link
          filename
        }
      }
      bodyParameters {
        type
        text
        currency {
          code
          amount_1000
          fallback_value
        }
        date_time {
          fallback_value
        }
      }
      urlButtonParameter {
        index
        parameter {
          type
          text
        }
      }
      replyButtons {
        index
        button {
          title
          blockId
          counterId
        }
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
