import React from 'react';
import cn from 'classnames';
import { Platform } from '@globals';
import { Icon } from '@ui/Icon';
import * as css from './CheckMark.css';

export interface CheckMarkProps {
  checked: boolean;
  platform: Platform;
}

export const CheckMark: React.FC<CheckMarkProps> = ({ checked, platform }) => (
  <div className={css.checkMark}>
    {checked ? (
      <div
        className={cn(
          css.checkIconContainer,
          {
            [Platform.facebook]: css.facebook,
            [Platform.instagram]: css.instagram,
            [Platform.whatsapp]: css.whatsapp,
          }[platform],
        )}
      >
        <Icon icon="check" color="white" />
      </div>
    ) : (
      <div className={css.uncheck} />
    )}
  </div>
);
