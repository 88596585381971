import React from 'react';
import { ScreenSizes } from '@utils/DOM/useDeviceMedia';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { BotSwitcher, BotSwitcherProps } from '../BotSwitcher';
import * as css from './BotSwitcherError.css';

type SpecifiedProps = 'active' | 'showProBadge' | 'src' | 'icon';

export interface BotSwitcherErrorChildrenProps {
  small?: boolean;
  shortText?: boolean;
}

export interface BotSwitcherErrorProps
  extends Omit<BotSwitcherProps, SpecifiedProps> {
  children: React.ReactNode;
}

export const BotSwitcherError: React.FC<BotSwitcherErrorProps> = ({
  children,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const commonProps = {
    ...props,
    active: false,
    showProBadge: false,
    src: null,
  };

  return {
    [ScreenSizes.small]: (
      <BotSwitcher
        {...commonProps}
        icon={<Icon size="24px" color="redSecondary" icon="warning" />}
      >
        <Type size="15px" color="redSecondary">
          {t('modernComponents.BotSwitcher.paymentFailed')}
        </Type>

        {children}
      </BotSwitcher>
    ),
    [ScreenSizes.medium]: (
      <BotSwitcher
        {...commonProps}
        icon={
          <Flex
            alignItems="center"
            justifyContent="center"
            className={css.iconWrapper}
          >
            <Icon size="24px" color="redSecondary" icon="warning" />
          </Flex>
        }
      >
        {children}
      </BotSwitcher>
    ),
    [ScreenSizes.large]: (
      <BotSwitcher
        {...commonProps}
        icon={<Icon size="16px" color="redSecondary" icon="warning" />}
      >
        <Type size="12px" color="redSecondary">
          {t('modernComponents.BotSwitcher.paymentFailed')}
        </Type>

        {children}
      </BotSwitcher>
    ),
  }[props.screenSize];
};
