import React from 'react';
import { PaymentIntent } from '../types';
import { PaywallPaymentForm } from '../PaywallPaymentForm';
import { RegularPaymentForm } from '../RegularPaymentForm';

interface PaymentFormProps {
  paymentIntent?: PaymentIntent;
  initialPayment?: boolean;
  loading?: boolean;
  submitting?: boolean;
  onSubmit?: () => void;
  onDismiss?: () => void;
}

export const PaymentForm: React.FC<PaymentFormProps> = ({
  paymentIntent,
  ...commonPaymentProps
}) => {
  if (paymentIntent === PaymentIntent.paywall) {
    return <PaywallPaymentForm {...commonPaymentProps} />;
  }
  return <RegularPaymentForm {...commonPaymentProps} />;
};
