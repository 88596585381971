import { PluginType } from '../common/PluginTypes';
import { whatsappTemplatePluginFragment_config as WhatsappTemplatePluginConfig } from './@types/whatsappTemplatePluginFragment';

export const PLUGIN_TYPE = PluginType.whatsapp_template;

export const PLUGIN_DEFAULT_CONFIG: WhatsappTemplatePluginConfig = {
  __typename: 'WhatsappTemplatePluginConfig',
  whatsappBusinessAccountId: null,
  managedTemplateId: null,
  managedTemplateActions: null,
  template: null,
  bodyParameters: [],
  headerParameter: null,
  replyButtons: [],
  urlButtonParameter: null,
};

export const PLUGIN_INPUT_FIELD = 'whatsappTemplatePluginConfig';
