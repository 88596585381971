import React from 'react';
import { OnboardingCallout } from '../../OnboardingCallout/OnboardingCallout';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useCheckWhatsappTemplateCreated } from './useCheckWhatsappTemplateCreated';

interface WANewTemplateProps {
  onComplete(): void;
}

export const WANewTemplate: React.FC<WANewTemplateProps> = ({ onComplete }) => {
  const { t } = useSafeTranslation();
  useCheckWhatsappTemplateCreated({
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (data.checkWhatsappTemplateCreated) onComplete();
    },
  });

  return (
    <OnboardingCallout
      show
      text={t('MiniOnboarding.WANewTemplate.waitingForYou')}
    />
  );
};
