import React, { useCallback, useContext } from 'react';
import cn from 'classnames';
import * as css from './CategoryMenu.css';
import { CategoryContext } from '../context';
import { Category, ICategory } from '../types';
import { CategoryMenuItem } from './CategoryMenuItem';
import { Platform } from '@globals';
import { Spacer } from '@ui/Spacer';

interface CategoryMenuProps {
  categories: ICategory[];
  platform?: Platform;
  onSelect?: (category: Category) => void;
}

export const CategoryMenu: React.FC<CategoryMenuProps> = ({
  categories,
  onSelect,
  platform,
}) => {
  const { activeCategory, setActiveCategory } = useContext(CategoryContext);

  const handleSelect = useCallback(
    (category) => {
      onSelect?.(category);
      setActiveCategory(category);
    },
    [onSelect, setActiveCategory],
  );

  return (
    <ul className={cn(css.resetUl, css.ul)}>
      {categories.map((category, index) => {
        const disable = platform && category.platform !== platform;

        return (
          <li key={category.title} className={css.templateListItem}>
            <CategoryMenuItem
              {...category}
              onSelect={handleSelect}
              active={category.category === activeCategory}
              disable={disable}
            />
            {index !== categories.length - 1 && <Spacer horizontalFactor={2} />}
          </li>
        );
      })}
    </ul>
  );
};
