import { PluginType } from '../common/PluginTypes';
import { instagramDirectEntryPointFragment_config as InstagramDirectEntryPointConfig } from './@types/instagramDirectEntryPointFragment';

export const PLUGIN_TYPE = PluginType.instagram_direct_entry_point;

export const PLUGIN_DEFAULT_CONFIG: InstagramDirectEntryPointConfig = {
  __typename: 'InstagramDirectEntryPointConfig',
  block_id: null,
  fill_ib_with_ig_data: false,
  ice_breakers: [],
  keywords_setup: {
    __typename: 'KeywordsSetup',
    intents: [],
    default_block_id: null,
  },
};

export const PLUGIN_INPUT_FIELD = 'instagramDirectEntryPointConfig';
