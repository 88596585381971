import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { Type, TypeProps as UITypeProps } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Divider } from '@ui/Menu';
import { Spacer } from '@ui/Spacer';
import { WorkspaceStatus } from '@components/Workspace/workspaceStatus';
import * as css from './WorkspaceInfoView.css';
import { ScreenSizes } from '@utils/DOM/useDeviceMedia';

type TypeProps = UITypeProps<HTMLDivElement>;

interface WorkspaceInfoRegularViewProps {
  title: string;
  status: Maybe<WorkspaceStatus>;
  fontSize: TypeProps['size'];
}

const WorkspaceInfoRegularView: React.FC<WorkspaceInfoRegularViewProps> = ({
  fontSize,
  status,
  title,
}) => (
  <Flex flexDirection="column">
    <Divider className={css.divider} />

    <Spacer factor={2} />

    <Flex alignItems="center">
      <TextEllipsis className={css.textEllipsis}>
        <Type as="span" size={fontSize} color="cobaltTertiary">
          {title}
        </Type>
      </TextEllipsis>

      <Type as="span" size={fontSize} color="cobaltTertiary">
        &#160;•&#160;
      </Type>

      {status && (
        <Type as="span" size={fontSize} rawColor={status.color}>
          {status.title}
        </Type>
      )}
    </Flex>
  </Flex>
);

export interface WorkspaceInfoViewProps
  extends Omit<WorkspaceInfoRegularViewProps, 'fontSize'> {
  screenSize: ScreenSizes;
}

export const WorkspaceInfoView: React.FC<WorkspaceInfoViewProps> = (props) => {
  return {
    [ScreenSizes.small]: (
      <WorkspaceInfoRegularView {...props} fontSize="15px" />
    ),
    [ScreenSizes.medium]: null,
    [ScreenSizes.large]: (
      <WorkspaceInfoRegularView {...props} fontSize="12px" />
    ),
  }[props.screenSize];
};
