import React from 'react';
import { BotPageRouteParams, BotTabs, getTabLink } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useParams } from 'react-router-dom';
import { TabNavLinkWithRole } from '../TabLink/TabLink';
import * as css from '../../common.css';
import { NavItemProps, NavItemViewProps } from './types';
import { NavItemView } from '../NavItems/NavItemView';

export const FlowsNavItemView: React.FC<NavItemViewProps> = ({ small }) => {
  const { t } = useSafeTranslation();

  return (
    <NavItemView
      title={t('modernComponents.LeftNavigationPanel.flowsSubTab')}
      small={small}
      icon="tabs_flows"
    />
  );
};

export const FlowsNavItem: React.FC<NavItemProps> = ({
  small,
  onClick,
  className,
  disabled,
}) => {
  const { botId } = useParams<BotPageRouteParams>();

  return (
    <TabNavLinkWithRole
      data-testid="test-nav-to-flows"
      tabUrl={getTabLink(BotTabs.flows, botId)}
      tabEvent="flows"
      domain="flows"
      small={small}
      disabled={disabled}
      onClick={onClick}
      className={className}
      contentClassName={css.linkTooltip}
      activeClassName={css.activeLink}
    >
      <FlowsNavItemView small={small} />
    </TabNavLinkWithRole>
  );
};
