import { mergeDeepWith } from 'ramda';
import { QuickReplyType } from '@globals';
import { PluginType } from '../common/PluginTypes';
import { quickReplyPluginFragment_config } from './@types/quickReplyPluginFragment';

export type QuickReplyPluginConfig = quickReplyPluginFragment_config;

export const PLUGIN_TYPE = PluginType.quick_reply;

export const PLUGIN_DEFAULT_CONFIG: QuickReplyPluginConfig = {
  __typename: 'QuickReplyPluginConfig',
  process_text_by_ai: false,
  type: QuickReplyType.text,
  allow_skip: false,
  property: null,
  input_timeout_millis: null,
  error_messages: {
    __typename: 'QuickReplyErrorMessages',
    text: null,
    number: null,
    phone: null,
    email: null,
    date: null,
    datetime: null,
  },
  phone: {
    __typename: 'FacebookSuggestButtonConfig',
    facebook_suggest: false,
    title: '',
    block_ids: [],
  },
  email: {
    __typename: 'FacebookSuggestButtonConfig',
    facebook_suggest: false,
    title: '',
    block_ids: [],
  },
  skip_button: {
    __typename: 'ButtonConfig',
    title: '',
    block_ids: [],
  },
  buttons: [],
  follow_ups: [],
};

type PartialDeep<T> = {
  [P in keyof T]?: PartialDeep<T[P]>;
};

export const getQuickReplyConfig = mergeDeepWith<
  QuickReplyPluginConfig,
  PartialDeep<QuickReplyPluginConfig>
>((_, b) => b, PLUGIN_DEFAULT_CONFIG);

export const getQuickReplyShortcut = (
  config: PartialDeep<QuickReplyPluginConfig>,
) => ({
  plugin_id: PluginType.quick_reply,
  config: getQuickReplyConfig(config),
});

export const PLUGIN_INPUT_FIELD = 'quickReplyPluginConfig';
