import { useMutation } from '@apollo/react-hooks';
import { SETUP_PREMIUM_PAYMENT_METHOD_MUTATION } from '../queries';

import {
  SetupPremiumPaymentMethodMutation,
  SetupPremiumPaymentMethodMutationVariables,
} from '../@types/SetupPremiumPaymentMethodMutation';
import { WORKSPACES_BILLING_INFO_QUERY } from '@utils/Data/Pricing/queries';
import { PaymentFlowError, PaymentFlowErrorKind } from 'cf-common/src/utils/Stripe/errors';

export const usePremiumPaymentMethodSetup = () => {
  const [invokeSetupPaymentMethod] = useMutation<
    SetupPremiumPaymentMethodMutation,
    SetupPremiumPaymentMethodMutationVariables
  >(SETUP_PREMIUM_PAYMENT_METHOD_MUTATION, {
    refetchQueries: [
      {
        query: WORKSPACES_BILLING_INFO_QUERY,
      },
    ],
  });

  const setupPaymentMethod = async (paymentMethodId: string) => {
    const { data } = await invokeSetupPaymentMethod({
      variables: {
        paymentMethodId,
      },
    });

    const stripeError = data?.setupPremiumPaymentMethod?.stripeError;

    if (stripeError) {
      throw new PaymentFlowError(stripeError.description, {
        kind: PaymentFlowErrorKind.stripeDecline,
        declineCode: stripeError.decline_code || stripeError.code,
      });
    }
  };

  return { setupPaymentMethod };
};
