import React, { createContext, useContext, useMemo, useState } from 'react';
import { FLOW_BUILDER_ROOT_ID } from '@components/FlowBuilder/consts';
import { EntryPointTipsQuery_entryPointTips_sections_entryPoints_onboardingTour as OnboardingTour } from '@utils/Data/MiniOnboarding/@types/EntryPointTipsQuery';
import { useFlowTourQuery } from '@utils/Data/MiniOnboarding/useFlowTourQuery';
import { useCurrentFlowId } from '@utils/Routing';
import Maybe from 'graphql/tsutils/Maybe';
import noop from 'lodash-es/noop';
import { useLocation } from 'react-router-dom';

type OnbordingContextValue = {
  /**
   * элемент canvas в котором отрисовывается flow
   */
  flowBuilderRootElement: HTMLElement | null;
  /**
   * тур который запускается в онбординге
   */
  onboardingTour: Maybe<OnboardingTour>;
  loading: boolean;
  setTipsOnboardingTour(tipsOnboardingTour: Maybe<OnboardingTour>): void;
  setFlowBuilderReady(isReady: boolean): void;
  setFlowBuilderElementId(flowBuilderElementId: string): void;
};

const OnboardingContext = createContext<OnbordingContextValue>({
  flowBuilderRootElement: null,
  onboardingTour: null,
  loading: true,
  setTipsOnboardingTour: noop,
  setFlowBuilderReady: noop,
  setFlowBuilderElementId: noop,
});

export const useOnbordingContext = () => useContext(OnboardingContext);

export const OnboardingProvider: React.FC = ({ children }) => {
  /**
   * на вкладке /flow flowTour запрашивается через useFlowTourQuery
   * TODO: перенести это на бекенде в запрос tips
   */
  const routeFlowId = useCurrentFlowId();
  const { data, loading: onboardingTourDataLoading } =
    useFlowTourQuery(routeFlowId);
  /**
   * конец отрисовки flow на canvas, до этого момента мы не сможем найти элемент и отрисовать flowTour
   */
  const [flowBuilderReady, setFlowBuilderReady] = useState<boolean>(false);
  /**
   * на вкладке /flow flowTour позиционируется на экране относительно flowBuilderRootElement
   */
  const [flowBuilderElementId, setFlowBuilderElementId] =
    useState<string>(FLOW_BUILDER_ROOT_ID);
  const location = useLocation();
  const flowBuilderRootElement = useMemo(
    () => document.querySelector<HTMLElement>(`#${flowBuilderElementId}`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowBuilderElementId, flowBuilderReady, location],
  );
  /**
   * на всех вкладках по мимо /flow onboardingTour приходит в tips
   */
  const [tipsOnboardingTour, setTipsOnboardingTour] =
    useState<Maybe<OnboardingTour>>(null);
  const flowOnboardingTour = data?.onboardingTour;

  const loading =
    onboardingTourDataLoading ||
    (Boolean(flowBuilderRootElement) && !flowBuilderReady);

  const value = useMemo<OnbordingContextValue>(
    () => ({
      flowBuilderRootElement,
      onboardingTour: tipsOnboardingTour ?? flowOnboardingTour,
      loading,
      setTipsOnboardingTour,
      setFlowBuilderReady,
      setFlowBuilderElementId,
    }),
    [
      flowBuilderRootElement,
      tipsOnboardingTour,
      loading,
      flowOnboardingTour,
      setFlowBuilderElementId,
      setTipsOnboardingTour,
      setFlowBuilderReady,
    ],
  );

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};
