import gql from 'graphql-tag';
import {
  COMMON_PLUGIN_FRAGMENT,
  LOCALIZATION_FRAGMENT,
} from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment sendEmailPluginFragment on SendEmailPlugin {
    ...commonPluginFragment
    config {
      body
      emails
      title
    }
    localization {
      ...localizationFragment
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
  ${LOCALIZATION_FRAGMENT}
`;
