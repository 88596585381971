import React from 'react';
import { compose } from 'ramda';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Input } from '../../modern-ui/Input';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { ShimmerText } from '../../modern-ui/ShimmerText';
import { Autofocus } from '../../modern-ui/Autofocus';
import { getCurrentBlocks } from '../../utils/BlocksUtils';
import {
  BLOCKS_QUERY as BlocksQuery,
  BLOCKS_QUERYVariables,
  BLOCKS_QUERY_bot_archiveBlocks as Block,
} from './@types/BLOCKS_QUERY';

interface IOneBlockSelectorProps {
  botId: string;
  blockId: string;
  onBlockSelected: (blockId: string) => void;
  autoFocus?: boolean;
  placeholder?: string;
}

const BLOCKS_QUERY = gql`
  query BLOCKS_QUERY($botId: String) {
    bot(id: $botId) {
      id
      archiveBlocks {
        id
        title
        removed
        reachable
      }
    }
  }
`;

export const OneBlockSelector: React.SFC<IOneBlockSelectorProps> = ({
  botId,
  blockId,
  onBlockSelected,
  placeholder = window.i18next.t(
    'OneBlockSelector-string-1609-enter-block-name',
  ),
  autoFocus = false,
}) => (
  <Query<BlocksQuery, BLOCKS_QUERYVariables>
    key={botId}
    query={BLOCKS_QUERY}
    variables={{ botId }}
    fetchPolicy="cache-and-network"
  >
    {({ error, loading, data }) => {
      if (error) {
        return (
          <span>
            {window.i18next.t('OneBlockSelector-JSXText-1556-some-error')}
          </span>
        );
      }
      const fetchingBlocks =
        loading && (!data || !data.bot || !data.bot.archiveBlocks);
      let items: Block[] = [];
      if (!fetchingBlocks && data) {
        items = getCurrentBlocks(data.bot.archiveBlocks);
      }
      const selectedItem = items.find((block) => block.id === blockId) || null;
      return (
        <SimpleCombobox
          onChange={(item) => item && onBlockSelected(item.id)}
          items={items}
          initialSelectedItem={selectedItem}
          key={fetchingBlocks ? 'wait' : 'ready'}
          renderInput={({
            getInputProps,
            openMenu,
            ref,
            selectInputValue,
            selectItem,
          }) => (
            <Autofocus
              shouldFocus={!fetchingBlocks && autoFocus}
              render={({ bind }) => (
                <Input
                  {...getInputProps({
                    ref: fetchingBlocks
                      ? undefined
                      : (n: HTMLInputElement) => {
                          ref(n);
                          bind.ref(n);
                        },
                    onFocus: compose(openMenu, selectInputValue),
                    onChange: () => {
                      selectItem(null);
                    },
                  })}
                  name="blockId"
                  tabIndex={autoFocus ? 1 : -1}
                  placeholder={placeholder}
                  required
                  autoComplete="new-block-id"
                  render={
                    fetchingBlocks && !selectedItem
                      ? () => (
                          <div>
                            <ShimmerText
                              play
                              style={{
                                paddingLeft: 12,
                                fontSize: 15,
                                lineHeight:
                                  'var(--control-decorator-min-height)',
                              }}
                            >
                              {window.i18next.t(
                                'OneBlockSelector-JSXText-6163-loading-blocks-list',
                              )}
                            </ShimmerText>
                          </div>
                        )
                      : undefined
                  }
                />
              )}
            />
          )}
        />
      );
    }}
  </Query>
);
