import React from 'react';
import { pathOr } from 'ramda';
import { ReactComponent as DotsIcon } from '../../../modern-ui/_deprecated/Icon/icons/3dots.svg';
import { Item, SimpleCombobox } from '@ui/SimpleCombobox';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
} from '../../../modern-ui/_deprecated/Button';
import { TooltipPure } from '../../../modern-ui/Tooltip2';
import { MenuItem } from '../../../modern-ui/Menu';

interface OptionPopupMenu {
  onDeleteRequest: () => void;
  showTooltip: boolean;
}

enum ActionType {
  delete = 'delete',
}

const items: () => Item[] = () => [
  {
    id: ActionType.delete,
    title: window.i18next.t('OptionPopupMenu-string--128-delete-variant'),
  },
];

const itemToString = pathOr('', ['title']);

export const OptionPopupMenu: React.FC<OptionPopupMenu> = ({
  onDeleteRequest,
  showTooltip,
}) => (
  <SimpleCombobox
    itemToString={itemToString}
    renderInput={({ getToggleButtonProps }) => (
      <Button
        {...getToggleButtonProps()}
        renderIcon={() => <DotsIcon />}
        intent={ButtonIntent.primary}
        colorWay={ButtonColorWay.white}
        size={ButtonSize.s}
        tall
        style={{ boxShadow: 'none', margin: '-5px 0' }}
      />
    )}
    items={items()}
    onChange={(item) => {
      if (!item) {
        return;
      }
      if (item.id === ActionType.delete) {
        onDeleteRequest();
      }
    }}
    menuboxStyle={{ width: '240px' }}
    unsaveSelectedOption
    renderItem={({ getItemProps, item, index, highlightedIndex }) => (
      <TooltipPure
        key={item.id}
        content={window.i18next.t(
          'OptionPopupMenu-string-1995-deleting-a-variant-may-affect-test-results',
        )}
        show={showTooltip && index === highlightedIndex}
        placement="right"
        type="small"
        disableFlip
      >
        {(ref) => (
          <MenuItem
            {...getItemProps({ item })}
            active={index === highlightedIndex}
            title={itemToString ? itemToString(item) : item.title}
            innerRef={ref}
          />
        )}
      </TooltipPure>
    )}
  />
);
