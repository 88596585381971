import gql from 'graphql-tag';
import {
  COMMON_PLUGIN_FRAGMENT,
  KEYWORDS_SETUP_FRAGMENT,
} from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment instagramCommentsAutoreplyEntryPointFragment on InstagramCommentsAutoreplyEntryPoint {
    ...commonPluginFragment
    enabled
    config {
      apply_for_all_posts
      posts {
        id
        ig_id
        media_type
        permalink
        media_url
        caption
        timestamp
      }
      already_subscribed_setup_enabled
      keywords_setup {
        ...keywordsSetupFragment
      }
      already_subscribed_keywords_setup {
        ...keywordsSetupFragment
      }
    }
  }
  ${KEYWORDS_SETUP_FRAGMENT}
  ${COMMON_PLUGIN_FRAGMENT}
`;
