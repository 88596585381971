import React from 'react';
import cn from 'classnames';
import { ReactComponent as LineSvg } from './line.svg';
import { Platform } from '@globals';
import { FadeInOutOpacity } from '@components/Onboarding/OnboardingTour/FadeInOutOpacity/FadeInOutOpacity';
import css from './Line.css';

interface LineProps {
  platform: Platform;
  enabled: boolean;
  extended: boolean;
}

export const Line: React.FC<LineProps> = ({ platform, enabled, extended }) => {
  return (
    <FadeInOutOpacity show={extended}>
      <div className={cn(css.container)}>
        <LineSvg
          className={cn(
            css.line,
            enabled && css.enabled,
            {
              [Platform.instagram]: css.instagram,
              [Platform.facebook]: css.facebook,
              [Platform.whatsapp]: css.whatsapp,
            }[platform],
          )}
        />
      </div>
    </FadeInOutOpacity>
  );
};
