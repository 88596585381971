import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { ReactComponent as Laptop } from './laptop.svg';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import css from './SwitchingDesktopDialog.css';

interface SwitchingDesktopDialogProps {
  close: VoidFunction;
  resolve: VoidFunction;
}

export const SwitchingDesktopDialog: React.FC<SwitchingDesktopDialogProps> = ({
  close,
  resolve,
}) => {
  const { t } = useSafeTranslation();

  return (
    <DefaultDialog onDismiss={close} mobileAdaptive>
      <Flex flexDirection="column" alignItems="center">
        <Laptop />
        <Spacer factor={3} />
        <Type size="24px" weight="semibold">
          {t('MiniOnboarding.SwitchingDesktopDialog.trySwitchingDesktop')}
        </Type>
        <Spacer factor={2} />
        <Type size="15px">
          {t('MiniOnboarding.SwitchingDesktopDialog.someFeaturesPageAvailable')}
        </Type>
        <Spacer factor={6} />
        <Button
          onClick={() => {
            resolve();
          }}
          className={css.showAnyway}
        >
          {t('MiniOnboarding.SwitchingDesktopDialog.showAnyway')}
        </Button>
      </Flex>
    </DefaultDialog>
  );
};
