import React from 'react';
import cn from 'classnames';
import { Spacer } from '@ui/Spacer';
import {
  Platform,
  OnboardingTipsStatus,
  BusinessVerificationStatus,
  EntryPointTooltip,
} from '@globals';
import { FadeInOutOpacity } from '@components/Onboarding/OnboardingTour/FadeInOutOpacity/FadeInOutOpacity';
import { Flex } from '@ui/Flex';
import { CheckMark } from './components/CheckMark';
import { Header } from './components/Header';
import { Description } from './components/Description';
import { Information } from './components/Information';
import { ActionButton } from './components/ActionButton';
import css from './OnboardingListItemView.css';

export interface OnboardingListItemViewProps {
  hasButton: boolean;
  status: OnboardingTipsStatus;
  containerClassName?: string;
  platform: Platform;
  title: string;
  description: string | null;
  verificationStatus: BusinessVerificationStatus | null;
  tooltip: EntryPointTooltip | null;
  lineToBottle?: React.ReactNode;
  handleOnbordingSetUpClick: VoidFunction;
  active: boolean;
}

export const OnboardingListItemView: React.FC<OnboardingListItemViewProps> = ({
  hasButton,
  status,
  handleOnbordingSetUpClick,
  containerClassName,
  platform,
  title,
  description,
  verificationStatus,
  tooltip,
  lineToBottle,
  active,
}) => {
  const actionButton = (
    <ActionButton
      active={active}
      hasButton={hasButton}
      status={status}
      handleOnbordingSetUpClick={handleOnbordingSetUpClick}
    />
  );

  return (
    <Flex
      alignItems="flex-start"
      className={cn(css.container, containerClassName)}
    >
      <CheckMark
        checked={status === OnboardingTipsStatus.done}
        platform={platform}
      />

      <Flex flexDirection="column" className={css.textContainer}>
        <Header
          title={title}
          verificationStatus={verificationStatus}
          status={status}
        />

        {description && (
          <>
            <Spacer factor={2} />
            <Description
              description={description}
              status={status}
              verificationStatus={verificationStatus}
            />
          </>
        )}

        {tooltip === EntryPointTooltip.WhatsappBusinessVerificationRequired && (
          <>
            <Spacer factor={3} />
            <Information />
          </>
        )}
      </Flex>
      <Spacer factor={0} horizontalFactor={0} />

      <FadeInOutOpacity show={Boolean(actionButton)}>
        <Flex className={css.actionContainer} gapFactor={2} alignItems="center">
          {actionButton}
        </Flex>
      </FadeInOutOpacity>
      {lineToBottle}
    </Flex>
  );
};
