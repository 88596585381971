import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Color } from '@ui/_common/colors';
import { BotsHeader, BotsHeaderProps } from './BotsHeader';

export interface Status {
  title: string;
  color: Color;
}

export interface WorkspaceHeaderProps extends BotsHeaderProps {
  status: Maybe<Status>;
}

export const WorkspaceHeader: React.FC<WorkspaceHeaderProps> = ({
  title,
  status,
}) => (
  <BotsHeader title={title}>
    <Spacer factor={0} horizontalFactor={2} />
    <Type size="15px" color="white">
      ·
    </Type>

    {status && (
      <>
        <Spacer factor={0} horizontalFactor={2} />
        <Type size="15px" rawColor={status.color}>
          {status.title}
        </Type>
      </>
    )}
  </BotsHeader>
);
