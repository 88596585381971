import { PluginType } from '../common/PluginTypes';
import { publicReplyEntryPointEntryPointFragment_config } from './@types/publicReplyEntryPointEntryPointFragment';

export type PublicReplyEntryPointConfig =
  publicReplyEntryPointEntryPointFragment_config;

export const PLUGIN_TYPE = PluginType.public_reply_entry_point;

export const PLUGIN_DEFAULT_CONFIG: PublicReplyEntryPointConfig = {
  __typename: 'PublicReplyEntryPointConfig',
  replies: [
    {
      __typename: 'PublicReplyContent',
      message: '',
      attachment_id: null,
      attachment_share_url: null,
      attachment_url: null,
      source: null,
    },
  ],
  enabled: true,
  delay_ms: 0,
};

export const PLUGIN_INPUT_FIELD = 'publicReplyEntryPointConfig';
