import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment datePickerPluginFragment on DatePickerPlugin {
    ...commonPluginFragment
    config {
      property
      type
      allow_skip
      date_button {
        title
        block_ids
      }
      skip_button {
        title
        block_ids
      }
      qr_card_id
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
