import { OnboardingZone } from '../../types';
import { getShapeName } from './getShapeName';

export const getZoneId = (zone: OnboardingZone, deep?: boolean) => {
  // eslint-disable-next-line no-underscore-dangle
  if (zone.__typename === 'OnboardingTourFlowBuilderZone') {
    const shapeName = getShapeName(zone);
    if (!deep) return zone.blockId ?? zone.cardId ?? shapeName;
    if (shapeName) return shapeName;
    return zone.cardId ?? zone.blockId;
  }
  return `${zone.htmlElementId}${zone.elementIndex ?? ''}`;
};
