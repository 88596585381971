import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment popupEntryPointFragment on PopupEntryPoint {
    ...commonPluginFragment
    config {
      integration_type
      block_id
      separate_response_for_existing_users
      existing_user_block_id
      shopify_popup {
        enabled
        title
        description
        button_text
        size
        color
        block_ids
        included_pages
        excluded_urls
        trigger {
          type
          idle_timeout_seconds
        }
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
