import React, { CSSProperties } from 'react';
import OutsideClick from 'react-outsideclick';
import cn from 'classnames';
import { useWhiteLabelAgencyStyles } from '@utils/WhiteLabel/useWhiteLabelAgencyStyles';
import * as css from '../../common.css';
import {
  HoverTabEventBox,
  HoverTabEventBoxProps,
} from '../HoverTabEventBox/HoverTabEventBox';
import { Portal } from 'react-portal';
import { Manager, Popper, Reference } from 'react-popper';
import { useTooltip } from '@ui/Tooltip2';

export type TabContainerType<T extends {} = {}> = React.FC<
  { className?: string } & T
>;

interface TabViewProps extends TestLocator {
  className?: string;
  contentClassName?: string;
  isContentLink?: boolean;
  submenu?: JSX.Element | null;
  submenuStyles?: CSSProperties;
  submenuClassName?: string;
  container: TabContainerType;
  disabled?: boolean;
}

const TabView: React.FC<TabViewProps> = ({
  className,
  contentClassName,
  isContentLink = true,
  submenu,
  submenuStyles,
  submenuClassName,
  container: Container,
  children,
  disabled,
}) => {
  const [show, setShow] = useTooltip(100, 100);

  const close = () => setShow(false);
  const open = () => setShow(true);
  const toggle = () => (show ? close() : open());

  return (
    <OutsideClick onClickOutside={() => show && close()}>
      <Manager>
        <Reference>
          {({ ref }) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              ref={ref}
              className={cn(
                css.link,
                css.linkWrapper,
                disabled && css.disabled,
                submenuClassName,
              )}
              onMouseLeave={close}
              onMouseEnter={open}
              onClick={toggle}
            >
              <Container className={className}>{children}</Container>
              {submenu && show && (
                <Portal>
                  <Popper positionFixed placement="right-start">
                    {({ ref, style }) => (
                      <div
                        ref={ref}
                        className={css.submenuContentWrapper}
                        style={style}
                      >
                        <div
                          className={css.submenuContent}
                          style={submenuStyles}
                        >
                          {isContentLink ? (
                            <Container
                              className={cn(contentClassName, submenuClassName)}
                            >
                              {submenu}
                            </Container>
                          ) : (
                            submenu
                          )}
                        </div>
                      </div>
                    )}
                  </Popper>
                </Portal>
              )}
            </div>
          )}
        </Reference>
      </Manager>
    </OutsideClick>
  );
};

const StyledTabSubmenu: React.FC<TabViewProps> = (props) => {
  const { data } = useWhiteLabelAgencyStyles();

  const whiteLabelUserStyles = {
    backgroundColor: data?.navigationColor,
  };

  const submenuStyles = {
    ...props.submenuStyles,
    ...whiteLabelUserStyles,
  };

  return <TabView {...props} submenuStyles={submenuStyles} />;
};

export interface TabViewWithEventProps
  extends HoverTabEventBoxProps,
    TabViewProps {}

export const TabViewWithEvent: React.FC<TabViewWithEventProps> = ({
  send,
  tabEvent,
  ...rest
}) => (
  <HoverTabEventBox send={send} tabEvent={tabEvent}>
    <StyledTabSubmenu {...rest} />
  </HoverTabEventBox>
);
