import { facebookShopsEntryPointFragment_config } from './@types/facebookShopsEntryPointFragment';
import { PluginType } from '../common/PluginTypes';

export type FacebookShopsEntryPointConfig = facebookShopsEntryPointFragment_config;

export const PLUGIN_TYPE = PluginType.facebook_shops_entry_point;

export const PLUGIN_DEFAULT_CONFIG: FacebookShopsEntryPointConfig = {
  __typename: 'FacebookShopsEntryPointConfig',
  products: [],
  apply_for_all_products: false,
  already_subscribed_setup_enabled: false,
  keywords_setup: {
    __typename: 'KeywordsSetup',
    intents: [],
    default_block_id: null,
  },
  already_subscribed_keywords_setup: {
    __typename: 'KeywordsSetup',
    intents: [],
    default_block_id: null,
  },
};

export const PLUGIN_INPUT_FIELD = 'facebookShopsEntryPointConfig';
