import React from 'react';
import { Flex } from '@ui/Flex';
import { IconButton } from '@ui/IconButton';
import { Type } from '@ui/Type';
import { AvatarWithPlatform } from '@ui/Avatar';
import { Spacer } from '@ui/Spacer';
import { Item, SimpleCombobox } from '@ui/SimpleCombobox';
import { LiveChatMobilePage } from '@pages/LiveChat/useLiveChatMobilePageNavigation';
import { useLiveChatMoveConversation } from '@pages/LiveChat/useLiveChatMoveConversation';
import { Folder, Platform } from '@globals';
import { LivechatSubscriberQuery_livechatConversation_subscriber } from '@pages/LiveChat/common/@types/LivechatSubscriberQuery';
import css from './ChatHeader.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';

enum Actions {
  showDetails = 'showDetails',
  moveToClosed = 'moveToClosed',
  moveToOpen = 'moveToOpen',
}

interface ChatHeaderProps {
  setActivePage(page: LiveChatMobilePage): void;
  folder?: Folder | null;
  platform: Platform;
  subscriber?: LivechatSubscriberQuery_livechatConversation_subscriber;
}

type MunuItem = Item & { id: Actions; onSelect: VoidFunction };

export const ChatHeader: React.FC<ChatHeaderProps> = ({
  setActivePage,
  folder,
  platform,
  subscriber,
}) => {
  const { t } = useSafeTranslation();
  const { moveConversationToClose, moveConversationToOpen } =
    useLiveChatMoveConversation({});

  const getMenuItems = () => {
    const menuItems: Array<MunuItem> = [
      {
        id: Actions.showDetails,
        title: t('pages.LiveChat.pageHeader.showDetails'),
        onSelect: () => setActivePage(LiveChatMobilePage.attributes),
      },
    ];

    if (folder === Folder.inbox)
      menuItems.push({
        id: Actions.moveToClosed,
        title: t('pages.LiveChat.pageHeader.moveClosed'),
        onSelect: () => moveConversationToClose(),
      });

    if (folder === Folder.closed)
      menuItems.push({
        id: Actions.moveToOpen,
        title: t('pages.LiveChat.pageHeader.moveOpen'),
        onSelect: () => moveConversationToOpen(),
      });

    return menuItems;
  };

  return (
    <Flex
      justifyContent="space-between"
      className={css.root}
      alignItems="center"
    >
      <Flex alignItems="center">
        <IconButton
          icon="leftArrow"
          color="white"
          onClick={() => {
            setActivePage(LiveChatMobilePage.chatList);
          }}
        />
        <Type
          className={css.liveChatTitle}
          size="15px"
          weight="medium"
          color="white"
          whiteSpace="nowrap"
        >
          {t('pages.LiveChat.pageHeader.liveChat')}
        </Type>
      </Flex>
      <Flex alignItems="center" className={css.nameContainer}>
        <Type
          className={css.name}
          size="15px"
          weight="medium"
          color="white"
          whiteSpace="nowrap"
        >
          {subscriber?.name}
        </Type>
        <Spacer horizontalFactor={2} />
        <AvatarWithPlatform
          size={32}
          containerClassName={css.avatarContainer}
          src={subscriber?.avatarUrl ?? null}
          platform={platform}
        />
        <SimpleCombobox
          position="bottom-end"
          ignoreParentsOverflow
          onSelect={(item) => item?.onSelect()}
          renderInput={({ ref, getToggleButtonProps }) => {
            return (
              <IconButton
                {...getToggleButtonProps()}
                ref={ref}
                size="20px"
                icon="more"
                color="white"
              />
            );
          }}
          items={getMenuItems()}
        />
      </Flex>
    </Flex>
  );
};
