import { PaymentFlowErrorData } from 'cf-common/src/utils/Stripe/errors';

export enum PaymentIntent {
  paywall = 'paywall',
  regular = 'regular',
}

export interface PaymentDialogProps
  extends Pick<PaymentFlowProps, 'renderPaymentError' | 'setupPayment'> {
  initialPayment?: boolean;
  paymentIntent?: PaymentIntent;
  onClose(): void;
  onSuccess?(): void;
}

export interface PaymentFlowProps {
  paymentIntent?: PaymentIntent;
  initialPayment: boolean;
  onDismiss(): void;
  onSuccess?(): void;
  renderPaymentError?(props: PaymentErrorProps): JSX.Element;
  setupPayment?: SetupPayment;
}

export interface SetupPayment {
  (paymentMethodId: string): Promise<void>;
}

export interface PaymentErrorProps {
  error?: PaymentFlowErrorData;
  onDismiss(): void;
  onTryAgain(): void;
}
