import React from 'react';
import cn from 'classnames';
import { BotPageRouteParams, BotTabs, getTabLink } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useParams } from 'react-router-dom';
import { TabNavLinkWithRole } from '../TabLink/TabLink';
import * as css from '../../common.css';
import { NavItemProps, NavItemViewProps } from './types';
import { NavItemView } from '../NavItems/NavItemView';
import { MessageTemplatesNavSubItems } from './MessageTemplatesNavSubItems';

export const MessagesTemplatesNavItemView: React.FC<NavItemViewProps> = ({
  small,
}) => {
  const { t } = useSafeTranslation();

  return (
    <NavItemView
      title={t('modernComponents.LeftNavigationPanel.messagesTemplates')}
      small={small}
      icon="tabs_reEngage"
    />
  );
};

export const MessagesTemplatesNavItem: React.FC<NavItemProps> = ({
  small,
  onClick,
  className,
  disabled,
}) => {
  const { botId } = useParams<BotPageRouteParams>();

  return (
    <TabNavLinkWithRole
      data-testid="test-nav-to-broadcasts"
      tabUrl={getTabLink(BotTabs.messagesTemplates, botId)}
      tabEvent="messages-templates"
      domain="broadcasting"
      className={className}
      small={small}
      onClick={onClick}
      disabled={disabled}
      contentClassName={cn(css.linkTooltip)}
      activeClassName={cn(css.activeLink)}
      isContentLink={false}
      submenu={
        small ? (
          <MessageTemplatesNavSubItems
            className={css.linkTooltip}
            small={small}
          />
        ) : null
      }
    >
      <MessagesTemplatesNavItemView small={small} />
    </TabNavLinkWithRole>
  );
};
