import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from 'react-apollo';
import {
  EntryPointTipsSelfSubscriptionQuery,
  EntryPointTipsSelfSubscriptionQueryVariables,
} from './@types/EntryPointTipsSelfSubscriptionQuery';

const ENTRY_POINTS_TIPS_SELF_SUBSCRIPTION_QUERY = gql`
  query EntryPointTipsSelfSubscriptionQuery(
    $botId: String!
    $text: String!
    $from: String!
  ) {
    entryPointTipsSelfSubscription(botId: $botId, text: $text, from: $from) {
      subscribed
    }
  }
`;

export const useEntryPointTipsSubscribeToBotCompleted = (
  options: QueryHookOptions<
    EntryPointTipsSelfSubscriptionQuery,
    EntryPointTipsSelfSubscriptionQueryVariables
  >,
) => {
  const { botId, from, text } = options.variables || {};

  return useQuery<
    EntryPointTipsSelfSubscriptionQuery,
    EntryPointTipsSelfSubscriptionQueryVariables
  >(ENTRY_POINTS_TIPS_SELF_SUBSCRIPTION_QUERY, {
    skip: !botId || !from || !text,
    pollInterval: 3000,
    ...options,
  });
};
