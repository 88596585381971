import { PluginType } from '../common/PluginTypes';
import { commentsLikePluginConfigFragment_config } from './@types/commentsLikePluginConfigFragment';

export type CommentsAutoreplyEntryPointConfig =
  commentsLikePluginConfigFragment_config;

export const PLUGIN_TYPE = PluginType.comments_like;

export const PLUGIN_DEFAULT_CONFIG: CommentsAutoreplyEntryPointConfig = {
  __typename: 'CommentsLikePluginConfig',
  likeComment: false,
};

export const PLUGIN_INPUT_FIELD = 'commentsLikePluginConfig';
