import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment inboundLinksEntryPointFragment on InboundLinksEntryPoint {
    ...commonPluginFragment
    config {
      flows {
        id
        title
        blocks {
          id
          title
          counter_id
          card_id
        }
      }
      blocks {
        id
        title
        redirects {
          counter_id
          card_id
        }
        buttons {
          counter_id
          title
          card_id
        }
        quick_replies {
          counter_id
          title
          card_id
        }
      }
      broadcasts {
        id
        title
        block {
          id
          title
          type
          buttons {
            counter_id
            title
            card_id
          }
          quick_replies {
            counter_id
            title
            card_id
          }
        }
      }
      last_refresh_date
    }
    enabled
    refresh
    synced
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
