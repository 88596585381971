import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment googleSheetPluginFragment on GoogleSheetPlugin {
    ...commonPluginFragment
    config {
      spreadsheet_id
      spreadsheet_title
      owner_id
      user_attributes
      use_all_attributes
      timezone
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
