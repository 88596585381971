import { PluginType } from '../common/PluginTypes';
import { instagramStoryMentionEntryPointFragment_config as InstagramStoryMentionEntryPointConfig } from './@types/instagramStoryMentionEntryPointFragment';

export const PLUGIN_TYPE = PluginType.instagram_story_mention_entry_point;

export const PLUGIN_DEFAULT_CONFIG: InstagramStoryMentionEntryPointConfig = {
  __typename: 'InstagramStoryMentionEntryPointConfig',
  block_id: null,
};

export const PLUGIN_INPUT_FIELD = 'instagramStoryMentionEntryPointConfig';
