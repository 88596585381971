import React from 'react';
import { Anchor } from '@ui/Links';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { VERIFICATION_GUIDE_LINK } from '@components/Onboarding/inititalModals/WhatsappBusinessVerification/consts';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './Information.css';

export const Information = () => {
  const { t } = useSafeTranslation();

  return (
    <Flex gapFactor={1} alignItems="center">
      <Icon size="16px" icon="circleWarning" color="cobaltTertiary" />
      <Type color="white" size="12px" weight="medium">
        {t('MiniOnboarding.banner.youNeedTo')}
      </Type>
      <Anchor
        intent="external"
        target="_blank"
        size="small"
        href={VERIFICATION_GUIDE_LINK}
        noUnderline
        hideArrow
        className={css.anchor}
      >
        {t('MiniOnboarding.banner.verifyYourBusiness')}
      </Anchor>
    </Flex>
  );
};
