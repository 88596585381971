import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment whatsappLocationPluginFragment on WhatsappLocationPlugin {
    ...commonPluginFragment
    config {
      longitude
      latitude
      name
      address
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
