import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment openAiPluginFragment on OpenAiPlugin {
    ...commonPluginFragment
    config {
      intents {
        key
        value
      }
      prompt
      liveChatPrompt
      saveToVariable
      goodAnswerNextBlock
      poorAnswerNextBlock
      liveChatAnswerNextBlock
      messagesHistory
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
