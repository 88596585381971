import React from 'react';
import { NavItemViewProps } from '../Tabs/types';
import { NavItemBigView, NavItemBigViewProps } from './NavItemBigView';
import { NavItemSmallView, NavItemSmallViewProps } from './NavItemSmallView';

type Props = NavItemBigViewProps & NavItemSmallViewProps & NavItemViewProps;

export const NavItemView: React.FC<Props> = ({
  small,
  title,
  icon,
  children,
  color,
}) =>
  small ? (
    <NavItemSmallView color={color} icon={icon}>
      {children}
    </NavItemSmallView>
  ) : (
    <NavItemBigView color={color} title={title} icon={icon}>
      {children}
    </NavItemBigView>
  );
