import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { VideoDialog } from '../VideoDialog/VideoDialog';

interface WhatsappTemplateProps {
  onDismiss: VoidFunction;
  onStart: VoidFunction;
}

export const WhatsappTemplate: React.FC<WhatsappTemplateProps> = ({
  onDismiss,
  onStart,
}) => {
  const { t } = useSafeTranslation();
  return (
    <VideoDialog
      buttonText={t('MiniOnboarding.WhatsappTemplate.button')}
      title={t('MiniOnboarding.WhatsappTemplate.title')}
      description={t('MiniOnboarding.WhatsappTemplate.description')}
      videoId="NvV5YpEfhSs"
      onDismiss={onDismiss}
      onComplete={onStart}
    />
  );
};
