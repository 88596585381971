import React from 'react';
import { ToasterProvider } from '@ui/Toaster';
import { LivechatSettingsContextProvider } from '@utils/Data/LiveChat/Settings/context';
import {
  AutomatedMessagesDialog,
  AutomatedMessagesDialogProps,
} from './AutomatedMessagesDialog';

export const AutomatedMessagesDialogContainer = (
  props: AutomatedMessagesDialogProps,
) => {
  return (
    <ToasterProvider>
      <LivechatSettingsContextProvider botId={props.botId}>
        <AutomatedMessagesDialog {...props} />
      </LivechatSettingsContextProvider>
    </ToasterProvider>
  );
};
