import React, { useCallback, useEffect } from 'react';
import { OnboardingTipsStatus } from '@globals';
import { useCurrentBotId } from '@utils/Routing';
import { waitFoTheReturnToTab } from '@utils/Event/waitFoTheReturnToTab';
import { useEntryPointTipsLazyQuery } from '@utils/Data/MiniOnboarding/useEntryPointTipsQuery';
import { EntryPointTipsQuery_entryPointTips_sections as Section } from '@utils/Data/MiniOnboarding/@types/EntryPointTipsQuery';
import { useBillingPaymentMethodsLink } from '@utils/Data/Whatsapp';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { CenteredLoader } from '@ui/Loader';
import imageSrc from './image.png';
import * as css from './WhatsappAddMetaPaymentMethod.css';

const ADD_META_PAYMENT_METHOD = 'add_meta_payment_method';

const metaPaymentMethodTip = (sections: Section[]) =>
  sections
    .flatMap((section) => section.entryPoints)
    .find((ep) => ep?.onboardingTour?.id === ADD_META_PAYMENT_METHOD);

export interface WhatsappAddMetaPaymentMethodProps {
  onDismiss: VoidFunction;
  onSubmit: VoidFunction;
}

export const WhatsappAddMetaPaymentMethod: React.FC<WhatsappAddMetaPaymentMethodProps> =
  ({ onDismiss, onSubmit }) => {
    const botId = useCurrentBotId()!;
    const { t } = useSafeTranslation();
    const { link, loading } = useBillingPaymentMethodsLink(botId);
    const [refetchEntryPointTips, { data, loading: refetchLoading }] =
      useEntryPointTipsLazyQuery(botId, { fetchPolicy: 'network-only' });

    const onSubmitHandler = useCallback(() => {
      window.open(link, '_blank');

      const promise = waitFoTheReturnToTab(0);
      promise.then(() => refetchEntryPointTips()).catch(onDismiss);

      return () => promise.abort();
    }, [link, onDismiss, refetchEntryPointTips]);

    useEffect(() => {
      if (refetchLoading) {
        return;
      }

      const tip = metaPaymentMethodTip(data?.entryPointTips?.sections || []);

      if (!tip) {
        return;
      }

      if (tip.status !== OnboardingTipsStatus.done) {
        return;
      }

      onSubmit();
    }, [data, onDismiss, onSubmit, refetchLoading]);

    return (
      <DefaultDialog
        onDismiss={onDismiss}
        header={<Spacer factor={6} />}
        contentClassName={css.content}
        dialogStyle={{ width: '456px', height: '480px' }}
      >
        {loading || refetchLoading ? (
          <CenteredLoader className={css.loader} />
        ) : (
          <Flex flexDirection="column">
            <img src={imageSrc} alt="" />
            <Spacer factor={4} />
            <Type as="h1" size="24px" weight="semibold">
              {t('MiniOnboarding.WhatsappAddMetaPaymentMethod.title')}
            </Type>
            <Spacer factor={4} />
            <Type>{t('MiniOnboarding.WhatsappAddMetaPaymentMethod.text')}</Type>
            <Spacer factor={6} />
            <Button
              data-testid="whatsapp-add-meta-payment"
              type="submit"
              onClick={onSubmitHandler}
            >
              {t('MiniOnboarding.WhatsappAddMetaPaymentMethod.submitButton')}
            </Button>
          </Flex>
        )}
      </DefaultDialog>
    );
  };
