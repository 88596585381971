import { whatsappWidgetEntryPointFragment_config as WhatsappWidgetEntryPointConfig } from './@types/whatsappWidgetEntryPointFragment';
import { PluginType } from '../common/PluginTypes';
import { DisplayVariant } from '@globals';

export const PLUGIN_TYPE = PluginType.whatsapp_widget_entry_point;

export const PLUGIN_DEFAULT_CONFIG: WhatsappWidgetEntryPointConfig = {
  __typename: 'WhatsappWidgetEntryPointConfig',
  prefilledMessage: 'Hi, I have a question',
  welcomeMessageDelay: 0,
  welcomeMessage: 'Hello, do you need any help?',
  displayVariant: DisplayVariant.leaveOpen,
};

export const PLUGIN_INPUT_FIELD = 'whatsappWidgetEntryPointConfig';
