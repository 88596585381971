import React from 'react';
import { propEq, propOr } from 'ramda';
import cn from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import {
  Button,
  ButtonColorWay,
  ButtonIntent,
} from '../../../../../modern-ui/_deprecated/Button';
import { ReactComponent as DropdownIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { sendEvent } from '../../../../../utils/Analytics';

import { facebookAnalyticsPluginFragment_config as FacebookAnalyticsPluginConfig } from '../../@types/facebookAnalyticsPluginFragment';
import {
  FacebookEventsQuery,
  FacebookEventsQuery_facebookEvents,
} from './@types/FacebookEventsQuery';
import { FacebookAnalyticsPluginDataProps } from '../../FacebookAnalyticsPlugin';

import * as css from './FacebookAnalyticsPluginStandardEvents.css';

// hack - for not generic SimpleCombobox
interface FacebookEvent extends FacebookEventsQuery_facebookEvents {
  title: string;
}

const FACEBOOK_EVENTS_QUERY = gql`
  query FacebookEventsQuery {
    facebookEvents {
      id
      name
    }
  }
`;

interface FacebookAnalyticsPluginStandardEventsProps
  extends FacebookAnalyticsPluginDataProps {
  setDropdownTouched: (value: React.SetStateAction<boolean>) => void;
  dropdownTouched: boolean;
}
export const FacebookAnalyticsPluginStandardEvents: React.FC<FacebookAnalyticsPluginStandardEventsProps> =
  ({
    pluginConfig,
    setPluginConfigState,
    savePlugin,
    setDropdownTouched,
    dropdownTouched,
  }) => {
    const { data, loading } = useQuery<FacebookEventsQuery>(
      FACEBOOK_EVENTS_QUERY,
    );
    const facebookEvents = (data?.facebookEvents || []) as FacebookEvent[];
    return (
      <div className={css.eventIdComboBox}>
        <SimpleCombobox
          className={css.comboBoxContainer}
          items={facebookEvents}
          selectedItem={
            facebookEvents.find(propEq('id', pluginConfig.eventId)) || null
          }
          itemToString={propOr('', 'name')}
          onChange={(item) => {
            const eventId: string = propOr('', 'id', item);
            const config: FacebookAnalyticsPluginConfig = {
              ...pluginConfig,
              eventId,
            };
            setPluginConfigState({ config });
            savePlugin();
            sendEvent({
              category: 'events to fb',
              action: 'standard select',
              label: eventId,
            });
          }}
          onStateChange={() => setDropdownTouched(true)}
          renderInput={({ getToggleButtonProps, selectedItem, isOpen }) => (
            <Button
              intent={ButtonIntent.primary}
              colorWay={ButtonColorWay.white}
              {...getToggleButtonProps()}
              renderIconRight={() => <DropdownIcon />}
              className={cn(css.eventIdComboBox, {
                [css.error]:
                  !pluginConfig.eventId &&
                  dropdownTouched &&
                  !loading &&
                  !isOpen,
              })}
              disabled={loading}
            >
              {selectedItem?.name ||
                window.i18next.t(
                  'FacebookAnalyticsPluginStandardEvents-string-7313-select-event',
                )}
            </Button>
          )}
        />
      </div>
    );
  };
