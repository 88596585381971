import { usePaymentFlowState } from '@utils/Payment/hooks/usePaymentFlowState';
import { PaymentFlowState } from '@utils/Payment/constants';
import { useIntentSetup } from './useIntentSetup';
import { usePaymentMethodSetup } from './usePaymentMethodSetup';
import { PaymentIntent, SetupPayment } from '../types';
import { PaymentFlowError, PaymentFlowErrorKind } from 'cf-common/src/utils/Stripe/errors';
import { useStripeCardConfirmation } from 'cf-common/src/utils/Stripe/hooks/useStripeCardConfirmation';
import { use3DSCallback } from 'cf-common/src/utils/Stripe/hooks/use3DSCallback';

export const useProPayment = (
  setupPayment?: SetupPayment,
  paymentIntent = PaymentIntent.regular,
) => {
  const { paymentFlowState, error, setFlowState, failWith } =
    usePaymentFlowState();

  const { setupIntent, clientSecret } = useIntentSetup();

  const { setupCard, retryCard, link3DSecure, stripeIsReady } =
    useStripeCardConfirmation(paymentIntent === PaymentIntent.paywall);

  const { setupPaymentMethod: baseSetupPayment } = usePaymentMethodSetup();
  const setupPaymentMethod = setupPayment ?? baseSetupPayment;

  use3DSCallback(async () => {
    setFlowState(PaymentFlowState.confirming3DSecure);

    try {
      const { paymentMethodId } = await retryCard(clientSecret!);

      await setupPaymentMethod(paymentMethodId);

      setFlowState(PaymentFlowState.success);
    } catch (exception) {
      if (exception instanceof PaymentFlowError) {
        failWith(exception.errorData);
      } else {
        failWith({ kind: PaymentFlowErrorKind.unhandledError });
      }
    }
  });

  const submitPayment = async () => {
    if (paymentFlowState !== PaymentFlowState.idle || !stripeIsReady) return;

    setFlowState(PaymentFlowState.submitting);

    try {
      const { clientSecret } = await setupIntent();
      const { redirectIsRequired, paymentMethodId } = await setupCard(
        clientSecret!,
      );

      if (redirectIsRequired) {
        setFlowState(PaymentFlowState.redirecting3DSecure);
      } else {
        await setupPaymentMethod(paymentMethodId!);
        setFlowState(PaymentFlowState.success);
      }
    } catch (exception) {
      if (exception instanceof PaymentFlowError) {
        failWith(exception.errorData);
      } else {
        failWith({ kind: PaymentFlowErrorKind.unhandledError });
      }
    }
  };

  const tryAgain = () => {
    setFlowState(PaymentFlowState.idle);
  };

  return {
    stripeIsReady,
    paymentFlowState,
    link3DSecure,
    error,
    submitPayment,
    tryAgain,
  };
};
