import { zapierEventTriggerPluginFragment_config } from '@components/Plugins/ZapierEventTriggerPlugin/@types/zapierEventTriggerPluginFragment';
import { PluginType } from '@components/Plugins/common/PluginTypes';

export type ZapierEventTriggerPluginConfig = zapierEventTriggerPluginFragment_config;

export const PLUGIN_TYPE = PluginType.event_trigger;

export const PLUGIN_DEFAULT_CONFIG: Partial<ZapierEventTriggerPluginConfig> = {
  __typename: 'ExportViaZapierPluginConfig',
};

export const PLUGIN_INPUT_FIELD = 'zapierEventTriggerPluginConfig';
