import React, { useMemo, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import cn from 'classnames';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { CenteredLoader } from '@ui/Loader';
import { Input } from '@ui/Input';
import { HEXColors } from '@ui/_common/colors';
import { PaymentFormTemplate } from '../PaymentFormTemplate';
import { usePaymentForm } from '../hooks/usePaymentForm';
import { getSubmitText } from './utils';

import * as css from './RegularPaymentForm.css';

interface PaymentFormProps {
  initialPayment?: boolean;
  loading?: boolean;
  submitting?: boolean;
  onSubmit?: () => void;
  onDismiss?: () => void;
}

export const RegularPaymentForm: React.FC<PaymentFormProps> = ({
  initialPayment = false,
  loading = false,
  submitting = false,
  onSubmit,
  onDismiss,
}) => {
  const { t } = useSafeTranslation();
  const submitText = getSubmitText(t, initialPayment);

  const [cardValidation, setCardValidation] = useState({
    complete: false,
    invalid: false,
  });

  const { complete, invalid: cardIsInvalid } = cardValidation;

  const handleCardChange = (event: StripeCardElementChangeEvent) => {
    setCardValidation({ invalid: !!event.error, complete: event.complete });
  };

  const { cardElementIsReady, handleCardReady, handlePayment } = usePaymentForm(
    { initialPayment, complete, onSubmit },
  );

  const cardElementOptions = useMemo(
    () => ({
      disabled: submitting,
      hidePostalCode: true,
      style: {
        base: {
          fontSize: '15px',
          '::placeholder': {
            color: HEXColors.grey,
          },
        },
        invalid: {
          color: HEXColors.red,
        },
      },
    }),
    [submitting],
  );

  const formClassName = cn(css.form, {
    [css.hidden]: !cardElementIsReady,
  });

  return (
    <PaymentFormTemplate onDismiss={onDismiss}>
      {(loading || !cardElementIsReady) && (
        <div className={css.loader}>
          <CenteredLoader />
        </div>
      )}
      {!loading && (
        <form onSubmit={handlePayment} className={formClassName}>
          <Spacer factor={4} />
          <Input
            error={cardIsInvalid}
            render={({ getInputProps }) => {
              const { onFocus, onBlur } = getInputProps({});
              return (
                <CardElement
                  className={css.cardElement}
                  onReady={handleCardReady}
                  onChange={handleCardChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  options={cardElementOptions}
                />
              );
            }}
          />
          <Spacer factor={4} />
          {submitting ? (
            <div className={css.submitting}>
              <CenteredLoader size="m" />
            </div>
          ) : (
            <Button
              type="submit"
              intent="primary"
              disabled={!complete}
              className={css.submitButton}
              data-testid="pro-payment_submit"
            >
              {submitText}
            </Button>
          )}
        </form>
      )}
    </PaymentFormTemplate>
  );
};
