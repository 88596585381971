import { ExternalIntegrationEntryPointType } from '@globals';
import { HEXColors } from '@ui/_common/colors';
import { PluginType } from '../common/PluginTypes';
import { shopifyCustomerChatEntryPointFragment_config } from './@types/shopifyCustomerChatEntryPointFragment';

export const PLUGIN_TYPE = PluginType.shopify_customer_chat_entry_point;

export const DEFAULT_GREETING = 'Hi! How can we help you?';
export const DEFAULT_THEME_COLOR = HEXColors.white;

export const PLUGIN_DEFAULT_CONFIG: shopifyCustomerChatEntryPointFragment_config =
  {
    __typename: 'ShopifyCustomerChatEntryPointConfig',
    domains: [],
    integration_type: ExternalIntegrationEntryPointType.shopify,
    logged_in_greeting: '',
    logged_out_greeting: '',
    theme_color: null,
    greeting_dialog_display: null,
    greeting_dialog_delay: null,
    supported_devices: null,
    pages_whitelist: ['/*'],
    pages_blacklist: [],
    block_id: null,
    guest_block_id: null,
    upgrade_block_id: null,
  };

export const PLUGIN_INPUT_FIELD = 'shopifyCustomerChatEntryPointConfig';
