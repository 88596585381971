import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ApolloProvider } from '@apollo/react-common';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import * as css from './AddPluginList.css';
import { Modal } from '@ui/Modal';
import { Dialog, DialogCloseButton, DialogHeading } from '@ui/Dialog';
import { AddPluginProps, AddPluginTabType } from '../types';
import { Tooltip2 } from '@ui/Tooltip2';
import client from '../../../../common/services/ApolloService';
import { PluginType } from '../../common/PluginTypes';
import { getPluginButtonConfig } from '../AddPluginButtonConfig';
import { AddPluginButton, AddPluginButtonMode } from '../AddPluginButton';
import { isWhiteLabelDomain, WhiteLabelUserData } from '@utils/WhiteLabelUtils';
import { sendEvent } from '@utils/Analytics';

interface AddPluginListProps extends AddPluginProps {
  isVisible: boolean;
  onDismiss: () => void;
}

interface AddPluginListStructure {
  [key: string]: {
    title: string;
    subtitle?: React.ReactNode;
    plugins: (null | PluginType)[];
  }[];
}

interface PluginItemProps {
  pluginType: PluginType;
  botId: string;
  label: string;
  onAddPlugin: (type: PluginType) => void;
}

const PluginItem: React.FC<PluginItemProps> = (props) => {
  const { t } = useSafeTranslation();

  const pluginConfig = getPluginButtonConfig({
    pluginType: props.pluginType,
    botId: props.botId,
    pluginList: true,
    t,
  });

  return (
    pluginConfig && (
      <Tooltip2
        content={pluginConfig.tooltip?.pro || pluginConfig.tooltip?.free}
        positionFixed
        placement="right"
        hoverable
        disabled={!pluginConfig?.tooltip || isWhiteLabelDomain()}
      >
        {(ref, bind) => (
          // TODO change div -> button + keyboard accessability
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <AddPluginButton
            {...bind}
            innerRef={ref}
            onClick={() => {
              props.onAddPlugin(props.pluginType);
              sendEvent(
                {
                  category: 'chatfuel plugins dialog',
                  action: 'click',
                  label: props.label,
                  propertyBag: {
                    pluginType: props.pluginType,
                  },
                },
                true,
              );
            }}
            className={pluginConfig.testClass}
            mode={AddPluginButtonMode.list}
            renderIcon={() => pluginConfig.img}
          >
            {pluginConfig.title}
          </AddPluginButton>
        )}
      </Tooltip2>
    )
  );
};

export const AddPluginList: React.FC<AddPluginListProps> = (props) => {
  const { isAutomateEnabled } = useAutomateEnabled();
  const structure: AddPluginListStructure = {
    [AddPluginTabType.STRUCTURE]: [
      {
        title: window.i18next.t('AddPluginList-string--766-add-send-content'),
        plugins: [
          PluginType.text,
          PluginType.typing,
          PluginType.image,
          PluginType.gallery,
          PluginType.video,
          PluginType.audio,
          PluginType.comment,
        ],
      },
      {
        title: window.i18next.t('AddPluginList-string--118-collect-user-data'),
        plugins: [
          PluginType.quick_reply,
          PluginType.form_v2,
          PluginType.setup_variable_plugin,
          PluginType.collect_phone,
          PluginType.collect_email,
        ],
      },
      {
        title: window.i18next.t('AddPluginList-string--635-export-import'),
        subtitle: (
          <p>
            {window.i18next.t(
              'AddPluginList-JSXText-8446-you-can-also-set-up-integrations-via-zapier',
            )}{' '}
            {!isWhiteLabelDomain() && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.chatfuel.com/tips-and-tricks/integrations/chatfuel-zapier-integration"
              >
                {window.i18next.t('AddPluginList-JSXText-1076-learn-more')}
              </a>
            )}
          </p>
        ),
        plugins: [
          PluginType.json_plugin,
          PluginType.google_sheet,
          PluginType.email,
          PluginType.event_trigger,
          PluginType.integration_zapier_plugin,
        ],
      },
      {
        title: window.i18next.t('AddPluginList-string-7638-redirect-users'),
        plugins: [
          PluginType.go_to_block_plugin,
          ...(isAutomateEnabled
            ? [PluginType.add_to_sequence, PluginType.remove_from_sequence]
            : []),
          PluginType.abt,
        ],
      },
      {
        title: window.i18next.t(
          'AddPluginList-string-1317-connect-users-to-a-human',
        ),
        plugins: [PluginType.communication, PluginType.handover],
      },
      {
        title: window.i18next.t(
          'AddPluginList-string--955-plugins-with-subscriptions',
        ),
        plugins: [
          PluginType.search_google_plugin,
          PluginType.search_bing_plugin,
          PluginType.search_swiftype_plugin,
          PluginType.search_rss_plugin,
          PluginType.subscribe_user_plugin,
          PluginType.subscriptions_manager_plugin,
          PluginType.subscription_broadcasting_plugin,
        ],
      },
    ],
    [AddPluginTabType.BROADCAST]: [
      {
        title: window.i18next.t('AddPluginList-string--766-add-send-content'),
        plugins: [
          PluginType.text,
          PluginType.typing,
          PluginType.image,
          PluginType.gallery,
          PluginType.video,
          PluginType.audio,
          PluginType.comment,
        ],
      },
      {
        title: window.i18next.t('AddPluginList-string--118-collect-user-data'),
        plugins: [PluginType.quick_reply],
      },
      {
        title: window.i18next.t('AddPluginList-string--635-export-import'),
        subtitle: (
          <p>
            {window.i18next.t(
              'AddPluginList-JSXText-8446-you-can-also-set-up-integrations-via-zapier',
            )}{' '}
            {!isWhiteLabelDomain() && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.chatfuel.com/tips-and-tricks/integrations/chatfuel-zapier-integration"
              >
                {window.i18next.t('AddPluginList-JSXText-1076-learn-more')}
              </a>
            )}
          </p>
        ),
        plugins: [PluginType.integration_zapier_plugin],
      },
      ...(isAutomateEnabled
        ? [
            {
              title: window.i18next.t(
                'AddPluginList-string-7638-redirect-users',
              ),
              plugins: [
                PluginType.add_to_sequence,
                PluginType.remove_from_sequence,
              ],
            },
          ]
        : []),
      {
        title: window.i18next.t(
          'AddPluginList-string--955-plugins-with-subscriptions',
        ),
        plugins: [PluginType.subscription_broadcasting_plugin],
      },
    ],
    [AddPluginTabType.AUTOPOST]: [
      {
        title: window.i18next.t('AddPluginList-string--766-add-send-content'),
        plugins: [
          PluginType.text,
          PluginType.typing,
          PluginType.image,
          PluginType.gallery,
          PluginType.video,
          PluginType.audio,
        ],
      },
      {
        title: window.i18next.t('AddPluginList-string--118-collect-user-data'),
        plugins: [PluginType.quick_reply],
      },
      {
        title: window.i18next.t('AddPluginList-string--635-export-import'),
        subtitle: (
          <p>
            {window.i18next.t(
              'AddPluginList-JSXText-8446-you-can-also-set-up-integrations-via-zapier',
            )}{' '}
            {!isWhiteLabelDomain() && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.chatfuel.com/tips-and-tricks/integrations/chatfuel-zapier-integration"
              >
                {window.i18next.t('AddPluginList-JSXText-1076-learn-more')}
              </a>
            )}
          </p>
        ),
        plugins: [PluginType.autoposting_zapier_plugin],
      },
      ...(isAutomateEnabled
        ? [
            {
              title: window.i18next.t(
                'AddPluginList-string-7638-redirect-users',
              ),
              plugins: [
                PluginType.add_to_sequence,
                PluginType.remove_from_sequence,
              ],
            },
          ]
        : []),
    ],
  };

  const onPluginClick = (pluginType: PluginType) => {
    props.onDismiss();
    props.onAddPlugin(pluginType, props.index);
  };

  return props.isVisible && structure[props.type] ? (
    <Modal onDismiss={props.onDismiss}>
      <Dialog className={css.dialog}>
        <ApolloProvider client={client}>
          <div style={{ marginBottom: 8 }}>
            <WhiteLabelUserData>
              {({ loading, isWhiteLabelUser }) =>
                loading ? null : isWhiteLabelUser ? (
                  <DialogHeading>
                    {window.i18next.t('AddPluginList-JSXText-1189-plugins')}
                  </DialogHeading>
                ) : (
                  <DialogHeading>
                    {window.i18next.t(
                      'AddPluginList-JSXText--213-chatfuel-plugins',
                    )}
                  </DialogHeading>
                )
              }
            </WhiteLabelUserData>
          </div>
          {structure[props.type].map((section) => (
            <div key={section.title} className={css.section}>
              <div data-testid="plugins__plugin-title" className={css.title}>
                {section.title}
              </div>
              {section.subtitle && (
                <div
                  data-testid="plugins__plugin-subtitle"
                  className={css.subtitle}
                >
                  {section.subtitle}
                </div>
              )}
              <div data-testid="plugins__plugin-group" className={css.plugins}>
                {section.plugins.map(
                  (pluginType) =>
                    pluginType && (
                      <PluginItem
                        key={pluginType}
                        pluginType={pluginType}
                        onAddPlugin={onPluginClick}
                        botId={props.botId}
                        label={section.title}
                      />
                    ),
                )}
              </div>
            </div>
          ))}
        </ApolloProvider>
        <DialogCloseButton onClick={props.onDismiss} />
      </Dialog>
    </Modal>
  ) : null;
};
