import React from 'react';
import { Spacer } from '@ui/Spacer';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { getWorkspaceDataByStatus } from '@components/Workspace/workspaceStatus';
import { Permission } from '@common/services/RoleService';
import { BotSwitcherWithData } from '../BotSwitcherWithData';
import { BotSwitcherProps } from '../types';
import { useBotSwitcherNavItemData } from '../../../hooks/useBotSwitcherNavItemData';
import { BotSwitcherLoading } from '../../../BotSwitcherLoading';
import { useWorkspace } from '@utils/Data/Workspaces/useWorkspace';
import { WorkspaceInfoView } from './WorkspaceInfoView';
import { BotSwitcherStatisticsFactory } from '../BotSwitcherStatisticsFactory';
import { BotSwitcherWorkspaceButton } from './BotSwitcherWorkspaceButton';
import { ChargingEntityType } from '@globals';

export const BotSwitcherWorkspace: React.FC<BotSwitcherProps> = ({
  onButtonClick,
  ...props
}) => {
  const { bot } = useBotSwitcherNavItemData();
  const { screenSize, isMediumScreenSize } = useDeviceMedia();
  const { workspace } = useWorkspace(bot?.workspace_id);

  if (!workspace) {
    return <BotSwitcherLoading screenSize={screenSize} />;
  }

  return (
    <BotSwitcherWithData {...props}>
      <WorkspaceInfoView
        status={getWorkspaceDataByStatus(workspace.subscription?.status)}
        title={workspace.name}
        screenSize={screenSize}
      />

      <Spacer factor={2} />

      {workspace.subscription?.chargingEntityType && (
        <BotSwitcherStatisticsFactory
          dialogsPricing={
            workspace.subscription.chargingEntityType ===
            ChargingEntityType.dialogs
          }
          numerator={workspace.limitation?.reached ?? 0}
          denominator={workspace.limitation?.limit ?? 0}
        />
      )}

      {workspace.permission === Permission.EDIT && (
        <BotSwitcherWorkspaceButton
          small={isMediumScreenSize}
          workspace={workspace}
        />
      )}
    </BotSwitcherWithData>
  );
};
