import React from 'react';
import { Focus } from 'react-powerplug';

import { Autofocus } from '../../../../../modern-ui/Autofocus';
import { Input } from '../../../../../modern-ui/Input';
import { Spacer } from '../../../../../modern-ui/Spacer';
import { Icon } from '../../../../../modern-ui/Icon';
import { Tooltip2 } from '../../../../../modern-ui/Tooltip2';
import { Type } from '../../../../../modern-ui/Type';
import { Flex } from '../../../../../modern-ui/Flex';

import { sendEvent } from '../../../../../utils/Analytics';

import { facebookAnalyticsPluginFragment_config as FacebookAnalyticsPluginConfig } from '../../@types/facebookAnalyticsPluginFragment';

import { FacebookAnalyticsPluginDataProps } from '../../FacebookAnalyticsPlugin';
import * as css from './FacebookAnalyticsPluginCustomEvents.css';

export const FacebookAnalyticsPluginCustomEvents: React.FC<FacebookAnalyticsPluginDataProps> = ({
  pluginConfig,
  setPluginConfigState,
  savePlugin,
  validationErrors,
}) => (
  <Flex alignItems="center">
    <div className={css.eventIdInput}>
      <Autofocus
        shouldFocus={!pluginConfig.eventId}
        render={({ bind: autofocusBind }) => (
          <Focus>
            {({ bind, focused }) => (
              <Input
                value={pluginConfig.eventId || ''}
                onChange={({ currentTarget: { value } }) => {
                  const config: FacebookAnalyticsPluginConfig = {
                    ...pluginConfig,
                    eventId: value,
                  };
                  setPluginConfigState({ config });
                }}
                placeholder={window.i18next.t(
                  'FacebookAnalyticsPluginCustomEvents-string-1028-name-your-custom-event',
                )}
                error={
                  (!pluginConfig.eventId || !!validationErrors?.eventId) &&
                  !focused
                }
                {...autofocusBind}
                onBlur={() => {
                  bind.onBlur();
                  savePlugin();
                  sendEvent({
                    category: 'events to fb',
                    action: 'custom select',
                    label: '',
                    propertyBag: {
                      value: pluginConfig.eventId,
                    },
                  });
                }}
                onFocus={bind.onFocus}
              />
            )}
          </Focus>
        )}
      />
    </div>
    {validationErrors?.eventId && (
      <>
        <Spacer horizontalFactor={2} />
        <Tooltip2
          placement="right"
          boundariesElement="viewport"
          content={
            <Type as="p" size="12px" color="white">
              {validationErrors?.eventId}
            </Type>
          }
          type="small"
          positionFixed
        >
          {(ref, bind) => <Icon {...bind} ref={ref} icon="info" color="red" />}
        </Tooltip2>
      </>
    )}
  </Flex>
);
