import gql from 'graphql-tag';
import {
  COMMON_PLUGIN_FRAGMENT,
  SEGMENTATION_FRAGMENT,
  LOCALIZATION_FRAGMENT,
} from './PluginQuery';

export const CARD_BUTTON_FRAGMENT = gql`
  fragment cardButtonFragment on CardButton {
    type
    title
    block_id
    block_ids
    url
    webview_height_ratio
    phone_number
    counter_id
    attribute_update_list {
      name
      value
    }
    shopify_page {
      path
      discount {
        discount_type
        code
        price_rule_id
        shop_id
      }
    }
    payment_blocks
    variables
    payment_summary {
      currency
      payment_type
      requested_user_info
      price_list {
        id
        label
        amount
      }
    }
  }
`;

export const NG_PLUGINS_FRAGMENTS = gql`
  fragment textPluginFragment on TextPlugin {
    ...commonPluginFragment
    config {
      text
      buttons {
        ...cardButtonFragment
      }
    }
  }
  fragment imagePluginFragment on ImagePlugin {
    ...commonPluginFragment
    config {
      url
    }
  }
  fragment galleryPluginFragment on GalleryPlugin {
    ...commonPluginFragment
    config {
      square_ratio
      gallery_cards {
        title
        subtitle
        item_url
        image_url
        buttons {
          ...cardButtonFragment
        }
      }
    }
  }
  fragment addToSequencePluginFragment on AddToSequencePlugin {
    ...commonPluginFragment
    config {
      user_filter {
        ...segmentationFragment
      }
      action {
        random
        items {
          groups
        }
      }
    }
  }
  fragment commentPluginFragment on CommentPlugin {
    ...commonPluginFragment
    config {
      text
    }
  }
  fragment userInputPluginFragment on UserInputPlugin {
    ...commonPluginFragment
    config {
      form_properties {
        type
        title
        caption
      }
    }
    localization {
      ...localizationFragment
    }
  }
  fragment collectPhonePluginFragment on CollectPhonePlugin {
    ...commonPluginFragment
    config {
      text
      variable_name
    }
  }
  fragment collectEmailPluginFragment on CollectEmailPlugin {
    ...commonPluginFragment
    config {
      text
      variable_name
    }
  }
  fragment exportViaZapierPluginFragment on ExportViaZapierPlugin {
    ...commonPluginFragment
  }
  fragment integrationZapierPluginFragment on IntegrationZapierPlugin {
    ...commonPluginFragment
    config {
      view
    }
  }
  fragment removeToSequencePluginFragment on RemoveFromSequencePlugin {
    ...commonPluginFragment
    config {
      user_filter {
        ...segmentationFragment
      }
      action {
        random
        items {
          groups
        }
      }
    }
  }
  fragment googleSearchPluginFragment on GoogleSearchPlugin {
    ...commonPluginFragment
    config {
      query
      api_key
      api_cx
      title
      image_caption
      number_of_pages
      subscriptions_available
    }
    localization {
      ...localizationFragment
    }
  }
  fragment bindSearchPluginFragment on BindSearchPlugin {
    ...commonPluginFragment
    config {
      query
      api_token
      title
      image_caption
      number_of_pages
      subscriptions_available
    }
    localization {
      ...localizationFragment
    }
  }
  fragment swiftypeSearchPluginFragment on SwiftypeSearchPlugin {
    ...commonPluginFragment
    config {
      query
      title
      number_of_pages
      subscriptions_available
      allow_read_in_bot
      api_token
      image_caption
    }
    localization {
      ...localizationFragment
    }
  }
  fragment rssSearchPluginFragment on RssSearchPlugin {
    ...commonPluginFragment
    config {
      title
      query
      image_caption
      allow_read_in_bot
      subscriptions_available
      number_of_pages
    }
    localization {
      ...localizationFragment
    }
  }
  fragment subscribeUserPluginFragment on SubscribeUserPlugin {
    ...commonPluginFragment
    config {
      subscriptions_block
      no_subscriptions_block_id
    }
  }
  fragment subscriptionsManagerPluginFragment on SubscriptionsManagerPlugin {
    ...commonPluginFragment
    config {
      subscriptions_block
      no_subscriptions_block_id
    }
  }
  fragment subscriptionBroadcastingPluginFragment on SubscriptionBroadcastingPlugin {
    ...commonPluginFragment
    config {
      title
      subscription_title
    }
  }
  fragment toDoPluginFragment on ToDoPlugin {
    ...commonPluginFragment
    config {
      title
      todo {
        id
        description
        done
      }
    }
  }
  fragment autopostingZapierPluginFragment on AutopostingZapierPlugin {
    ...commonPluginFragment
  }
  ${COMMON_PLUGIN_FRAGMENT}
  ${CARD_BUTTON_FRAGMENT}
  ${SEGMENTATION_FRAGMENT}
  ${LOCALIZATION_FRAGMENT}
`;
