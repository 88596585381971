import gql from 'graphql-tag';
import {
  COMMON_PLUGIN_FRAGMENT,
  KEYWORDS_SETUP_FRAGMENT,
} from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment instagramDirectEntryPointFragment on InstagramDirectEntryPoint {
    ...commonPluginFragment
    config {
      block_id
      fill_ib_with_ig_data
      ice_breakers {
        title
        block_id
        counter_id
      }
      keywords_setup {
        ...keywordsSetupFragment
      }
    }
    enabled
    refresh
    synced
  }
  ${KEYWORDS_SETUP_FRAGMENT}
  ${COMMON_PLUGIN_FRAGMENT}
`;
