import { sendToMessengerEntryPointFragment_config as SendToMessengerEntryPointConfig } from './@types/sendToMessengerEntryPointFragment';

import { PluginType } from '../common/PluginTypes';
import {
  SendToMessengerColor,
  SendToMessengerSize,
} from '../../../../@types/globalTypes';

export const PLUGIN_TYPE = PluginType.send_to_messenger_entry_point;

export const PLUGIN_DEFAULT_CONFIG: SendToMessengerEntryPointConfig = {
  __typename: 'SendToMessengerEntryPointConfig',
  domains: [],
  color: SendToMessengerColor.blue,
  size: SendToMessengerSize.standard,
  cta_text: '',
  block_id: null,
};

export const PLUGIN_INPUT_FIELD = 'sendToMessengerEntryPointConfig';
