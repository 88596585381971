/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Mutation, Query } from '@apollo/react-components';
import { pathOr } from 'ramda';
import cn from 'classnames';
import { log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { Platform } from '@globals';
import { PluginBox, PluginData, PluginDataChildrenParams } from '../common';
import { IPlugin } from '../common/Plugin';
import {
  JsonPluginRequestPostFormat,
  JsonPluginRequestType,
  PLUGIN_TYPE,
  SEND_TO_MESSENGER_REF,
  jsonPluginRequestTypes,
  jsonPluginRequestPostFormats,
  FULL_PROFILE_BODY_BY_PLATFORM,
  bodilessTypes,
} from './JsonPluginConst';
import { jsonPluginFragment_config as IJsonPluginConfig } from './@types/jsonPluginFragment';
import { PluginBlock } from '../common/PluginBlock';
import { PluginControlLabel } from '../common/PluginControlLabel';
import * as css from './JsonPlugin.css';
import { Dropdown } from '@ui/_deprecated/Dropdown';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
} from '../../../modern-ui/_deprecated/Button';
import { Input } from '@ui/Input';
import { CheckBox } from '@ui/CheckBox';
import { Flex } from '@ui/Flex';
import { SegmentedGroupTrack } from '@ui/SegmentedGroup';
import { SegmentedGroupTrackColorWay } from '@ui/SegmentedGroup/SegmentedGroupTrack';
import { Localization } from '../common/Localization';
import {
  TestRequestMutation,
  TestRequestMutationVariables,
} from './@types/TestRequestMutation';
import { JsonPluginRequestTestResult } from './JsonPluginRequestTestResult/JsonPluginRequestTestResult';
import { FocusWithin } from '@ui/FocusWithin';
import {
  deserialize,
  TextWithAttributesEditor,
} from '@ui/TextWithAttributesEditor';
import { SendToMessengerPluginButton } from '../../FBPlugins/SendToMessengerPluginButton';
import { toaster } from '@services/MessageService';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import timer from '../../../utils/timer';
import {
  SendToMessengerDataQuery,
  SendToMessengerDataQueryVariables,
} from './@types/SendToMessengerDataQuery';
import { JsonPluginHeader } from './JsonPluginHeader';
import {
  isJsonEncoded,
  isNotShowJsonValidError,
  setSendToMessengerClickedToLocaleStore,
} from './JsonPluginUtils';
import {
  TEST_REQUEST_MUTATION,
  SEND_TO_MESSENGER_DATA_QUERY,
} from './JsonPluginQuery';
import { useJsonPluginState } from './useJsonPluginState';
import { JsonRequestHeaders } from './JsonRequestHeaders';
import { UrlEncodedData } from './UrlEncodedData';
import { ignoreEnterKey } from '@utils/DOM/keyHandlers';

export interface IJsonPluginProps extends IPlugin {
  botId: string;
}

export const JsonPlugin: React.FC<IJsonPluginProps> = ({
  id,
  blockId,
  position,
  botId,
}) => (
  <PluginData<IJsonPluginConfig>
    key={id}
    id={id}
    blockId={blockId}
    pluginType={PLUGIN_TYPE}
    position={position}
  >
    {(pluginDataProps) => {
      if (pluginDataProps.loading) {
        return null;
      }
      return (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <JsonPluginContent
          id={id}
          botId={botId}
          pluginDataProps={pluginDataProps}
        />
      );
    }}
  </PluginData>
);

interface JsonPluginContentProps {
  id: string;
  botId: string;
  pluginDataProps: PluginDataChildrenParams<IJsonPluginConfig>;
}

const JsonPluginContent: React.FC<JsonPluginContentProps> = ({
  id,
  botId,
  pluginDataProps,
}) => {
  const {
    jsonBody,
    setJsonBody,
    urlEncodedBody,
    setUrlEncodedBody,
    testRequestSend,
    setTestRequestSend,
    dontSendToMessenger,
    setDontShowSendToMessenger,
    sendToMessengerRenderWatchDog,
  } = useJsonPluginState(botId);

  const { attributes } = useAttributes(
    botId,
    VariableSuggestType.template,
    Platform.facebook,
  );

  const {
    savePlugin,
    pluginConfig,
    pluginLocalization,
    validationErrors,
    saving,
    savePluginAsync,
    isNewPlugin,
    setPluginConfigState,
  } = pluginDataProps;

  return (
    <PluginBox>
      <JsonPluginHeader />
      <PluginBlock useCols>
        <PluginControlLabel
          className={css.requestMethodSelectorCol}
          label={window.i18next.t('JsonPlugin-string-2622-type')}
        >
          {({ id }) => (
            <Dropdown
              items={jsonPluginRequestTypes}
              selectedItem={pluginConfig.method}
              fullWidth
              buttonColorWay={ButtonColorWay.white}
              itemClassName={css.dropdownItem}
              onChange={(method) => {
                const config = {
                  ...pluginConfig,
                  method: method as JsonPluginRequestType,
                };
                setPluginConfigState({ config });
                savePlugin();
              }}
              id={id}
            />
          )}
        </PluginControlLabel>
        <PluginControlLabel className={css.urlCol} label="URL" required>
          {({ id: domId }) => (
            <FocusWithin
              render={({ focusedWithin, bind }) => {
                return (
                  <div {...bind}>
                    <Input
                      error={
                        !focusedWithin &&
                        !saving &&
                        (!!validationErrors.url || !pluginConfig.url)
                      }
                      render={() => (
                        <TextWithAttributesEditor
                          key={id}
                          defaultValue={deserialize(pluginConfig.url || '')}
                          onBlur={(_, value) => {
                            setPluginConfigState({
                              config: {
                                ...pluginConfig,
                                url: value,
                              },
                            });
                            savePlugin();
                          }}
                          placeholder={window.i18next.t(
                            'JsonPlugin-string-1144-enter-url',
                          )}
                          attributes={attributes}
                          className={css.urlInput}
                          autoFocus={isNewPlugin}
                          onKeyDown={ignoreEnterKey}
                          id={domId}
                          trimOnBlur
                        />
                      )}
                    />
                  </div>
                );
              }}
            />
          )}
        </PluginControlLabel>
      </PluginBlock>
      <PluginBlock className={css.headersBlock}>
        <JsonRequestHeaders
          headers={pluginConfig.headers}
          botAttributes={attributes}
          onHeadersChange={(nextHeaders) => {
            setPluginConfigState({
              config: {
                ...pluginConfig,
                headers: nextHeaders,
              },
            });
          }}
          onHeadersSave={(nextHeaders) => {
            setPluginConfigState({
              config: {
                ...pluginConfig,
                headers: nextHeaders,
              },
            });
            savePlugin();
          }}
        />
      </PluginBlock>
      {!bodilessTypes.includes(pluginConfig.method) && (
        <PluginBlock>
          <PluginControlLabel
            label={window.i18next.t('JsonPlugin-string-2573-send')}
            description={
              isJsonEncoded(pluginConfig.post_format) ||
              pluginConfig.post_format ===
                JsonPluginRequestPostFormat.full_json_profile ? (
                <Flex justifyContent="space-between">
                  <div>
                    {isJsonEncoded(pluginConfig.post_format) &&
                      window.i18next.t(
                        'JsonPlugin-string-3698-you-can-add-existing-user-attributes-with',
                        { skipInterpolation: true },
                      )}
                    {pluginConfig.post_format ===
                      JsonPluginRequestPostFormat.full_json_profile &&
                      window.i18next.t(
                        'JsonPlugin-string--169-all-of-the-users-attributes-will-be-sent-the-above-json-profile-sample-may-vary-for-each-user-depending-on-their-specific-attributes',
                      )}
                  </div>
                  {!isNotShowJsonValidError(pluginConfig.body) && (
                    <div className={css.error}>
                      {window.i18next.t('JsonPlugin-JSXText-6320-invalid-json')}
                    </div>
                  )}
                </Flex>
              ) : undefined
            }
          >
            {({ id: domId }) => (
              <React.Fragment>
                <Flex alignItems="center" style={{ marginBottom: 12 }}>
                  <SegmentedGroupTrack
                    role="tablist"
                    colorWay={SegmentedGroupTrackColorWay.grey}
                  >
                    {jsonPluginRequestPostFormats().map((format) => (
                      <Button
                        key={format.type}
                        role="tab"
                        intent={ButtonIntent.toggle}
                        colorWay={ButtonColorWay.toggleWhiteHover}
                        size={ButtonSize.s}
                        aria-selected={
                          pluginConfig.post_format === format.type ||
                          (!pluginConfig.post_format &&
                            format.type === JsonPluginRequestPostFormat.json)
                        }
                        onClick={() => {
                          const fromFormat = pluginConfig.post_format;
                          const toFormat = format.type;

                          if (fromFormat === JsonPluginRequestPostFormat.json) {
                            setJsonBody(pluginConfig.body || '');
                          }
                          if (
                            fromFormat ===
                            JsonPluginRequestPostFormat.urlencoded
                          ) {
                            setUrlEncodedBody(pluginConfig.body || '');
                          }

                          const toBody =
                            toFormat === JsonPluginRequestPostFormat.json
                              ? jsonBody
                              : toFormat ===
                                JsonPluginRequestPostFormat.urlencoded
                              ? urlEncodedBody
                              : '';

                          const config: IJsonPluginConfig = {
                            ...pluginConfig,
                            body: toBody,
                            post_format: toFormat,
                          };
                          setPluginConfigState({ config });
                          savePlugin();
                        }}
                        className={css.sendAsButtonTab}
                      >
                        {format.title}
                      </Button>
                    ))}
                  </SegmentedGroupTrack>
                </Flex>
                {isJsonEncoded(pluginConfig.post_format) && (
                  <Input
                    render={() => (
                      <TextWithAttributesEditor
                        defaultValue={deserialize(pluginConfig.body || '')}
                        onStringChange={(value) =>
                          setPluginConfigState({
                            config: {
                              ...pluginConfig,
                              body: value,
                            },
                          })
                        }
                        onBlur={() => savePlugin()}
                        placeholder={window.i18next.t(
                          'JsonPlugin-string--453-enter-request-body',
                        )}
                        attributes={attributes}
                        className={css.requestBody}
                        id={domId}
                      />
                    )}
                  />
                )}

                {pluginConfig.post_format ===
                  JsonPluginRequestPostFormat.full_json_profile && (
                  <Input
                    render={() => (
                      <TextWithAttributesEditor
                        key={attributes.length}
                        defaultValue={
                          FULL_PROFILE_BODY_BY_PLATFORM[Platform.facebook]
                        }
                        attributes={[]}
                        className={cn(css.requestBody, css.fullProfile)}
                        readonly
                        id={domId}
                      />
                    )}
                  />
                )}

                {pluginConfig.post_format ===
                  JsonPluginRequestPostFormat.urlencoded && (
                  <UrlEncodedData
                    body={pluginConfig.body}
                    botAttributes={attributes}
                    onBodyChange={(nextBody) => {
                      setPluginConfigState({
                        config: {
                          ...pluginConfig,
                          body: nextBody,
                        },
                      });
                    }}
                    onBodySave={(nextBody) => {
                      setPluginConfigState({
                        config: {
                          ...pluginConfig,
                          body: nextBody,
                        },
                      });
                      savePlugin();
                    }}
                  />
                )}
              </React.Fragment>
            )}
          </PluginControlLabel>
        </PluginBlock>
      )}
      <Mutation<TestRequestMutation, TestRequestMutationVariables>
        mutation={TEST_REQUEST_MUTATION}
        variables={{
          cardId: id || '',
        }}
        fetchPolicy="no-cache"
      >
        {(testRequest, { data }) => {
          const res = data && data.testJsonPluginRequest;
          const saveDataAndSendRequest = async () => {
            try {
              setTestRequestSend(true);
              await savePluginAsync(pluginConfig);
              await testRequest();
            } catch (error) {
              toaster.show({
                type: ServiceMessageType.error,
                payload: {
                  message: window.i18next.t(
                    'JsonPlugin-string-3944-something-went-wrong-please-try-again-later',
                  ),
                },
              });

              log.error({
                error,
                msg: 'Error test request in JSON plugin',
                data: {
                  label: 'automate_json_plugin',
                  requestId: getRequestIdFromApolloError(error as any),
                },
              });
            } finally {
              setTestRequestSend(false);
            }
          };
          const sendTestRequestDontReady =
            isNewPlugin || testRequestSend || !pluginConfig.url;

          const testTheRequestButton = (
            <Button
              intent={ButtonIntent.primary}
              disabled={sendTestRequestDontReady}
              onClick={saveDataAndSendRequest}
            >
              {window.i18next.t('JsonPlugin-JSXText-2012-test-the-request')}
            </Button>
          );
          return (
            <React.Fragment>
              <PluginBlock>
                <Flex justifyContent="space-between" alignItems="center">
                  {/* eslint-disable-next-line jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control */}
                  <label className={css.reportErrorLabel}>
                    <CheckBox
                      className={css.reportErrorCheckbox}
                      checked={!!pluginConfig.show_error_messages}
                      onChange={() => {
                        const config: IJsonPluginConfig = {
                          ...pluginConfig,
                          show_error_messages:
                            !pluginConfig.show_error_messages,
                        };
                        setPluginConfigState({ config });
                        savePlugin();
                      }}
                    />
                    <span>
                      {window.i18next.t(
                        'JsonPlugin-JSXText--730-report-errors-in-the-bot-bot-users-will-see-the-error-messages',
                      )}
                    </span>
                  </label>
                  {pluginConfig.post_format !==
                    JsonPluginRequestPostFormat.full_json_profile ||
                  dontSendToMessenger ? (
                    testTheRequestButton
                  ) : (
                    <Query<
                      SendToMessengerDataQuery,
                      SendToMessengerDataQueryVariables
                    >
                      query={SEND_TO_MESSENGER_DATA_QUERY}
                      variables={{ botId }}
                    >
                      {({ data }) => {
                        if (!data || !data.bot) {
                          return null;
                        }
                        const pageId = pathOr<string>(
                          window.CHATFUEL_CONFIG.CF_BOT_TEST_PAGE_ID!,
                          ['bot', 'status', 'page'],
                        )(data);
                        const adminId = pathOr<string>('', ['me', 'id'])(data);
                        return (
                          <SendToMessengerPluginButton
                            render={() => testTheRequestButton}
                            dataRef={`${SEND_TO_MESSENGER_REF}${adminId}`}
                            pageId={pageId}
                            appId={window.CHATFUEL_CONFIG.APP_ID!}
                            onOptIn={async () => {
                              setTestRequestSend(true);
                              await timer(1000);
                              await saveDataAndSendRequest();
                              setDontShowSendToMessenger(true);
                              setSendToMessengerClickedToLocaleStore(botId);
                            }}
                            preventAutoReInit
                            onClick={() => {
                              if (!sendTestRequestDontReady) {
                                saveDataAndSendRequest();
                              }
                            }}
                            onRendered={sendToMessengerRenderWatchDog.cancel}
                          />
                        );
                      }}
                    </Query>
                  )}
                </Flex>
              </PluginBlock>
              {pluginConfig.show_error_messages && (
                <PluginBlock>
                  <Localization
                    localization={pluginLocalization}
                    onChange={(localization) =>
                      setPluginConfigState({ localization })
                    }
                    onBlur={() => savePlugin()}
                  />
                </PluginBlock>
              )}
              {res && <JsonPluginRequestTestResult result={res} />}
            </React.Fragment>
          );
        }}
      </Mutation>
    </PluginBox>
  );
};
