import React, { useRef } from 'react';
import { Input } from '@ui/Input';
import { useHoverDisclosure } from '@ui/HoverDisclosure';
import {
  deserialize,
  TextWithAttributesEditor,
} from '@ui/TextWithAttributesEditor';
import { ignoreEnterKey } from '@utils/DOM/keyHandlers';
import { IconButton } from '@ui/IconButton';
import { AttributesQuery_bot_variableSuggest as Attribute } from '@utils/AttributesUtils/@types/AttributesQuery';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as originCss from '../JsonPlugin.css';
import * as css from './UrlEncodedParam.css';

interface UrlEncodedParamProps {
  paramName: string;
  paramValue: string;
  autoFocus: boolean;
  botAttributes: Attribute[];
  onParamNameChange(nextParamName: string): void;
  onParamValueChange(nextParamValue: string): void;
  onBlur(): void;
  onDelete(): void;
}

export const UrlEncodedParam: React.FC<UrlEncodedParamProps> = ({
  paramName,
  paramValue,
  autoFocus,
  botAttributes,
  onParamNameChange,
  onParamValueChange,
  onBlur,
  onDelete,
}) => {
  const { t } = useSafeTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { visible } = useHoverDisclosure(containerRef);

  return (
    <div ref={containerRef} className={originCss.itemLine}>
      <div className={css.itemNameInput}>
        <Input
          render={() => (
            <TextWithAttributesEditor
              autoFocus={autoFocus}
              defaultValue={deserialize(paramName)}
              onStringChange={onParamNameChange}
              onBlur={onBlur}
              placeholder={t('JSONPlugin-string-8081-title')}
              attributes={botAttributes}
              onKeyDown={ignoreEnterKey}
              singleLine
              hasManageAttributes
            />
          )}
        />
      </div>
      <div className={css.itemValueInput}>
        <Input
          render={() => (
            <TextWithAttributesEditor
              defaultValue={deserialize(paramValue)}
              onStringChange={onParamValueChange}
              onBlur={onBlur}
              placeholder={t('JSONPlugin-string-8242-value')}
              attributes={botAttributes}
              onKeyDown={ignoreEnterKey}
              singleLine
              hasManageAttributes
            />
          )}
        />
      </div>
      {visible && (
        <IconButton
          icon="delete"
          className={originCss.trashIcon}
          onClick={onDelete}
        />
      )}
    </div>
  );
};
