import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { BotPageRouteParams, BotTabs, getTabLink } from '@utils/Routing';
import { TabNavLinkWithRole } from '../TabLink/TabLink';
import * as css from '../../common.css';
import { NavItemProps, NavItemViewProps } from './types';
import { NavItemView } from '../NavItems/NavItemView';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { OnboardingTourEventType, OnboardingTourHTMLElementId } from '@globals';

export const ReEngageNavItemView: React.FC<NavItemViewProps> = ({ small }) => {
  const { t } = useSafeTranslation();

  return (
    <NavItemView
      title={t('modernComponents.LeftNavigationPanel.reengageTab')}
      small={small}
      icon="tabs_reEngage"
    />
  );
};

export const ReEngageNavItem: React.FC<NavItemProps> = ({
  small,
  onClick,
  className,
  disabled,
}) => {
  const { botId } = useParams<BotPageRouteParams>();
  const { t } = useSafeTranslation();

  const handleClick = () => {
    onClick?.();
    OnboardingEmitter.emit(OnboardingTourEventType.click, {
      element: OnboardingTourHTMLElementId.NavLinkToBroadcast,
      value: null,
    });
  };

  return (
    <TabNavLinkWithRole
      data-testid="test-nav-to-broadcast"
      id={OnboardingTourHTMLElementId.NavLinkToBroadcast}
      tabUrl={getTabLink(BotTabs.reEngage, botId)}
      tabEvent="broadcast"
      domain="broadcasting"
      className={className}
      small={small}
      onClick={handleClick}
      disabled={disabled}
      contentClassName={cn(css.linkTooltip)}
      activeClassName={cn(css.activeLink)}
      submenu={
        small ? (
          <Type weight="medium" size="12px">
            {t('modernComponents.LeftNavigationPanel.reengageTab')}
          </Type>
        ) : null
      }
    >
      <ReEngageNavItemView small={small} />
    </TabNavLinkWithRole>
  );
};
