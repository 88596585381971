import { useEffect, useRef, useState } from 'react';
import { PluginDataChildrenParams } from './PluginData';
import isEmpty from 'lodash-es/isEmpty';

interface UseReactPluginConfigArgs<TPluginConfig extends {}> {
  saving: PluginDataChildrenParams<TPluginConfig>['saving'];
  pluginConfig: PluginDataChildrenParams<TPluginConfig>['pluginConfig'];
  savePluginAsync: PluginDataChildrenParams<TPluginConfig>['savePluginAsync'];
  setPluginConfigState: PluginDataChildrenParams<TPluginConfig>['setPluginConfigState'];
}

interface UseReactPluginConfigResult<TPluginConfig extends {}> {
  configState: TPluginConfig;

  /**
   * WARNING: {@param configPatch} патч применяется только на верхнем уровне вложенности
   */
  updateConfig(configPatch?: Partial<TPluginConfig>): void;

  /**
   * WARNING: {@param configPatch} патч применяется только на верхнем уровне вложенности
   */
  savePluginSafely(configPatch?: Partial<TPluginConfig>): void;
}

/**
 * Попытка сделать PluginData дружественной для работы с реакт компонентами
 *
 * Какие сейчас есть проблемы:
 * 1. `setPluginConfigState` работает через apollo, `setState` вызывает асинхронно,
 *    поэтому строковые значение не получается использовать напрямую в инпутах
 *    (нельзя нормально редактировать текст в середине)
 * 2. если давать редактировать плагин во время `saving` можно получить несколько неприятных артефактов:
 *    - ответ сервера перезапишет текущее состояние плагина
 *    - при отправлении двух запросов с небольшой разницей, можно получить race condition
 */
export const useReactPluginConfig = <TPluginConfig extends {}>({
  saving,
  pluginConfig,
  savePluginAsync,
  setPluginConfigState,
}: UseReactPluginConfigArgs<TPluginConfig>): UseReactPluginConfigResult<TPluginConfig> => {
  const [configState, setConfigState] = useState<TPluginConfig>(pluginConfig);
  const delayedSaveConfig = useRef<TPluginConfig>();

  const updateConfig = (configPatch: Partial<TPluginConfig> = {}) => {
    if (isEmpty(configPatch)) return;

    const nextConfig = {
      ...configState,
      ...configPatch,
    };

    setConfigState(nextConfig);
    setPluginConfigState({ config: nextConfig });
  };

  const savePluginSafely = (configPatch: Partial<TPluginConfig> = {}) => {
    const nextConfig = isEmpty(configPatch)
      ? configState
      : {
          ...configState,
          ...configPatch,
        };

    setConfigState(nextConfig);
    setPluginConfigState({ config: nextConfig });

    if (!saving) {
      savePluginAsync(nextConfig);
    } else {
      // запоминаем состояние, для того чтобы сохранять конфиг только в ожидаемые моменты
      // например на блюр, на добавление / удаление элементов
      // (чтобы избежать сохранения кусков названий атрибутов)
      delayedSaveConfig.current = nextConfig;
    }
  };

  useEffect(() => {
    if (!saving && delayedSaveConfig.current) {
      savePluginAsync(delayedSaveConfig.current);
      delayedSaveConfig.current = undefined;
    }
  });

  return {
    configState,
    updateConfig,
    savePluginSafely,
  };
};
