import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment whatsappPopupEntryPointFragment on WhatsappPopupEntryPoint {
    ...commonPluginFragment
    config {
      header
      text
      image
      buttonText
      prefilledMessage
      delay_s
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
