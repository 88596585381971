import { ExternalIntegrationEntryPointType } from '@globals';
import { PluginType } from '../common/PluginTypes';
import { externalIntegrationEntryPointFragment_config } from './@types/externalIntegrationEntryPointFragment';

export type ExternalIntegrationEntryPointConfig = externalIntegrationEntryPointFragment_config;

export const PLUGIN_TYPE = PluginType.external_integration_entry_point;

export const PLUGIN_DEFAULT_CONFIG: Partial<ExternalIntegrationEntryPointConfig> = {
  __typename: 'ExternalIntegrationEntryPointConfig',
  integration_type: ExternalIntegrationEntryPointType.zapier,
};

export const PLUGIN_INPUT_FIELD = 'externalIntegrationEntryPointConfig';
