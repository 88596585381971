import React from 'react';
import { Formik } from 'formik';
import { Type } from '@ui/Type';
import { RadioButton } from '@ui/Radio';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { FormGroup, FormValue } from './types';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export interface CreateNewWorkspaceBotContentProps {
  name: string;
  onSubmit: (value: FormValue) => void;
  onClose: () => void;
}

export const CreateNewWorkspaceBotContent: React.FC<CreateNewWorkspaceBotContentProps> =
  ({ name, onSubmit, onClose }) => {
    const { t } = useSafeTranslation();

    return (
      <Formik
        onSubmit={(values) => onSubmit(values)}
        initialValues={{ group: FormGroup.workspace }}
      >
        {({ values, handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Type size="15px" weight="medium">
              {t('modernComponents.BotSwitcher.createNewBotModal.description')}
            </Type>

            <Spacer factor={4} />

            <RadioButton
              name="group"
              id={FormGroup.workspace}
              label={name}
              onChange={handleChange}
              value={values.group}
            />

            <Spacer factor={4} />

            <RadioButton
              name="group"
              id={FormGroup.withoutworkspace}
              label={t(
                'modernComponents.BotSwitcher.createNewBotModal.withoutWorkspace',
              )}
              onChange={handleChange}
              value={values.group}
            />

            <Spacer factor={6} />

            <Flex>
              <Button
                onClick={() => handleSubmit()}
                intent="primary"
                data-testid="new-bot-modal__create"
              >
                {t('modernComponents.BotSwitcher.createNewBotModal.create')}
              </Button>

              <Spacer factor={0} horizontalFactor={3} />

              <Button
                onClick={() => onClose()}
                intent="secondary"
                data-testid="new-bot-modal__cancel"
              >
                {t('modernComponents.BotSwitcher.createNewBotModal.cancel')}
              </Button>
            </Flex>
          </form>
        )}
      </Formik>
    );
  };
