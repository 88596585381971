import React from 'react';
import { Flex } from '@ui/Flex';
import { Icon, IconProps } from '@ui/Icon';
import { IconNames } from '@ui/Icon/icons';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { ProBadge } from '@components/ProBadge';
import { ScreenSizes } from '@utils/DOM/useDeviceMedia';
import { FormattedStatistics, FormattedStatisticsProps } from './Statistics';

interface BotSwitcherStatisticsRegularProps extends FormattedStatisticsProps {
  showProBadge: boolean;
  iconSize: IconProps['size'];
  label?: string;
  icon: IconNames;
}

const BotSwitcherStatisticsRegular: React.FC<BotSwitcherStatisticsRegularProps> =
  ({ label, showProBadge, iconSize, icon, ...props }) => (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex>
        <Icon icon={icon} size={iconSize} color={props.numeratorColor} />
        <Spacer factor={0} horizontalFactor={0.5} />
        <FormattedStatistics {...props} />
        {label && (
          <Type size={props.fontSize} color={props.denominatorColor}>
            &#160;{label}
          </Type>
        )}
      </Flex>
      {showProBadge && (
        <>
          <Spacer factor={0} horizontalFactor={1.5} />

          <ProBadge size="9px" data-testid="bot-switcher__pro-badge" />
        </>
      )}
    </Flex>
  );

interface BotSwitcherStatisticsSmallProps extends FormattedStatisticsProps {}

const BotSwitcherStatisticsSmall: React.FC<BotSwitcherStatisticsSmallProps> = (
  props,
) => (
  <Flex inline alignItems="center" justifyContent="center" flexWrap>
    <FormattedStatistics {...props} />
  </Flex>
);

export interface BotSwitcherStatisticsProps
  extends Omit<
    BotSwitcherStatisticsSmallProps & BotSwitcherStatisticsRegularProps,
    'fontSize' | 'iconSize'
  > {
  screenSize: ScreenSizes;
}

export const BotSwitcherStatistics: React.FC<BotSwitcherStatisticsProps> = ({
  label,
  ...props
}) => {
  return {
    [ScreenSizes.small]: (
      <BotSwitcherStatisticsRegular
        {...props}
        fontSize="15px"
        iconSize="20px"
        label={label}
      />
    ),
    [ScreenSizes.medium]: (
      <BotSwitcherStatisticsSmall {...props} fontSize="12px" />
    ),
    [ScreenSizes.large]: (
      <BotSwitcherStatisticsRegular
        {...props}
        fontSize="12px"
        iconSize="16px"
      />
    ),
  }[props.screenSize];
};
