import { CustomerActionType, WelcomeMessageMediaType } from '@globals';
import { PluginType } from '../common/PluginTypes';
import { facebookAdsEntryPointFragment_config } from './@types/facebookAdsEntryPointFragment';

export type FacebookAdsEntryPointConfig = facebookAdsEntryPointFragment_config;

const PLUGIN_DEFAULT_CONFIG: Omit<
  FacebookAdsEntryPointConfig,
  'customer_action_type'
> = {
  __typename: 'FacebookAdsEntryPointConfig',
  campaigns: [],
  greeting: {
    __typename: 'FacebookAdsGreeting',
    type: WelcomeMessageMediaType.text,
  },
  welcomeMessageType: WelcomeMessageMediaType.text,
};

export const PLUGIN_DEFAULT_CTM_CONFIG: FacebookAdsEntryPointConfig = {
  ...PLUGIN_DEFAULT_CONFIG,
  customer_action_type: CustomerActionType.quick_replies,
};

export const PLUGIN_DEFAULT_CTM_JSON_CONFIG: FacebookAdsEntryPointConfig = {
  ...PLUGIN_DEFAULT_CONFIG,
  customer_action_type: CustomerActionType.quick_replies,
};

export const PLUGIN_DEFAULT_SM_CONFIG: FacebookAdsEntryPointConfig = {
  ...PLUGIN_DEFAULT_CONFIG,
  customer_action_type: CustomerActionType.buttons,
};

export const PLUGIN_INPUT_FIELD = 'facebookAdsEntryPointConfig';

export const PLUGIN_TYPE_CTM = PluginType.ads_manager_ctm_entry_point;
export const PLUGIN_TYPE_CTM_JSON = PluginType.ads_manager_ctm_json_entry_point;
export const PLUGIN_TYPE_SM = PluginType.ads_manager_sm_entry_point;
