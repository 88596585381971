import React, { useState } from 'react';

import { PluginBlock } from '../../../common/PluginBlock';
import { PluginControlLabel } from '../../../common/PluginControlLabel';

import { SegmentedGroupTrack } from '../../../../../modern-ui/SegmentedGroup';
import { SegmentedGroupTrackColorWay } from '../../../../../modern-ui/SegmentedGroup/SegmentedGroupTrack';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
} from '../../../../../modern-ui/_deprecated/Button';

import { EventType } from '../../FacebookAnalyticsPluginConst';

import { facebookAnalyticsPluginFragment_config as FacebookAnalyticsPluginConfig } from '../../@types/facebookAnalyticsPluginFragment';
import { FacebookAnalyticsPluginDataProps } from '../../FacebookAnalyticsPlugin';

import * as css from './FacebookAnalyticsPluginEventTypesSwitch.css';

interface FacebookAnalyticsPluginEventTypesSwitchProps
  extends FacebookAnalyticsPluginDataProps {
  setDropdownTouched: (value: React.SetStateAction<boolean>) => void;
}

export const FacebookAnalyticsPluginEventTypesSwitch: React.FC<FacebookAnalyticsPluginEventTypesSwitchProps> = ({
  pluginConfig,
  setPluginConfigState,
  savePlugin,
  setDropdownTouched,
}) => {
  const [otherEventTypeValue, setOtherEventTypeValue] = useState('');
  return (
    <PluginBlock>
      <PluginControlLabel
        label={window.i18next.t(
          'FacebookAnalyticsPluginEventTypesSwitch-string-2855-event-types',
        )}
      >
        {() => (
          <SegmentedGroupTrack
            role="tablist"
            colorWay={SegmentedGroupTrackColorWay.grey}
            className={css.eventType}
          >
            <Button
              role="tab"
              intent={ButtonIntent.toggle}
              colorWay={ButtonColorWay.toggleWhiteHover}
              size={ButtonSize.s}
              aria-selected={
                pluginConfig.eventType === EventType.standard ||
                !pluginConfig.eventType
              }
              onClick={() => {
                const config: FacebookAnalyticsPluginConfig = {
                  ...pluginConfig,
                  eventType: EventType.standard,
                  eventId: otherEventTypeValue,
                };
                setOtherEventTypeValue(pluginConfig.eventId || '');
                setPluginConfigState({ config });
                savePlugin();
                setDropdownTouched(false);
              }}
            >
              {window.i18next.t(
                'FacebookAnalyticsPluginEventTypesSwitch-JSXText-3319-standard',
              )}
            </Button>
            <Button
              role="tab"
              intent={ButtonIntent.toggle}
              colorWay={ButtonColorWay.toggleWhiteHover}
              size={ButtonSize.s}
              aria-selected={pluginConfig.eventType === EventType.custom}
              onClick={() => {
                const config: FacebookAnalyticsPluginConfig = {
                  ...pluginConfig,
                  eventType: EventType.custom,
                  eventId: otherEventTypeValue,
                };
                setOtherEventTypeValue(pluginConfig.eventId || '');
                setPluginConfigState({ config });
                savePlugin();
              }}
            >
              {window.i18next.t(
                'FacebookAnalyticsPluginEventTypesSwitch-JSXText-9966-custom',
              )}
            </Button>
          </SegmentedGroupTrack>
        )}
      </PluginControlLabel>
    </PluginBlock>
  );
};
