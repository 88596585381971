import { openAiPluginFragment_config as OpenAiPluginConfig } from './@types/openAiPluginFragment';

import { PluginType } from '../common/PluginTypes';

export const PLUGIN_TYPE = PluginType.open_ai;

const LIVE_CHAT_DEFAULT_PROMPT = `is irritated, annoyed, unsatisfied, frustrated, etc.;
states that this conversation is useless, unhelpful, pointless, frustrating, inadequate, ineffective, and incompetent;
requests to speak to a human, person, representative, manager, administrator, operator, someone, real person, customer service agent, etc.;
requests to end the conversation and stop chatting, etc.`;

export const PLUGIN_DEFAULT_CONFIG: OpenAiPluginConfig = {
  __typename: 'OpenAiPluginConfig',
  intents: [
    {
      __typename: 'OpenAiPluginIntent',
      key: 'Company name',
      value: '',
    },
    {
      __typename: 'OpenAiPluginIntent',
      key: 'Company phone',
      value: '',
    },
    {
      __typename: 'OpenAiPluginIntent',
      key: 'Company description',
      value: '',
    },
  ],
  goodAnswerNextBlock: null,
  poorAnswerNextBlock: null,
  liveChatAnswerNextBlock: null,
  /**
   * https://github.com/chatfuel-lab/backend/commit/200c346e3ee581db5993dd542356c9d393e67b64#diff-aed5560b0f5477f2465f4596b4c71504f0580e074fa151df760a54840e72a367R967
   */
  messagesHistory: 5,
  prompt: '',
  liveChatPrompt: LIVE_CHAT_DEFAULT_PROMPT,
  saveToVariable: null,
};

export const PLUGIN_INPUT_FIELD = 'openAiPluginConfig';
