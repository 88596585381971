import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React from 'react';

interface CloseDialogProps {
  onClose: VoidFunction;
  onHidePopup: VoidFunction;
}

export const CloseDialog: React.FC<CloseDialogProps> = ({
  onClose,
  onHidePopup,
}) => {
  const { t } = useSafeTranslation();

  return (
    <DefaultDialog
      header={t('MiniOnboarding.CloseDialog.header')}
      onDismiss={onClose}
      footer={
        <Flex>
          <Button onClick={onClose}>
            {t('MiniOnboarding.CloseDialog.cancel')}
          </Button>
          <Spacer horizontalFactor={4} factor={1} />
          <Button intent="secondary" onClick={onHidePopup}>
            {t('MiniOnboarding.CloseDialog.hide')}
          </Button>
        </Flex>
      }
    >
      {t('MiniOnboarding.CloseDialog.areYouSure')}
    </DefaultDialog>
  );
};
