import React from 'react';
import { Bottle } from '@components/MiniOnboarding/Container/Bottle/Bottle';
import { ListItem } from '@components/MiniOnboarding/ListItem/ListItem';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { CenteredLoader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';
import { useEntryPointTipsQuery } from '@utils/Data/MiniOnboarding/useEntryPointTipsQuery';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useCurrentBotId, useCurrentFlowId } from '@utils/Routing';
import { LSKey, useLocalStorage } from '@utils/LocalStorage';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import css from './TipsDialog.css';
import { OnboardingTipsStatus } from '@globals';
import Maybe from 'graphql/tsutils/Maybe';

interface TipsDialogProps {
  onClose: VoidFunction;
  onboardingTourId: Maybe<string>;
}

export const TipsDialog: React.FC<TipsDialogProps> = ({
  onClose,
  onboardingTourId,
}) => {
  const botId = useCurrentBotId();
  const flowId = useCurrentFlowId();
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const [showFlowBuilderOnMobile, setShowFlowBuilderOnMobile] = useLocalStorage(
    LSKey.showFlowBuilderOnMobile,
  );

  const { data: entryPointTipsData } = useEntryPointTipsQuery(botId, {
    fetchPolicy: 'network-only',
  });

  const tips = entryPointTipsData?.entryPointTips;

  const { platform, sections } = tips ?? {};
  const entryPoints = sections?.flatMap(({ entryPoints }) => entryPoints);

  const currentEntryPoint = (entryPoints ?? []).find(
    (entryPoint) =>
      (onboardingTourId &&
        entryPoint.onboardingTour?.id === onboardingTourId) ||
      entryPoint.flowId === flowId,
  );

  if (!entryPoints || !platform || !currentEntryPoint)
    return <CenteredLoader />;

  const nextEntryPoint = entryPoints.find(
    (entryPoint) =>
      entryPoint.status === OnboardingTipsStatus.available &&
      entryPoint.onboardingTour?.id !== onboardingTourId &&
      entryPoint.flowId !== flowId,
  );

  return (
    <DefaultDialog
      className={css.dialog}
      onDismiss={onClose}
      closeButtonClassName={css.closeButtonDialog}
    >
      <Flex flexDirection="column" alignItems="center">
        <Bottle platform={platform} entryPoints={entryPoints} />
        <Spacer factor={6} />
        {nextEntryPoint ? (
          <Flex flexDirection="column" gapFactor={1}>
            <ListItem
              {...currentEntryPoint}
              isButtonActive={false}
              containerClassName={css.listItem}
              status={OnboardingTipsStatus.done}
              platform={platform}
              botId={botId!}
              showFlowBuilderOnMobile={showFlowBuilderOnMobile}
              setShowFlowBuilderOnMobile={setShowFlowBuilderOnMobile}
              isSmallScreenSize={isSmallScreenSize}
              isTipsDialog
            />
          </Flex>
        ) : (
          <Flex flexDirection="column" gapFactor={8} alignItems="center">
            <Type
              size="24px"
              weight="semibold"
              whiteSpace="pre"
              align="center"
              color="white"
            >
              {t('MiniOnboarding.yourBotSetUp')}
            </Type>
            <Button onClick={onClose}>{t('MiniOnboarding.close')}</Button>
          </Flex>
        )}
        <Spacer factor={2} />
      </Flex>
    </DefaultDialog>
  );
};
