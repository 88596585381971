import React, { useEffect, useState } from 'react';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import css from './WelcomeDialog.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface WelcomeDialogProps {
  title: string;
  description: string;
  imageUrl: string;
  onDismiss: VoidFunction;
  onStart: VoidFunction;
}

export const WelcomeDialog: React.FC<WelcomeDialogProps> = ({
  title,
  description,
  imageUrl,
  onDismiss,
  onStart,
}) => {
  const [imageLoadind, setImageLoading] = useState(true);
  const { t } = useSafeTranslation();

  useEffect(() => {
    if (imageLoadind) setImageLoading(true);
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      setImageLoading(false);
    };

    return () => {
      image.onload = null;
    };
  }, [imageUrl, imageLoadind, setImageLoading]);

  return (
    <DefaultDialog
      footer={
        <Button
          className={css.startAction}
          onClick={onStart}
          data-testid="onbording-tour_welcome-dialog_start-button"
        >
          {t('MiniOnboarding.start')}
        </Button>
      }
      onDismiss={onDismiss}
    >
      <Flex flexDirection="column" alignItems="center">
        {!imageLoadind && (
          <div className={css.imageContainer}>
            <Spacer factor={8} />
            <img
              src={imageUrl}
              alt="onboarding tour preview"
              className={css.image}
            />
            <Spacer factor={6} />
          </div>
        )}
        <Type size="24px" weight="semibold">
          {title}
        </Type>
        <Spacer factor={4} />
        <Type size="15px">{description}</Type>
      </Flex>
    </DefaultDialog>
  );
};
