import cn from 'classnames';
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useParams } from 'react-router-dom';
import { useAdminFeatures } from '@utils/Data/Admin';
import { useBotFeatures } from '@utils/Data/Bot';
import { RoleConsumer } from '@utils/Roles';
import { BotPageRouteParams, BotTabs, getTabLink } from '@utils/Routing';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { Permission } from '../../../../common/services/RoleService';
import { TabNavLinkWithRole } from '../TabLink/TabLink';
import { TabLinkSkeleton } from '../TabLinkSkeleton/TabLinkSkeleton';
import { AutomationNavSubItems } from './AutomationNavSubItems';
import { NavItemProps, NavItemViewProps } from './types';
import * as css from '../../common.css';
import { NavItemView } from '../NavItems/NavItemView';

export const AutomationNavItemView: React.FC<NavItemViewProps> = ({
  small,
}) => {
  const { t } = useSafeTranslation();

  return (
    <NavItemView
      title={t('modernComponents.LeftNavigationPanel.automateTab')}
      small={small}
      icon="tabs_automate"
    />
  );
};

export const AutomationNavItem: React.FC<NavItemProps> = ({
  small,
  onClick,
  className,
  disabled,
}) => {
  const { botId } = useParams<BotPageRouteParams>();
  const { botFeatures } = useBotFeatures(botId || '');
  const { adminFeatures } = useAdminFeatures();
  const { isAutomateEnabled } = useAutomateEnabled();

  return (
    <RoleConsumer domain="flows" can={Permission.VIEW}>
      {({ allowed, loading }) => {
        const isFlowAvailable =
          allowed && (botFeatures?.flow_builder || adminFeatures?.flow_builder);

        const botTab = isFlowAvailable
          ? BotTabs.flows
          : isAutomateEnabled
          ? BotTabs.automate
          : BotTabs.keywords;
        const tabEvent = isFlowAvailable
          ? 'flows'
          : isAutomateEnabled
          ? 'automate'
          : 'ai';
        const domain = isFlowAvailable
          ? 'flows'
          : isAutomateEnabled
          ? 'groups'
          : 'ai';

        if (loading) {
          return <TabLinkSkeleton small={small} />;
        }

        return (
          <TabNavLinkWithRole
            data-testid="test-nav-to-automation"
            tabUrl={getTabLink(botTab, botId)}
            tabEvent={tabEvent}
            domain={domain}
            className={className}
            onClick={onClick}
            small={small}
            disabled={disabled}
            contentClassName={cn(css.linkTooltip)}
            activeClassName={cn(css.activeLink)}
            isContentLink={false}
            submenuStyles={{ top: allowed ? '-32px' : '-16px' }}
            submenu={
              small ? (
                <AutomationNavSubItems
                  className={css.linkTooltip}
                  small={small}
                />
              ) : null
            }
          >
            <AutomationNavItemView small={small} />
          </TabNavLinkWithRole>
        );
      }}
    </RoleConsumer>
  );
};
