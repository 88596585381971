import React, { useMemo } from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { useQrCodeUrl } from '@utils/QrCodes/useQrCodeUrl';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { useEntryPointTipsSubscribeToBotCompleted } from './useEntryPointTipsSubscribeToBotCompleted';
import { useCurrentBotId } from '@utils/Routing';
import { removeNonDigits } from '@utils/String/removeNonDigits';
import {
  useWhatsappConnectionState
} from '@pages/BotPage/HomeTab/components/WhatsappAccount/hooks/useWhatsappConnectionState';

interface WhatsappSubscribeToPhoneProps {
  onComplete: VoidFunction;
  onDismiss: VoidFunction;
}

export const WhatsappSubscribeToPhone: React.FC<WhatsappSubscribeToPhoneProps> =
  ({ onComplete, onDismiss }) => {
    const { t } = useSafeTranslation();
    const { isSmallScreenSize } = useDeviceMedia();
    const { currentPhone } = useWhatsappConnectionState();
    const from = useMemo(() => String(Date.now()), []);
    const textToSubscribe = t(
      'MiniOnboarding.WhatsappSubscribeToPhone.helloWorld',
    );
    const botId = useCurrentBotId()!;
    useEntryPointTipsSubscribeToBotCompleted({
      variables: {
        botId,
        text: textToSubscribe,
        from,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted(data) {
        if (data.entryPointTipsSelfSubscription?.subscribed === true) {
          onComplete();
        }
      },
    });
    const phone = currentPhone.display_phone_number;
    const phoneUrl =
      phone &&
      `https://wa.me/${removeNonDigits(phone)}?${new URLSearchParams({
        text: textToSubscribe,
      }).toString()}`;
    const { qrCodeUrl } = useQrCodeUrl(phoneUrl);

    return (
      <DefaultDialog
        header={t('MiniOnboarding.WhatsappSubscribeToPhone.scanQRcode')}
        onDismiss={onDismiss}
        mobileAdaptive={isSmallScreenSize}
      >
        <Flex alignItems="center" flexDirection="column">
          {qrCodeUrl && <img alt="" height={168} width={168} src={qrCodeUrl} />}
          <Spacer factor={4} />
          {phoneUrl && (
            <Type>
              <Anchor
                intent="external"
                href={phoneUrl}
                target="_blank"
                hideArrow
              >
                {t('MiniOnboarding.WhatsappSubscribeToPhone.clickHere')}
              </Anchor>{' '}
              {t('MiniOnboarding.WhatsappSubscribeToPhone.usingDesktop')}
            </Type>
          )}
          <Spacer factor={4} />
          <Type align="center">
            {t('MiniOnboarding.WhatsappSubscribeToPhone.openWAWithText')}
          </Type>
        </Flex>
      </DefaultDialog>
    );
  };
