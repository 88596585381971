import { Platform } from '@globals';

export enum Category {
  facebook = 'facebook',
  instagram = 'instagram',
  website = 'website',
  shopify = 'shopify',
  whatsapp = 'whatsapp',
}

export interface ICategory {
  title: string;
  category: Category;
  platform: Platform;
  childrenAmount?: number;
  disableTooltipContent?: React.ReactNode;
}

export interface CategoryItem {
  id: String;
  category: Category;
}
