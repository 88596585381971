import { PluginType } from '@components/Plugins/common/PluginTypes';
import { ExternalIntegrationEntryPointType } from '@globals';
import { orderReceiptPluginFragment_config } from './@types/orderReceiptPluginFragment';

export type OrderReceiptPluginConfig = orderReceiptPluginFragment_config;

export const PLUGIN_TYPE = PluginType.order_receipt;

export const PLUGIN_DEFAULT_CONFIG: OrderReceiptPluginConfig = {
  __typename: 'OrderReceiptPluginConfig',
  integration_type: ExternalIntegrationEntryPointType.shopify,
  shopify_order: {
    __typename: 'ShopifyOrder',
    order_id: '{{ last shopify order id }}',
  },
};

export const PLUGIN_INPUT_FIELD = 'orderReceiptPluginConfig';
