import { PluginType } from '../common/PluginTypes';
import { customAiPluginFragment_config } from './@types/customAiPluginFragment';

export const PLUGIN_TYPE = PluginType.custom_ai;

export const PLUGIN_INPUT_FIELD = 'customAiPluginConfig';

// no sense, plugin configured by backend
export const PLUGIN_DEFAULT_CONFIG: customAiPluginFragment_config = {
  __typename: 'CustomAiPluginConfig',
  aiConditions: [],
  aiIntents: [],
  description: '',
  prompt: '',
  title: '',
  aiModel: 'dumb-model',
  templateId: 'dumb-template',
  userInputMaxTokens: 12000,
};
