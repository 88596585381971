import React, { useState } from 'react';
import { UserNavigationItemButton } from '@common/user/UserNavigationItem/UserNavigationItemButton';
import { UserNavigationItemWithData } from '@common/user/UserNavigationItem/UserNavigationItemWithData';
import { TabButton } from '../TabLink/TabLink';
import { NavItemProps } from './types';

export const UserNavItem: React.FC<NavItemProps> = React.memo(
  ({ small, onClick }) => {
    const [isOpen, setOpen] = useState(false);

    return (
      <TabButton
        tabEvent="user"
        data-testid="test-nav-user"
        onClick={() => {
          onClick?.();
          setOpen((open) => !open);
        }}
      >
        <UserNavigationItemWithData
          placement="right-end"
          offset="-6, 10"
          small={Boolean(small)}
          setOpen={setOpen}
          isOpen={isOpen}
        >
          {({ user, isPremium, hasNotification, small }) => (
            <UserNavigationItemButton
              isPremium={isPremium}
              hasNotification={hasNotification}
              src={user.picture}
              name={user.name}
              small={small}
            />
          )}
        </UserNavigationItemWithData>
      </TabButton>
    );
  },
);
