import { useMemo, useRef, useState } from 'react';
import { getFlowController } from '@components/FlowBuilder/PixiFieldRepository';
import { OnbordingZoneData, Rect } from '../../types';
import { addOffsetToRect } from '../utils/addOffsetToRect';
import { addPaddingToRect } from '../utils/addPaddingToRect';
import { getZoneId } from '../utils/getZoneId';
import {
  getFlowBuilderZoneBounds,
  getHTMLElementZoneBounds,
} from '../utils/getZoneBounds';
import { useUnmountRef } from '@utils/useUnmountRef';
import { getShapeName } from '../utils/getShapeName';
import isEqual from 'lodash-es/isEqual';

interface useZoneBoundsPayload {
  flowBuilderRootElement: HTMLElement | null;
  viewportUpdating: boolean;
  zoneData: OnbordingZoneData;
  needUpdateZone: boolean;
  setNeedUpdateZone(value: boolean): void;
}

const PADDING = 5;

export const useZoneBounds = ({
  flowBuilderRootElement,
  viewportUpdating,
  zoneData,
  needUpdateZone,
  setNeedUpdateZone,
}: useZoneBoundsPayload) => {
  const [focusedId, setFocusedId] = useState<string | null>(null);

  const unmountRef = useUnmountRef();

  const { focus, active } = zoneData;
  const focusId = focus ? getZoneId(focus) : null;

  const flowBuilderRootElementBounds = useMemo(
    () => flowBuilderRootElement?.getBoundingClientRect(),
    [flowBuilderRootElement],
  );

  const prevfocusZoneBoundsRef = useRef<Rect | undefined>();
  const focusZoneBounds = useMemo<Rect | undefined>(() => {
    /**
     * если сейчас происходят перерисовки внутри flowBuilder то мы ждем
     */
    if (viewportUpdating) {
      prevfocusZoneBoundsRef.current = undefined;
      return undefined;
    }

    const flowController = flowBuilderRootElementBounds
      ? getFlowController()
      : null;
    const isFocusFlowBuilderBlock =
      // eslint-disable-next-line no-underscore-dangle
      focus && focus.__typename === 'OnboardingTourFlowBuilderZone';
    const isFocusHTMLElement =
      // eslint-disable-next-line no-underscore-dangle
      focus && focus.__typename === 'OnboardingTourHTMLZone';

    /**
     * скролим в видимую облость и устаравливаем focusedId
     */
    if (focusedId !== focusId) {
      if (isFocusFlowBuilderBlock && focus.blockId) {
        flowController?.focusOnBlock(focus.blockId).then(() => {
          if (unmountRef.current) return;
          setFocusedId(focus.blockId);
        });
      } else {
        flowController?.fitScreen();
      }
      let newFocusedId = null;
      if (isFocusHTMLElement) newFocusedId = focusId;
      else if (isFocusFlowBuilderBlock)
        newFocusedId = focus.blockId ?? focus.cardId ?? getShapeName(focus);
      setFocusedId(newFocusedId);
    }

    /**
     * вычисляем новые кординаты zoneBounds
     */
    let zoneBounds;
    if (isFocusFlowBuilderBlock) {
      zoneBounds = getFlowBuilderZoneBounds(flowController, focus);
      if (zoneBounds && flowBuilderRootElementBounds)
        zoneBounds = addOffsetToRect(zoneBounds, flowBuilderRootElementBounds);
    } else if (isFocusHTMLElement) {
      zoneBounds = getHTMLElementZoneBounds(focus, true);
      if (zoneBounds) zoneBounds = addPaddingToRect(zoneBounds, PADDING);
    }

    if (needUpdateZone) setNeedUpdateZone(false);

    if (
      prevfocusZoneBoundsRef.current &&
      isEqual(prevfocusZoneBoundsRef.current, zoneBounds)
    ) {
      return prevfocusZoneBoundsRef.current;
    }
    prevfocusZoneBoundsRef.current = zoneBounds;
    return zoneBounds;
  }, [
    focusId,
    focusedId,
    focus,
    flowBuilderRootElementBounds,
    viewportUpdating,
    setFocusedId,
    needUpdateZone,
    setNeedUpdateZone,
    unmountRef,
  ]);

  const activeZoneBounds = useMemo<Rect | undefined>(() => {
    if (focusZoneBounds === undefined) return undefined;
    if (!active) return undefined;
    const isActiveFlowBuilderBlock =
      // eslint-disable-next-line no-underscore-dangle
      active.__typename === 'OnboardingTourFlowBuilderZone';
    if (isActiveFlowBuilderBlock && flowBuilderRootElementBounds) {
      const flowController = getFlowController();
      const zoneBounds = getFlowBuilderZoneBounds(flowController, active);
      return (
        zoneBounds && addOffsetToRect(zoneBounds, flowBuilderRootElementBounds)
      );
    }
    // eslint-disable-next-line no-underscore-dangle
    return active.__typename === 'OnboardingTourHTMLZone'
      ? getHTMLElementZoneBounds(active, false)
      : undefined;
  }, [flowBuilderRootElementBounds, focusZoneBounds, active]);

  return {
    focusZoneBounds,
    activeZoneBounds,
    focusZoneUpdating: focusId !== focusedId,
  };
};
