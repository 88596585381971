import { PluginType } from '../common/PluginTypes';
import { whatsappBotLinkEntryPointFragment_config as WhatsappBotLinkEntryPointConfig } from './@types/whatsappBotLinkEntryPointFragment';

export const PLUGIN_TYPE = PluginType.whatsapp_bot_link;

export const PLUGIN_DEFAULT_CONFIG: WhatsappBotLinkEntryPointConfig = {
  __typename: 'WhatsappBotLinkEntryPointConfig',
  prefilledMessage: null,
};

export const PLUGIN_INPUT_FIELD = 'whatsappBotLinkEntryPointConfig';
