export const MIN_BOTS_AMOUNT = 5;

export const NAVIGATION_HEIGHT = 64;
export const SEARCH_INPUT_HEIGHT = 60;
export const ALL_BOTS_BUTTON_HEIGHT = 60;
export const OTHER_ITEMS_HEIGHT = SEARCH_INPUT_HEIGHT + ALL_BOTS_BUTTON_HEIGHT;
export const OTHER_ITEMS_HEIGHT_WL = SEARCH_INPUT_HEIGHT;

export const MOBILE_ITEM_HEIGHT = 46;
export const ITEM_HEIGHT = 36;
