import React from 'react';
import { ShowNTimes } from '../../../utils/ShowNTimes';
import { PluginHeader } from '../common/PluginHeader';
import { PLUGIN_HELP_URL, PLUGIN_NAME } from './JsonPluginConst';
import { ReactComponent as Icon } from '../common/icons/icon_JSON_API.svg';
import { PluginBlock } from '../common/PluginBlock';
import { isWhiteLabelDomain } from '../../../utils/WhiteLabelUtils';
import { PLUGIN_TYPE } from '../FacebookAnalyticsPlugin/FacebookAnalyticsPluginConst';
import { ServerStorageItemKeys } from '../../../utils/ServerStorage';

export const JsonPluginHeader: React.FC = () => (
  <React.Fragment>
    <ShowNTimes
      showLimit={1}
      storageKey={ServerStorageItemKeys.jsonPluginHelpTooltipShowCount}
    >
      {({ show }) => (
        <PluginHeader
          title={PLUGIN_NAME}
          icon={<Icon />}
          helpUrl={PLUGIN_HELP_URL}
          pluginType={PLUGIN_TYPE}
          showHelpTooltip={show}
        />
      )}
    </ShowNTimes>
    <PluginBlock>
      <span>
        {window.i18next.t(
          'JsonPluginHeader-JSXText--150-create-integrations-with-your-own-server-or-other-3rd-party-systems-for-example-you-can-generate-dynamic-content-in-messenger-or-export-data-to-a-crm',
        )}
      </span>
      {!isWhiteLabelDomain() && (
        <a href={PLUGIN_HELP_URL} target="_blank" rel="noopener noreferrer">
          {window.i18next.t('JsonPluginHeader-JSXText--988-learn-more')}
        </a>
      )}
    </PluginBlock>
  </React.Fragment>
);
