import React, { useCallback, useState } from 'react';
import { Button } from '@ui/Button';
import { OnboardingStep } from '../types';
import { useOnboardingEvents } from './hooks/useOnboardingEvents';
import { usePixiVieportUpdate } from './hooks/usePixiVieportUpdate';
import { Overlay } from './Overlay/Overlay';
import { useZoneBounds } from './hooks/useZoneBounds';
import { sendEvent } from '@utils/Analytics';
import { OnboardingCallout } from './OnboardingCallout/OnboardingCallout';
import { WANewTemplate } from './CustomStep/WANewTemplate/WANewTemplate';
import { OnboardingTourCustomPopupId } from '@globals';

interface OnboardingTourProps {
  steps: OnboardingStep[];
  flowBuilderRootElement: HTMLElement | null;
  onCompletle: VoidFunction;
  tourName: string;
}

export const OnboardingTour: React.FC<OnboardingTourProps> = ({
  steps,
  flowBuilderRootElement,
  onCompletle,
  tourName,
}) => {
  const [step, setStep] = useState(0);
  const maxStep = steps.length - 1;
  const { nextStepEvent, popUpData, zoneData } = steps[step];

  const setOnbordingStep = useCallback(
    (newStep: number, isClick?: boolean) => {
      sendEvent({
        category: 'onbording-tour ',
        action: `next step ${isClick ? 'click' : 'trigger'}`,
        propertyBag: {
          step,
          stepText:
            // eslint-disable-next-line no-underscore-dangle
            popUpData.__typename === 'OnboardingTourPopupTextData'
              ? popUpData.text
              : popUpData.popupId,
          flowTourName: tourName,
        },
      });
      if (newStep > maxStep) onCompletle();
      else setStep(newStep);
    },
    [setStep, onCompletle, maxStep, popUpData, step, tourName],
  );

  const {
    nextStepButtonEnabled,
    clearInteractionData,
    needUpdateZone,
    setNeedUpdateZone,
  } = useOnboardingEvents({
    step,
    setOnbordingStep,
    nextStepTriggerEvent: nextStepEvent,
    buttonEnabledEvent:
      // eslint-disable-next-line no-underscore-dangle
      popUpData.__typename === 'OnboardingTourPopupTextData'
        ? popUpData.buttonEvent
        : null,
    zoneData,
  });

  const { viewportUpdating } = usePixiVieportUpdate();

  const { focusZoneBounds, activeZoneBounds, focusZoneUpdating } =
    useZoneBounds({
      flowBuilderRootElement,
      zoneData,
      viewportUpdating,
      needUpdateZone,
      setNeedUpdateZone,
    });

  return (
    <Overlay
      activeZoneBounds={activeZoneBounds}
      focusZoneBounds={focusZoneBounds}
      callout={() =>
        // eslint-disable-next-line no-underscore-dangle
        popUpData.__typename === 'OnboardingTourCustomPopupData' ? (
          {
            [OnboardingTourCustomPopupId.WANewTemplate]: (
              <WANewTemplate
                onComplete={() => {
                  setOnbordingStep(step + 1);
                  clearInteractionData();
                }}
              />
            ),
          }[popUpData.popupId]
        ) : (
          <OnboardingCallout
            focusZoneBounds={focusZoneBounds}
            show={!viewportUpdating && !focusZoneUpdating}
            text={popUpData.text}
            button={
              nextStepEvent ? null : (
                <Button
                  data-testid="onbording-tour_next-button"
                  onClick={() => {
                    setOnbordingStep(step + 1, true);
                    clearInteractionData();
                  }}
                  disabled={!nextStepButtonEnabled}
                >
                  {popUpData.buttonText}
                </Button>
              )
            }
          />
        )
      }
    />
  );
};
