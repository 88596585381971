import React from 'react';
import cn from 'classnames';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { BotPageRouteParams, BotTabs, getTabLink } from '@utils/Routing';
import { Type } from '@ui/Type';
import { isBotPaused, isBotPro } from '@utils/Pro';
import { TabNavLinkWithRole } from '../TabLink/TabLink';
import {
  GetBotDataForLeftNavigationQuery,
  GetBotDataForLeftNavigationQueryVariables,
} from './@types/GetBotDataForLeftNavigationQuery';
import * as css from '../../common.css';
import { TabLinkSkeleton } from '../TabLinkSkeleton/TabLinkSkeleton';
import { NavItemProps, NavItemViewProps } from './types';
import { NavItemView } from '../NavItems/NavItemView';

const GET_BOT_DATA_FOR_LEFT_NAVIGATION_QUERY = gql`
  query GetBotDataForLeftNavigationQuery($botId: String!) {
    bot(id: $botId) {
      id
      pro {
        status
        manual
      }
    }
  }
`;

export const UpgradeNavItemView = ({ small }: NavItemViewProps) => {
  const { t } = useSafeTranslation();

  return (
    <NavItemView
      color="red"
      title={t('modernComponents.LeftNavigationPanel.upgradeTab')}
      small={small}
      icon="tabs_upgrade"
    />
  );
};

export const UpgradeNavItem: React.FC<NavItemProps> = ({
  small,
  onClick,
  className,
  disabled,
}) => {
  const { botId } = useParams<BotPageRouteParams>();
  const { t } = useSafeTranslation();
  const { data, loading, error } = useQuery<
    GetBotDataForLeftNavigationQuery,
    GetBotDataForLeftNavigationQueryVariables
  >(GET_BOT_DATA_FOR_LEFT_NAVIGATION_QUERY, {
    variables: { botId: botId || '' },
    skip: !botId,
  });

  if (loading) {
    return <TabLinkSkeleton small={small} />;
  }

  if (error || !data || isBotPro(data.bot) || isBotPaused(data.bot)) {
    return null;
  }

  return (
    <TabNavLinkWithRole
      data-testid="test-nav-to-upgrade"
      tabUrl={getTabLink(BotTabs.upgrade, botId)}
      tabEvent="upgrade"
      domain="pro"
      small={small}
      disabled={disabled}
      onClick={onClick}
      className={className}
      contentClassName={cn(css.linkTooltip)}
      activeClassName={cn(css.activeLink)}
      submenu={
        small ? (
          <Type weight="medium" size="12px" color="red">
            {t('modernComponents.LeftNavigationPanel.upgradeTab')}
          </Type>
        ) : null
      }
    >
      <UpgradeNavItemView small={small} />
    </TabNavLinkWithRole>
  );
};
