import React from 'react';
import { useCurrentBotId } from '@utils/Routing';
import { useBotFeatures } from '@utils/Data/Bot';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { BotSwitcherLoading } from '../BotSwitcherLoading';
import { useBotSwitcherNavItemData } from '../hooks/useBotSwitcherNavItemData';
import { BotSwitcherFactory } from './components/BotSwitcherFactory';
import { useWorkspacesAvailable } from '@utils/Data/Workspaces/useWorkspacesAvailable';

export interface BotSwitcherNavItemContainerProps extends TestLocator {
  loading?: boolean;
  onClick: () => void;
  onButtonClick?: () => void;
  menuDisabled?: boolean;
}

export const BotSwitcherNavItemContainer: React.FC<BotSwitcherNavItemContainerProps> =
  ({ loading, ...props }) => {
    const botId = useCurrentBotId();
    const { botFeatures, botFeaturesLoading } = useBotFeatures(botId);
    const { bot, loading: dataLoading } = useBotSwitcherNavItemData();
    const [isWorkspaceAvailable, isWorkspaceAvailableLoading] =
      useWorkspacesAvailable();
    const { screenSize } = useDeviceMedia();

    if (
      loading ||
      botFeaturesLoading ||
      dataLoading ||
      isWorkspaceAvailableLoading
    ) {
      return <BotSwitcherLoading screenSize={screenSize} />;
    }

    return (
      <BotSwitcherFactory
        isWorkspace={Boolean(bot?.workspace_id && isWorkspaceAvailable)}
        dialogsPricing={Boolean(botFeatures?.dialogs_pricing_enabled)}
        {...props}
      />
    );
  };
