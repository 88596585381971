import React from 'react';
import { Flex } from '@ui/Flex';
import { Type, TypeProps as UITypeProps } from '@ui/Type';
import { formatNumberShort } from '@utils/Number/formatNumberShort';

type TypeProps = UITypeProps<HTMLDivElement>;

export interface StatisticsProps {
  fontSize: TypeProps['size'];
  numerator: string;
  numeratorColor: TypeProps['color'];
  denominator: string;
  denominatorColor: TypeProps['color'];
  hideDenominator?: boolean;
}

export const Statistics: React.FC<StatisticsProps> = ({
  fontSize,
  numerator,
  numeratorColor,
  denominator,
  denominatorColor,
  hideDenominator,
}) => (
  <>
    <Type
      size={fontSize}
      color={numeratorColor}
      data-testid="bot-switcher__statistics-numerator"
    >
      {numerator}
    </Type>
    {!hideDenominator && (
      <Flex>
        <Type size={fontSize} color={denominatorColor}>
          &#160;/&#160;
        </Type>
        <Type
          size={fontSize}
          color={denominatorColor}
          data-testid="bot-switcher__statistics-denominator"
        >
          {denominator}
        </Type>
      </Flex>
    )}
  </>
);

export interface FormattedStatisticsProps
  extends Omit<StatisticsProps, 'numerator' | 'denominator'> {
  numerator: number;
  denominator: number;
}

export const FormattedStatistics: React.FC<FormattedStatisticsProps> = ({
  numerator,
  denominator,
  ...props
}) => (
  <Statistics
    {...props}
    numerator={formatNumberShort(numerator)}
    denominator={formatNumberShort(denominator)}
  />
);
