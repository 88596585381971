import React from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { WhiteLabelUserData } from '@utils/WhiteLabelUtils';
import { Type } from '@ui/Type';
import { NavItemView } from '../NavItems/NavItemView';
import { TabAnchor } from '../TabLink/TabLink';
import { NavItemProps, NavItemViewProps } from './types';
import * as css from '../../common.css';

export const HelpCenterNavItemView: React.FC<NavItemViewProps> = ({
  small,
}) => {
  const { t } = useSafeTranslation();

  return (
    <NavItemView
      title={t('modernComponents.LeftNavigationPanel.helpCenter')}
      small={small}
      icon="question"
    />
  );
};

export const HelpCenterNavItem: React.FC<NavItemProps> = ({
  small,
  className,
  onClick,
}) => {
  const { t } = useSafeTranslation();

  return (
    <WhiteLabelUserData>
      {({ loading, isWhiteLabelUser }) => {
        if (loading || isWhiteLabelUser) {
          return null;
        }

        return (
          <TabAnchor
            data-testid="test-nav-to-help-center"
            href={t('modernComponents.LeftNavigationPanel.docsLink')}
            tabEvent="helpCenter"
            className={className}
            onClick={onClick}
            contentClassName={cn(css.linkTooltip)}
            send={small}
            submenu={
              small ? (
                <Type weight="medium" size="12px" color="grey">
                  {t('modernComponents.LeftNavigationPanel.helpCenter')}
                </Type>
              ) : null
            }
          >
            <HelpCenterNavItemView small={small} />
          </TabAnchor>
        );
      }}
    </WhiteLabelUserData>
  );
};
