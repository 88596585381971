import React, { useRef } from 'react';
import cn from 'classnames';
import * as parts from './parts';
import { VariableSizeList } from 'react-window';
import { ListItem } from '../../../modern-ui/List';
import * as css from './PaymentHistory.css';

interface VariableSizeListRef {
  resetAfterIndex(index: number, shouldForceUpdate: boolean): void;
}

export interface Column<T> {
  columnId: string;
  render(item: T, error: boolean, index: number): React.ReactElement | null;
}

export interface PaymentHistoryProps<T> extends TestLocator {
  items: T[];
  columns: Column<T>[];
  getItemId?(item: T, index: number): string | number;
  getRejectedItem?(item: T, index: number): boolean;
  isError?(item: T): boolean;
  rowClassName?: string;
  header?: string;
  itemHeight: number | ((index: number) => number);
  listHeight: number;
  columnClassName?: string;
  itemClassName?: string;
}

export function PaymentHistory<T>({
  listHeight,
  itemHeight,
  items,
  columns,
  getItemId = (_, index) => index,
  getRejectedItem = () => false,
  isError = () => false,
  rowClassName,
  header,
  columnClassName,
  itemClassName,
  ...props
}: PaymentHistoryProps<T>) {
  const listRef = useRef<VariableSizeListRef | null>(null);

  listRef.current?.resetAfterIndex(0, false);

  return (
    <>
      {header && <div className={css.header}>{header}</div>}
      <VariableSizeList
        ref={(reference) => {
          listRef.current = reference;
        }}
        height={listHeight}
        width="100%"
        itemSize={
          typeof itemHeight === 'function' ? itemHeight : () => itemHeight
        }
        itemCount={items.length}
        style={{ overflow: 'hidden auto' }}
      >
        {({ index, style }) => {
          const item = items[index];
          const id = getItemId(item, index);
          const rejected = getRejectedItem(item, index);
          return (
            <div style={style}>
              <ListItem
                boxClassName={css.listItem}
                itemClassName={cn(css.item, itemClassName)}
                drawDivider={index < items.length - 1}
              >
                <div
                  data-testid={props['data-testid']}
                  className={cn(css.row, rowClassName, {
                    [css.rowDanger]: rejected,
                  })}
                >
                  {columns.map((column) => (
                    <div
                      className={cn(columnClassName)}
                      key={id + column.columnId}
                    >
                      {column.render(item, isError(item), index)}
                    </div>
                  ))}
                </div>
              </ListItem>
            </div>
          );
        }}
      </VariableSizeList>
    </>
  );
}

PaymentHistory.Avatar = parts.Avatar;
PaymentHistory.Card = parts.Card;
PaymentHistory.Download = parts.Download;
PaymentHistory.PaymentDate = parts.PaymentDate;
PaymentHistory.Price = parts.Price;
PaymentHistory.Users = parts.Users;
PaymentHistory.Dialogues = parts.Dialogues;
