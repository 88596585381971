import React from 'react';
import { formatDateShort } from '@utils/DateTime/formatDate';

interface Props {
  date: string;
  today?: number;
}

export const PaymentDate: React.FC<Props> = ({ date }) => (
  <div data-testid="payments-history__payment-date">
    {formatDateShort(Number(date))}
  </div>
);
