import ng from 'angular';
import React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import { react2angular } from 'react2angular';
import client from '../../../common/services/ApolloService';
import { IWhiteLabelLogoProps, WhiteLabelLogo } from './WhiteLabelLogo';

const WhiteLabelLogoWrapper: React.FC<IWhiteLabelLogoProps> = (props) => (
  <ApolloProvider client={client}>
    <WhiteLabelLogo {...props} />
  </ApolloProvider>
);

export const ngWhiteLabelLogo = ng
  .module('app.modernUi.whiteLabelLogo', [])
  .component(
    'whiteLabelLogo',
    react2angular(WhiteLabelLogoWrapper, ['logoSize'], []),
  ).name;
