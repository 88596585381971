import React, { useMemo } from 'react';
import cn from 'classnames';
import debounce from 'lodash-es/debounce';
import OutsideClick from 'react-outsideclick';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import * as css from './TopPanel.css';
import { Platform } from '@globals';
import { NEW_PRODUCT_LINK } from './consts';

interface TopPanelProps {
  showPlatformSelector: boolean;
  onAddBotButtonClick: VoidFunction;
  onAddBotButtonOutsideClick: VoidFunction;
  addBotButtonDisabled: boolean;
  onAllBotsButtonClick: VoidFunction;
  onBotCreateRequest: (platforms: Platform[]) => void;
}

export const TopPanel: React.FC<TopPanelProps> = ({
  showPlatformSelector,
  onAddBotButtonClick,
  onAddBotButtonOutsideClick,
  addBotButtonDisabled,
  onAllBotsButtonClick,
  onBotCreateRequest,
}) => {
  const { t } = useSafeTranslation();
  const outsideClickHandler = useMemo(
    () => debounce(onAddBotButtonOutsideClick, 10),
    [onAddBotButtonOutsideClick],
  );

  return (
    <>
      <Flex justifyContent="space-between" className={css.buttonWrapper}>
        <OutsideClick onClickOutside={outsideClickHandler}>
          <ButtonUnstyled
            onClick={() => {
              onAddBotButtonClick();
            }}
            disabled={addBotButtonDisabled}
            className={cn(css.addBotButton, showPlatformSelector && css.active)}
            data-testid="bot-list__add-new-bot"
          >
            <Flex alignItems="center">
              <Icon
                icon="plus"
                size="24px"
                color="blue"
                className={cn({ [css.icon]: !showPlatformSelector })}
              />
              <Spacer factor={0} horizontalFactor={2} />
              <Type size="15px" color="blue" weight="medium">
                {t('common.BotHeader.newBot')}
              </Type>
            </Flex>
          </ButtonUnstyled>
        </OutsideClick>

        <ButtonUnstyled
          onClick={onAllBotsButtonClick}
          className={css.allBotsButton}
          data-testid="bot-list__all-bots-list"
        >
          <Type size="15px" color="blue" weight="medium">
            {t('common.BotHeader.seeAllBots')}
          </Type>
        </ButtonUnstyled>
      </Flex>
      {showPlatformSelector && (
        <div className={css.platformSelector}>
          <ButtonUnstyled
            onClick={() => {
              onBotCreateRequest([Platform.facebook, Platform.instagram]);
            }}
            data-testid="bot-list__add-bot-type__fbig"
          >
            <Flex alignItems="center">
              <Icon icon="fbIgBot" size="24px" />
              <Spacer factor={0} horizontalFactor={2} />
              <Type size="15px" color="blue">
                {t('common.BotHeader.fbIgBot')}
              </Type>
            </Flex>
          </ButtonUnstyled>
          <Spacer factor={3} />
          <ButtonUnstyled
            onClick={() => {
              onBotCreateRequest([Platform.whatsapp]);
            }}
            data-testid="bot-list__add-bot-type__whatsapp"
          >
            <Flex alignItems="center">
              <Icon icon="whatsappBot" size="24px" />
              <Spacer factor={0} horizontalFactor={2} />
              <Type size="15px" color="blue">
                {t('common.BotHeader.whatsappBot')}
              </Type>
            </Flex>
          </ButtonUnstyled>
          <Spacer factor={3} />
          <ButtonUnstyled
            onClick={() => {
              window.open(NEW_PRODUCT_LINK, '__blank');
            }}
            data-testid="bot-list__add-bot-type__whatsapp"
          >
            <Flex alignItems="center">
              <Icon icon="newWebsite" size="24px" />
              <Spacer factor={0} horizontalFactor={2} />
              <Type size="15px" color="blue">
                {t('common.BotHeader.website')}
              </Type>
              <Spacer factor={0} horizontalFactor={2} />
              <Type
                size="9px"
                color="white"
                weight="bold"
                as="div"
                className={css.beta}
              >
                {t('common.BotHeader.beta')}
              </Type>
            </Flex>
          </ButtonUnstyled>
          <Spacer factor={2} />
        </div>
      )}
      <Spacer factor={2} />
    </>
  );
};
