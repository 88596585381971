import { PluginType } from '../common/PluginTypes';
import {
  whatsappListPluginFragment_config as WhatsappListPluginConfig,
  whatsappListPluginFragment_config_listBody,
  whatsappListPluginFragment_config_listFooter,
  whatsappListPluginFragment_config_listHeader,
  whatsappListPluginFragment_config_listSections,
  whatsappListPluginFragment_config_listSections_rows,
} from './@types/whatsappListPluginFragment';
import { WhatsappListPlugHeaderType } from '@globals';
import { ButtonTypes } from '../../FlowBuilder/FlowBuilderOverlay/overlays/ButtonPopupOverlay/types';

export const PLUGIN_TYPE = PluginType.whatsapp_list;

export const DEFAULT_ROW: whatsappListPluginFragment_config_listSections_rows =
  {
    __typename: 'WhatsappListPlugSectionRow',
    type: ButtonTypes.listButton,
    title: '',
    description: null,
    block_id: [],
    counterId: null,
  };

export const DEFAULT_LIST_FOOTER: whatsappListPluginFragment_config_listFooter =
  {
    __typename: 'WhatsappListPluginFooter',
    text: '',
  };

export const DEFAULT_LIST_BODY: whatsappListPluginFragment_config_listBody = {
  __typename: 'WhatsappListPluginBody',
  text: '',
};

export const DEFAULT_LIST_HEADER: whatsappListPluginFragment_config_listHeader =
  {
    __typename: 'WhatsappListPluginHeader',
    type: WhatsappListPlugHeaderType.text,
    text: null,
  };

export const DEFAULT_SECTION: whatsappListPluginFragment_config_listSections = {
  __typename: 'WhatsappListPlugSection',
  title: null,
  rows: [DEFAULT_ROW],
};

export const PLUGIN_DEFAULT_CONFIG: WhatsappListPluginConfig = {
  __typename: 'WhatsappListPluginConfig',
  listHeader: null,
  listBody: null,
  listFooter: null,
  listButtonTitle: null,
  listSections: [DEFAULT_SECTION],
};

export const PLUGIN_INPUT_FIELD = 'whatsappListPluginConfig';
