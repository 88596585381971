import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment setupAttributePluginFragment on SetupAttributePlugin {
    ...commonPluginFragment
    config {
      items {
        variable_name
        value
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
