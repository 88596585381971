import React from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useBotFeatures } from '@utils/Data/Bot';
import { useAdminFeatures } from '@utils/Data/Admin';
import { BotPageRouteParams, BotTabs, getTabLink } from '@utils/Routing';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { TabNavLinkWithRole } from '../TabLink/TabLink';
import { NavItemProps } from './types';
import * as css from '../../LeftNavigationPanel.css';
import * as commonCSS from '../../common.css';

export const AutomationNavSubItems: React.FC<NavItemProps> = ({
  small,
  onClick,
  className,
  disabled,
}) => {
  const { botId } = useParams<BotPageRouteParams>();
  const { t } = useSafeTranslation();
  const { botFeatures } = useBotFeatures(botId);
  const { adminFeatures } = useAdminFeatures();

  return (
    <div
      className={cn({
        [css.extendedContent]: !small,
      })}
    >
      {(botFeatures?.flow_builder || adminFeatures?.flow_builder) && (
        <TabNavLinkWithRole
          data-testid="test-nav-to-flows"
          onClick={onClick}
          tabUrl={getTabLink(BotTabs.flows, botId)}
          tabEvent="flows"
          domain="flows"
          className={className}
          disabled={disabled}
          small={small}
          activeClassName={cn(commonCSS.activeLinkSubMenu)}
        >
          {!small && <Spacer factor={1} horizontalFactor={5} />}
          <Type weight="medium" size="12px" color="grey">
            {t('modernComponents.LeftNavigationPanel.flowsSubTab')}
          </Type>
          <Spacer horizontalFactor={2} />
        </TabNavLinkWithRole>
      )}

      <TabNavLinkWithRole
        data-testid="test-nav-to-automate"
        onClick={onClick}
        tabUrl={getTabLink(BotTabs.automate, botId)}
        tabEvent="automate"
        domain="groups"
        disabled={disabled}
        className={className}
        small={small}
        activeClassName={cn(commonCSS.activeLinkSubMenu)}
      >
        {!small && <Spacer factor={1} horizontalFactor={5} />}
        <Type weight="medium" size="12px" color="grey">
          {t('modernComponents.LeftNavigationPanel.blocks')}
        </Type>
      </TabNavLinkWithRole>

      <TabNavLinkWithRole
        data-testid="test-nav-to-ai-setup"
        onClick={onClick}
        tabUrl={getTabLink(BotTabs.keywords, botId)}
        tabEvent="ai"
        domain="ai"
        disabled={disabled}
        className={className}
        small={small}
        activeClassName={cn(commonCSS.activeLinkSubMenu)}
      >
        {!small && <Spacer factor={1} horizontalFactor={5} />}
        <Type weight="medium" size="12px" color="grey">
          {t(
            adminFeatures?.show_keywords_tab
              ? 'modernComponents.LeftNavigationPanel.keywords'
              : 'modernComponents.LeftNavigationPanel.aiTab',
          )}
        </Type>
      </TabNavLinkWithRole>
    </div>
  );
};
