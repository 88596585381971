import React from 'react';
import gql from 'graphql-tag';
import { ReactComponent as Icon } from './images/ic-abt-plugin.svg';
import { abtPluginFragment_config as AbtPluginConfig } from './@types/abtPluginFragment';
import { IPluginButton } from '../common/Plugin';

export const PLUGIN_FRAGMENT = gql`
  fragment abtPluginFragment on AbtPlugin {
    id
    is_valid
    plugin_id
    config {
      options {
        name
        percent
        blocks {
          id
          title
          removed
          is_flow
        }
      }
      user_filter {
        operation
        valid
        parameters {
          type
          name
          operation
          values
        }
      }
    }
  }
`;

export const PLUGIN_TYPE = 'abt';
export const PLUGIN_NAME = 'A/B Test';
export const PLUGIN_DEFAULT_CONFIG: AbtPluginConfig = {
  __typename: 'AbtPluginConfig',
  options: [
    {
      __typename: 'AbtPluginOption',
      name: 'A',
      percent: 50,
      blocks: [],
    },
    {
      __typename: 'AbtPluginOption',
      name: 'B',
      percent: 50,
      blocks: [],
    },
  ],
  user_filter: null,
};
export const PLUGIN_ICON = <Icon />;
export const PLUGIN_HELP_URL =
  'https://docs.chatfuel.com/en/articles/3322716-a-b-test-plugin';
export const PLUGIN_INPUT_FIELD = 'abtPluginConfig';
export const PLUGIN_BUTTON_CONFIG: IPluginButton = {
  title: PLUGIN_NAME,
  img: PLUGIN_ICON,
  pro: true,
};
