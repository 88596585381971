import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React from 'react';
import { TrialBadge } from './TrialBadge/TrialBadge';

interface TimeLimitedTrialStatisticsProps {
  trialRemainingDays: number | null | undefined;
  trialEndDate: number | null | undefined;
}

export const TimeLimitedTrialStatistics: React.FC<TimeLimitedTrialStatisticsProps> =
  ({ trialRemainingDays, trialEndDate }) => {
    const { isMediumScreenSize } = useDeviceMedia();
    const { t } = useSafeTranslation();

    if (isMediumScreenSize || typeof trialRemainingDays !== 'number')
      return null;

    if (typeof trialEndDate !== 'number') return null;

    if (trialRemainingDays <= 0)
      return (
        <Type size="12px" color="accent4Secondary">
          {t('pages.Pricing.trialEnded')}
        </Type>
      );

    return (
      <Flex justifyContent="space-between" alignItems="center">
        <Type
          size="12px"
          color={trialRemainingDays < 3 ? 'orangeNormal' : 'cobaltSemilight'}
        >
          {t('pages.Pricing.dayLeft', {
            count: trialRemainingDays,
          })}
        </Type>
        <TrialBadge />
      </Flex>
    );
  };
