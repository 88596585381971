import React from 'react';
import ng from 'angular';
import { react2angular } from 'react2angular';
import {
  GalleryAspectRatioPrompt,
  GalleryAspectRatioPromptProps,
} from './GalleryAspectRatioPrompt';

const GalleryAspectRatioPromptWrapper: React.FC<GalleryAspectRatioPromptProps> = (
  props,
) => <GalleryAspectRatioPrompt {...props} />;

export const ngGalleryAspectRatioPrompt = ng
  .module('app.components.galleryAspectRatioPrompt', [])
  .component(
    'galleryAspectRatioPrompt',
    react2angular(
      GalleryAspectRatioPromptWrapper,
      ['image', 'onSave', 'onDismiss'],
      [],
    ),
  ).name;
