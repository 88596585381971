import React from 'react';
import gql from 'graphql-tag';
import { PluginType } from '../common/PluginTypes';
import { ReactComponent as Icon } from '../common/icons/icon_abandoned_cart.svg';
import { IPluginButton } from '../common/Plugin';
import {
  abandonedCartPluginFragment_config as AbandonedCartPluginConfig,
  abandonedCartPluginFragment_config_gallery_plugin,
} from './@types/abandonedCartPluginFragment';

export const PLUGIN_FRAGMENT = gql`
  fragment abandonedCartPluginFragment on AbandonedCartPlugin {
    id
    plugin_id
    position
    is_valid
    config {
      reminder_type
      text_plugin {
        text
        button_title
      }
      gallery_plugin {
        button_title
      }
      enabled
    }
    validation_details {
      fields {
        field
        error
      }
    }
  }
`;

export enum AbandonedCartType {
  gallery = 'gallery',
  text = 'text',
}

export const PLUGIN_TYPE = PluginType.abandoned_cart;
export const PLUGIN_NAME = 'Abandoned Cart Message';
export const PLUGIN_ICON = <Icon />;
export const PLUGIN_HELP_URL = 'https://docs.chatfuel.com/en/articles/3456927';
export const PLUGIN_INPUT_FIELD = 'abandonedCartPluginConfig';
export const PLUGIN_BUTTON_CONFIG: IPluginButton = {
  title: PLUGIN_NAME,
  img: PLUGIN_ICON,
};

type AbandonedCartDefaultConfig = Omit<
  abandonedCartPluginFragment_config_gallery_plugin,
  'button_title'
> & { button_title: string };

export const getAbandonedCartPluginGalleryDefaultConfig = (
  buttonTitle?: string | null,
) =>
  ({
    __typename: 'AbandonedCartPluginGalleryConfig',
    button_title: buttonTitle ?? '',
  } as AbandonedCartDefaultConfig);

export const PLUGIN_DEFAULT_CONFIG: AbandonedCartPluginConfig = {
  __typename: 'AbandonedCartPluginConfig',
  reminder_type: AbandonedCartType.gallery,
  gallery_plugin: getAbandonedCartPluginGalleryDefaultConfig('Button title'),
  text_plugin: {
    __typename: 'AbandonedCartPluginTextConfig',
    text: 'Insert text here',
    button_title: 'Button title',
  },
  enabled: true,
};

export const PLUGIN_DEFAULT_FLOW_BUILDER_CONFIG: AbandonedCartPluginConfig = {
  __typename: 'AbandonedCartPluginConfig',
  reminder_type: AbandonedCartType.gallery,
  gallery_plugin: getAbandonedCartPluginGalleryDefaultConfig('Buy now'),
  text_plugin: {
    __typename: 'AbandonedCartPluginTextConfig',
    text:
      'Hi, {{first name}}.\n' +
      'Hard to pick just the right dress for the summer, right? We feel you. To make it worth your while, here’s an offer: buy two summer dresses, and you’ll get the second one half price.',
    button_title: 'Go to cart',
  },
  enabled: true,
};
