import React, { useState } from 'react';
import cn from 'classnames';
import { Trans } from 'react-i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Attributes } from '@ui/TextWithAttributesEditor';
import { Box } from '@ui/Box';
import { Spacer } from '@ui/Spacer';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { CenteredLoader } from '@ui/Loader';
import { ButtonUnstyled } from '@ui/Button';
import { ImageResizeDialog } from '@components/Plugins/ImagePlugin';
import { useLivechatSettingsContext } from '@utils/Data/LiveChat/Settings/context';
import { Modal } from '@services/index';
import { EditableField } from '../EditableField';
import { EditableButton } from '../EditableButton';
import { useAutomatedMessages } from '../../useAutomatedMessages';
import { AutomatedMessagesSource } from '../types';
import * as css from './EditableGalleryCard.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { fileUpload } from '@utils/Fetch/fileUpload';
import { FileSelector } from '../../../FileSelector';
import { Platform } from '@globals';
import { FileAttachmentType } from '@utils/UploadService/types';

interface EditableGalleryCardProps {
  botId: string;
  attributes: Attributes;
  source: AutomatedMessagesSource;
  disabled: boolean;
  onResizeImage?(): void;
}

export const EditableGalleryCard: React.FC<EditableGalleryCardProps> = ({
  attributes,
  botId,
  onResizeImage,
  disabled,
  source,
}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const [imageUploading, setImageUploading] = useState(false);
  const { isWhatsappEnabled, loading: whatsappStatusLoading } =
    useWhatsappEnabled();
  const {
    livechatSettings,
    setConfig,
    loading: configLoading,
  } = useLivechatSettingsContext();
  const { showModal: showAutomatedMessagesModal } = useAutomatedMessages({
    botId,
    source,
    isSmallScreenSize,
  });

  const uploadImageCommonConfig = {
    url: '/imgupload',
    fileFieldName: 'file',
    onError() {
      setImageUploading(false);
    },
    onProgress() {
      if (!imageUploading) {
        setImageUploading(true);
      }
    },
    onLoad(image_url: string) {
      setConfig({ image_url });
      setImageUploading(false);
    },
  };

  const showLoader = imageUploading || configLoading || whatsappStatusLoading;
  const imageUrl = livechatSettings?.config?.image_url;
  return (
    <div className={css.galleryCardContainer}>
      <FileSelector
        platform={isWhatsappEnabled ? Platform.whatsapp : Platform.facebook}
        data-testid="live-chat-automated-messages__image-input"
        onFileProcessed={({ url: fileUrl }) => {
          fileUpload<string>({
            fileUrl,
            ...uploadImageCommonConfig,
          });
        }}
      >
        {({ chooseFile, ...props }) => (
          <ButtonUnstyled
            onClick={() => {
              chooseFile(FileAttachmentType.image);
            }}
            {...props}
            disabled={disabled}
            data-testid="live-chat-automated-messages__image-button"
            className={css.button}
          >
            <Box
              className={cn(css.imageBox, {
                [css.noPadding]: imageUrl,
              })}
              borderRadiusFactor={3}
            >
              {showLoader ? (
                <CenteredLoader />
              ) : imageUrl ? (
                <>
                  <div
                    className={css.image}
                    style={{
                      backgroundImage: `url(${imageUrl})`,
                    }}
                  />
                  <ButtonUnstyled
                    data-testid="live-chat-automated-messages__image-crop-button"
                    className={css.cropButton}
                    onClick={(event) => {
                      event.stopPropagation();
                      onResizeImage?.();
                      Modal.show(
                        ({ close }) => (
                          <ImageResizeDialog
                            image={imageUrl ?? ''}
                            onDismiss={close}
                            onSave={(file) => {
                              fileUpload<string>({
                                fileBlob: file,
                                ...uploadImageCommonConfig,
                              });
                              close();
                            }}
                          />
                        ),
                        { mobileAdaptive: true },
                      )?.onClose(showAutomatedMessagesModal);
                    }}
                  >
                    <Icon icon="crop" size="32px" />
                  </ButtonUnstyled>
                </>
              ) : (
                <>
                  <Icon icon="attachment" size="32px" />{' '}
                  <Type size="15px">
                    <Trans
                      t={t}
                      i18nKey="modernComponents.AutomatedMessagesDialog.galleryCard.imagePlaceholder"
                    />
                  </Type>
                </>
              )}
            </Box>
          </ButtonUnstyled>
        )}
      </FileSelector>
      <Box className={css.controlsBox} borderRadiusFactor={3}>
        <Spacer factor={2} />
        <EditableField
          data-testid="live-chat-automated-messages__image-title-input"
          textEditorClassName={css.titleField}
          onChange={(title) => {
            setConfig({ title });
          }}
          attributes={attributes}
          text={livechatSettings?.config?.title ?? ''}
          placeholder={t(
            'modernComponents.AutomatedMessagesDialog.galleryCard.titlePlaceholder',
          )}
          disabled={disabled}
          maxTextLength={80}
        />
        <Spacer factor={1} />
        {!isWhatsappEnabled && (
          <>
            <EditableField
              data-testid="live-chat-automated-messages__image-subtitle-input"
              textEditorClassName={css.subtitleField}
              onChange={(subtitle) => {
                setConfig({ subtitle });
              }}
              attributes={attributes}
              text={livechatSettings?.config?.subtitle ?? ''}
              placeholder={t(
                'modernComponents.AutomatedMessagesDialog.galleryCard.subtitlePlaceholder',
              )}
              disabled={disabled}
              maxTextLength={80}
            />
            <Spacer factor={1} />
          </>
        )}
        <EditableButton
          data-testid="live-chat-automated-messages__image-button-title-input"
          onChange={(button_text) => {
            setConfig({ button_text });
          }}
          attributes={attributes}
          text={livechatSettings?.config?.button_text ?? ''}
          placeholder={t(
            'modernComponents.AutomatedMessagesDialog.galleryCard.buttonPlaceholder',
          )}
          disabled={disabled}
          maxTextLength={20}
        />
      </Box>
    </div>
  );
};
