import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { WhiteLabelAgencyStyles } from '@utils/WhiteLabel/useWhiteLabelAgencyStyles';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { HelpCenterNavItem } from './components/Tabs/HelpCenterNavItem';
import { UserNavItem } from './components/Tabs/UserNavItem';
import { NavigationItemsList } from './components/NavigationItemsList/NavigationItemsList';
import * as css from './LeftNavigationPanel.css';
import { NavigationHeader } from './components/NavigationHeader/NavigationHeader';
import {
  BotHeaderContainer,
  BotHeaderContainerWrapper,
} from '@components/PageHeader/BotHeader';
import { LiveChatNavigationHeader } from './components/LiveChatNavigationHeader';
import { useCurrentBotId, useIsFirstSessionTab } from '@utils/Routing';
import { useFirstSessionStep } from '@pages/BotPage/KeywordsPage/FirstSessionPage/hooks/useFirstSessionStep';
import { FirstSessionStep } from '@pages/BotPage/KeywordsPage/FirstSessionPage/consts';
import { setHideIntercomDefaultLauncher } from '@utils/Intercom/Intercom';
import { ReferralProgramButton } from '../ReferralProgram';
import { FirstSessionHeaderMenu } from './components/FirstSessionHeaderMenu/FirstSessionHeaderMenu';

interface LeftNavigationPanelProps {
  whiteLabelAgencyStyles?: WhiteLabelAgencyStyles;
}

export const LeftNavigationPanel: React.FC<LeftNavigationPanelProps> = ({
  whiteLabelAgencyStyles,
}) => {
  const liveChatMatch = useRouteMatch<{ botId: string }>(
    '/bot/:botId/livechat',
  );
  const botId = useCurrentBotId()!;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNavigationOpened, setIsNavigationOpened] = useState(false);
  const { screenSize, isSmallScreenSize, isMediumScreenSize } =
    useDeviceMedia();
  const isFirstSessionTab = useIsFirstSessionTab();
  const [firstSessionStep] = useFirstSessionStep();

  const onMenuOpenChange = useCallback((value: boolean) => {
    setIsMenuOpen(value);
    setIsNavigationOpened(value);
  }, []);

  const closeMenu = useCallback(
    () => onMenuOpenChange(false),
    [onMenuOpenChange],
  );

  useEffect(() => {
    onMenuOpenChange(false);
  }, [screenSize, onMenuOpenChange, botId]);

  return (
    <Flex
      className={cn(
        { [css.leftNavigationPanelClosed]: !isMenuOpen },
        { [css.leftNavigationSmall]: isMediumScreenSize },
        css.leftNavigationPanel,
      )}
      flexDirection="column"
      style={{
        backgroundColor:
          whiteLabelAgencyStyles?.navigationColor || 'var(--cobalt)',
      }}
    >
      <div className={css.headerWrapper}>
        {liveChatMatch?.isExact && !isNavigationOpened && isSmallScreenSize ? (
          <LiveChatNavigationHeader
            botId={liveChatMatch.params.botId}
            isMenuOpen={isNavigationOpened}
            setIsMenuOpen={onMenuOpenChange}
            buttonWrapper={isFirstSessionTab}
          />
        ) : (
          <NavigationHeader
            isMenuOpen={isNavigationOpened}
            setIsMenuOpen={onMenuOpenChange}
            buttonWrapper={isFirstSessionTab}
          />
        )}
      </div>
      {isSmallScreenSize &&
      isFirstSessionTab &&
      firstSessionStep !== FirstSessionStep.done ? (
        <FirstSessionHeaderMenu />
      ) : (
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          className={cn(
            {
              [css.menuContent]: !isMenuOpen,
              [css.disabled]:
                isFirstSessionTab && firstSessionStep !== FirstSessionStep.done,
            },
            css.scrollableContent,
          )}
        >
          <div>
            <BotHeaderContainerWrapper>
              <BotHeaderContainer
                onButtonClick={closeMenu}
                onMenuOpen={() => {
                  if (isSmallScreenSize) {
                    setIsNavigationOpened(false);
                    setHideIntercomDefaultLauncher(true);
                  }
                }}
                onMenuClose={() => {
                  if (isSmallScreenSize) {
                    setIsNavigationOpened(true);
                    setHideIntercomDefaultLauncher(false);
                  }
                }}
              />
            </BotHeaderContainerWrapper>

            <NavigationItemsList onItemClick={closeMenu} />
          </div>
          <Spacer factor={4} />
          <div>
            <ReferralProgramButton onOpenReferralPage={closeMenu} />
            <UserNavItem small={isMediumScreenSize} />

            {!isFirstSessionTab && (
              <HelpCenterNavItem small={isMediumScreenSize} />
            )}
          </div>
        </Flex>
      )}
    </Flex>
  );
};
