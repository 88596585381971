import { DefaultDialog } from '@ui/Dialog';
import React from 'react';
import { ReactComponent as ImageSvg } from './image.svg';
import { TypeTranslation } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { VERIFICATION_GUIDE_LINK } from './consts';

interface WhatsappBusinessVerificationProps {
  onDismiss: VoidFunction;
}

export const WhatsappBusinessVerification: React.FC<WhatsappBusinessVerificationProps> =
  ({ onDismiss }) => {
    const { t } = useSafeTranslation();
    return (
      <DefaultDialog
        onDismiss={onDismiss}
        footer={
          <Button
            onClick={() => window.open(VERIFICATION_GUIDE_LINK, '_blank')}
          >
            {t(
              'MiniOnboarding.WhatsappBusinessVerification.openVerificationGuide',
            )}
          </Button>
        }
      >
        <Flex flexDirection="column">
          <Flex justifyContent="center">
            <ImageSvg />
          </Flex>
          <Spacer factor={5} />
          <TypeTranslation
            i18nKey="MiniOnboarding.WhatsappBusinessVerification.title"
            size="24px"
            weight="semibold"
          />
          <Spacer factor={3} />
          <div>
            <TypeTranslation i18nKey="MiniOnboarding.WhatsappBusinessVerification.clickThe" />{' '}
            <TypeTranslation
              i18nKey="MiniOnboarding.WhatsappBusinessVerification.openVerification"
              weight="bold"
            />{' '}
            <TypeTranslation i18nKey="MiniOnboarding.WhatsappBusinessVerification.buttonBelow" />
          </div>
          <Spacer factor={4} />
          <div>
            <TypeTranslation i18nKey="MiniOnboarding.WhatsappBusinessVerification.followTheSteps" />
          </div>
          <Spacer factor={4} />
          <TypeTranslation
            i18nKey="MiniOnboarding.WhatsappBusinessVerification.verificationCanTake"
            color="cobaltTertiary"
          />
        </Flex>
      </DefaultDialog>
    );
  };
