import gql from 'graphql-tag';
import React from 'react';

import { ReactComponent as Icon } from '../common/icons/ic-facebook-analytics-plugin.svg';
import { PluginType } from '../common/PluginTypes';
import { sendEvent } from '../../../utils/Analytics';

import { createTooltip } from '../AddPlugin/createTooltip';

import { facebookAnalyticsPluginFragment_config as FacebookAnalyticsPluginConfig } from './@types/facebookAnalyticsPluginFragment';

import * as css from '../AddPlugin/AddPlugin.css';

export const PLUGIN_FRAGMENT = gql`
  fragment facebookAnalyticsPluginFragment on FacebookAnalyticsPlugin {
    id
    is_valid
    plugin_id
    config {
      eventId
      eventType
    }
    validation_details {
      fields {
        field
        error
      }
    }
  }
`;

export enum EventType {
  custom = 'custom',
  standard = 'standard',
}

export const FROM_PERMISSION_GRANT = 'from_permission_grant';

export const PLUGIN_TYPE = PluginType.facebook_analytics;
export const PLUGIN_NAME = 'Send Analytics Events to Facebook';
export const PLUGIN_DEFAULT_CONFIG: FacebookAnalyticsPluginConfig = {
  __typename: 'FacebookAnalyticsPluginConfig',
  eventId: '',
  eventType: EventType.standard,
};
export const PLUGIN_ICON = <Icon />;
export const PLUGIN_HELP_URL =
  'https://docs.chatfuel.com/en/articles/3742284-send-analytics-events-to-facebook-beta';
export const PLUGIN_INPUT_FIELD = 'facebookAnalyticsPluginConfig';
export const getPluginButtonConfig = (botId: string) => ({
  title: window.i18next.t(
    'FacebookAnalyticsPluginConst-string--865-send-analytics-events-to-fb',
  ),
  img: PLUGIN_ICON,
  tooltip: {
    pro: createTooltip(
      window.i18next.t(
        'FacebookAnalyticsPluginConst-string-1465-use-this-plugin-to-track-metrics-for-your-bot-flows-in-facebook-ads-manager-or-facebook-analytics',
      ),
      PLUGIN_HELP_URL,
      undefined,
      () => {
        sendEvent({
          category: 'events to fb',
          action: 'click',
          label: 'learn more tooltip',
        });
      },
    ),
    free: (
      <p style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
        {window.i18next.t(
          'FacebookAnalyticsPluginConst-JSXText--151-use-this-plugin-to-track-metrics-for-your-bot-flows-in-facebook-ads-manager-or-facebook-analytics',
        )}{' '}
        <a href={`/bot/${botId}/settings/`} className={css.tooltipLink}>
          {window.i18next.t(
            'FacebookAnalyticsPluginConst-JSXText-1227-upgrade-to-pro',
          )}
        </a>{' '}
        {window.i18next.t(
          'FacebookAnalyticsPluginConst-JSXText--203-to-activate-it',
        )}
      </p>
    ),
  },
  pro: true,
});
