import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment shopifyBackInStockEntryPointFragment on ShopifyBackInStockEntryPoint {
    ...commonPluginFragment
    config {
      title
      description
      button_text
      button_size
      button_color
      on_subscribe_block_id
      on_back_in_stock_block_id
      on_back_in_stock_counter_id
      notify_unreachable
      on_back_in_stock_unreachable_block_id
      on_back_in_stock_unreachable_counter_id
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
