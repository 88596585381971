import React from 'react';
import { Category } from './types';

export interface CategoryContextData {
  activeCategory: Category;
  setActiveCategory: (category: Category) => void;
}

export const CategoryContext = React.createContext<CategoryContextData>({
  activeCategory: Category.facebook,
  setActiveCategory: () => undefined,
});
