import React from 'react';
import cn from 'classnames';
import { ApolloProvider, Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import {
  Dialog,
  DialogHeading,
  DialogContent,
  DialogCloseButton,
} from '../../../../modern-ui/Dialog';
import { Modal } from '../../../../modern-ui/Modal';
import { Button, ButtonIntent } from '../../../../modern-ui/_deprecated/Button';
import client from '../../../../common/services/ApolloService';
import {
  PaymentsQuery,
  PaymentsQuery_me_paymentsHistory as Payment,
} from './@types/PaymentsQuery';
import { InvoiceQuery, InvoiceQueryVariables } from './@types/InvoiceQuery';
import { ReactComponent as Receipt } from './images/receipt.svg';
import loaderUrl from '../../../../modern-ui/_deprecated/Icon/icons/loader.svg';
import { downloadInvoice } from '../../../../utils/downloadInvoice';
import { PaymentHistory } from '..';
import * as css from './PaymentsHistoryModal.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export interface IPaymentsHistoryModalProps {
  onRequestClose: () => void;
  onRequestUpgradeToPro?: () => void;
  showProButton?: boolean;
}

const PAYMENTS_QUERY = gql`
  query PaymentsQuery {
    me {
      id
      paymentsHistory {
        id
        adminName
        date
        amount
        currency
        activeUsers
        last4CardDigits
        page {
          id
          picture
          title
        }
      }
    }
  }
`;

const INVOICE_QUERY = gql`
  query InvoiceQuery($id: String!) {
    invoice(id: $id) {
      id
      html
    }
  }
`;

const { Avatar, Card, Download, PaymentDate, Price, Users } = PaymentHistory;

export const PaymentsHistoryModal: React.FC<IPaymentsHistoryModalProps> = ({
  onRequestClose,
  onRequestUpgradeToPro,
  showProButton,
}) => {
  const { t } = useSafeTranslation();
  return (
    <ApolloProvider client={client}>
      <Modal onDismiss={onRequestClose}>
        <Dialog className={css.paymentsHistoryDialog}>
          <DialogContent>
            <DialogHeading>
              {t('PaymentsHistoryModal-JSXText-5837-my-pro-plan-payments')}
            </DialogHeading>
            <Query<PaymentsQuery>
              query={PAYMENTS_QUERY}
              fetchPolicy="cache-and-network"
            >
              {({ error, data }) => {
                if (!error && (!data || !data.me)) {
                  // don't use loading for cache-and-network fetch policy
                  return (
                    <div
                      className={cn(css.loaderBox, {
                        [css.loaderBoxMini]: !showProButton,
                      })}
                    >
                      <img
                        src={loaderUrl}
                        alt={t('PaymentsHistoryModal-string-4081-loading')}
                      />
                    </div>
                  );
                }
                if (error) {
                  return (
                    <div className={css.error}>
                      {t(
                        'PaymentsHistoryModal-JSXText--128-payments-history-loading-error',
                      )}
                    </div>
                  );
                }
                const {
                  me: { paymentsHistory },
                } = data as PaymentsQuery;
                if (paymentsHistory && paymentsHistory.length) {
                  return (
                    <div className={css.paymentHistory}>
                      <PaymentHistory<Payment>
                        listHeight={396}
                        itemHeight={() => 64}
                        items={paymentsHistory}
                        rowClassName={css.paymentHistoryRow}
                        getItemId={(item) => item.id}
                        columns={[
                          {
                            columnId: 'date',
                            render: (item) => (
                              <>
                                <PaymentDate date={item.date} />
                                <Avatar
                                  title={item.page.title}
                                  url={item.page.picture || ''}
                                />
                              </>
                            ),
                          },
                          {
                            columnId: 'users',
                            render: (item) => (
                              <Users count={item.activeUsers!} />
                            ),
                          },
                          {
                            columnId: 'card',
                            render: (item) => (
                              <Card last4={item.last4CardDigits} />
                            ),
                          },
                          {
                            columnId: 'price',
                            render: (item) => (
                              <Price
                                amount={item.amount}
                                currency={item.currency ?? undefined}
                              />
                            ),
                          },
                          {
                            columnId: 'download',
                            render: ({ id, date }) => (
                              <div>
                                <Download
                                  onDownload={async () => {
                                    const res = await client.query<
                                      InvoiceQuery,
                                      InvoiceQueryVariables
                                    >({
                                      query: INVOICE_QUERY,
                                      variables: { id },
                                      fetchPolicy: 'no-cache',
                                    });
                                    if (res.data.invoice) {
                                      downloadInvoice(
                                        date,
                                        res.data.invoice.html,
                                      );
                                    }
                                  }}
                                />
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                  );
                }

                return (
                  <div className={css.noInvoice}>
                    <Receipt />
                    <div className={css.noInvoiceText}>
                      {t(
                        'PaymentsHistoryModal-JSXText-1400-receipts-will-appear',
                      )}
                      <br />
                      {t(
                        'PaymentsHistoryModal-JSXText-2126-here-when-you-upgrade',
                      )}
                      <br />
                      {t('PaymentsHistoryModal-JSXText--190-to-pro-plan')}
                    </div>
                    {showProButton && onRequestUpgradeToPro ? (
                      <Button
                        intent={ButtonIntent.primary}
                        onClick={onRequestUpgradeToPro}
                      >
                        {t('PaymentsHistoryModal-JSXText-1227-upgrade-to-pro')}
                      </Button>
                    ) : null}
                  </div>
                );
              }}
            </Query>
          </DialogContent>
          <DialogCloseButton
            className={css.closeButton}
            onClick={onRequestClose}
          />
        </Dialog>
      </Modal>
    </ApolloProvider>
  );
};
