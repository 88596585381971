import React, { useCallback } from 'react';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { usePaywallPaymentFlow } from '@pages/Plans/hooks/usePaywallPaymentFlow';
import { DoneSetupBox } from '@pages/BotPage/KeywordsPage/FirstSessionPage/components/DoneSetupBox';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDialoguesPricing, useTiersData } from '@utils/Data/Pricing';
import { useCurrentBotId } from '@utils/Routing';
import { PaywallStatus } from '@globals';
import { PaywallPlansBlock } from './PaywallPlansBlock';
import { PlanDescriptionBlock } from './PlanDescriptionBlock';
import { SkipPaymentBlock } from './SkipPaymentBlock';
import { useBotPromoCodeQuery } from '@utils/Data/PromoCode/useBotPromoCodeQuery';
import { PromoCode } from '@components/PromoCode';
import { checkPromoCodeAvailableFreeMonth } from '@utils/Data/PromoCode/checkPromoCodeAvailableFreeMonth';
import css from './PlansPaywall.css';

interface PlansPaywallProps {
  status: PaywallStatus;
  onDone?(): void;
}

export const PlansPaywall: React.FC<PlansPaywallProps> = ({
  status,
  onDone,
}) => {
  const { t } = useSafeTranslation();
  const { getValueDueToSize } = useDeviceMedia();

  const botId = useCurrentBotId()!;
  const { pricing, pageId, pricingLoading } = useDialoguesPricing();
  const { data: botPromoCodeData } = useBotPromoCodeQuery(botId);
  const checkAvailableFreeMonth = useCallback(
    checkPromoCodeAvailableFreeMonth.bind(null, botPromoCodeData),
    [botPromoCodeData],
  );
  const { currency, pricing_plan_id: pricingPlanId } = pricing || {};
  const { tiers, tiersLoading } = useTiersData(botId, pricingPlanId, currency);
  const paywallTiers = tiers.slice(1, 4);

  const {
    loading: paymentFlowLoading,
    onSkip,
    onUpgrade,
  } = usePaywallPaymentFlow({
    botId,
    pageId,
    pricing,
    onDone: () => {
      onDone?.();
    },
  });

  return (
    <>
      <DoneSetupBox />
      <Spacer factor={3} />
      <div
        className={css.container}
        style={{
          padding: getValueDueToSize(16, 20, 32),
          paddingTop: getValueDueToSize(20, 24),
        }}
      >
        <Flex>
          <Type
            color="white"
            size={getValueDueToSize('24px', '44px')!}
            weight={getValueDueToSize('semibold', 'bold')}
          >
            {t('pages.FirstSession.PlansPaywall.title')}
          </Type>
        </Flex>

        <PlanDescriptionBlock />

        <Spacer factor={10} />

        <PaywallPlansBlock
          currency={currency}
          tiers={paywallTiers}
          onUpgrade={onUpgrade}
          isBlockLoading={tiersLoading || pricingLoading}
          isCardsLoading={paymentFlowLoading}
          checkAvailableFreeMonth={checkAvailableFreeMonth}
        />

        <Spacer factor={4} />

        <PromoCode
          botId={botId}
          pricingPlanId={pricingPlanId}
          currency={currency}
        />

        {status === PaywallStatus.skippable && (
          <SkipPaymentBlock onSkip={onSkip} loading={paymentFlowLoading} />
        )}
      </div>
    </>
  );
};
