import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Maybe from 'graphql/tsutils/Maybe';
import {
  BotTitlesQuery,
  useCreateBlankBot,
} from '@utils/Data/Bot/useCreateBlankBot';
import { Modal } from '@services/index';
import { getDefaultTab } from '@utils/Routing';
import {
  CreateNewWorkspaceBotModal,
  FormGroup,
} from './CreateNewWorkspaceBotModal';
import { Platform } from '@globals';
import { useLightWorkspaceBots } from '@utils/Data/Bot/useLightBots';
import { createNewBlankBotMutation } from '@common/services/GQLqueries/@types/createNewBlankBotMutation';
import { ExecutionResult } from 'react-apollo';

export const useCreateBotFromHeader = (data: BotTitlesQuery | undefined) => {
  const history = useHistory();
  const [createBlankBot, { loading, error }] = useCreateBlankBot(data);

  const onCreateBot = useCallback(
    async (workspaceId: Maybe<string>, platform?: Platform[]) => {
      const result = await createBlankBot(workspaceId, platform);
      const botId = result.data?.createBlankBot?.id;
      if (botId) {
        history.push(getDefaultTab(botId), {
          botCreated: true,
        });
      }

      return result;
    },
    [createBlankBot, history],
  );

  return { onCreateBot, loading, error };
};

export const useSubscribeToBotCreation = () => {
  const history = useHistory();
  const [isRenaming, setIsRenaming] = useState<boolean>(false);

  useEffect(() => {
    if (history.location.state?.botCreated) {
      setIsRenaming(true);
      history.replace({
        state: {},
      });
    }
  }, [history]);

  return {
    isRenaming,
    doneRenaming: () => setIsRenaming(false),
    startRenaming: () => setIsRenaming(true),
  };
};

export const useCreateWorkspaceBotFromHeader = () => {
  const { loading: botsLoading, flatBots: bots } = useLightWorkspaceBots();
  const { onCreateBot, loading, error } = useCreateBotFromHeader({ bots });

  const onCreate = (workspaceId: Maybe<string>, platform?: Platform[]) => {
    return Modal.show<ExecutionResult<createNewBlankBotMutation>>(
      ({ close, resolve }) => {
        return (
          <CreateNewWorkspaceBotModal
            workspaceId={workspaceId}
            onClose={close}
            onSubmit={({ group }) => {
              onCreateBot(
                group === FormGroup.workspace ? workspaceId : undefined,
                platform,
              ).then(resolve);
              close();
            }}
          />
        );
      },
      {
        mobileAdaptive: true,
        mobileProps: {
          fitHeight: true,
        },
      },
    );
  };

  return { onCreateBot: onCreate, loading, botsLoading, error };
};
