import gql from 'graphql-tag';

export const MESSAGE_TAGS_FLOW_BLOCKS_QUERY = gql`
  query MessageTagsFlowBlocksQuery($botId: String!) {
    messageTags(botId: $botId) {
      id
      tag
      disabled
    }
    oneTimeNotifications(botId: $botId) {
      purpose
      users
    }
    bot(id: $botId) {
      id
      status {
        page_info {
          id
          eu_restricted
        }
      }
    }
  }
`;
