import React from 'react';
import { escapeAttrValue, filterXSS } from 'xss';

export const HtmlTextMemoized = React.memo<{ title: string }>(({ title }) => (
  <div
    style={{ display: 'inline' }}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: filterXSS(title, {
        onIgnoreTagAttr: (_, name, value) =>
          name === 'style' ? `${name}="${escapeAttrValue(value)}"` : undefined,
      }),
    }}
  />
));
