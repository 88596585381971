import ng from 'angular';
import { react2angular } from 'react2angular';
import React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import {
  FacebookAnalyticsPlugin,
  FacebookAnalyticsPluginProps,
} from './FacebookAnalyticsPlugin';
import client from '../../../common/services/ApolloService';

const FacebookAnalyticsWrapper: React.FC<FacebookAnalyticsPluginProps> = (
  props,
) => (
  <ApolloProvider client={client}>
    <FacebookAnalyticsPlugin {...props} />
  </ApolloProvider>
);

export const ngFacebookAnalyticsPlugin = ng
  .module('app.plugins.facebookAnalyticsPlugin', [])
  .component(
    'facebookAnalyticsPlugin',
    react2angular(
      FacebookAnalyticsWrapper,
      ['id', 'blockId', 'position', 'botId'],
      [],
    ),
  ).name;
