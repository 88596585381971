import React from 'react';
import cn from 'classnames';

import { ChatfuelLogo } from '@components/PageHeader/ChatfuelLogo';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { WhiteLabelLogo } from '@components/PageHeader/WhiteLabelLogo';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import * as css from './NavigationCompanyIcon.css';
import { useAdminId } from '@utils/Admin/useAdminId';

interface NavigationCompanyIconProps {
  buttonWrapper: boolean;
  fullLogo: boolean;
  small?: boolean;
  big?: boolean;
  wide?: boolean;
}

const DATA_TEST_ID = 'test-nav-to-bot-list';

export const NavigationCompanyIcon: React.FC<NavigationCompanyIconProps> = ({
  buttonWrapper,
  fullLogo,
  small,
  big,
  wide,
}) => {
  const { id, loading } = useAdminId({
    errorPolicy: 'all',
  });

  const width = fullLogo ? 84 : 18;
  const height = 18;
  let content = null;

  if (loading) {
    content = <LoadingPlaceholder width={width} height={height} />;
  } else if (isWhiteLabelDomain()) {
    content = (
      <WhiteLabelLogo
        data-testid={DATA_TEST_ID}
        style={{
          width,
          height,
          justifyContent: big ? 'flex-start' : undefined,
        }}
      />
    );
  } else if (id) {
    content = (
      <ChatfuelLogo
        buttonWrapper={buttonWrapper}
        full={fullLogo}
        data-testid={DATA_TEST_ID}
      />
    );
  }

  return (
    <div
      className={cn(
        {
          [css.small]: small,
          [css.wide]: wide,
          [css.big]: big,
        },
        css.icon,
      )}
    >
      {content}
    </div>
  );
};
