import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React from 'react';

interface Props {
  count: number;
  broadcast?: boolean;
}

export const Dialogues: React.FC<Props> = ({ count = 0, broadcast }) => {
  const { t } = useSafeTranslation();
  return (
    <Type
      size="15px"
      as="p"
      noWrap
      data-testid="payments-history__dialogues-count"
    >
      {count.toLocaleString('en-EN')}{' '}
      {broadcast
        ? t('modernComponents.Payments.PaymentHistory.broadcasts')
        : t('modernComponents.Payments.PaymentHistory.dialogues')}
    </Type>
  );
};
