import ng from 'angular';
import { react2angular } from 'react2angular';

import { PeopleTabNoPageWrapper } from './PeopleTabNoPage';

export const ngPeopleTabNoPage = ng
  .module('app.components.peopleTabNoPage', [])
  .component(
    'peopleTabNoPage',
    react2angular(PeopleTabNoPageWrapper, ['onConnectPageRequest'], []),
  ).name;
