import gql from 'graphql-tag';
import {
  COMMON_PLUGIN_FRAGMENT,
  KEYWORDS_SETUP_FRAGMENT,
} from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment commentsAutoreplyEntryPointFragment on CommentsAutoreplyEntryPoint {
    ...commonPluginFragment
    config {
      apply_for_all_posts
      posts {
        id
        full_picture
        message
        permalink_url
      }
      already_subscribed_setup_enabled
      keywords_setup {
        ...keywordsSetupFragment
      }
      already_subscribed_keywords_setup {
        ...keywordsSetupFragment
      }
    }
  }
  ${KEYWORDS_SETUP_FRAGMENT}
  ${COMMON_PLUGIN_FRAGMENT}
`;
