import { useEffect, useState } from 'react';
import noop from 'lodash-es/noop';
import { getPixiField } from '@components/FlowBuilder/PixiFieldRepository';

/**
 * Хук подписывается на события изменения сролла и зума
 */
export const usePixiVieportUpdate = () => {
  const [viewportMoved, setVieportMoved] = useState<boolean>(false);
  const [viewportZoomed, setVieportZoomed] = useState<boolean>(false);

  useEffect(() => {
    /**
     * Ожидается, что на момент использования хука
     * pixiField exist
     */
    const pixiField = getPixiField();
    if (!pixiField) return noop;

    const handleZoomedStart = () => {
      if (!viewportZoomed) setVieportZoomed(true);
    };
    const handleZoomedEnd = () => {
      if (viewportZoomed) setVieportZoomed(false);
    };
    const handleMovedStart = () => {
      if (!viewportMoved) setVieportMoved(true);
    };
    const handleMovedEnd = () => {
      if (viewportMoved) setVieportMoved(false);
    };

    pixiField.viewport.on('moved', handleMovedStart);
    pixiField.viewport.on('zoomed', handleZoomedStart);
    pixiField.viewport.on('moved-end', handleMovedEnd);
    pixiField.viewport.on('zoomed-end', handleZoomedEnd);
    return () => {
      pixiField.viewport.off('moved', handleMovedStart);
      pixiField.viewport.off('zoomed', handleZoomedStart);
      pixiField.viewport.off('moved-end', handleMovedEnd);
      pixiField.viewport.off('zoomed-end', handleZoomedEnd);
    };
  }, [setVieportZoomed, setVieportMoved, viewportMoved, viewportZoomed]);

  return { viewportUpdating: viewportMoved || viewportZoomed };
};
