export enum FBAspectRatio {
  square = 'square',
  horizontal = 'horizontal',
}

export enum AspectRatioValue {
  square = 1 / 1,
  horizontal = 16 / 9,
  horizontalFacebookGallery = 1.91 / 1,
}
