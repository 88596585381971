import React from 'react';
import { Modal } from '@services/index';
import { sendEvent } from '@utils/Analytics';
import { AutomatedMessagesDialogContainer } from './AutomatedMessagesDialogContainer';
import { AutomatedMessagesSource } from './components/types';

export const useAutomatedMessages = ({
  botId,
  source,
  isSmallScreenSize,
}: {
  botId: string;
  source: AutomatedMessagesSource;
  isSmallScreenSize?: boolean;
}) => {
  const showModal = () => {
    sendEvent({
      category: 'live chat',
      action: 'show automated messages modal',
      label: 'automated messages',
      propertyBag: {
        source,
      },
    });
    Modal.show(
      ({ close }) => (
        <AutomatedMessagesDialogContainer
          botId={botId}
          source={source}
          onDismiss={close}
          isSmallScreenSize={isSmallScreenSize}
        />
      ),
      { mobileAdaptive: isSmallScreenSize },
    );
  };

  return { showModal };
};
