import React, { FC, useEffect } from 'react';
import { Dialog } from '@ui/Dialog';
import { PaymentFlowState } from '@utils/Payment/constants';
import { sendEvent } from '@utils/Analytics';
import { PaymentForm } from '../PaymentForm';
import { usePremiumPayment } from '../hooks/usePremiumPayment';
import { PaymentError } from '../PaymentError';
import { PaymentSuccess } from '../PaymentSuccess';
import { addBankingCardEvent } from 'cf-common/src/conversionTracking';
import { PaymentFlowProps } from '../types';
import { useAdminId } from '@utils/Admin/useAdminId';

export const PremiumPaymentFlow: FC<PaymentFlowProps> = ({
  initialPayment,
  onDismiss,
  onSuccess,
  renderPaymentError,
  setupPayment,
}) => {
  const {
    stripeIsReady,
    paymentFlowState,
    link3DSecure,
    error,
    submitPayment,
    tryAgain,
  } = usePremiumPayment(setupPayment);
  const { id: adminId } = useAdminId();

  useEffect(() => {
    sendEvent({
      category: 'premium plan payments',
      action: 'shown',
      label: 'stripe popup',
    });
  }, []);

  const handleTryAgain = () => {
    sendEvent({
      category: 'premium plan payments',
      action: 'retry payment',
      label: '3d secure',
    });
    tryAgain();
  };

  switch (paymentFlowState) {
    case PaymentFlowState.idle:
      return (
        <PaymentForm
          initialPayment={initialPayment}
          loading={!stripeIsReady}
          onSubmit={() => {
            addBankingCardEvent(adminId!);
            submitPayment();
          }}
          onDismiss={onDismiss}
        />
      );
    case PaymentFlowState.submitting:
      return <PaymentForm initialPayment={initialPayment} submitting />;
    case PaymentFlowState.redirecting3DSecure:
      return link3DSecure ? (
        <Dialog style={{ width: '500px', overflow: 'hidden' }}>
          <iframe
            width={500}
            height={600}
            src={link3DSecure}
            title="3dsIframe"
            frameBorder={0}
            style={{ backgroundColor: 'white' }}
          />
        </Dialog>
      ) : null;
    case PaymentFlowState.success:
      return <PaymentSuccess onDismiss={onSuccess ?? onDismiss} />;
    case PaymentFlowState.error: {
      const props = {
        error,
        onDismiss,
        onTryAgain: handleTryAgain,
      };
      if (renderPaymentError) {
        return renderPaymentError(props);
      }
      return <PaymentError {...props} />;
    }
    default:
      return <PaymentForm loading />;
  }
};
