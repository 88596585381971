import React, { CSSProperties } from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { Icon, IconProps } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import * as css from './BotPicture.css';

interface BotPictureProps {
  imageSrc: Maybe<string>;
  size?: IconProps['size'];
  style?: CSSProperties;
}

export const BotPicture: React.FC<BotPictureProps> = ({
  imageSrc,
  size = '16px',
  style,
}) => {
  return (
    <div className={css.box}>
      {imageSrc && (
        <div
          style={{
            backgroundImage: `url(${imageSrc})`,
            ...style,
          }}
          className={css.pictureBox}
        />
      )}
      <Flex
        alignItems="center"
        justifyContent="center"
        className={css.defaultPictureBox}
        style={style}
      >
        <Icon icon="emoji" color="greyDark" size={size} />
      </Flex>
    </div>
  );
};
