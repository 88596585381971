import React, { useEffect, useState } from 'react';
import { useBotFeatures, useBotFeaturePermissions } from '@utils/Data/Bot';
import { PermissionGroup } from '@utils/Permissions';
import { useIsBotProOrTrial } from '../../../utils/Pro';
import { sendEvent } from '../../../utils/Analytics';
import { redirect } from '../../../utils/UrlUtils';
import {
  PluginBox,
  PluginData,
  PluginDataSetPluginConfigState,
  ValidationErrors,
} from '../common';
import { IPlugin } from '../common/Plugin';
import { PluginHeader } from '../common/PluginHeader';
import { PluginBlock } from '../common/PluginBlock';
import {
  EventType,
  FROM_PERMISSION_GRANT,
  PLUGIN_HELP_URL,
  PLUGIN_ICON,
  PLUGIN_NAME,
  PLUGIN_TYPE,
} from './FacebookAnalyticsPluginConst';
import {
  FacebookAnalyticsPluginPermissionsOverlay,
  FacebookAnalyticsPluginProOverlay,
} from './components/FacebookAnalyticsPluginOverlays';
import { FacebookAnalyticsPluginEventTypesSwitch } from './components/FacebookAnalyticsPluginEventTypesSwitch';
import { FacebookAnalyticsPluginFooter } from './components/FacebookAnalyticsPluginFooter';
import { FacebookAnalyticsPluginHeader } from './components/FacebookAnalyticsPluginHeader';
import { FacebookAnalyticsPluginCustomEvents } from './components/FacebookAnalyticsPluginCustomEvents';
import { FacebookAnalyticsPluginStandardEvents } from './components/FacebookAnalyticsPluginStandardEvents';
import { facebookAnalyticsPluginFragment_config as FacebookAnalyticsPluginConfig } from './@types/facebookAnalyticsPluginFragment';
import * as css from './FacebookAnalyticsPlugin.css';

export interface FacebookAnalyticsPluginDataProps {
  pluginConfig: FacebookAnalyticsPluginConfig;
  setPluginConfigState: PluginDataSetPluginConfigState<FacebookAnalyticsPluginConfig>;
  savePlugin(): void;
  validationErrors?: ValidationErrors;
}

export interface FacebookAnalyticsPluginProps extends IPlugin {
  botId: string;
}
export const FacebookAnalyticsPlugin: React.FC<FacebookAnalyticsPluginProps> =
  ({ id, blockId, botId, position }) => {
    const [dropdownTouched, setDropdownTouched] = useState(false);
    const { proOrTrialBot: isBotProOrTrial, loading: loadingBotPro } =
      useIsBotProOrTrial(botId);
    const { botFeatures, botFeaturesLoading } = useBotFeatures(botId);
    const { botFeaturePermissions, botFeaturePermissionsLoading } =
      useBotFeaturePermissions(botId);
    const fbAnalyticsPermission = botFeaturePermissions?.facebook_analytics;

    const isFromPermissionGrant = window.location.href.includes(
      FROM_PERMISSION_GRANT,
    );

    useEffect(() => {
      if (isFromPermissionGrant && !botFeaturePermissionsLoading) {
        sendEvent(
          {
            category: 'events to fb',
            action: 'give permissions',
            label: Boolean(fbAnalyticsPermission?.granted).toString(),
          },
          true,
        );
        const [cleanUrl] = window.location.href.split(FROM_PERMISSION_GRANT);
        redirect(cleanUrl);
      }
    }, [
      isFromPermissionGrant,
      botFeaturePermissionsLoading,
      fbAnalyticsPermission,
    ]);

    return botFeaturesLoading || !botFeatures?.facebook_analytics ? null : (
      <PluginData<FacebookAnalyticsPluginConfig>
        id={id}
        blockId={blockId}
        pluginType={PLUGIN_TYPE}
        position={position}
      >
        {({
          savePlugin,
          pluginConfig,
          loading,
          setPluginConfigState,
          validationErrors,
        }) => {
          if (loading) {
            return null;
          }

          const isProUpgradeNeeded = !isBotProOrTrial;
          const isPermissionsGrantNeeded =
            !isProUpgradeNeeded && !fbAnalyticsPermission?.granted;

          return (
            <PluginBox className={css.container}>
              <PluginHeader
                title={PLUGIN_NAME}
                icon={PLUGIN_ICON}
                helpUrl={PLUGIN_HELP_URL}
                pluginType={PLUGIN_TYPE}
              />

              {isProUpgradeNeeded && (
                <FacebookAnalyticsPluginProOverlay
                  isLoading={loadingBotPro}
                  botId={botId}
                />
              )}

              {isPermissionsGrantNeeded && (
                <FacebookAnalyticsPluginPermissionsOverlay
                  isLoading={botFeaturePermissionsLoading}
                  permissionGroup={
                    fbAnalyticsPermission?.neededPermissionGroup! as PermissionGroup
                  }
                />
              )}

              <FacebookAnalyticsPluginHeader />

              <FacebookAnalyticsPluginEventTypesSwitch
                pluginConfig={pluginConfig}
                setPluginConfigState={setPluginConfigState}
                savePlugin={savePlugin}
                setDropdownTouched={setDropdownTouched}
              />

              <PluginBlock>
                {pluginConfig.eventType === EventType.custom && (
                  <FacebookAnalyticsPluginCustomEvents
                    pluginConfig={pluginConfig}
                    setPluginConfigState={setPluginConfigState}
                    savePlugin={savePlugin}
                    validationErrors={validationErrors}
                  />
                )}
                {pluginConfig.eventType === EventType.standard && (
                  <FacebookAnalyticsPluginStandardEvents
                    pluginConfig={pluginConfig}
                    setPluginConfigState={setPluginConfigState}
                    savePlugin={savePlugin}
                    setDropdownTouched={setDropdownTouched}
                    dropdownTouched={dropdownTouched}
                  />
                )}
              </PluginBlock>

              <FacebookAnalyticsPluginFooter botId={botId} />
            </PluginBox>
          );
        }}
      </PluginData>
    );
  };
