import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { DefaultDialog } from '@ui/Dialog';
import { sendEvent } from '@utils/Analytics';
import { ReactComponent as Logo } from '@ui/Icon/icons/chatfuel-logo.svg';
import { STRIPE_ADMIN_QUERY } from './queries';

import * as css from './PaymentFormTemplate.css';

import { StripeAdminQuery } from './@types/StripeAdminQuery';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

interface PaymentFormProps {
  done?: boolean;
  onDismiss?: () => void;
}

export const PaymentFormTemplate: React.FC<PaymentFormProps> = ({
  done = false,
  onDismiss,
  children,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { data: adminData } = useQuery<StripeAdminQuery>(STRIPE_ADMIN_QUERY);
  const { t } = useSafeTranslation();

  const handleDismiss = () => {
    if (!done) {
      sendEvent({
        category: 'pro plan payments',
        action: 'cancel submit payment',
        label: '3d secure',
      });
    }
    onDismiss?.();
  };

  return (
    <DefaultDialog mobileAdaptive={isSmallScreenSize}>
      <Flex
        alignItems="center"
        className={css.titlePart}
        flexDirection="column"
      >
        <Flex
          className={css.logoContainer}
          alignItems="center"
          justifyContent="center"
        >
          <Logo className={css.logoImg} />
        </Flex>
        <Type weight="semibold" size="15px_DEPRECATED">
          200 Labs, Inc.
        </Type>
        <Type size="15px_DEPRECATED">
          {window.i18next.t('PaymentFormTemplate-JSXText-1502-chatfuel')}
        </Type>
      </Flex>
      <Flex alignItems="center" className={css.formPart} flexDirection="column">
        <Flex justifyContent="center" className={css.formEmail}>
          <Type
            color="grey"
            data-testid="pro-payment_admin-email"
            size="15px_DEPRECATED"
          >
            {adminData?.me.email}
          </Type>
        </Flex>
        {children}
        <Spacer factor={4} />
        <Button
          intent="text"
          className={css.dismissButton}
          onClick={handleDismiss}
          data-testid="pro-payment_cancel"
        >
          {done
            ? t('modernComponents.Payments.PaymentDialog.done')
            : t('modernComponents.Payments.PaymentDialog.cancel')}
        </Button>
      </Flex>
    </DefaultDialog>
  );
};
