import React from 'react';
import ng from 'angular';
import { ApolloProvider } from '@apollo/react-common';
import { react2angular } from 'react2angular';

import { Router } from 'react-router-dom';
import client from '../../../common/services/ApolloService';
import PluginAddController from '../../../common/plugins/controllers/plugin.add.controller';

import { AddPlugin } from './AddPlugin';
import { AddPluginProps } from './types';
import { globalHistory } from '../../../utils/Routing';

const AddPluginWrapper: React.FC<AddPluginProps> = (props) => (
  <ApolloProvider client={client}>
    <Router history={globalHistory}>
      <AddPlugin {...props} />
    </Router>
  </ApolloProvider>
);

const ngAddPluginInner = ng
  .module('add.components.addPluginInner', [])
  .component(
    'addPluginInner',
    react2angular(AddPluginWrapper, [
      'onAddPlugin',
      'type',
      'index',
      'botId',
      'mode',
    ]),
  ).name;

export const ngAddPlugin = ng
  .module('app.plugins.addPlugin', [ngAddPluginInner])
  .component('addPlugin', {
    require: {
      PluginListController: '^^pluginList',
    },
    template: () =>
      `<div class="test-add-plugin-buttons">
   <div
      ng-if="vm.mode === 'static'"
      style="font-size: 20px; line-height: 28px; margin-bottom: 14px; font-weight: 300;">
      {{'modernComponents.plugins.AddPlugin.addElement' | i18next}}
   </div>
   <div style="display: flex">
     <add-skill
        block="vm.block"
        ng-if="vm.mode === 'static' && vm.shouldShowAddSkillButton()"
      ></add-skill>
     <add-plugin-inner
        type="vm.type"
        index="vm.index"
        on-add-plugin="vm.addNew"
        bot-id="vm.botId"
        mode="vm.mode"
      ></add-plugin-inner>
    </div>
  </div>`,
    controller: PluginAddController,
    controllerAs: 'vm',
    bindings: {
      type: '@',
      index: '=',
      botId: '=',
      mode: '@',
      block: '=',
    },
  }).name;
