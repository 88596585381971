import { PluginType } from '../common/PluginTypes';
import { instagramStoryReplyEntryPointFragment_config as InstagramStoryReplyEntryPointConfig } from './@types/instagramStoryReplyEntryPointFragment';

export const PLUGIN_TYPE = PluginType.instagram_story_reply_entry_point;

export const PLUGIN_DEFAULT_CONFIG: InstagramStoryReplyEntryPointConfig = {
  __typename: 'InstagramStoryReplyEntryPointConfig',
  keywords_setup: {
    __typename: 'KeywordsSetup',
    intents: [],
    default_block_id: null,
  },
  already_subscribed_setup_enabled: false,
  already_subscribed_keywords_setup: {
    __typename: 'KeywordsSetup',
    intents: [],
    default_block_id: null,
  },
  apply_for_all_stories: true,
};

export const PLUGIN_INPUT_FIELD = 'instagramStoryReplyEntryPointConfig';
