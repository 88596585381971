import React from 'react';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import {
  BotSwitcherStatistics,
  BotSwitcherStatisticsProps,
} from '../../BotSwitcherStatistics';
import { useBotSwitcherNavItemData } from '../../hooks/useBotSwitcherNavItemData';

type SpecifiedProps =
  | 'screenSize'
  | 'showProBadge'
  | 'numeratorColor'
  | 'denominatorColor';

export interface BotSwitcherStatisticsWithDataProps
  extends Omit<BotSwitcherStatisticsProps, SpecifiedProps> {}

export const BotSwitcherStatisticsWithData: React.FC<BotSwitcherStatisticsWithDataProps> =
  (props) => {
    const { screenSize } = useDeviceMedia();
    const { bot, isBotPro } = useBotSwitcherNavItemData();

    const color =
      props.numerator >= props.denominator ? 'orangeNormal' : 'cobaltSemilight';

    return (
      <BotSwitcherStatistics
        screenSize={screenSize}
        showProBadge={isBotPro}
        numeratorColor={color}
        denominatorColor="cobaltTertiary"
        hideDenominator={Boolean(bot?.pro?.manual)}
        {...props}
      />
    );
  };
