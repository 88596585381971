import { PluginType } from '../common/PluginTypes';
import { facebookShopsProductListPluginFragment_config } from './@types/facebookShopsProductListPluginFragment';

export type FacebookShopsProductListPluginConfig = facebookShopsProductListPluginFragment_config;

export const PLUGIN_TYPE = PluginType.facebook_shops_product_list;

export const PLUGIN_DEFAULT_CONFIG: FacebookShopsProductListPluginConfig = {
  __typename: 'FacebookShopsProductListPluginConfig',
  products: [],
};

export const PLUGIN_INPUT_FIELD = 'facebookShopsProductListPluginConfig';
