import { jsonPluginFragment_config as JsonPluginConfig } from './@types/jsonPluginFragment';
import { PluginType } from '../common/PluginTypes';
import { deserialize } from '../../../modern-ui/TextWithAttributesEditor';
import { Platform } from '@globals';

export enum JsonPluginRequestPostFormat {
  full_json_profile = 'full_json_profile',
  json = 'json',
  urlencoded = 'urlencoded',
}

export enum JsonPluginRequestType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export const PLUGIN_NAME = 'JSON API';

export const PLUGIN_TYPE = PluginType.json_plugin;

export const PLUGIN_DEFAULT_CONFIG: JsonPluginConfig = {
  __typename: 'JsonPluginConfig',
  url: '',
  method: JsonPluginRequestType.POST,
  show_error_messages: false,
  headers: [],
  body: '',
  post_format: JsonPluginRequestPostFormat.full_json_profile,
  use_custom_response: false,
  custom_response_config: [],
};

export const PLUGIN_HELP_URL =
  'https://docs.chatfuel.com/api/json-api/json-api';

export const PLUGIN_INPUT_FIELD = 'jsonPluginConfig';

export const FULL_PROFILE_BODY_BY_PLATFORM = {
  [Platform.facebook]: deserialize(`{
  "messenger user id": "{{messenger user id}}",
  "first name": "{{first name}}",
  "last name": "{{last name}}",
  "gender": "{{gender}}",
  "profile pic url": "{{profile pic url}}",
  "timezone": "{{timezone}}",
  "locale": "{{locale}}",
  "source": "{{source}}",
  "last seen": "{{last seen}}",
  "signed up": "{{signed up}}",
  "sessions": {{sessions}},
  "last visited block name": "{{last visited block name}}",
  "last visited block id": "{{last visited block id}}",
  "last clicked button name": "{{last clicked button name}}"
 }`),
  [Platform.instagram]: deserialize(`{
  "instagram user id": "{{instagram user id}}",
  "instagram name": "{{instagram name}}",
  "gender": "{{gender}}",
  "profile pic url": "{{profile pic url}}",
  "timezone": "{{timezone}}",
  "locale": "{{locale}}",
  "source": "{{source}}",
  "last seen": "{{last seen}}",
  "signed up": "{{signed up}}",
  "sessions": {{sessions}},
  "last visited block name": "{{last visited block name}}",
  "last visited block id": "{{last visited block id}}",
  "last clicked button name": "{{last clicked button name}}"
 }`),
  [Platform.whatsapp]: deserialize(`{
  "whatsapp user id": "{{whatsapp user id}}",
  "whatsapp phone": "{{whatsapp phone}}",
  "source": "{{source}}",
  "last seen": "{{last seen}}",
  "signed up": "{{signed up}}",
  "sessions": {{sessions}},
  "last visited block name": "{{last visited block name}}",
  "last visited block id": "{{last visited block id}}",
  "last clicked button name": "{{last clicked button name}}"
 }`),
};

export const SEND_TO_MESSENGER_REF = 'admin_user_';

export const SEND_TO_MESSENGER_RENDER_TIMEOUT = 5000;

export const jsonPluginRequestTypes: JsonPluginRequestType[] = [
  JsonPluginRequestType.GET,
  JsonPluginRequestType.POST,
  JsonPluginRequestType.PUT,
  JsonPluginRequestType.PATCH,
  JsonPluginRequestType.DELETE,
];

export const bodilessTypes: JsonPluginRequestType[] = [
  JsonPluginRequestType.GET,
  JsonPluginRequestType.DELETE,
];

export const jsonPluginRequestPostFormats = () => [
  {
    type: JsonPluginRequestPostFormat.full_json_profile,
    title: window.i18next.t('JsonPluginConst-string-5593-full-json-profile'),
  },
  {
    type: JsonPluginRequestPostFormat.json,
    title: 'JSON',
  },
  {
    type: JsonPluginRequestPostFormat.urlencoded,
    title: window.i18next.t('JsonPluginConst-string-1344-url-encoded'),
  },
];

export const HEADER_VALUE_LIMIT = 10000;
