import React from 'react';
import { MobileTransition } from '@pages/LiveChat/MobileTransition';
import { NavigationHeaderProps } from '../NavigationHeader/NavigationHeader';
import {
  LiveChatMobilePage,
  useLiveChatMobilePageNavigation,
} from '@pages/LiveChat/useLiveChatMobilePageNavigation';
import { useLiveChatSubscriber } from '@pages/LiveChat/hooks/useLiveChatSubscriber';
import { useLocation } from 'react-router-dom';
import { CONVERSATION_ID } from '@pages/LiveChat/consts';
import {
  getUrlSearchParams,
  getUrlSearchParamsEnumSafe,
} from '@utils/Routing/getUrlSearchParams';
import { ChatListHeader } from './ChatListHeader/ChatListHeader';
import { ChatHeader } from './ChatHeader/ChatHeader';
import { AttributesHeader } from './AttributesHeader/AttributesHeader';
import css from './LiveChatNavigationHeader.css';
import { useLiveChatConversation } from '@pages/LiveChat/hooks/useLiveChatConversation';
import { Platform } from '@globals';

interface LiveChatNavigationHeaderProps extends NavigationHeaderProps {
  botId: string;
}

export const LiveChatNavigationHeader: React.FC<LiveChatNavigationHeaderProps> =
  ({ botId, ...props }) => {
    const { search } = useLocation();
    const conversationId = getUrlSearchParams(search, CONVERSATION_ID);
    const platform = getUrlSearchParamsEnumSafe(search, 'platform', Platform)!;

    const { data: conversationData } = useLiveChatConversation({
      botId,
      conversationId,
      platform,
    });

    const folder = conversationData?.folder;

    const [activePage, setActivePage] = useLiveChatMobilePageNavigation();

    const { subscriber } = useLiveChatSubscriber({
      botId,
      conversationId,
      platform,
    });

    return (
      <MobileTransition
        mobilePageClassName={css.mobilePageClassName}
        pages={[
          {
            id: LiveChatMobilePage.chatList,
            page: <ChatListHeader {...props} />,
            active: activePage === LiveChatMobilePage.chatList,
          },
          {
            id: LiveChatMobilePage.chat,
            page: (
              <ChatHeader
                setActivePage={setActivePage}
                folder={folder}
                platform={platform}
                subscriber={subscriber}
              />
            ),
            active: activePage === LiveChatMobilePage.chat,
          },
          {
            id: LiveChatMobilePage.attributes,
            page: (
              <AttributesHeader
                setActivePage={setActivePage}
                subscriber={subscriber}
              />
            ),
            active: activePage === LiveChatMobilePage.attributes,
          },
        ]}
      />
    );
  };
