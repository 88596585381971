import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { removeNullFieldsDeep } from '@utils/GQL/utils';
import { Frequency, QuickReplyType, ShopifyEventType } from '@globals';
import {
  AbandonedCartType,
  PLUGIN_DEFAULT_FLOW_BUILDER_CONFIG as ABANDONED_CART_PLUGIN_DEFAULT_FLOW_BUILDER_CONFIG,
} from '../AbandonedCartPlugin/AbandonedCartPluginConst';
import { shopifyEventEntryPointFragment_config as ShopifyEventEntryPointConfig } from '../ShopifyEventEntryPoint/@types/shopifyEventEntryPointFragment';
import { abandonedCartPluginFragment_config as AbandonedCartPluginConfig } from '../AbandonedCartPlugin/@types/abandonedCartPluginFragment';
import { productListPluginFragment_config as ProductListPluginConfig } from '../ProductListPlugin/@types/productListPluginFragment';
import {
  PLUGIN_DEFAULT_CONFIG as SHOPIFY_EVENT_ENTRY_POINT_DEFAULT_CONFIG,
  SHOPIFY_EVENT_ENTRY_POINT_EVENT_DEFAULT_CONFIGS,
} from '../ShopifyEventEntryPoint/ShopifyEventEntryPointConst';
import {
  PLUGIN_SHOPIFY_PRODUCT_LIST_DEFAULT_CONFIG,
  PLUGIN_SHOPIFY_PRODUCT_LIST_PRODUCT_VISIT_DEFAULT_CONFIG,
} from '../ProductListPlugin/ProductListPluginConst';

import { getPluginsDefaultData } from './pluginConst';
import { PluginType } from './PluginTypes';
import {
  PLUGIN_BACK_IN_STOCK_MESSAGE_PRODUCT_LIST_DEFAULT_CONFIG,
  PLUGIN_OPT_IN_CONFIRMATION_PRODUCT_LIST_DEFAULT_CONFIG,
} from '../ShopifyBackInStockEntryPoint/ShopifyBackInStockEntryPointConst';
import { getNTimeNotificationPluginConfig } from '../NTimeNotificationPlugin/NTimeNotificationPluginConst';
import {
  getQuickReplyConfig,
  QuickReplyPluginConfig,
} from '../QuickReplyPlugin/QuickReplyPluginConst';
import { getDatePickerConfig } from '../DatePickerPlugin/DatePickerPluginConst';
import {
  KOMMO_PLUGIN_DEFAULT_CONFIG,
  KommoPluginConfig,
} from '../KommoPlugin/KommoPlugin';

interface PluginDefaultData {
  pluginType: PluginType;
  config:
    | AbandonedCartPluginConfig
    | ShopifyEventEntryPointConfig
    | ProductListPluginConfig
    | QuickReplyPluginConfig
    | KommoPluginConfig;
}

const PLUGINS_SHORTCUTS: () => Record<string, PluginDefaultData> = () => ({
  [PluginType.gallery_reminder_shortcut]: {
    pluginType: PluginType.abandoned_cart,
    config: {
      ...ABANDONED_CART_PLUGIN_DEFAULT_FLOW_BUILDER_CONFIG,
      reminder_type: AbandonedCartType.gallery,
    },
  },
  [PluginType.text_reminder_shortcut]: {
    pluginType: PluginType.abandoned_cart,
    config: {
      ...ABANDONED_CART_PLUGIN_DEFAULT_FLOW_BUILDER_CONFIG,
      reminder_type: AbandonedCartType.text,
    },
  },
  [PluginType.order_status_update_shortcut]: {
    pluginType: PluginType.shopify_event_entry_point,
    config: removeNullFieldsDeep({
      ...SHOPIFY_EVENT_ENTRY_POINT_DEFAULT_CONFIG,
      events: [
        SHOPIFY_EVENT_ENTRY_POINT_EVENT_DEFAULT_CONFIGS[
          ShopifyEventType.order_status_update
        ],
      ],
    }),
  },
  [PluginType.product_list_shopify_order_shortcut]: {
    pluginType: PluginType.product_list,
    config: PLUGIN_SHOPIFY_PRODUCT_LIST_DEFAULT_CONFIG,
  },
  [PluginType.product_visit_shortcut]: {
    pluginType: PluginType.shopify_event_entry_point,
    config: removeNullFieldsDeep({
      ...SHOPIFY_EVENT_ENTRY_POINT_DEFAULT_CONFIG,
      events: [
        SHOPIFY_EVENT_ENTRY_POINT_EVENT_DEFAULT_CONFIGS[
          ShopifyEventType.product_visit
        ],
      ],
    }),
  },
  [PluginType.product_list_product_visit_shortcut]: {
    pluginType: PluginType.product_list,
    config: PLUGIN_SHOPIFY_PRODUCT_LIST_PRODUCT_VISIT_DEFAULT_CONFIG,
  },
  [PluginType.product_list_opt_in_confirmation_shortcut]: {
    pluginType: PluginType.product_list,
    config: PLUGIN_OPT_IN_CONFIRMATION_PRODUCT_LIST_DEFAULT_CONFIG,
  },
  [PluginType.product_list_back_in_stock_message_shortcut]: {
    pluginType: PluginType.product_list,
    config: PLUGIN_BACK_IN_STOCK_MESSAGE_PRODUCT_LIST_DEFAULT_CONFIG,
  },
  [PluginType.product_visit_shortcut]: {
    pluginType: PluginType.shopify_event_entry_point,
    config: removeNullFieldsDeep({
      ...SHOPIFY_EVENT_ENTRY_POINT_DEFAULT_CONFIG,
      events: [
        SHOPIFY_EVENT_ENTRY_POINT_EVENT_DEFAULT_CONFIGS[
          ShopifyEventType.product_visit
        ],
      ],
    }),
  },
  [PluginType.order_confirmation_shortcut]: {
    pluginType: PluginType.shopify_event_entry_point,
    config: removeNullFieldsDeep({
      ...SHOPIFY_EVENT_ENTRY_POINT_DEFAULT_CONFIG,
      events: [
        SHOPIFY_EVENT_ENTRY_POINT_EVENT_DEFAULT_CONFIGS[
          ShopifyEventType.order_confirmation
        ],
      ],
    }),
  },
  [PluginType.product_list_product_visit_shortcut]: {
    pluginType: PluginType.product_list,
    config: PLUGIN_SHOPIFY_PRODUCT_LIST_PRODUCT_VISIT_DEFAULT_CONFIG,
  },
  [PluginType.kommo_plugin]: {
    pluginType: PluginType.kommo_plugin,
    config: KOMMO_PLUGIN_DEFAULT_CONFIG,
  },
  [PluginType.nTNDaily]: {
    pluginType: PluginType.notification_messages_request,
    config: getNTimeNotificationPluginConfig({ frequency: Frequency.DAILY }),
  },
  [PluginType.nTNMonthly]: {
    pluginType: PluginType.notification_messages_request,
    config: getNTimeNotificationPluginConfig({ frequency: Frequency.MONTHLY }),
  },
  [PluginType.nTNWeekly]: {
    pluginType: PluginType.notification_messages_request,
    config: getNTimeNotificationPluginConfig({ frequency: Frequency.WEEKLY }),
  },
  [PluginType.datepicker_quick_reply_shortcut]: {
    pluginType: PluginType.date_picker,
    config: getDatePickerConfig({
      property: 'date',
      date_button: {
        title: i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.datePicker.shortcut.buttonTitle',
        ),
      },
      skip_button: {
        title: i18next.t('QuickReplyPlugin-string-2578-skip'),
      },
    }),
  },
  [PluginType.date_and_time_shortcut]: {
    pluginType: PluginType.date_picker,
    config: getDatePickerConfig({
      type: QuickReplyType.datetime,
      property: 'datetime',
      date_button: {
        title: i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.dateAndTime.shortcut.buttonTitle',
        ),
      },
      skip_button: {
        title: i18next.t('QuickReplyPlugin-string-2578-skip'),
      },
    }),
  },
  [PluginType.collect_answers_shortcut]: {
    pluginType: PluginType.quick_reply,
    config: getQuickReplyConfig({
      type: QuickReplyType.text,
      error_messages: {
        text: i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.answers.shortcut.errorMessage',
        ),
      },
    }),
  },
  [PluginType.collect_emails_shortcut]: {
    pluginType: PluginType.quick_reply,
    config: getQuickReplyConfig({
      type: QuickReplyType.email,
      property: 'email',
      error_messages: {
        email: i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.emails.shortcut.errorMessage',
        ),
      },
    }),
  },
  [PluginType.collect_phones_shortcut]: {
    pluginType: PluginType.quick_reply,
    config: getQuickReplyConfig({
      type: QuickReplyType.phone,
      property: 'phone',
      error_messages: {
        phone: i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.phones.shortcut.errorMessage',
        ),
      },
    }),
  },
  [PluginType.collect_numbers_shortcut]: {
    pluginType: PluginType.quick_reply,
    config: getQuickReplyConfig({
      type: QuickReplyType.number,
      error_messages: {
        number: i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.numbers.shortcut.errorMessage',
        ),
      },
    }),
  },
});

export const getShortcutPluginDefaultData = memoize(
  (pluginType: string) => {
    const shortcut = PLUGINS_SHORTCUTS()[pluginType];
    const defaultConfig = getPluginsDefaultData()[pluginType];
    return (
      shortcut || {
        pluginType,
        config:
          defaultConfig &&
          removeNullFieldsDeep(Object.values(defaultConfig)[0]),
      }
    );
  },
  (...args) => Object.values(args).join('_'),
);
