import { useCurrentBot } from '@utils/Data/Bot';
import { BotLivelinessStatus } from '@globals';
import { useCurrentBotId } from '@utils/Routing';
import { isBotPro, isBotProInWorkspace } from '@utils/Pro';
import { useRolePermission } from '@utils/Roles';
import { refetchDialogsPricingQuery } from '@utils/Data/Pricing/hooks';
import { refetchBotPricingCurrentUsersData } from '@utils/Data/Pricing/refetchBotPricingCurrentUsersData/refetchBotPricingCurrentUsersData';
import { Permission } from '@common/services/RoleService';
import { calcPlanLimitReached } from '@utils/Data/Pricing';
import { usePaymentData } from '@pages/BotPage/HomeTab/components/BotSectionView/Data/usePaymentData';
import { useDialoguesPricingData } from '@pages/BotPage/HomeTab/components/BotSectionView/Data/useDialoguesPricingData';
import { useOnTabChange } from './useOnTabChange';
import { useWorkspace } from '@utils/Data/Workspaces/useWorkspace';

export const useDialogsPricingStatistics = () => {
  const botId = useCurrentBotId();
  const { pricing, pricingLoading } = useDialoguesPricingData();

  useOnTabChange(() => {
    refetchDialogsPricingQuery(botId!);
  });

  const statistics = [
    pricing?.reached_limit ?? 0,
    pricing?.current_tier_limit ?? 0,
  ] as const;

  const loading = pricingLoading;

  return {
    statistics,
    loading,
  };
};

export const useSubscribersPricingStatistics = () => {
  const { bot, loading: botLoading } = useCurrentBot();
  const { pricing, pricingLoading } = useDialoguesPricingData();

  useOnTabChange(() => {
    refetchBotPricingCurrentUsersData(bot?.id!);
  });

  const statistics = [
    bot?.pricingData?.reached_limit ?? 0,
    pricing?.current_tier_limit ?? 0,
  ] as const;

  const loading = botLoading || pricingLoading;

  return {
    statistics,
    loading,
  };
};

export const useBotSwitcherNavItemData = () => {
  const { bot, loading: botLoading } = useCurrentBot();
  const { pricing, pricingLoading } = useDialoguesPricingData();
  const { paymentError, loading: paymentDataLoading } =
    usePaymentData('cache-and-network');
  const { allowed, loading: roleLoading } = useRolePermission({
    domain: 'pro',
    can: Permission.EDIT,
  });
  const { workspace, loading: workspaceLoading } = useWorkspace(
    bot?.workspace_id,
  );

  const loading =
    botLoading ||
    pricingLoading ||
    roleLoading ||
    workspaceLoading ||
    paymentDataLoading;

  const active = bot?.botLivelinessStatus === BotLivelinessStatus.LIVE;
  const isLimitReached = calcPlanLimitReached(pricing, loading);
  const isPaymentFailed = Boolean(paymentError?.isPaymentFailed);

  return {
    bot,
    loading,
    isBotPro: isBotPro(bot) || isBotProInWorkspace(bot, workspace || undefined),
    allowed,
    active,
    isPaymentFailed,
    isLimitReached,
  };
};
