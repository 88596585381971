import gql from 'graphql-tag';
import {
  COMMON_PLUGIN_FRAGMENT,
  KEYWORDS_SETUP_FRAGMENT,
} from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment adCommentsAutoreplyEntryPointFragment on AdCommentsAutoreplyEntryPoint {
    ...commonPluginFragment
    config {
      adAccount {
        id
        name
        tos_accepted {
          custom_audience_tos
          value_based_custom_audience_tos
        }
      }
      posts {
        ad {
          id
          name
          thumbnailUrl
          createdAt
          updatedAt
        }
        postIds
      }
      apply_for_all_posts
      already_subscribed_setup_enabled
      keywords_setup {
        ...keywordsSetupFragment
      }
      already_subscribed_keywords_setup {
        ...keywordsSetupFragment
      }
    }
  }
  ${KEYWORDS_SETUP_FRAGMENT}
  ${COMMON_PLUGIN_FRAGMENT}
`;
