import React from 'react';
import { Spacer } from '@ui/Spacer';
import { PaymentFormTemplate } from '../PaymentFormTemplate';
import { ReactComponent as SuccessIcon } from './success-icon.svg';

interface PaymentSuccessProps {
  onDismiss: () => void;
}

export const PaymentSuccess: React.FC<PaymentSuccessProps> = ({
  onDismiss,
}) => {
  return (
    <PaymentFormTemplate onDismiss={onDismiss} done>
      <Spacer factor={9} />
      <SuccessIcon />
      <Spacer factor={8} />
    </PaymentFormTemplate>
  );
};
