import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { PaymentDetailsStatus } from '@globals';
import { useGoPremiumBillingPage } from '@pages/BotListPage/components/WorkspaceList/Workspace/utils';
import * as css from './BotSwitcherWorkspaceButton.css';
import {
  BotSwitcherWorkspaceAdmins,
  BotSwitcherWorkspaceAdminsVariables,
} from './@types/BotSwitcherWorkspaceAdmins';
import { Workspace } from '@utils/Data/Workspaces/useWorkspace';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  SizeDependentButton,
  SizeDependentButtonProps,
} from '@pages/BotPage/HomeTab/components/BotSectionView/PlanButton/SizeDependentButton';

export const WORKSPACE_ADMINS_AND_INVITES_QUERY = gql`
  query BotSwitcherWorkspaceAdmins($workspaceId: String!) {
    me {
      id
    }
    workspaceAdmins(workspaceId: $workspaceId) {
      id
      name
      pictureUrl
      roleId
      mixedPermissions
    }
  }
`;

export interface BotSwitcherWorkspaceButtonProps
  extends SizeDependentButtonProps {
  workspace: Workspace;
}

export const BotSwitcherWorkspaceButton: React.FC<BotSwitcherWorkspaceButtonProps> =
  ({ workspace, ...props }) => {
    const { t } = useSafeTranslation();
    const { data } = useQuery<
      BotSwitcherWorkspaceAdmins,
      BotSwitcherWorkspaceAdminsVariables
    >(WORKSPACE_ADMINS_AND_INVITES_QUERY, {
      variables: {
        workspaceId: workspace.id,
      },
    });
    const goPremiumBillingPage = useGoPremiumBillingPage({ update: 'true' });

    const admin = data?.workspaceAdmins?.find(({ id }) => id === data?.me.id);

    if (
      !admin ||
      ![
        PaymentDetailsStatus.cancelled,
        PaymentDetailsStatus.to_be_cancelled,
      ].includes(workspace.subscription?.status as PaymentDetailsStatus)
    ) {
      return null;
    }

    return (
      <SizeDependentButton
        {...props}
        className={css.button}
        onClick={goPremiumBillingPage}
        data-testid="bot-switcher__update-workspace"
      >
        {t('modernComponents.BotSwitcher.upgrade')}
      </SizeDependentButton>
    );
  };
