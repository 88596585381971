import { PluginType } from '../common/PluginTypes';
import { instagramCommentsAutoreplyEntryPointFragment_config } from './@types/instagramCommentsAutoreplyEntryPointFragment';

export type InstagramCommentsAutoreplyEntryPointConfig = instagramCommentsAutoreplyEntryPointFragment_config;

export const PLUGIN_TYPE = PluginType.instagram_comments_autoreply_entry_point;

export const PLUGIN_DEFAULT_CONFIG: InstagramCommentsAutoreplyEntryPointConfig = {
  __typename: 'InstagramCommentsAutoreplyEntryPointConfig',
  already_subscribed_setup_enabled: false,
  posts: [],
  apply_for_all_posts: true,
  keywords_setup: {
    __typename: 'KeywordsSetup',
    intents: [],
    default_block_id: null,
  },
  already_subscribed_keywords_setup: {
    __typename: 'KeywordsSetup',
    intents: [],
    default_block_id: null,
  },
};

export const PLUGIN_INPUT_FIELD = 'instagramCommentsAutoreplyEntryPointConfig';
