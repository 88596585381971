import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment instagramStoryMentionEntryPointFragment on InstagramStoryMentionEntryPoint {
    ...commonPluginFragment
    config {
      block_id
    }
    enabled
    refresh
    synced
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
