import gql from 'graphql-tag';

export const UPDATE_BOT_TITLE_MUTATION = gql`
  mutation UpdateBotTitleMutation($botId: String!, $title: String!) {
    updateBotTitle_v2(id: $botId, title: $title) {
      id
      title
    }
  }
`;
