import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment calendlyIntegrationPluginFragment on CalendlyIntegrationPlugin {
    ...commonPluginFragment
    config {
      account_id
      event_type_uri
      export_attributes {
        name
        email
      }
      booked_block_id
      booked_counter_id
      rescheduled_block_id
      rescheduled_counter_id
      cancelled_block_id
      cancelled_counter_id
      reminders {
        before_ms
        block_id
        counter_id
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
