import gql from 'graphql-tag';

export const COMMON_PLUGIN_FRAGMENT = gql`
  fragment commonPluginFragment on CardI {
    id
    is_valid
    plugin_id
    position
    validation_details {
      fields {
        field
        error
      }
    }
  }
`;

export const KEYWORDS_SETUP_FRAGMENT = gql`
  fragment keywordsSetupFragment on KeywordsSetup {
    intents {
      id
      intent_id
      lines
      goto_block
      filter_type
    }
    default_block_id
  }
`;

export const SEGMENTATION_FRAGMENT = gql`
  fragment segmentationFragment on Segmentation {
    valid
    operation
    parameters {
      type
      name
      values
      operation
    }
  }
`;

export const LOCALIZATION_FRAGMENT = gql`
  fragment localizationFragment on Localization {
    key
    value {
      default
    }
  }
`;

export const VALIDATION_FRAGMENT = gql`
  fragment validationFragment on CardI {
    validation_details {
      fields {
        field
        error
      }
    }
  }
`;

export const DEFAULT_COMMON_TYPE_PLUGIN_FRAGMENT = gql`
  fragment defaultCommonTypePluginFragment on DefaultCommonTypePlugin {
    id
    is_valid
    position
    localization {
      key
      value {
        default
      }
    }
    ...validationFragment
  }
  ${VALIDATION_FRAGMENT}
`;
