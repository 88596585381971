import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { FadeInOutOpacity } from '../FadeInOutOpacity/FadeInOutOpacity';
import { Callout } from '@ui/Callout';
import { CalloutCoord, Rect } from '@components/Onboarding/types';
import { Icon } from '@ui/Icon';
import { MousemoveTracker } from '@utils/MousemoveTracker';
import {
  disableSelection,
  enableSelection,
  getMinBottomCoordinate,
  getMinRightCoordinate,
} from '@components/YouTubeFramePlayer/utils';
import throttle from 'lodash-es/throttle';
import { useWindowSize } from '@utils/DOM/useWindowSize';
import css from './OnboardingCallout.css';
import { useCalloutSize } from './useCalloutSize';
import { useBaseCoord } from './useBaseCoord';

interface OnboardingCalloutProps {
  focusZoneBounds?: Rect | null;
  show: boolean;
  text: React.ReactNode;
  button?: React.ReactNode | null;
}

export const ONBOARDING_CALLOUT_ID = 'ONBOARDING_CALLOUT_ID';

export const OnboardingCallout: React.FC<OnboardingCalloutProps> = ({
  focusZoneBounds,
  show,
  text,
  button,
}) => {
  const windowSize = useWindowSize();
  const movableContainerRef = useRef<HTMLDivElement>(null);
  const mousemoveTrackerRef = useRef(new MousemoveTracker());

  const calloutSize = useCalloutSize({
    calloutRef: movableContainerRef,
    text,
    button,
  });

  const { baseCoord, previousBaseCoord } = useBaseCoord({
    focusZoneBounds,
    windowSize,
    calloutSize,
  });

  const [normalizeCoord, setNormalizeCoord] = useState<CalloutCoord | null>(
    null,
  );
  const [customCoord, setCustomCoord] = useState<CalloutCoord | null>(null);
  const coord = customCoord || normalizeCoord;

  const wHeight = windowSize.height;
  useEffect(() => {
    setNormalizeCoord(
      !calloutSize || !baseCoord
        ? baseCoord ?? null
        : {
            ...baseCoord,
            top: Math.max(
              0,
              wHeight > calloutSize.height + baseCoord.top
                ? baseCoord?.top
                : wHeight - calloutSize.height,
            ),
          },
    );
    setCustomCoord(null);
  }, [baseCoord, wHeight, calloutSize]);

  const startTrackMousemove = useCallback(
    (event: { clientX: number; clientY: number }, isTouch: boolean = false) => {
      disableSelection();
      const containerClientRect =
        movableContainerRef.current?.getBoundingClientRect();
      const prevTop = containerClientRect?.top ?? 0;
      const prevLeft = containerClientRect?.left ?? 0;
      mousemoveTrackerRef.current.startTrack(
        throttle(({ deltaX, deltaY }) => {
          setCustomCoord({
            top: Math.max(0, prevTop + deltaY),
            left: Math.max(0, prevLeft + deltaX),
          });
        }, 5),
        () => {
          enableSelection();
        },
        {
          bottom: getMinBottomCoordinate(movableContainerRef, event),
          right: getMinRightCoordinate(movableContainerRef, event),
        },
        isTouch,
      );
    },
    [setCustomCoord],
  );

  const handleTouchStart = (e: React.TouchEvent) => {
    startTrackMousemove(e.touches[0], true);
  };

  useEffect(
    () => () => {
      mousemoveTrackerRef.current.stopTrack();
      enableSelection();
    },
    [],
  );

  return (
    <FadeInOutOpacity show={show}>
      <div
        id={ONBOARDING_CALLOUT_ID}
        ref={movableContainerRef}
        className={cn(css.container, {
          [css.center]: !coord,
          [css.withoutAnimation]:
            !previousBaseCoord ||
            (previousBaseCoord && !baseCoord) ||
            customCoord,
        })}
        style={
          coord ?? {
            left: '50%',
            top: '50%',
          }
        }
      >
        <div className={css.header}>
          <Icon
            icon="drag"
            className={cn(css.gragIcon, css.grabbing)}
            onMouseDown={startTrackMousemove}
            onTouchStart={handleTouchStart}
          />
        </div>
        <Callout
          className={css.callout}
          iconDisabled
          type="info"
          text={text}
          button={button}
        />
      </div>
    </FadeInOutOpacity>
  );
};
