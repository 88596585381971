import { getPluginGQLInputFieldByPluginType } from '@utils/GQL/getPluginGQLInputFieldByPluginType';
import gql from 'graphql-tag';
import { clone } from 'ramda';
import nanoid from 'nanoid';
import { MutationUpdaterFn } from 'apollo-client';
import { getGqlTypenameByPluginType, removeTypename } from '@utils/GQL/utils';
import { SavePluginMutationCommon } from './@types/SavePluginMutationCommon';
import { getShortcutPluginDefaultData } from './getShortcutPluginDefaultData';
import {
  BLOCK_QUERY,
  FLOW_BLOCK_FRAGMENT,
  createPluginMutation,
} from './PluginGQL';
import { BlockQuery } from './@types/BlockQuery';
import { FlowBlockQuery_flowBlock } from './@types/FlowBlockQuery';

export const NEW_PLUGIN_PREFIX = 'new_';

export const isNewPlugin = (id: string | undefined) =>
  !!id && id.indexOf(NEW_PLUGIN_PREFIX) === 0;

export const generatePluginId = () => `${NEW_PLUGIN_PREFIX}${nanoid()}`;

interface NewPluginParams {
  botId: string;
  pluginType: string;
  blockId: string;
  position: number;
  blockSubtype?: string;
}

export const FLOW_BLOCK_QUERY = gql`
  query FlowBlockQuery($blockId: ID!, $botId: ID!) {
    flowBlock(blockId: $blockId, botId: $botId) {
      ...FlowBlockFragment
    }
  }
  ${FLOW_BLOCK_FRAGMENT}
`;

const createPluginCommon = async (
  optimisticId: string,
  params: NewPluginParams,
  update?: MutationUpdaterFn<SavePluginMutationCommon>,
) => {
  const { botId, ...savePluginParams } = params;

  const { pluginType, config } = getShortcutPluginDefaultData(
    params.pluginType,
  );

  const optimisticResponse = {
    saveCard: {
      id: optimisticId,
      source_card_id: null,
      is_valid: false,
      localization: null,
      validation_details: null,
      position: params.position ?? null,
      plugin_id: pluginType,
      config,
      __typename: getGqlTypenameByPluginType(pluginType),
    },
  };

  const variables = {
    ...savePluginParams,
    pluginType,
    ...removeTypename({
      [getPluginGQLInputFieldByPluginType(pluginType)]: config,
    }),
  };

  const result = await createPluginMutation({
    pluginType,
    variables,
    optimisticResponse,
    update,
  });

  return result.data!.saveCard;
};

export const createPlugin = async (
  optimisticId: string,
  params: NewPluginParams,
) => {
  const { botId, blockId } = params;
  return createPluginCommon(optimisticId, params, (store, { data }) => {
    if (data && data.saveCard) {
      const { saveCard: newPlugin } = data;
      const blockQuery: BlockQuery | null = clone(
        store.readQuery({
          query: BLOCK_QUERY,
          variables: {
            botId,
            blockId,
          },
        }),
      );
      const block = blockQuery?.bot.block;
      if (block) {
        block.cards = [...(block.cards ?? []), newPlugin];

        store.writeQuery({
          query: BLOCK_QUERY,
          variables: {
            botId,
            blockId,
          },
          data: blockQuery,
        });
      }
    }
  });
};

export const createFlowPlugin = async (
  optimisticId: string,
  params: NewPluginParams,
) => {
  const { blockId } = params;
  return createPluginCommon(optimisticId, params, (store, { data }) => {
    if (data && data.saveCard) {
      const { saveCard: newPlugin } = data;
      let flowBlock: FlowBlockQuery_flowBlock | null | undefined;

      try {
        flowBlock = clone(
          store.readFragment<FlowBlockQuery_flowBlock>({
            fragment: FLOW_BLOCK_FRAGMENT,
            fragmentName: 'FlowBlockFragment',
            id: `FlowBlock:${blockId}`,
          }),
        );
      } catch {
        flowBlock = null;
      }

      if (flowBlock) {
        flowBlock.cards = [...(flowBlock.cards ?? []), newPlugin];
        store.writeFragment<FlowBlockQuery_flowBlock>({
          fragment: FLOW_BLOCK_FRAGMENT,
          fragmentName: 'FlowBlockFragment',
          id: `FlowBlock:${blockId}`,
          data: flowBlock,
        });
      }
    }
  });
};
