import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Anchor } from '../../../../../modern-ui/Links';
import { Type } from '../../../../../modern-ui/Type';

import { sendEvent } from '../../../../../utils/Analytics';
import { PluginBlock } from '../../../common/PluginBlock';

import {
  FacebookAnalyticsBotPageQuery,
  FacebookAnalyticsBotPageQueryVariables,
} from './@types/FacebookAnalyticsBotPageQuery';

import * as css from './FacebookAnalyticsPluginFooter.css';

const FACEBOOK_ANALYTICS_BOT_PAGE_QUERY = gql`
  query FacebookAnalyticsBotPageQuery($botId: String!) {
    bot(id: $botId) {
      status {
        page
      }
    }
  }
`;

interface FacebookAnalyticsPluginFooterProps {
  botId: string;
}

export const FacebookAnalyticsPluginFooter: React.FC<FacebookAnalyticsPluginFooterProps> = ({
  botId,
}) => {
  const { data } = useQuery<
    FacebookAnalyticsBotPageQuery,
    FacebookAnalyticsBotPageQueryVariables
  >(FACEBOOK_ANALYTICS_BOT_PAGE_QUERY, {
    variables: { botId },
  });
  const pageId = data?.bot?.status?.page;
  return (
    <PluginBlock className={css.footer}>
      <Type size="15px_DEPRECATED">
        {window.i18next.t(
          'FacebookAnalyticsPluginFooter-JSXText--303-you-can-see-sent-events-in',
        )}
      </Type>
      <Anchor
        hideArrow
        intent="external"
        href="https://www.facebook.com/adsmanager/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          sendEvent({
            category: 'events to fb',
            action: 'click',
            label: 'ads manager',
          });
        }}
      >
        {window.i18next.t(
          'FacebookAnalyticsPluginFooter-JSXText--190-facebook-ads-manager',
        )}
      </Anchor>
      <Type size="15px_DEPRECATED">
        {window.i18next.t('FacebookAnalyticsPluginFooter-JSXText-1856-and-the')}
      </Type>
      <Anchor
        hideArrow
        intent="external"
        href={
          pageId
            ? `https://www.facebook.com/analytics/${pageId}/AppEvents`
            : 'https://www.facebook.com/analytics'
        }
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          sendEvent({
            category: 'events to fb',
            action: 'click',
            label: 'fb analytics',
          });
        }}
      >
        {window.i18next.t(
          'FacebookAnalyticsPluginFooter-JSXText--386-facebook-events',
        )}
      </Anchor>
      <Type size="15px_DEPRECATED">.</Type>
    </PluginBlock>
  );
};
