import React from 'react';
import cn from 'classnames';

import gql from 'graphql-tag';
import { PageTitle } from '@ui/PageTitle';
import * as css from './LivechatIcon.css';
import * as commonCSS from '../../common.css';
import { getCounterLabel } from './utils';
import {
  ActiveDialogsSubscription,
  ActiveDialogsSubscriptionVariables,
} from './@types/ActiveDialogsSubscription';
import { useSubscriptionWithAuth } from '@utils/Data/hocks/useSubscriptionWithAuth';
import { useQuery } from 'react-apollo';
import {
  ActiveDialogsQuery,
  ActiveDialogsQueryVariables,
} from './@types/ActiveDialogsQuery';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { DIALOGS_UNSEEN_MESSAGES_COUNT_SUBSCRIPTION } from '@pages/LiveChat/common/subscriptions';
import { playLiveChatNotificationSound } from '@pages/LiveChat/utils/liveChatNotificationSound';
import {
  DialogsUnseenMessagesCountSubscription,
  DialogsUnseenMessagesCountSubscriptionVariables,
} from '@pages/LiveChat/common/@types/DialogsUnseenMessagesCountSubscription';

export interface ILivechatIconProps {
  botId?: string;
  positioned?: boolean;
}

export const ACTIVE_DIALOGS_QUERY = gql`
  query ActiveDialogsQuery($botId: String!) {
    activeDialogsCount(botId: $botId) {
      id
      count
    }
  }
`;

export const ACTIVE_DIALOGS_SUBSCRIPTION = gql`
  subscription ActiveDialogsSubscription($botId: String!) {
    activeDialogsCount(botId: $botId) {
      id
      count
    }
  }
`;

export const LivechatIcon: React.FC<ILivechatIconProps> = ({
  botId,
  positioned,
}) => {
  const { allowed: allowedInboxPermission, loading: permissionLoading } =
    useRolePermission({
      domain: 'inbox',
      can: Permission.VIEW,
    });

  useSubscriptionWithAuth<
    ActiveDialogsSubscription,
    ActiveDialogsSubscriptionVariables
  >(ACTIVE_DIALOGS_SUBSCRIPTION, {
    variables: {
      botId: botId || '',
    },
    skip: !botId || !allowedInboxPermission,
  });

  useSubscriptionWithAuth<
    DialogsUnseenMessagesCountSubscription,
    DialogsUnseenMessagesCountSubscriptionVariables
  >(DIALOGS_UNSEEN_MESSAGES_COUNT_SUBSCRIPTION, {
    variables: {
      botId: botId || '',
    },
    onSubscriptionData() {
      playLiveChatNotificationSound(botId);
    },
    skip: !botId,
  });

  const { data, loading, error } = useQuery<
    ActiveDialogsQuery,
    ActiveDialogsQueryVariables
  >(ACTIVE_DIALOGS_QUERY, {
    variables: {
      botId: botId || '',
    },
    skip: !botId || !allowedInboxPermission,
  });

  if (loading || error || !botId || permissionLoading) {
    return null;
  }

  const counter = data?.activeDialogsCount.count || 0;
  const counterLabel = getCounterLabel(counter);
  const shouldShowLabel = counter > 0 && counterLabel;

  return (
    <>
      <PageTitle dialogsCounter={counter} />
      {shouldShowLabel && (
        <div
          className={cn(css.counter, {
            [commonCSS.labelPositioned]: positioned,
          })}
        >
          {counterLabel}
        </div>
      )}
    </>
  );
};
