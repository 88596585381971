import React from 'react';
import { BotFeatures } from '@utils/Data/Bot';

import { PermissionGroup } from '@utils/Permissions';
import { getPlansPageUrl } from '@utils/Routing';

import { BlockOverlay } from '../../../../../modern-ui/BlockOverlay';
import { Loader } from '../../../../../modern-ui/Loader';
import { Type } from '../../../../../modern-ui/Type';
import {
  Button,
  ButtonIntent,
} from '../../../../../modern-ui/_deprecated/Button';

import { sendEvent } from '../../../../../utils/Analytics';
import { redirect } from '../../../../../utils/UrlUtils';

import { FROM_PERMISSION_GRANT } from '../../FacebookAnalyticsPluginConst';

import * as css from './FacebookAnalyticsPluginOverlays.css';
import { goToGetPermissions } from '../../../../../utils/FacebookPages/goToGetPermissions';

interface FacebookAnalyticsPluginOverlayProps {
  isLoading: boolean;
}
interface WithBot {
  botId: string;
}

type FacebookAnalyticsPluginProOverlayProps =
  FacebookAnalyticsPluginOverlayProps & WithBot;
export const FacebookAnalyticsPluginProOverlay: React.FC<FacebookAnalyticsPluginProOverlayProps> =
  ({ isLoading, botId }) => (
    <BlockOverlay className={css.overlay}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={css.overlayHeader}>
            <Type size="24px" weight="medium" as="div" align="center">
              {window.i18next.t(
                'FacebookAnalyticsPluginOverlays-JSXText--211-send-analytics-events-to-facebook-plugin',
              )}
              <br />
              {window.i18next.t(
                'FacebookAnalyticsPluginOverlays-JSXText-2212-is-a-pro-feature',
              )}
            </Type>
          </div>
          <Type size="18px" as="div" align="center">
            {window.i18next.t(
              'FacebookAnalyticsPluginOverlays-JSXText-6143-upgrade-to-pro-to-enable-this-plugin',
            )}
            <br />
            {window.i18next.t(
              'FacebookAnalyticsPluginOverlays-JSXText-1654-all-your-data-will-be-restored',
            )}
          </Type>
          <BotFeatures botId={botId}>
            {({ botFeatures, botFeaturesLoading }) => (
              <Button
                className={css.overlayButton}
                wide
                intent={ButtonIntent.primary}
                disabled={botFeaturesLoading}
                onClick={() => {
                  sendEvent(
                    {
                      category: 'events to fb',
                      action: 'upgrade to pro',
                    },
                    true,
                  );
                  redirect(
                    getPlansPageUrl(
                      botId,
                      !!botFeatures?.dialogs_pricing_enabled,
                      'facebook-events',
                    ),
                  );
                }}
              >
                {window.i18next.t(
                  'FacebookAnalyticsPluginOverlays-JSXText-1227-upgrade-to-pro',
                )}
              </Button>
            )}
          </BotFeatures>
        </>
      )}
    </BlockOverlay>
  );

interface FacebookAnalyticsPluginPermissionsOverlay
  extends FacebookAnalyticsPluginOverlayProps {
  permissionGroup: PermissionGroup;
}

export const FacebookAnalyticsPluginPermissionsOverlay: React.FC<FacebookAnalyticsPluginPermissionsOverlay> =
  ({ isLoading, permissionGroup }) => (
    <BlockOverlay className={css.overlay}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={css.overlayHeader}>
            <Type size="24px" weight="medium">
              {window.i18next.t(
                'FacebookAnalyticsPluginOverlays-JSXText-1700-please-grant-permissions-to-use-plugin',
              )}
            </Type>
          </div>
          <Type size="18px">
            {window.i18next.t(
              'FacebookAnalyticsPluginOverlays-JSXText--970-you-need-to-grant-us-permissions-to-log-events-on-your',
            )}
          </Type>
          <Type size="18px">
            {window.i18next.t(
              'FacebookAnalyticsPluginOverlays-JSXText-1617-pages-behalf-and-send-those-events-to-facebook',
            )}
          </Type>
          <Button
            className={css.overlayButton}
            wide
            intent={ButtonIntent.primary}
            onClick={() => {
              sendEvent({
                category: 'events to fb',
                action: 'click',
                label: 'grant permission',
              });
              goToGetPermissions({
                permissionGroup,
                interruptedHref: `${window.location.href}?${FROM_PERMISSION_GRANT}`,
              });
            }}
          >
            {window.i18next.t(
              'FacebookAnalyticsPluginOverlays-JSXText--164-grant-permission',
            )}
          </Button>
        </>
      )}
    </BlockOverlay>
  );
