import { PaymentFlowErrorData, PaymentFlowErrorKind } from 'cf-common/src/utils/Stripe/errors';


const codeToMessage: () => Record<
  string,
  { header: String; body: String }
> = () => ({
  authentication_required: {
    header: window.i18next.t(
      'messages-string--662-the-card-was-declined-as-the-transaction-requires-pin-code-or-your-sign',
    ),
    body: window.i18next.t(
      'messages-string--329-please-use-another-card-or-contact-your-card-issuer-for-more-information',
    ),
  },
  approve_with_id: {
    header: 'The payment could not be processed for an unknown reason.',
    body: window.i18next.t(
      'messages-string-4649-please-try-in-10-minutes-or-use-another-card',
    ),
  },
  call_issuer: {
    header: 'The payment could not be processed for an unknown reason.',
    body: window.i18next.t(
      'messages-string--329-please-use-another-card-or-contact-your-card-issuer-for-more-information',
    ),
  },
  card_not_supported: {
    header: window.i18next.t(
      'messages-string--136-the-card-does-not-support-online-purchase',
    ),
    body: window.i18next.t(
      'messages-string--122-please-allow-internet-purchases-or-use-another-card',
    ),
  },
  card_velocity_exceeded: {
    header: window.i18next.t(
      'messages-string-5810-youve-exceeded-the-balance-or-credit-limit-available-on-your-card',
    ),
    body: window.i18next.t(
      'messages-string--132-please-top-up-your-balance-or-check-credit-limits-and-try-again-or-use-another-card',
    ),
  },
  currency_not_supported: {
    header: window.i18next.t(
      'messages-string-6829-the-card-does-not-support-the-specified-currency',
    ),
    body: window.i18next.t('messages-string--148-please-use-another-card'),
  },
  do_not_honor: {
    header: 'The payment could not be processed for an unknown reason.',
    body: window.i18next.t(
      'messages-string--329-please-use-another-card-or-contact-your-card-issuer-for-more-information',
    ),
  },
  do_not_try_again: {
    header: 'The payment could not be processed for an unknown reason.',
    body: window.i18next.t(
      'messages-string--329-please-use-another-card-or-contact-your-card-issuer-for-more-information',
    ),
  },
  duplicate_transaction: {
    header: window.i18next.t(
      'messages-string--965-a-transaction-with-identical-amount-and-credit-card-information-was-submitted-very-recently',
    ),
    body: window.i18next.t(
      'messages-string-4649-please-try-in-10-minutes-or-use-another-card',
    ),
  },
  expired_card: {
    header: window.i18next.t('messages-string-1394-the-card-has-expired'),
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  fraudulent: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  generic_decline: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  incorrect_number: {
    header: window.i18next.t(
      'messages-string-1524-the-card-number-is-incorrect',
    ),
    body: window.i18next.t(
      'messages-string-1373-please-check-card-number-and-try-again-or-use-another-card',
    ),
  },
  incorrect_cvc: {
    header: window.i18next.t(
      'messages-string-1083-the-cvc-number-is-incorrect',
    ),
    body: window.i18next.t(
      'messages-string-3635-please-check-cvc-and-try-again-or-use-another-card',
    ),
  },
  insufficient_funds: {
    header: window.i18next.t(
      'messages-string-1889-the-card-has-insufficient-funds-to-complete-the-purchase',
    ),
    body: window.i18next.t(
      'messages-string--132-please-top-up-your-balance-or-check-credit-limits-and-try-again-or-use-another-card',
    ),
  },
  invalid_account: {
    header: window.i18next.t(
      'messages-string--152-the-card-or-account-the-card-is-connected-to-is-invalid',
    ),
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  invalid_amount: {
    header: window.i18next.t(
      'messages-string--167-the-payment-amount-is-invalid-or-exceeds-the-amount-that-is-allowed',
    ),
    body: window.i18next.t(
      'messages-string--132-please-top-up-your-balance-or-check-credit-limits-and-try-again-or-use-another-card',
    ),
  },
  invalid_cvc: {
    header: window.i18next.t(
      'messages-string-1083-the-cvc-number-is-incorrect',
    ),
    body: window.i18next.t(
      'messages-string-3635-please-check-cvc-and-try-again-or-use-another-card',
    ),
  },
  invalid_expiry_month: {
    header: window.i18next.t(
      'messages-string--862-the-expiration-month-is-invalid',
    ),
    body: window.i18next.t(
      'messages-string-8538-please-check-the-expiration-month-and-try-again-or-use-another-card',
    ),
  },
  invalid_expiry_year: {
    header: window.i18next.t(
      'messages-string--139-the-expiration-year-is-invalid',
    ),
    body: window.i18next.t(
      'messages-string-1476-please-check-the-expiration-year-and-try-again-or-use-another-card',
    ),
  },
  invalid_number: {
    header: window.i18next.t(
      'messages-string-1524-the-card-number-is-incorrect',
    ),
    body: window.i18next.t(
      'messages-string-1373-please-check-card-number-and-try-again-or-use-another-card',
    ),
  },
  issuer_not_available: {
    header: window.i18next.t(
      'messages-string-7215-the-card-issuer-could-not-be-reached',
    ),
    body: window.i18next.t(
      'messages-string-4649-please-try-in-10-minutes-or-use-another-card',
    ),
  },
  lost_card: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  merchant_blacklist: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  new_account_information_available: {
    header: window.i18next.t(
      'messages-string--152-the-card-or-account-the-card-is-connected-to-is-invalid',
    ),
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  no_action_taken: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  not_permitted: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  offline_pin_required: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  online_or_offline_pin_required: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  pickup_card: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  processing_error: {
    header: 'The payment could not be processed for an unknown reason.',
    body: window.i18next.t(
      'messages-string-4417-please-try-in-10-minutes-or-use-another-card-or-contact-your-card-issuer-for-more-information',
    ),
  },
  reenter_transaction: {
    header: 'The payment could not be processed for an unknown reason.',
    body: window.i18next.t(
      'messages-string-4417-please-try-in-10-minutes-or-use-another-card-or-contact-your-card-issuer-for-more-information',
    ),
  },
  restricted_card: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  revocation_of_all_authorizations: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  revocation_of_authorization: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  security_violation: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  service_not_allowed: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  stolen_card: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  stop_payment_order: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  testmode_decline: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  transaction_not_allowed: {
    header: 'The payment could not be processed for an unknown reason.',
    body:
      'Please use another card or contact your card issuer for more information.',
  },
  try_again_later: {
    header: 'The payment could not be processed for an unknown reason.',
    body: window.i18next.t(
      'messages-string-4417-please-try-in-10-minutes-or-use-another-card-or-contact-your-card-issuer-for-more-information',
    ),
  },
  withdrawal_count_limit_exceeded: {
    header: window.i18next.t(
      'messages-string-5810-youve-exceeded-the-balance-or-credit-limit-available-on-your-card',
    ),
    body: window.i18next.t(
      'messages-string--132-please-top-up-your-balance-or-check-credit-limits-and-try-again-or-use-another-card',
    ),
  },
});

export const getErrorHeader = (error?: PaymentFlowErrorData) => {
  if (
    error?.kind === PaymentFlowErrorKind.stripeDecline &&
    error?.declineCode &&
    codeToMessage()[error.declineCode]
  ) {
    return codeToMessage()[error.declineCode].header;
  }

  return window.i18next.t(
    'messages-string--128-the-payment-could-not-be-processed-for-an-unknown-reason',
  );
};

export const getErrorDescription = (error?: PaymentFlowErrorData) => {
  if (
    error?.kind === PaymentFlowErrorKind.stripeDecline &&
    error?.declineCode &&
    codeToMessage()[error.declineCode]
  ) {
    return codeToMessage()[error.declineCode].body;
  }

  return window.i18next.t(
    'messages-string--433-please-try-again-in-10-minutes-or-use-another-card',
  );
};
