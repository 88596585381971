import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { VideoDialog } from '../VideoDialog/VideoDialog';

interface WhatsappConnectPhoneTutorialProps {
  onDismiss: VoidFunction;
  onStart: VoidFunction;
}

export const WhatsappConnectPhoneTutorialDialog: React.FC<WhatsappConnectPhoneTutorialProps> =
  ({ onDismiss, onStart }) => {
    const { t } = useSafeTranslation();
    return (
      <VideoDialog
        buttonText={t(
          'MiniOnboarding.WhatsappConnectPhoneTutorialDialog.button',
        )}
        title={t('MiniOnboarding.WhatsappConnectPhoneTutorialDialog.title')}
        description={t(
          'MiniOnboarding.WhatsappConnectPhoneTutorialDialog.description',
        )}
        videoId="CCCtRwkN1L4"
        onDismiss={onDismiss}
        onComplete={onStart}
      />
    );
  };
