import gql from 'graphql-tag';
import { DIALOGS_PRICING_FRAGMENT } from '@utils/Data/Pricing/queries';

export const STRIPE_KEY_QUERY = gql`
  query StripeKeyQuery {
    stripeKey {
      key
    }
  }
`;

export const SETUP_PAYMENT_METHOD = gql`
  mutation SetupPaymentMethod($botId: String!, $paymentMethodId: String!) {
    setupPaymentMethod(botId: $botId, paymentMethodId: $paymentMethodId) {
      stripeError {
        description
        code
        decline_code
      }
      bot {
        id
        pro {
          is_paused
          status
          debt
          days_before_cancel
          millis_before_cancel
          manual
        }
        dialogsPricing {
          ...dialogsPricingFragment
        }
        paymentInformation {
          active_card {
            card4LastDigits
          }
          nextBillingDate
          status
          payments {
            currency
            activeUsers
            amount
            last4CardDigits
            date
            stripeResult {
              id
              failed
            }
          }
        }
      }
    }
  }
  ${DIALOGS_PRICING_FRAGMENT}
`;

export const SETUP_INTENT_MUTATION = gql`
  mutation SetupIntentMutation($botId: String!) {
    setupIntent(botId: $botId) {
      client_secret
    }
  }
`;

export const SETUP_PREMIUM_PAYMENT_METHOD_MUTATION = gql`
  mutation SetupPremiumPaymentMethodMutation($paymentMethodId: String!) {
    setupPremiumPaymentMethod(paymentMethodId: $paymentMethodId) {
      stripeError {
        description
        code
        decline_code
      }
    }
  }
`;

export const SETUP_PREMIUM_INTENT_MUTATION = gql`
  mutation SetupPremiumIntentMutation {
    setupPremiumIntent {
      client_secret
    }
  }
`;
