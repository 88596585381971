import React from 'react';
import { LocaleMenu } from '@common/user/UserNavigationItem/LocaleMenu/LocaleMenu';
import { LogoutButton } from '@common/user/UserNavigationItem/LogoutButton';
import { Flex } from '@ui/Flex';
import css from './FirstSessionHeaderMenu.css';

export const FirstSessionHeaderMenu: React.FC = () => (
  <Flex flexDirection="column" className={css.container}>
    <LocaleMenu className={css.localeMenu} />
    <LogoutButton />
  </Flex>
);
