import { sendEvent } from '../../utils/Analytics';

export const sendClickEvent = (tab: string) => {
  sendEvent({
    category: 'tab',
    action: 'click',
    label: tab,
    propertyBag: {
      tab,
    },
  });
};

export const sendHoverEvent = (tab: string) => {
  sendEvent({
    category: 'tab',
    action: 'hover',
    label: tab,
    propertyBag: {
      tab,
    },
  });
};
