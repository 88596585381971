import React from 'react';
import memoize from 'lodash-es/memoize';
import { Modal } from '@services/index';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { BotTabs, useCurrentBotId } from '@utils/Routing';
import { useCreateNewFlow } from '@components/FlowSelectorDialog/hooks';
import { Spacer } from '@ui/Spacer';
import { ButtonUnstyled } from '@ui/Button';
import { useHistory } from 'react-router-dom';
import { Platform } from '@globals';
import {
  DeepLinksMode,
  DeepLinksQueryParam,
} from '../../../pages/DeepLinks/types';
import { TFunction } from 'i18next';

const getButtonText = memoize(
  (t: TFunction, platform: Platform) =>
    ({
      [Platform.facebook]: t(
        'modernComponents.EntryPointsModal.disableCategory.createLink.facebook',
      ),
      [Platform.instagram]: t(
        'modernComponents.EntryPointsModal.disableCategory.createLink.instagram',
      ),
      [Platform.whatsapp]: t(
        'modernComponents.EntryPointsModal.disableCategory.createLink.whatsapp',
      ),
    }[platform]),
);

export const CreateFlowLink: React.FC<{ platform: Platform }> = ({
  platform,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const history = useHistory();
  const { createNewFlow } = useCreateNewFlow({
    platform,
    onCreateNewFlow: (flowId) => {
      const params = new URLSearchParams({
        [DeepLinksQueryParam.mode]: DeepLinksMode.entryPointsModal,
        [DeepLinksQueryParam.platform]: platform,
        [DeepLinksQueryParam.flowId]: flowId,
      });
      history.push(
        `/bot/${botId}/${BotTabs.flows}/${flowId}?${params.toString()}`,
      );
    },
  });

  const text = getButtonText(t, platform);

  return (
    <>
      <Spacer factor={5} />
      <ButtonUnstyled
        onClick={() => {
          Modal.hideAll();
          createNewFlow();
        }}
      >
        <u>{text}</u>
      </ButtonUnstyled>
    </>
  );
};
