import React, { useState } from 'react';
import { Input } from '../../../modern-ui/Input';
import { Flex } from '../../../modern-ui/Flex';
import * as css from './HandoverPlugin.css';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
} from '../../../modern-ui/_deprecated/Button';
import { ReactComponent as DropdownIcon } from '../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { SimpleCombobox } from '@ui/SimpleCombobox';

type TimeSpan = {
  id: TimeUnits;
  title: string;
  titleSingle: string;
  titlePlural: string;
  ratio: number;
};

enum TimeUnits {
  seconds = 'seconds',
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
  off = 'off',
}

const TIMESPANS: () => { [key in keyof typeof TimeUnits]: TimeSpan } = () => ({
  [TimeUnits.seconds]: {
    id: TimeUnits.seconds,
    title: window.i18next.t('HandoverTimeoutSetter-string--182-second'),
    titleSingle: window.i18next.t('HandoverTimeoutSetter-string--182-second'),
    titlePlural: window.i18next.t('HandoverTimeoutSetter-string--660-seconds'),
    ratio: 1,
  },
  [TimeUnits.minutes]: {
    id: TimeUnits.minutes,
    title: window.i18next.t('HandoverTimeoutSetter-string--199-minute'),
    titleSingle: window.i18next.t('HandoverTimeoutSetter-string--199-minute'),
    titlePlural: window.i18next.t('HandoverTimeoutSetter-string--156-minutes'),
    ratio: 60,
  },
  [TimeUnits.hours]: {
    id: TimeUnits.hours,
    title: window.i18next.t('HandoverTimeoutSetter-string-2255-hour'),
    titleSingle: window.i18next.t('HandoverTimeoutSetter-string-2255-hour'),
    titlePlural: window.i18next.t('HandoverTimeoutSetter-string--212-hours'),
    ratio: 3600,
  },
  [TimeUnits.days]: {
    id: TimeUnits.days,
    title: window.i18next.t('HandoverTimeoutSetter-string-6847-day'),
    titleSingle: window.i18next.t('HandoverTimeoutSetter-string-6847-day'),
    titlePlural: window.i18next.t('HandoverTimeoutSetter-string-2122-days'),
    ratio: 3600 * 24,
  },
  [TimeUnits.off]: {
    id: TimeUnits.off,
    title: 'Off',
    titleSingle: 'Off',
    titlePlural: 'Off',
    ratio: -1,
  },
});

interface HandoverTimeoutSetterProps {
  disabled?: boolean;
  defaultValue?: number;
  onChange: (timeout?: number) => void;
  onSave: () => void;
}

export const HandoverTimeoutSetter: React.FC<HandoverTimeoutSetterProps> = (
  props,
) => {
  const getCurrentValue = (value?: number) => {
    if (!value || value === -1) {
      return { number: value, unit: TimeUnits.off };
    }

    let currNumber = 1;
    let currUnit = TimeUnits.off;

    Object.values(TIMESPANS())
      .slice(0, -1)
      .forEach((item) => {
        if (value % item.ratio === 0) {
          currNumber = Math.floor(value / item.ratio);
          currUnit = item.id;
        }
      });

    return { number: currNumber, unit: currUnit };
  };

  const [number, setNumber] = useState<number | undefined>(
    getCurrentValue(props.defaultValue).number,
  );
  const [unit, setUnit] = useState<TimeUnits>(
    getCurrentValue(props.defaultValue).unit,
  );
  const [valid, setValid] = useState<boolean>(true);

  const getUnitTitle = (unitItem: TimeSpan, currNumber?: number) => ({
    id: unitItem.id,
    title:
      !currNumber || currNumber === 1
        ? unitItem.titleSingle
        : unitItem.titlePlural,
  });

  const countTimeout = (unit: TimeUnits, number?: number) =>
    !number
      ? undefined
      : unit === TimeUnits.off
      ? -1
      : number * TIMESPANS()[unit].ratio;

  const validate = (unit: TimeUnits, number?: number) =>
    setValid(unit === TimeUnits.off || (!!number && number > 0));

  return (
    <Flex flexDirection="row" alignItems="center">
      <div className={css.timeoutSelectorCounterWrapper}>
        <Input
          type="number"
          className={css.timeoutSelectorCounter}
          value={
            number !== undefined ? Math.max(number, 1).toString() : undefined
          }
          onChange={(e) => {
            const currNumber = parseInt(e.currentTarget.value, 10);
            setNumber(currNumber);
            props.onChange(countTimeout(unit, currNumber));
          }}
          error={!valid}
          onBlur={() => {
            validate(unit, number);
            props.onSave();
          }}
          disabled={props.disabled || unit === TimeUnits.off}
        />
      </div>
      <SimpleCombobox
        items={Object.values(TIMESPANS()).map((item) =>
          getUnitTitle(item, number),
        )}
        onChange={(item) => {
          if (item) {
            const currUnit = item.id as TimeUnits;
            setUnit(currUnit);
            props.onChange(countTimeout(currUnit, number));
            props.onSave();
          }
        }}
        selectedItem={getUnitTitle(TIMESPANS()[unit], number)}
        renderInput={({ getToggleButtonProps, selectedItem }) => (
          <Button
            {...getToggleButtonProps()}
            type="button"
            disabled={props.disabled}
            renderIconRight={() => <DropdownIcon />}
            intent={ButtonIntent.primary}
            size={ButtonSize.m}
            colorWay={ButtonColorWay.white}
            className={css.timeoutButton}
          >
            {selectedItem
              ? selectedItem.title
              : window.i18next.t(
                  'HandoverTimeoutSetter-string--229-select-time-unit',
                )}
          </Button>
        )}
      />
    </Flex>
  );
};
