import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment whatsappListPluginFragment on WhatsappListPlugin {
    ...commonPluginFragment
    config {
      listHeader {
        type
        text
      }
      listBody {
        text
      }
      listFooter {
        text
      }
      listSections {
        title
        rows {
          type
          title
          description
          block_id
          counterId
        }
      }
      listButtonTitle
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
