import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sendEvent } from '@utils/Analytics';
import { BotPageRouteParams, getDefaultTab } from '@utils/Routing';
import {
  BotHeaderProps,
  BotHeaderRegular,
  BotHeaderWorkspaces,
} from './components/BotHeader';
import { ComboboxItemType, ComboboxType } from './types';
import { useWorkspacesAvailable } from '@utils/Data/Workspaces/useWorkspacesAvailable';
import { BotSwitcherLoading } from '@components/LeftNavigationPanel/components/BotSwitcher/BotSwitcherLoading';
import { ScreenSizes, useDeviceMedia } from '@utils/DOM/useDeviceMedia';

export const BotHeaderContainerWrapper: React.FC = ({ children }) => {
  const { screenSize } = useDeviceMedia();

  const padding = {
    [ScreenSizes.large]: '8px',
    [ScreenSizes.medium]: '4px',
    [ScreenSizes.small]: '20px',
  }[screenSize];

  return <div style={{ padding }}>{children}</div>;
};

interface BotHeaderContainerProps {
  onMenuClose?: BotHeaderProps['onMenuClose'];
  onMenuOpen?: BotHeaderProps['onMenuOpen'];
  onButtonClick?: BotHeaderProps['onButtonClick'];
  menuDisabled?: boolean;
}

export const BotHeaderContainer = React.memo(
  (props: BotHeaderContainerProps) => {
    const { botId } = useParams<BotPageRouteParams>();
    const history = useHistory();
    const { screenSize } = useDeviceMedia();
    const [isWorkspaceAvailable, isWorkspaceAvailableLoading] =
      useWorkspacesAvailable();
    const onChangeBot = useCallback(
      (item: ComboboxItemType | null) => {
        if (!item || item.type !== ComboboxType.item) {
          return;
        }
        sendEvent({
          category: 'header bot dropdown',
          action: 'choose another bot',
          propertyBag: {
            botId,
            selectedBotId: item.bot.id,
          },
        });
        history.push(getDefaultTab(item.bot.id));
      },
      [history, botId],
    );

    const onAllBotsSelected = useCallback(() => {
      sendEvent({
        category: 'header bot dropdown',
        action: 'All bots list selected',
        propertyBag: {
          botId,
        },
      });
      history.push(`/bots`);
    }, [history, botId]);

    if (isWorkspaceAvailableLoading) {
      return <BotSwitcherLoading screenSize={screenSize} />;
    }

    let BotHeaderView = BotHeaderRegular;

    if (isWorkspaceAvailable) {
      BotHeaderView = BotHeaderWorkspaces;
    }

    return (
      <BotHeaderView
        loading={isWorkspaceAvailableLoading}
        onChangeBot={onChangeBot}
        onAllBotsSelected={onAllBotsSelected}
        {...props}
      />
    );
  },
);
