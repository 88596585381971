import React from 'react';
import memoize from 'lodash-es/memoize';
import i18n from 'i18next';
import { groupBy, length, map, prop, uniq } from 'ramda';
import { Category, CategoryItem, ICategory } from './types';
import { Platform } from '@globals';
import { CreateFlowLink } from './components/CreateFlowLink';

export const getAllCategories = memoize((): ICategory[] => [
  {
    title: i18n.t(
      'modernComponents.TemplatesModal.templateCategories.facebook',
    ),
    category: Category.facebook,
    platform: Platform.facebook,
    disableTooltipContent: (
      <>
        {i18n.t(
          'modernComponents.EntryPointsModal.disableCategory.tooltip.facebook',
        )}
        <CreateFlowLink platform={Platform.facebook} />
      </>
    ),
  },
  {
    title: i18n.t(
      'modernComponents.TemplatesModal.templateCategories.instagram',
    ),
    category: Category.instagram,
    platform: Platform.instagram,
    disableTooltipContent: (
      <>
        {i18n.t(
          'modernComponents.EntryPointsModal.disableCategory.tooltip.instagram',
        )}
        <CreateFlowLink platform={Platform.instagram} />
      </>
    ),
  },
  {
    title: i18n.t(
      'modernComponents.TemplatesModal.templateCategories.whatsapp',
    ),
    category: Category.whatsapp,
    platform: Platform.whatsapp,
    disableTooltipContent: (
      <>
        {i18n.t(
          'modernComponents.EntryPointsModal.disableCategory.tooltip.whatsapp',
        )}
        <CreateFlowLink platform={Platform.whatsapp} />
      </>
    ),
  },
  {
    title: i18n.t('modernComponents.TemplatesModal.templateCategories.website'),
    category: Category.website,
    platform: Platform.facebook,
    disableTooltipContent: (
      <>
        {i18n.t(
          'modernComponents.EntryPointsModal.disableCategory.tooltip.website',
        )}
        <CreateFlowLink platform={Platform.facebook} />
      </>
    ),
  },
  {
    title: i18n.t('modernComponents.TemplatesModal.templateCategories.shopify'),
    category: Category.shopify,
    platform: Platform.facebook,
    disableTooltipContent: (
      <>
        {i18n.t(
          'modernComponents.EntryPointsModal.disableCategory.tooltip.shopify',
        )}
        <CreateFlowLink platform={Platform.facebook} />
      </>
    ),
  },
]);

export const getCategories = (categories: Category[]) =>
  getAllCategories().filter(({ category }) => categories.includes(category));

export const getCategoriesData = memoize((items: CategoryItem[]) => {
  const groups = groupBy(prop('category'), items);
  const counters: Record<string, number> = map(length, groups);
  const itemsCategories = uniq(map(prop('category'), items));
  const categories = getCategories(itemsCategories);

  return map(
    (item) => ({ ...item, childrenAmount: counters[item.category] }),
    categories,
  );
});
