import { mergeDeepWith } from 'ramda';
import { nTimeNotificationPluginFragment_config } from './@types/nTimeNotificationPluginFragment';

import { PluginType } from '../common/PluginTypes';

export const PLUGIN_TYPE = PluginType.notification_messages_request;

export const PLUGIN_DEFAULT_CONFIG: nTimeNotificationPluginFragment_config = {
  __typename: 'NTimeNotificationPluginConfig',
  title: null,
  topic_id: null,
  image_url: null,
  frequency: null,
  block_id: null,
};

export const PLUGIN_INPUT_FIELD = 'nTimeNotificationPluginConfig';

export const getNTimeNotificationPluginConfig = mergeDeepWith(
  (_, b) => b,
  PLUGIN_DEFAULT_CONFIG,
);
