import React from 'react';
import cn from 'classnames';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useRouteMatch } from 'react-router-dom';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { useIsFirstSessionTab } from '@utils/Routing';
import { Flex } from '@ui/Flex';
import { HEXColors } from '@ui/_common/colors';

import { PageHeaderNav } from './PageHeaderNav';
import { ChatfuelLogo } from './ChatfuelLogo';
import { LogoSize, WhiteLabelLogo } from './WhiteLabelLogo';
import { PAGE_HEADER_CONTAINER_ID } from './constants';
import { ReactComponent as LogoFullSvg } from './ChatfuelLogo/images/logo_full.svg';
import { Type } from '@ui/Type';
import { PageHeaderUserItem } from './BotHeader/PageHeaderUserItem';
import { HeaderAdminPanel } from './@types/HeaderAdminPanel';
import { LocaleMenu } from '@common/user/UserNavigationItem/LocaleMenu/LocaleMenu';
import { LogoutButton } from '@common/user/UserNavigationItem/LogoutButton';

import * as css from './PageHeader.css';

interface IPageHeaderProps extends React.HTMLProps<HTMLDivElement> {
  logoSize?: LogoSize;
  hideNav?: boolean;
}

const HEADER_ADMIN_QUERY = gql`
  query HeaderAdminPanel {
    me {
      id
    }
  }
`;

export const PageHeader: React.FC<IPageHeaderProps> = ({
  logoSize,
  hideNav,
  ...props
}) => {
  const { data, loading } = useQuery<HeaderAdminPanel>(HEADER_ADMIN_QUERY, {
    errorPolicy: 'all',
  });
  const isBotPage = !!useRouteMatch(`/bot/:id`);
  const isFirstSessionTab = useIsFirstSessionTab();

  const specialTab = isFirstSessionTab;

  if (loading) {
    return null;
  }

  const hasAuth = data && data.me && data.me.id;

  return (
    <div className={css.pageHeaderContainer} id={PAGE_HEADER_CONTAINER_ID}>
      <div
        {...props}
        className={cn(css.pageHeader, props.className, {
          [css.contrastBackground]: hasAuth && !hideNav && !specialTab,
          [css.cobaltBackground]: hasAuth && !hideNav && specialTab,
        })}
      >
        {!isBotPage && (
          <div className={css.logoWrapper}>
            {isWhiteLabelDomain() ? (
              <WhiteLabelLogo logoSize={logoSize} />
            ) : (
              <ChatfuelLogo
                buttonWrapper={isFirstSessionTab}
                full={!(hasAuth && logoSize !== LogoSize.big)}
                style={{ color: HEXColors.blue }}
              />
            )}
          </div>
        )}
        {specialTab && (
          <Type size="15px" color="white">
            <LogoFullSvg />
          </Type>
        )}
        {hasAuth && !hideNav && (
          <Flex className={css.navigation}>
            {!specialTab && <PageHeaderNav className={css.nav} />}
            {!specialTab && <PageHeaderUserItem />}
            {specialTab && (
              <>
                <LocaleMenu className={css.localeMenu} />
                <LogoutButton />
              </>
            )}
          </Flex>
        )}
      </div>
    </div>
  );
};
