import React from 'react';
import cn from 'classnames';
import {
  PaymentInterval,
  paymentIntervalToString,
} from '@utils/Payment/paymentInterval';

import * as css from './PlanDisplayPrice.css';

interface Props {
  displayAmount: string;
  intervalName: PaymentInterval;
  displayAmountFontSize?: number;
  intervalNameFontSize?: number;
  strikethrough?: boolean;
  style?: React.CSSProperties;
}

export const PlanDisplayPrice: React.FC<Props> = ({
  displayAmount,
  intervalName,
  style,
  displayAmountFontSize = 64,
  intervalNameFontSize = 18,
  strikethrough = false,
}) => {
  return (
    <div
      style={style}
      className={cn(css.priceText, { [css.strikethrough]: strikethrough })}
    >
      <span style={{ fontSize: displayAmountFontSize }}>{displayAmount}</span>
      <span style={{ fontSize: intervalNameFontSize }}>
        {`/${paymentIntervalToString()[intervalName]}`}
      </span>
    </div>
  );
};
