import React from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { TextEllipsis } from '@ui/TextEllipsis';
import { isBotPro, isBotProDebt } from '@utils/Pro';
import { ComboboxItemBotType } from '../../types';
import { ProInformer } from '../../ProInformer';
import * as css from './BotTitle.css';

interface BotTitleProps {
  active: boolean;
  item: ComboboxItemBotType;
}

export const BotTitle = React.memo<BotTitleProps>(({ active, item }) => (
  <Flex alignItems="center">
    <Type
      size="15px"
      color={active ? 'white' : 'cobaltSemilight'}
      className={css.botItemTitle}
      data-testid="bot-title"
    >
      <TextEllipsis>{item.bot.title}</TextEllipsis>
    </Type>
    <ProInformer
      botIsPro={isBotPro(item.bot)}
      botInDebt={!!isBotProDebt(item.bot)}
      daysLeft={item.bot.pro?.days_before_cancel ?? undefined}
    />
  </Flex>
));
