import { DefaultDialog } from '@ui/Dialog';
import React from 'react';
import css from './ManageUsersDialog.css';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { ScrollBoxWithShadow } from '@ui/ScrollBox/ScrollBoxWithShadow';
import { Button } from '@ui/Button';
import { TextEllipsis } from '@ui/TextEllipsis';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

export interface ManageUsersDialogProps {
  className?: string;
  users?: React.ReactNode[];
  onClose: VoidFunction;
  title: string;
  onInviteClick: VoidFunction;
}

export const ManageUsersDialog: React.FC<ManageUsersDialogProps> = ({
  className,
  users,
  onClose,
  title,
  onInviteClick,
}) => {
  const { t } = useSafeTranslation();
  const header = `${t('ManageUsersDialog.manageUsers')} ${
    users?.length ? `(${users.length})` : ''
  }`;
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <DefaultDialog
      className={className}
      onDismiss={onClose}
      header={header}
      mobileAdaptive={isSmallScreenSize}
      footer={
        <Button
          className={css.inviteButton}
          intent="secondary"
          onClick={onInviteClick}
        >
          {t('ManageUsersDialog.inviteTeammate')}
        </Button>
      }
    >
      <TextEllipsis>
        <Type size="15px" color="greyDark">
          {title}
        </Type>
      </TextEllipsis>
      <Spacer factor={6} />
      {users && (
        <ScrollBoxWithShadow className={css.userList}>
          {users}
        </ScrollBoxWithShadow>
      )}
    </DefaultDialog>
  );
};
