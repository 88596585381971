import React, { memo, useCallback, useEffect, useState } from 'react';
import { Modal } from '@services/index';
import { useSearchParams } from '@utils/UrlUtils/useSearchParams';
import { OnboardingTour } from './OnboardingTour/OnboardingTour';
import { TipsDialog } from './TipsDialog/TipsDialog';
import { WelcomeDialog } from './WelcomeDialog/WelcomeDialog';
import {
  ONBOARDING_TOUR,
  ONBORDING_TOUR_OPENED,
} from '@components/MiniOnboarding/MiniOnboardingDialog/consts';
import { sendEvent } from '@utils/Analytics';
import { useOnbordingContext } from './OnboardingContext';
import { FlowTourInitialModal } from '@globals';
import { WABookingDemo } from '@components/MiniOnboarding/ListItem/WABookingDemo/WABookingDemo';
import { WhatsappSubscribeToPhone } from '@components/MiniOnboarding/ListItem/WhatsappSubscribeToPhone/WhatsappSubscribeToPhone';
import { WhatsappConnectPhoneTutorialDialog } from './inititalModals/WhatsappConnectPhoneTutorial/WhatsappConnectPhoneTutorial';
import { WhatsappTemplate } from './inititalModals/WhatsappTemplate/WhatsappTemplate';
import { WhatsappReengage } from './inititalModals/WhatsappReengage/WhatsappReengage';
import { WhatsappBusinessVerification } from './inititalModals/WhatsappBusinessVerification/WhatsappBusinessVerification';
import { WhatsappAddMetaPaymentMethod } from './inititalModals/WhatsappAddMetaPaymentMethod/WhatsappAddMetaPaymentMethod';
import { exhaustiveCheck } from '@utils/exhaustiveCheck';
import { GetGreenCheckmarkDialog } from '@components/MiniOnboarding/ListItem/GetGreenCheckmarkDialog';
import {
  OnboardingState,
  OnboardingManualStateEmitter,
} from './OnboardingTour/utils/onboardingEmitter';

const OnboardingTourMemo = memo(OnboardingTour);

export const Onboarding: React.FC = () => {
  const { onboardingTour, loading, flowBuilderRootElement } =
    useOnbordingContext();
  const [starting, setStarting] = useState(false);
  const { searchParams, deleteSearchParams, addSearchParams } =
    useSearchParams();

  const isTourStart = starting && onboardingTour;

  useEffect(() => {
    return OnboardingManualStateEmitter.on(OnboardingState.abort, () =>
      setStarting(false),
    );
  }, [setStarting]);

  /**
   * добавляем флаг что в данный момент onboarding открыт
   */
  useEffect(() => {
    if (isTourStart) {
      addSearchParams(ONBORDING_TOUR_OPENED, 'true');
    } else {
      deleteSearchParams(ONBORDING_TOUR_OPENED);
    }
    return () => {
      deleteSearchParams(ONBORDING_TOUR_OPENED);
    };
  }, [isTourStart, searchParams, addSearchParams, deleteSearchParams]);

  const flowTourName = onboardingTour?.initialStep?.title ?? onboardingTour?.id;

  const handleCompletle = useCallback(() => {
    setStarting(false);
    sendEvent({
      category: 'onbording-tour ',
      action: 'complete flow tour',
      propertyBag: {
        flowTourName,
      },
    });
    Modal.showImmediate(({ close }) => (
      <TipsDialog onClose={close} onboardingTourId={onboardingTour?.id} />
    ));
  }, [setStarting, flowTourName, onboardingTour]);

  useEffect(() => {
    if (loading || !searchParams.has(ONBOARDING_TOUR)) return;

    deleteSearchParams(ONBOARDING_TOUR);

    if (!onboardingTour) return;

    sendEvent({
      category: 'onbording-tour',
      action: 'show flow tour welcome dialog',
      propertyBag: {
        flowTourName,
      },
    });

    const onStart = () => {
      if (onboardingTour.steps.length) setStarting(true);
      else handleCompletle();
    };

    if (!onboardingTour.initialStep) {
      onStart();
      return;
    }

    const { title, description, imageUrl } = onboardingTour.initialStep;

    switch (onboardingTour.initialStep.modalId) {
      case FlowTourInitialModal.WhatsappConnectPhoneTutorial: {
        Modal.show(
          ({ close, resolve }) => (
            <WhatsappConnectPhoneTutorialDialog
              onDismiss={() => {
                close();
                sendEvent({
                  category: 'onbording-tour ',
                  action: 'close flow tour welcome dialog',
                  propertyBag: {
                    flowTourName,
                  },
                });
              }}
              onStart={() => {
                resolve();
                sendEvent({
                  category: 'onbording-tour ',
                  action: 'flow tour welcome dialog start butoon click',
                  propertyBag: {
                    flowTourName,
                  },
                });
              }}
            />
          ),
          {
            id: FlowTourInitialModal.WhatsappConnectPhoneTutorial,
          },
        )?.then(onStart);
        break;
      }
      case FlowTourInitialModal.WhatsappTemplates: {
        Modal.show(
          ({ close, resolve }) => (
            <WhatsappTemplate
              onDismiss={() => {
                close();
                sendEvent({
                  category: 'onbording-tour ',
                  action: 'close flow tour welcome dialog',
                  propertyBag: {
                    flowTourName,
                  },
                });
              }}
              onStart={() => {
                resolve();
                sendEvent({
                  category: 'onbording-tour ',
                  action: 'flow tour welcome dialog start butoon click',
                  propertyBag: {
                    flowTourName,
                  },
                });
              }}
            />
          ),
          {
            id: FlowTourInitialModal.WhatsappTemplates,
          },
        )?.then(onStart);
        break;
      }
      case FlowTourInitialModal.WhatsappBookingDemo: {
        Modal.show(({ close }) => <WABookingDemo close={close} />, {
          id: FlowTourInitialModal.WhatsappBookingDemo,
        })?.then(onStart);
        break;
      }
      case FlowTourInitialModal.WhatsappSubscribeToPhone: {
        Modal.show(
          ({ close, resolve }) => (
            <WhatsappSubscribeToPhone onComplete={resolve} onDismiss={close} />
          ),
          {
            id: FlowTourInitialModal.WhatsappSubscribeToPhone,
            mobileAdaptive: true,
          },
        );
        break;
      }
      case FlowTourInitialModal.WhatsappGreenBadge: {
        Modal.show(
          ({ close }) => (
            <GetGreenCheckmarkDialog
              onDismiss={() => {
                sendEvent({
                  category: 'onbording-tour',
                  action: 'close get gren checkmark dialog',
                  propertyBag: { flowTourName },
                });

                close();
              }}
              onSubmit={() => {
                sendEvent({
                  category: 'onbording-tour',
                  action: 'open whatsapp manager click',
                  propertyBag: { flowTourName },
                });
              }}
            />
          ),
          {
            id: FlowTourInitialModal.WhatsappGreenBadge,
            mobileAdaptive: true,
          },
        )?.then(onStart);
        break;
      }
      case FlowTourInitialModal.WhatsappReengage: {
        Modal.show(
          ({ close, resolve }) => (
            <WhatsappReengage onComplete={resolve} onDismiss={close} />
          ),
          {
            id: FlowTourInitialModal.WhatsappReengage,
            mobileAdaptive: true,
          },
        )?.then(onStart);
        break;
      }
      case FlowTourInitialModal.WhatsappBusinessVerification: {
        Modal.show(
          ({ close }) => <WhatsappBusinessVerification onDismiss={close} />,
          {
            id: FlowTourInitialModal.WhatsappBusinessVerification,
          },
        )?.then(onStart);
        break;
      }
      case FlowTourInitialModal.WelcomeMessage: {
        Modal.show(
          ({ close, resolve }) => (
            <WelcomeDialog
              title={title ?? ''}
              description={description ?? ''}
              imageUrl={imageUrl ?? ''}
              onDismiss={() => {
                close();
                sendEvent({
                  category: 'onbording-tour ',
                  action: 'close flow tour welcome dialog',
                  propertyBag: {
                    flowTourName,
                  },
                });
              }}
              onStart={() => {
                resolve();
                sendEvent({
                  category: 'onbording-tour ',
                  action: 'flow tour welcome dialog start butoon click',
                  propertyBag: {
                    flowTourName,
                  },
                });
              }}
            />
          ),
          { id: FlowTourInitialModal.WelcomeMessage },
        )?.then(onStart);
        break;
      }
      case FlowTourInitialModal.WhatsappAddMetaPaymentMethod: {
        Modal.show(
          ({ close, resolve }) => (
            <WhatsappAddMetaPaymentMethod
              onSubmit={resolve}
              onDismiss={close}
            />
          ),
          {
            id: FlowTourInitialModal.WhatsappAddMetaPaymentMethod,
          },
        )?.then(onStart);
        break;
      }
      default:
        exhaustiveCheck(onboardingTour.initialStep.modalId);
    }
  }, [
    loading,
    searchParams,
    flowTourName,
    deleteSearchParams,
    onboardingTour,
    handleCompletle,
  ]);

  return isTourStart ? (
    <OnboardingTourMemo
      tourName={flowTourName ?? ''}
      steps={onboardingTour.steps as any}
      flowBuilderRootElement={flowBuilderRootElement}
      onCompletle={handleCompletle}
    />
  ) : null;
};
