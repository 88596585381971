import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment customAiPluginFragment on CustomAiPlugin {
    ...commonPluginFragment
    config {
      title
      description
      prompt
      templateId
      aiModel
      userInputMaxTokens
      aiIntents {
        title
        description
        prompt
      }
      aiConditions {
        statId
        blockId
        title
        prompt
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
