import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { BotTabs, getTabLink } from '@utils/Routing';
import { OnboardingTourHTMLElementId } from '@globals';

export const useOnboardingStepDone = (botId: string) => {
  const history = useHistory();

  const onDone = useCallback(() => {
    history.push(
      getTabLink(BotTabs.home, botId, {
        onboardingTour: OnboardingTourHTMLElementId.HomeTabWhatsappAccountBlock,
      }),
    );
  }, [botId, history]);

  return { onDone };
};
