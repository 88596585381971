import { formatPricePromo } from '@utils/Format/priceFormatter';
import React, { useMemo } from 'react';
import { Type } from '../../../modern-ui/Type';
import { Flex } from '../../../modern-ui/Flex';
import { PlanDisplayPrice } from '../PlanDisplayPrice';
import { PaymentInterval } from '../../../pages/UpgradePage/components/paymentInterval';

export const PaymentStatus: React.FC<{
  amount: number;
  intervalName: PaymentInterval;
  nextPaymentDate: Date;
  currency?: string;
}> = ({ amount, currency, nextPaymentDate, intervalName }) => {
  const dateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
    [],
  );
  return (
    <Flex justifyContent="space-between" alignItems="flex-end">
      <div style={{ paddingBottom: 1 }}>
        <Type color="greyDark" size="15px_DEPRECATED">
          {window.i18next.t('PaymentStatus-JSXText-1406-next-payment-on')}
        </Type>
        <br />
        <Type size="24px">{dateFormatter.format(nextPaymentDate)}</Type>
      </div>
      <PlanDisplayPrice
        style={{ lineHeight: 1 }}
        displayAmountFontSize={48}
        intervalNameFontSize={14}
        displayAmount={formatPricePromo(amount, { currency })}
        intervalName={intervalName}
      />
    </Flex>
  );
};
