import gql from 'graphql-tag';
import { ZAP_FRAGMENT } from '@components/FlowBuilder/views/components/Zapier/query';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment zapierEventTriggerPluginFragment on ExportViaZapierPlugin {
    ...commonPluginFragment
    config {
      name
      connected_zaps {
        ...zapFragment
      }
      attributes
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
  ${ZAP_FRAGMENT}
`;
