import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment nTimeNotificationPluginFragment on NTimeNotificationPlugin {
    ...commonPluginFragment
    config {
      title
      topic_id
      image_url
      frequency
      block_id
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
