import { BotStatus } from '@utils/Bot';

export const getPaymentStatus = (status: BotStatus) => ({
  isPaused: status === BotStatus.paused,
  isPausedWithDebt: status === BotStatus.pausedAndExpiredDebt,
  isPro: status === BotStatus.pro || status === BotStatus.debt,
  isPremium: status === BotStatus.premium,
  needUpdatePayment:
    status === BotStatus.debt ||
    status === BotStatus.expiredDebt ||
    status === BotStatus.pausedAndExpiredDebt,
});
