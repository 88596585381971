import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Icon } from '@ui/Icon';
import { ButtonUnstyled } from '@ui/Button';
import { OnboardingButtonBox } from './ListItem/components/ActionButton';
import * as css from './OnboardingListAnchor.css';

export interface OnboardingListAnchorProps {
  onClick: VoidFunction;
  opened: boolean;
  title: string;
  stats: string;
}

export const OnboardingListAnchor: React.FC<OnboardingListAnchorProps> = ({
  onClick,
  opened,
  title,
  stats,
}) => {
  return (
    <ButtonUnstyled
      className={css.button}
      onClick={onClick}
      data-testid="onboarding-list__anchor"
    >
      <Flex alignItems="center">
        <OnboardingButtonBox active={false}>
          <Flex alignItems="center" justifyContent="center">
            <Icon
              size="16px"
              icon="arrow_right"
              color="accent1Secondary"
              className={cn(css.icon, {
                [css.opened]: opened,
                [css.closed]: !opened,
              })}
            />
          </Flex>
        </OnboardingButtonBox>

        <Spacer factor={0} horizontalFactor={6} />

        <Flex fullWidth justifyContent="space-between">
          <Type size="24px" color="accent1Light">
            {title}
          </Type>
          <Type size="24px" color="baseTertiary">
            {stats}
          </Type>
        </Flex>
      </Flex>
    </ButtonUnstyled>
  );
};
