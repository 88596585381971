import { useCallback, useEffect, useState } from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { usePrevious } from 'cf-common/src/utils/hooks';
import {
  InteractionData,
  OnboardingEvent,
  OnbordingZoneData,
} from '../../types';
import { matchingEvent } from '../utils/matchindEvent';
import { OnboardingEmitter } from '../utils/onboardingEmitter';
import { getZoneId } from '../utils/getZoneId';
import { OnboardingTourEventType } from '@globals';
import debounce from 'lodash-es/debounce';

interface useOnboardingEventsPayload {
  step: number;
  setOnbordingStep(step: number): void;
  nextStepTriggerEvent: Maybe<OnboardingEvent>;
  buttonEnabledEvent: Maybe<OnboardingEvent>;
  zoneData: OnbordingZoneData;
}

const emptyObject = {};

export const useOnboardingEvents = ({
  setOnbordingStep,
  nextStepTriggerEvent,
  buttonEnabledEvent,
  zoneData,
  step,
}: useOnboardingEventsPayload) => {
  const [interactionData, setData] = useState<InteractionData>({});
  const debounceSetData = useCallback(debounce(setData, 300), []);
  const prevInteractionData = usePrevious(interactionData);
  const [needUpdateZone, setNeedUpdateZone] = useState(false);
  const { focus, active } = zoneData;
  const activeZoneId = active ? getZoneId(active, true) : null;
  const setInteractionData = useCallback(
    (data: InteractionData) => {
      if (
        data.element !== activeZoneId &&
        data.event !== OnboardingTourEventType.connectWhatsapp
      )
        return;

      debounceSetData(data);
    },
    [activeZoneId, debounceSetData],
  );
  const clearInteractionData = useCallback(() => {
    if (interactionData !== emptyObject) setData(emptyObject);
  }, [interactionData]);

  /**
   * Отвечает за подписку на события.
   * TODO: вынести в отдельный хук
   */
  useEffect(() => {
    const unsubscribeChange = OnboardingEmitter.on(
      OnboardingTourEventType.change,
      (payload) => {
        setInteractionData({
          event: OnboardingTourEventType.change,
          ...payload,
        });
      },
    );
    const unsubscribeClick = OnboardingEmitter.on(
      OnboardingTourEventType.click,
      (payload) =>
        setInteractionData({
          event: OnboardingTourEventType.click,
          ...payload,
        }),
    );
    // TODO: значение OnboardingTourEventType вынести в payload
    const unsubscribeConnectWhatsapp = OnboardingEmitter.on(
      OnboardingTourEventType.connectWhatsapp,
      (payload) => {
        setInteractionData({
          event: OnboardingTourEventType.connectWhatsapp,
          ...payload,
        });
      },
    );
    const unsubscribeRestart = OnboardingEmitter.on(
      OnboardingTourEventType.restart,
      () => {
        setOnbordingStep(0);
        clearInteractionData();
      },
    );
    return () => {
      unsubscribeChange();
      unsubscribeClick();
      unsubscribeConnectWhatsapp();
      unsubscribeRestart();
    };
  }, [clearInteractionData, setInteractionData, setOnbordingStep]);

  /**
   * Отвечает за тригер перехода на следующий шаг
   * TODO: вынести в отдельный хук
   */
  useEffect(() => {
    if (
      nextStepTriggerEvent &&
      matchingEvent(nextStepTriggerEvent, interactionData, activeZoneId)
    )
      setOnbordingStep(step + 1);
  }, [
    step,
    nextStepTriggerEvent,
    interactionData,
    setOnbordingStep,
    activeZoneId,
  ]);

  useEffect(() => {
    const mutationObserver = new MutationObserver(
      debounce(() => {
        setNeedUpdateZone(true);
      }, 500),
    );
    mutationObserver.observe(document.documentElement, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: true,
      attributeOldValue: true,
      characterDataOldValue: true,
    });

    return () => {
      mutationObserver.disconnect();
    };
  }, [setNeedUpdateZone]);

  /**
   * подписка на изменения дом ноды
  useEffect(() => {
    const isHTMLElement = focus && 'htmlElementId' in focus;
    if (!isHTMLElement) return noop;

    const htmlElementId = getZoneId(focus);
    const htmlElement = document.querySelector(htmlElementId);
    if (!htmlElement) return noop;

    const setUpdate = (e: Event) => {
      if (e.target && (e.target as HTMLElement).id !== htmlElementId)
        return;

      setInteractionData({
        event: OnboardingTourEventType.remove,
        element: htmlElementId,
        value: undefined,
      });
    };
    // htmlElement.addEventListener('DOMNodeRemoved', setUpdate);
    return () => {
      htmlElement.removeEventListener('DOMNodeRemoved', setUpdate);
    };
  }, [setInteractionData, setNeedUpdateZone, focus]);
  */

  /**
   * изменение блока, карточки или shape находящихся в фокусе
   */
  useEffect(() => {
    const isBlockId = focus && 'blockId' in focus;
    if (!isBlockId) return;

    if (prevInteractionData !== interactionData) setNeedUpdateZone(true);
  }, [setNeedUpdateZone, focus, interactionData, prevInteractionData]);

  const isNoEmptyString =
    typeof interactionData.value === 'string'
      ? interactionData.value.trim().length
      : true;

  return {
    nextStepButtonEnabled:
      !buttonEnabledEvent ||
      (matchingEvent(buttonEnabledEvent, interactionData, activeZoneId) &&
        isNoEmptyString),
    clearInteractionData,
    needUpdateZone,
    setNeedUpdateZone,
  };
};
