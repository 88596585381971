import { PluginType } from '../common/PluginTypes';

export enum AddPluginMode {
  STATIC = 'static',
  POPUP = 'popup',
}

export enum AddPluginTabType {
  STRUCTURE = 'structure',
  BROADCAST = 'broadcast',
  AUTOPOST = 'autopost',
}

export interface AddPluginProps {
  type: AddPluginTabType;
  index?: number;
  botId: string;
  onAddPlugin: (type: string, index?: number | undefined) => void;
  mode: AddPluginMode;
}

export interface AddPluginMenuProps extends AddPluginProps {
  onHidePopupMenu?: () => void;
  onShowPluginList: () => void;
}

export interface PluginArray {
  [key: string]: PluginType[];
}
