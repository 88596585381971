import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Anchor } from '@ui/Links';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { ReactComponent as CheckMarkSvg } from './CheckMark.svg';
import { Trans } from 'react-i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './GetGreenCheckmarkDialog.css';
import { useWhatsappSettings } from '@utils/Data/Whatsapp';
import { useCurrentBotId } from '@utils/Routing';
import { assert } from '@utils/Assert';

interface GetWhatsappManagerLinkParams {
  wabaId: string;
}

const getWhatsappManagerLink = ({ wabaId }: GetWhatsappManagerLinkParams) =>
  `https://business.facebook.com/wa/manage/home/?waba_id=${wabaId}`;

const HOW_TO_GET_GREEN_CHECKMARK_DOCS_LINK =
  'https://docs.chatfuel.com/en/articles/7161276-how-to-get-a-green-checkmark-for-your-whatsapp-business';

interface GetGreenCheckmarkDialogProps {
  onDismiss: VoidFunction;
  onSubmit: VoidFunction;
}

export const GetGreenCheckmarkDialog: React.FC<GetGreenCheckmarkDialogProps> =
  ({ onDismiss, onSubmit }) => {
    const botId = useCurrentBotId()!;
    const { t } = useSafeTranslation();
    const { isSmallScreenSize } = useDeviceMedia();
    const { whatsappSettings, loading } = useWhatsappSettings(botId);

    return (
      <DefaultDialog
        onDismiss={onDismiss}
        mobileAdaptive={isSmallScreenSize}
        className={css.dialog}
      >
        <Spacer factor={6} />
        <Flex justifyContent="center">
          <CheckMarkSvg />
        </Flex>

        <Spacer factor={5} />
        <Type as="h1" size="24px" weight="semibold">
          {t('MiniOnboarding.GetGreenCheckmark.title')}
        </Type>

        <Spacer factor={2} />
        <ol className={css.ol}>
          <li className={css.li}>
            <Type>1.</Type>
            <Spacer factor={0} horizontalFactor={2} />
            <Trans i18nKey="MiniOnboarding.GetGreenCheckmark.step1" t={t}>
              <Type>
                Open our
                <Anchor
                  intent="external"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={HOW_TO_GET_GREEN_CHECKMARK_DOCS_LINK}
                >
                  How to get a green checkmark
                </Anchor>
                guide
              </Type>
            </Trans>
          </li>
          <Spacer factor={4} />
          <li className={css.li}>
            <Type>2.</Type>
            <Spacer factor={0} horizontalFactor={2} />
            <Type>{t('MiniOnboarding.GetGreenCheckmark.step2')}</Type>
          </li>
        </ol>

        <Spacer factor={4} />
        <Type color="cobaltTertiary">
          {t('MiniOnboarding.GetGreenCheckmark.note')}
        </Type>

        <Spacer factor={5} />
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            loading={loading}
            data-testid="whatsapp__open-whatsapp-manager"
            onClick={() => {
              onSubmit();

              assert(whatsappSettings?.whatsappBusinessAccount?.id, {
                msg: 'Waba id should be defined ',
              });

              const url = getWhatsappManagerLink({
                wabaId: whatsappSettings?.whatsappBusinessAccount?.id,
              });

              window.open(url, '_blank');
            }}
          >
            {t('MiniOnboarding.GetGreenCheckmark.submit')}
          </Button>
        </Flex>
      </DefaultDialog>
    );
  };
