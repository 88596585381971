import React, { FC, useEffect } from 'react';
import { Dialog } from '@ui/Dialog';
import { PaymentFlowState } from '@utils/Payment/constants';
import { sendEvent } from '@utils/Analytics';
import { PaymentForm } from '../PaymentForm';
import { useProPayment } from '../hooks/useProPayment';
import { PaymentError } from '../PaymentError';
import { PaymentSuccess } from '../PaymentSuccess';
import {
  addBankingCardEvent,
  submitPaymentEvent,
} from 'cf-common/src/conversionTracking';
import { PaymentFlowProps, PaymentIntent } from '../types';
import { useCurrentBotId } from '../../../../utils/Routing';

export const PaymentFlow: FC<PaymentFlowProps> = ({
  paymentIntent,
  initialPayment,
  onDismiss,
  onSuccess,
  renderPaymentError,
  setupPayment,
}) => {
  const botId = useCurrentBotId();
  const {
    stripeIsReady,
    paymentFlowState,
    link3DSecure,
    error,
    submitPayment,
    tryAgain,
  } = useProPayment(setupPayment, paymentIntent);

  useEffect(() => {
    sendEvent({
      category: 'pro plan payments',
      action: 'shown',
      label: 'stripe popup',
    });
  }, []);

  useEffect(() => {
    if (paymentFlowState !== PaymentFlowState.success) return;

    if (initialPayment) {
      sendEvent({
        event: 'monetization_pro-enabled',
      });
      submitPaymentEvent(undefined, botId!);
    }

    sendEvent({
      category: 'pro plan payments',
      action: 'payment successful',
      label: '3d secure',
    });

    if (paymentIntent === PaymentIntent.paywall) {
      (onSuccess ?? onDismiss)?.();
    }
  }, [paymentFlowState, initialPayment]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTryAgain = () => {
    sendEvent({
      category: 'pro plan payments',
      action: 'retry payment',
      label: '3d secure',
    });
    tryAgain();
  };

  switch (paymentFlowState) {
    case PaymentFlowState.idle:
      return (
        <PaymentForm
          initialPayment={initialPayment}
          paymentIntent={paymentIntent}
          loading={!stripeIsReady}
          onSubmit={() => {
            addBankingCardEvent(botId!);
            submitPayment();
          }}
          onDismiss={onDismiss}
        />
      );
    case PaymentFlowState.submitting:
      return (
        <PaymentForm
          paymentIntent={paymentIntent}
          initialPayment={initialPayment}
          submitting
        />
      );
    case PaymentFlowState.redirecting3DSecure:
      return link3DSecure ? (
        <Dialog style={{ width: '500px', overflow: 'hidden' }}>
          <iframe
            width={500}
            height={600}
            src={link3DSecure}
            title="3dsIframe"
            frameBorder={0}
            style={{ backgroundColor: 'white' }}
          />
        </Dialog>
      ) : null;
    case PaymentFlowState.success:
      return paymentIntent === PaymentIntent.paywall ? (
        <PaymentForm
          paymentIntent={paymentIntent}
          initialPayment={initialPayment}
          submitting
        />
      ) : (
        <PaymentSuccess onDismiss={onSuccess ?? onDismiss} />
      );
    case PaymentFlowState.error: {
      const props = {
        error,
        onDismiss,
        onTryAgain: handleTryAgain,
      };
      if (renderPaymentError) {
        return renderPaymentError(props);
      }
      return <PaymentError {...props} />;
    }
    default:
      return <PaymentForm paymentIntent={paymentIntent} loading />;
  }
};
