import { PluginType } from '../common/PluginTypes';
import { inboundLinksEntryPointFragment_config as InboundLinksEntryPointConfig } from './@types/inboundLinksEntryPointFragment';

export const PLUGIN_TYPE = PluginType.inbound_links_entry_point;

export const PLUGIN_DEFAULT_CONFIG: InboundLinksEntryPointConfig = {
  __typename: 'InboundLinksEntryPointConfig',
  blocks: [],
  broadcasts: [],
  flows: [],
  last_refresh_date: null,
};

export const PLUGIN_INPUT_FIELD = 'inboundLinksEntryPointConfig';
