import { useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { log } from 'cf-common/src/logger';
import { ServiceMessageType, toaster } from '@services/MessageService';
import { sendEvent } from '@utils/Analytics';
import { UPDATE_BOT_TITLE_MUTATION } from './queries';
import {
  UpdateBotTitleMutation,
  UpdateBotTitleMutationVariables,
} from './@types/UpdateBotTitleMutation';

interface UseRenameBotProps {
  onDone?: () => void;
  eventCategory: string;
}

interface Bot {
  id: string;
  title: string;
}

export const useRenameBot = ({ onDone, eventCategory }: UseRenameBotProps) => {
  const [updateBotTitleMutation, { error }] = useMutation<
    UpdateBotTitleMutation,
    UpdateBotTitleMutationVariables
  >(UPDATE_BOT_TITLE_MUTATION);

  useEffect(() => {
    if (error) {
      log.warn({ error, msg: 'Rename bot' });
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: "Couldn't rename bot. Please try again later.",
        },
      });
    }
  }, [error]);

  const updateBotTitle = useCallback(
    (bot: Bot, title: string) => {
      const clearTitle = title.trim();

      if (clearTitle.length === 0) {
        return;
      }

      if (clearTitle === bot.title) {
        onDone?.();
        return;
      }

      updateBotTitleMutation({
        variables: {
          botId: bot.id,
          title: clearTitle,
        },
        optimisticResponse: {
          updateBotTitle_v2: {
            __typename: 'Bot',
            id: bot.id,
            title: clearTitle,
          },
        },
      });
      onDone?.();

      sendEvent({
        category: eventCategory,
        action: 'rename done',
        label: 'bot',
        propertyBag: {
          botId: bot.id,
          'new title': clearTitle,
        },
      });
    },
    [eventCategory, onDone, updateBotTitleMutation],
  );

  return updateBotTitle;
};
