import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment flowBuilderLiveChatPluginFragment on FlowBuilderLiveChatPlugin {
    ...commonPluginFragment
    config {
      dynamic_menu_title
      timeout_config {
        time_value
        time_period
      }
      button_text
      send_admin_notifications
      stop_message
      subtitle
      title
      image_url
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
