import gql from 'graphql-tag';

export const PLUGIN_FRAGMENT = gql`
  fragment jsonPluginFragment on JsonPlugin {
    id
    is_valid
    plugin_id
    config {
      url
      method
      show_error_messages
      headers {
        key
        value
      }
      body
      post_format
      use_custom_response
      custom_response_config {
        attr
        path
      }
    }
    localization {
      key
      value {
        default
      }
    }
    validation_details {
      fields {
        field
        error
      }
    }
  }
`;

export const PRO_STATUS_QUERY = gql`
  query ProStatusQuery($botId: String!) {
    bot(id: $botId) {
      id
      pro {
        status
        manual
      }
    }
  }
`;

export const TEST_REQUEST_MUTATION = gql`
  mutation TestRequestMutation($cardId: String!) {
    testJsonPluginRequest(cardId: $cardId) {
      url
      method
      responseCode
      requestHeaders {
        key
        value
      }
      body
      error
      response
      responseHeaders {
        key
        value
      }
    }
  }
`;

export const SEND_TO_MESSENGER_DATA_QUERY = gql`
  query SendToMessengerDataQuery($botId: String!) {
    bot(id: $botId) {
      id
      status {
        page
      }
    }
    me {
      id
    }
  }
`;
