import { sendEmailPluginFragment_config as EmailPluginConfig } from './@types/sendEmailPluginFragment';

import { PluginType } from '../common/PluginTypes';

export const PLUGIN_TYPE = PluginType.email;

export const PLUGIN_DEFAULT_CONFIG: EmailPluginConfig = {
  __typename: 'SendEmailPluginConfig',
  title: '',
  emails: [],
  body: '',
};

export const PLUGIN_INPUT_FIELD = 'sendEmailPluginConfig';
