import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

const INSTAGRAM_ADS_ENTRY_POINT_CONFIG_FRAGMENT = gql`
  fragment instagramAdsEntryPointConfigFragment on InstagramAdsEntryPointConfig {
    campaigns {
      id
      name
      ads {
        id
        name
        campaign_id
        account_id
        preview_image_small
        type
        sync
        last_synced_date
        call_to_action_type
      }
    }
    greeting {
      type
    }
    customer_action_type
    welcomeMessageType
  }
`;

export const PLUGIN_FRAGMENT = gql`
  fragment instagramAdsEntryPointFragment on InstagramAdsEntryPoint {
    ...commonPluginFragment
    config {
      ...instagramAdsEntryPointConfigFragment
    }
    enabled
    refresh
    synced
  }
  ${COMMON_PLUGIN_FRAGMENT}
  ${INSTAGRAM_ADS_ENTRY_POINT_CONFIG_FRAGMENT}
`;
