import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment persistentMenuEntryPointFragment on PersistentMenuEntryPoint {
    ...commonPluginFragment
    config {
      language
      localized_items {
        language
        items {
          title
          block_ids
          url
          webview_height_ratio
          counter_id
        }
        user_input_disabled
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
