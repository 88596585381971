import { goToBlockPluginFragment_config as GoToBlockPluginConfig } from './@types/goToBlockPluginFragment';
import { PluginType } from '../common/PluginTypes';
import { ParametersOperator } from '../../../../@types/globalTypes';

export const PLUGIN_TYPE = PluginType.go_to_block_plugin;

export const PLUGIN_DEFAULT_CONFIG: GoToBlockPluginConfig = {
  __typename: 'GoToBlockPluginConfig',
  user_filter: {
    __typename: 'Segmentation',
    operation: ParametersOperator.and,
    valid: true,
    parameters: [],
  },
  action: {
    __typename: 'GoToBlockPluginAction',
    random: false,
    items: [
      {
        __typename: 'GoToBlockPluginActionItem',
        item_type: 'block',
        blocks: [],
        text: '',
      },
    ],
  },
};

export const PLUGIN_INPUT_FIELD = 'goToBlockPluginConfig';
