import React from 'react';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { BotPicture } from '../BotPicture/BotPicture';
import { ChangeTextInput } from '@ui/ChangeTextInput/ChangeTextInput';
import { useRenameBot } from './useRenameBot';
import { BotListType } from '../../types';

interface BotNameInputProps {
  bot: BotListType;
  onDone: () => void;
  eventCategory: string;
  hidePicture?: boolean;
  checkClassName?: string;
}

export const BotNameInput: React.FC<BotNameInputProps> = ({
  bot,
  onDone,
  eventCategory,
  hidePicture = false,
  checkClassName,
}) => {
  const updateBotTitle = useRenameBot({ onDone, eventCategory });

  return (
    <Flex alignItems="center">
      {!hidePicture && (
        <>
          <BotPicture imageSrc={bot.status?.page_info?.picture} />
          <Spacer factor={1} horizontalFactor={3} />
        </>
      )}
      <ChangeTextInput
        style={{ width: 195 }}
        initialValue={bot.title}
        error={(value) => value.trim().length === 0}
        onFocus={({ currentTarget }) => {
          currentTarget.select();
        }}
        maxLength={255}
        checkClassName={checkClassName}
        onClick={(e) => e.stopPropagation()}
        onRenameClick={(e, title) => {
          e.stopPropagation();
          updateBotTitle(bot, title);
        }}
      />
    </Flex>
  );
};
