import { PluginType } from '../common/PluginTypes';
import { privateReplyEntryPointEntryPointFragment_config } from './@types/privateReplyEntryPointEntryPointFragment';

export type PrivateReplyEntryPointConfig =
  privateReplyEntryPointEntryPointFragment_config;

export const PLUGIN_TYPE = PluginType.private_reply_entry_point;

export const PLUGIN_DEFAULT_CONFIG: PrivateReplyEntryPointConfig = {
  __typename: 'PrivateReplyEntryPointConfig',
  enabled: true,
  delay_ms: null,
  content_type: PluginType.text,
};

export const PLUGIN_INPUT_FIELD = 'privateReplyEntryPointConfig';
