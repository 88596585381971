import { PluginType } from '../common/PluginTypes';
import { documentPluginFragment_config } from './@types/documentPluginFragment';

export type DocumentPluginConfig = documentPluginFragment_config;

export const PLUGIN_TYPE = PluginType.document;

export const PLUGIN_DEFAULT_CONFIG: DocumentPluginConfig = {
  __typename: 'DocumentPluginConfig',
  url: '',
  filename: '',
};

export const PLUGIN_INPUT_FIELD = 'documentPluginConfig';
