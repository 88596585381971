import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { Focus } from 'react-powerplug';
import { Autofocus } from '../../../modern-ui/Autofocus';
import { Input } from '../../../modern-ui/Input';
import { EmojiPicker } from '../../../modern-ui/EmojiPicker';
import { insertText } from '../../../utils/documentExecCommand';
import { Flex } from '../../../modern-ui/Flex';
import { BUTTON_TITLE_MAX_LENGTH } from '../../ButtonEditorPopup/common/ButtonPopupTitle';
import * as css from './AbandonedCartPlugin.css';
import { SymbolsLimitBubble } from '../../../modern-ui/SymbolsLimitBubble';

interface AbandonedCartPluginTextProps {
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  savePlugin: () => void;
  text: string | null;
  button?: boolean;
}

const MESSAGE_TEXT_LIMIT = 600;

export const AbandonedCartPluginText: React.FC<AbandonedCartPluginTextProps> = (
  props,
) => {
  const textRef = useRef<HTMLInputElement | null>(null);
  const blurTimeoutRef = useRef(setTimeout(() => null, 1000));
  const [disableButtonTabIndex, setDisableButtonTabIndex] = useState(false);

  return (
    <Focus>
      {({ bind, focused }) => (
        <>
          <button
            tabIndex={disableButtonTabIndex ? 0 : undefined}
            onClick={() => {
              setDisableButtonTabIndex(true);
              bind.onFocus();
            }}
            onFocus={() => {
              setDisableButtonTabIndex(true);
              bind.onFocus();
            }}
            className={cn(css.previewText, {
              [css.hidePreviewText]: focused,
              [css.previewButton]: props.button,
              [css.previewTextarea]: !props.button,
              [css.previewButtonSuccess]:
                (props.text || '').length > 0 && props.button,
              [css.previewButtonError]: (props.text || '').length === 0,
            })}
          >
            <span className={cn(!props.button && css.textPlaceholder)}>
              {(props.text || '').length > 0
                ? props.text
                : props.button
                ? window.i18next.t(
                    'AbandonedCartPluginText-string--160-set-button-title',
                  )
                : window.i18next.t(
                    'AbandonedCartPluginText-string-1855-set-message-text',
                  )}
            </span>
          </button>
          {focused ? (
            <Autofocus
              selectAll
              render={({ bind: autofocusBind }) => (
                <Input
                  error={!props.text || !props.text.length}
                  render={({ getInputProps }) => (
                    <EmojiPicker
                      onSelect={(emoji, el) => {
                        insertText(emoji.native, el);
                      }}
                    >
                      {({ bind: { ref, ...emojiPickerBind } }) => (
                        <Flex
                          alignItems={props.button ? 'center' : 'flex-end'}
                          onClick={() => {
                            if (textRef.current) {
                              textRef.current.focus();
                            }
                          }}
                        >
                          {props.button ? (
                            <input
                              {...getInputProps({
                                defaultValue: props.text || undefined,
                                onChange: props.onChange,
                                onFocus: () => {
                                  if (blurTimeoutRef.current) {
                                    clearTimeout(blurTimeoutRef.current);
                                  }
                                  bind.onFocus();
                                  emojiPickerBind.onFocus();
                                },
                                onBlur: () => {
                                  emojiPickerBind.onBlur();
                                  blurTimeoutRef.current = setTimeout(() => {
                                    props.savePlugin();
                                    bind.onBlur();
                                    setDisableButtonTabIndex(false);
                                  }, 200);
                                },
                                onKeyDown: ({ key }: React.KeyboardEvent) => {
                                  if (key === 'Enter') {
                                    props.savePlugin();
                                    bind.onBlur();
                                    emojiPickerBind.onBlur();
                                  }
                                },
                                maxLength: BUTTON_TITLE_MAX_LENGTH,
                                placeholder: window.i18next.t(
                                  'AbandonedCartPluginText-string-1280-add-button-title',
                                ),
                                ref: (el: HTMLInputElement) => {
                                  textRef.current = el;
                                  // eslint-disable-next-line no-param-reassign
                                  ref.current = el;
                                  autofocusBind.ref(el);
                                },
                                className: css.buttonTitleInput,
                              })}
                            />
                          ) : (
                            <textarea
                              {...getInputProps({
                                defaultValue: props.text || undefined,
                                onChange: props.onChange,
                                onFocus: () => {
                                  if (blurTimeoutRef.current) {
                                    clearTimeout(blurTimeoutRef.current);
                                  }
                                  bind.onFocus();
                                  emojiPickerBind.onFocus();
                                },
                                onBlur: () => {
                                  emojiPickerBind.onBlur();
                                  blurTimeoutRef.current = setTimeout(() => {
                                    props.savePlugin();
                                    bind.onBlur();
                                    setDisableButtonTabIndex(false);
                                  }, 200);
                                },

                                maxLength: MESSAGE_TEXT_LIMIT,
                                placeholder: window.i18next.t(
                                  'AbandonedCartPluginText-string--117-message-text',
                                ),
                                ref: (el: HTMLInputElement) => {
                                  textRef.current = el;
                                  // eslint-disable-next-line no-param-reassign
                                  ref.current = el;
                                  autofocusBind.ref(el);
                                },
                                className: css.textTextarea,
                              })}
                            />
                          )}
                          {focused ? (
                            <SymbolsLimitBubble
                              className={cn(
                                !props.button && css.textareaSymbolCounter,
                              )}
                              value={
                                (props.button
                                  ? BUTTON_TITLE_MAX_LENGTH
                                  : MESSAGE_TEXT_LIMIT) -
                                (props.text || '').length
                              }
                            />
                          ) : null}
                        </Flex>
                      )}
                    </EmojiPicker>
                  )}
                />
              )}
            />
          ) : null}
        </>
      )}
    </Focus>
  );
};
