import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Box } from '@ui/Box';
import { EditableField, EditableFieldProps } from '../EditableField';
import * as css from './EditableAnswer.css';

interface EditableAnswerProps extends Omit<EditableFieldProps, 'placeholder'> {}

export const EditableAnswer: React.FC<EditableAnswerProps> = ({
  attributes,
  onChange,
  disabled,
  text,
  onManageAttributesClick,
  'data-testid': dataTestId,
}) => {
  const { t } = useSafeTranslation();
  return (
    <Box className={css.box} borderRadiusFactor={3}>
      <EditableField
        onManageAttributesClick={onManageAttributesClick}
        onChange={onChange}
        attributes={attributes}
        text={text}
        placeholder={t(
          'modernComponents.AutomatedMessagesDialog.fieldPlaceholder',
        )}
        disabled={disabled}
        data-testid={dataTestId}
      />
    </Box>
  );
};
