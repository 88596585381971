import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Flex } from '@ui/Flex';
import cn from 'classnames';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { ReactComponent as PaywallRocketIcon } from '@pages/Plans/assets/PaywallRocket.svg';
import { ReactComponent as PaywallLine1Icon } from '@pages/Plans/assets/PaywallLine1.svg';
import { ReactComponent as PaywallBellIcon } from '@pages/Plans/assets/PaywallBell.svg';
import { ReactComponent as PaywallLine2Icon } from '@pages/Plans/assets/PaywallLine2.svg';
import { ReactComponent as PaywallLaptopIcon } from '@pages/Plans/assets/PaywallLaptop.svg';
import * as css from './PlanDescriptionBlock.css';
import { useTimeLimitedTrial } from '@utils/Data/Pricing/useTimeLimitedTrial';

const FREE_CONVERSATIONS_COUNT = 50;
const NOTIFICATION_REACHED_CONVERSATIONS_COUNT = 40;
const CHARGE_REACHED_CONVERSATIONS_COUNT = 50;

export const PlanDescriptionBlock = React.memo(() => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const { currentTierTimeLimitedTrial } = useTimeLimitedTrial();

  return (
    <Flex flexDirection={isSmallScreenSize ? 'column' : 'row'}>
      <Flex
        className={cn({ [css.column]: !isSmallScreenSize })}
        flexDirection="column"
      >
        {isSmallScreenSize ? (
          <Spacer factor={6} />
        ) : (
          <Flex flexDirection="row">
            <PaywallRocketIcon className={css.picture} />
            <PaywallLine1Icon />
          </Flex>
        )}
        <Flex
          alignItems="center"
          flexDirection={isSmallScreenSize ? 'column' : 'row'}
        >
          <Flex flexDirection={isSmallScreenSize ? 'row' : 'column'}>
            <Flex flexDirection="column">
              <Type color="white" size="15px" weight="semibold">
                {t(
                  currentTierTimeLimitedTrial
                    ? 'pages.FirstSession.PlansPaywall.today'
                    : 'pages.FirstSession.PlansPaywall.startTodayTitle',
                )}
              </Type>
              <Type className={css.description} color="white" size="15px">
                {currentTierTimeLimitedTrial
                  ? t('pages.FirstSession.PlansPaywall.startWithFreeTrial')
                  : t('pages.FirstSession.PlansPaywall.startTodayDescription', {
                      count: FREE_CONVERSATIONS_COUNT,
                    })}
              </Type>
            </Flex>
            {isSmallScreenSize && (
              <>
                <Spacer horizontalFactor={3} />
                <PaywallRocketIcon className={css.picture} />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Flex
        className={cn({ [css.column]: !isSmallScreenSize })}
        flexDirection="column"
      >
        {isSmallScreenSize ? (
          <Spacer factor={6} />
        ) : (
          <Flex flexDirection="row">
            <PaywallBellIcon className={css.picture} />
            <PaywallLine2Icon />
          </Flex>
        )}
        <Flex flexDirection={isSmallScreenSize ? 'row' : 'column'}>
          <Flex flexDirection="column">
            <Type color="white" size="15px" weight="semibold">
              {currentTierTimeLimitedTrial
                ? t('pages.FirstSession.PlansPaywall.day5')
                : t('pages.FirstSession.PlansPaywall.getNotificationTitle')}
            </Type>
            <Type className={css.description} color="white" size="15px">
              {currentTierTimeLimitedTrial
                ? t('pages.FirstSession.PlansPaywall.wellNotifyYouTrial')
                : t(
                    'pages.FirstSession.PlansPaywall.getNotificationDescription',
                    {
                      count: NOTIFICATION_REACHED_CONVERSATIONS_COUNT,
                    },
                  )}
            </Type>
          </Flex>
          {isSmallScreenSize && (
            <>
              <Spacer horizontalFactor={3} />
              <PaywallBellIcon className={css.picture} />
            </>
          )}
        </Flex>
      </Flex>

      <Flex
        className={cn({ [css.column]: !isSmallScreenSize })}
        flexDirection="column"
      >
        {isSmallScreenSize ? (
          <Spacer factor={6} />
        ) : (
          <Flex flexDirection="row">
            <PaywallLaptopIcon className={css.picture} />
          </Flex>
        )}
        <Flex flexDirection={isSmallScreenSize ? 'row' : 'column'}>
          <Flex flexDirection="column">
            <Type color="white" size="15px" weight="semibold">
              {currentTierTimeLimitedTrial
                ? t('pages.FirstSession.PlansPaywall.day7')
                : t('pages.FirstSession.PlansPaywall.payLaterTitle')}
            </Type>
            <Type className={css.description} color="white" size="15px">
              {currentTierTimeLimitedTrial
                ? t('pages.FirstSession.PlansPaywall.weWillCountDay')
                : t('pages.FirstSession.PlansPaywall.payLaterDescription', {
                    count: CHARGE_REACHED_CONVERSATIONS_COUNT,
                  })}
            </Type>
          </Flex>
          {isSmallScreenSize && (
            <>
              <Spacer horizontalFactor={3} />
              <PaywallLaptopIcon className={css.picture} />
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
});
