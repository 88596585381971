import React, { useCallback } from 'react';
import cn from 'classnames';
import { ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Tooltip2 } from '@ui/Tooltip2';
import { Spacer } from '@ui/Spacer';
import { Category, ICategory } from '../types';
import { CategoryIcon } from '../components/CategoryIcon';
import * as css from './CategoryMenu.css';

export interface CategoryMenuItemProps extends ICategory {
  active: boolean;
  disable?: boolean;
  onSelect: (category: Category) => void;
}

export const CategoryMenuItem: React.FC<CategoryMenuItemProps> = ({
  category,
  title,
  childrenAmount,
  active,
  onSelect,
  disable,
  disableTooltipContent,
}) => {
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        onSelect(category);
      }
    },
    [onSelect, category],
  );

  const handleClick = useCallback(() => {
    onSelect(category);
  }, [onSelect, category]);

  return (
    <Tooltip2
      placement="right"
      boundariesElement="viewport"
      content={disableTooltipContent}
      type="small"
      positionFixed
      disabled={!disableTooltipContent || !disable}
      hoverable
    >
      {(tooltipRef, bind) => (
        <div ref={tooltipRef} {...bind}>
          <ButtonUnstyled
            className={cn(css.templatesModalCategory, {
              [css.active]: active,
            })}
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            disabled={disable}
            data-testid="modal_category"
          >
            <Flex alignItems="center">
              <Flex
                alignItems="center"
                justifyContent="center"
                className={css.iconWrapper}
              >
                <CategoryIcon category={category} />
              </Flex>
              <Type
                color={active ? 'white' : 'black'}
                weight={active ? 'semibold' : 'regular'}
                size="15px"
              >
                {title}
              </Type>
            </Flex>
            <Spacer factor={0} horizontalFactor={2} />
            <Type color={active ? 'white' : 'greyDark'} size="15px">
              {childrenAmount}
            </Type>
          </ButtonUnstyled>
        </div>
      )}
    </Tooltip2>
  );
};
