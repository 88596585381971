import { OnboardingTipsStatus } from '@globals';
import { OnboardingOpenSectionState } from './hooks';

export interface Section {
  id: string;
  entryPoints: { status: OnboardingTipsStatus }[];
}

export const getUpdatedSectionsState = (
  sections: Section[],
  getState: (id: string) => boolean,
) => {
  let oneOpened = false;

  const newState = Object.entries(
    sections?.reduce((prev, curr) => {
      const isSectionCompleted = curr.entryPoints.every(
        ({ status }) => status === OnboardingTipsStatus.done,
      );

      return {
        ...prev,
        [curr.id]: isSectionCompleted,
      };
    }, {} as OnboardingOpenSectionState['']) || [],
  ).map(([id, isCompleted]) => {
    if (getState(id) && !isCompleted) {
      return [id, true];
    }

    if (oneOpened || isCompleted) {
      return [id, false];
    }

    oneOpened = true;
    return [id, true];
  });

  return Object.fromEntries(newState);
};
