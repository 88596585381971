import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { BotSwitcherIcon, BotSwitcherIconProps } from '../BotSwitcherIcon';
import * as css from '../BotSwitcher.css';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { ButtonUnstyled } from '@ui/Button';

export interface BotSwitcherSmallProps extends BotSwitcherIconProps {
  icon?: React.ReactElement;
  className?: string;
  menuDisabled?: boolean;
}

export const BotSwitcherSmall: React.FC<BotSwitcherSmallProps> = ({
  icon,
  children,
  className,
  screenSize,
  active,
  src,
  showProBadge,
  menuDisabled,
  ...buttonProps
}) => {
  const IconContainer = menuDisabled ? React.Fragment : ButtonUnstyled;

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      className={cn(css.wrapper, className)}
      data-testid="bot-switcher"
    >
      <IconContainer {...(menuDisabled ? undefined : buttonProps)}>
        {icon || (
          <BotSwitcherIcon
            screenSize={screenSize}
            active={active}
            src={src}
            showProBadge={showProBadge}
          />
        )}
      </IconContainer>

      {children && (
        <>
          <Spacer factor={2} />
          {children}
        </>
      )}
    </Flex>
  );
};

export interface BotSwitcherSmallLoadingProps {
  className?: string;
}

export const BotSwitcherSmallLoading: React.FC<BotSwitcherSmallLoadingProps> =
  ({ className }) => (
    <Flex
      flexDirection="column"
      alignItems="center"
      className={cn(css.wrapper, className)}
    >
      <LoadingPlaceholder width={44} height={44} />
      <Spacer factor={8} />
      <LoadingPlaceholder fullWidth height={8} />
    </Flex>
  );
