import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import PlaceholderImage from './assets/picture-placeholder.svg';
import {
  getAutoResizedHorizontalGalleryImage,
  getAutoResizedSquareImage,
} from '../ImageResize/utils/cropper';
import { FBAspectRatio, AspectRatioValue } from '../../ratio';
import { Modal } from '../../../../../modern-ui/Modal';
import { DefaultDialog } from '../../../../../modern-ui/Dialog/DefaultDialog';
import { Button } from '../../../../../modern-ui/Button';
import { Spacer } from '../../../../../modern-ui/Spacer';
import { LoadingPlaceholder } from '../../../../../modern-ui/LoadingPlaceholder';
import { RadioButton } from '../../../../../modern-ui/Radio';
import { Type } from '../../../../../modern-ui/Type';
import * as css from './GalleryAspectRatioPrompt.css';

const TextPlaceholder: React.FC<{ width: string }> = ({ width }) => (
  <LoadingPlaceholder
    className={css.textPlaceholder}
    style={{ width }}
    animated={false}
  />
);

const PreviewCard: React.FC<{
  type: FBAspectRatio;
  image?: string;
}> = React.memo(
  ({ type, image }) => (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor={type} className={css.card}>
      <div
        className={css.imageWrapper}
        style={{
          height:
            type === FBAspectRatio.horizontal
              ? 178 / AspectRatioValue.horizontalFacebookGallery
              : 178,
        }}
      >
        <img
          className={cn(css.image, { [css.imagePreview]: !image })}
          src={image || PlaceholderImage}
          alt={`${type}${window.i18next.t(
            'GalleryAspectRatioPrompt-Template--382-preview',
          )}`}
        />
      </div>
      <div className={css.text}>
        <TextPlaceholder width="131px" />
        <TextPlaceholder width="68px" />
        <TextPlaceholder width="113px" />
      </div>
    </label>
  ),
  ({ image: prevImage }, { image }) => prevImage === image,
);

export interface GalleryAspectRatioPromptProps {
  image: File;
  onSave(format: FBAspectRatio): void;
  onDismiss(): void;
}

export const GalleryAspectRatioPrompt: React.FC<GalleryAspectRatioPromptProps> = ({
  image,
  onSave,
  onDismiss,
}) => {
  const [format, setFormat] = useState<FBAspectRatio>(FBAspectRatio.horizontal);
  const [previewImages, setPreviewImages] = useState<string[]>([]);

  const handleFormatChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      setFormat(event.currentTarget.value as FBAspectRatio);
    },
    [],
  );

  const handleNextClick = useCallback(() => {
    onSave(format);
  }, [format, onSave]);

  useEffect(() => {
    if (image) {
      Promise.all([
        getAutoResizedHorizontalGalleryImage(image),
        getAutoResizedSquareImage(image),
      ]).then((images) =>
        setPreviewImages(images.map((i) => URL.createObjectURL(i))),
      );
    }
    return () => {
      previewImages.forEach((pi) => URL.revokeObjectURL(pi));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [horizontalImagePreview, squareImagePreview] = previewImages;
  return (
    <Modal onDismiss={onDismiss}>
      <DefaultDialog
        header={window.i18next.t(
          'GalleryAspectRatioPrompt-string-1833-set-format-for-gallery-images',
        )}
        footer={
          <Button intent="primary" onClick={handleNextClick}>
            {window.i18next.t('GalleryAspectRatioPrompt-JSXText-9136-next')}
          </Button>
        }
        onDismiss={onDismiss}
      >
        <>
          <Type as="p" size="15px_DEPRECATED">
            {window.i18next.t(
              'GalleryAspectRatioPrompt-JSXText--252-this-setting-will-apply-to-all-images-in-this-gallery-and-cant-be-changed-later',
            )}
          </Type>
          <Spacer factor={8} />
          <div className={css.contentRow}>
            <RadioButton
              name="gallery-format"
              id={FBAspectRatio.horizontal}
              label={window.i18next.t(
                'GalleryAspectRatioPrompt-string--860-landscape',
              )}
              onChange={handleFormatChange}
              value={format}
            />
            <RadioButton
              name="gallery-format"
              id={FBAspectRatio.square}
              label={window.i18next.t(
                'GalleryAspectRatioPrompt-string--181-square',
              )}
              onChange={handleFormatChange}
              value={format}
            />
          </div>
          <Spacer factor={4} />
          <div className={css.contentRow}>
            <PreviewCard
              type={FBAspectRatio.horizontal}
              image={horizontalImagePreview}
            />
            <PreviewCard
              type={FBAspectRatio.square}
              image={squareImagePreview}
            />
          </div>
        </>
      </DefaultDialog>
    </Modal>
  );
};
