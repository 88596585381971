import gql from 'graphql-tag';
import {
  COMMON_PLUGIN_FRAGMENT,
  SEGMENTATION_FRAGMENT,
} from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment goToBlockPluginFragment on GoToBlockPlugin {
    ...commonPluginFragment
    config {
      user_filter {
        ...segmentationFragment
      }
      action {
        random
        items {
          item_type
          text
          blocks
        }
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
  ${SEGMENTATION_FRAGMENT}
`;
