import React from 'react';
import { Flex } from '@ui/Flex';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Type } from '@ui/Type';
import * as css from './BotsHeader.css';

export interface BotsHeaderProps {
  title: string;
}

export const BotsHeader: React.FC<BotsHeaderProps> = ({ title, children }) => (
  <Flex className={css.wrapper}>
    <TextEllipsis className={css.ellipsis}>
      <Type size="15px" color="white" weight="semibold">
        {title}
      </Type>
    </TextEllipsis>

    {children}
  </Flex>
);
