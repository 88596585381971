import gql from 'graphql-tag';
import { ZAP_FRAGMENT } from '@components/FlowBuilder/views/components/Zapier/query';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment externalIntegrationEntryPointFragment on ExternalIntegrationEntryPoint {
    ...commonPluginFragment
    config {
      integration_type
      attributes
      connected_zaps {
        ...zapFragment
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
  ${ZAP_FRAGMENT}
`;
