import { PluginType } from '@components/Plugins/common/PluginTypes';
import { persistentMenuEntryPointFragment_config as PersistentMenuEntryPointConfig } from './@types/persistentMenuEntryPointFragment';
import { WebviewHeightRatio } from '@globals';

export const PLUGIN_TYPE = PluginType.persistent_menu_entry_point;

export const PLUGIN_DEFAULT_CONFIG: PersistentMenuEntryPointConfig = {
  __typename: 'PersistentMenuEntryPointConfig',
  language: 'default',
  localized_items: [
    {
      __typename: 'PersistentMenuLocaleItem',
      language: 'default',
      user_input_disabled: false,
      items: [
        {
          __typename: 'PersistentMenuItemConfig',
          title: '',
          block_ids: null,
          url: null,
          webview_height_ratio: WebviewHeightRatio.tall,
          counter_id: null,
        },
      ],
    },
  ],
};

export const PLUGIN_INPUT_FIELD = 'persistentMenuEntryPointConfig';
