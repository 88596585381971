import React from 'react';
import { useRolePermission } from '@utils/Roles';
import { TabLinkSkeleton } from '../TabLinkSkeleton/TabLinkSkeleton';
import { Domain, Permission } from '../../../../common/services/RoleService';

export interface ChecekTabRoleProps {
  domain: Domain;
  small?: boolean;
}

export const CheckTabRole: React.FC<ChecekTabRoleProps> = ({
  domain,
  small,
  children,
}) => {
  const { allowed, loading } = useRolePermission({
    domain,
    can: Permission.VIEW,
  });

  if (loading) {
    return <TabLinkSkeleton small={small} />;
  }

  if (!allowed) {
    return null;
  }

  return <>{children}</>;
};
