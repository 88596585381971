import React from 'react';
import { Type } from '@ui/Type';
import { BusinessVerificationStatus, OnboardingTipsStatus } from '@globals';

export interface DescriptionProps {
  description: string;
  status: OnboardingTipsStatus;
  verificationStatus: BusinessVerificationStatus | null;
}

export const Description: React.FC<DescriptionProps> = ({
  description,
  status,
  verificationStatus,
}) => {
  const getColor = () => {
    if (status === OnboardingTipsStatus.disabled) return 'baseTertiary';
    if (
      verificationStatus === BusinessVerificationStatus.pending_need_more_info
    )
      return 'accent4Tertialy';
    return 'accent1Light';
  };

  return (
    <Type color={getColor()} data-testid="onbording-tour_tips-desctiption">
      {description}
    </Type>
  );
};
