import React from 'react';
import { Icon } from '@ui/Icon';
import { Button } from '@ui/Button';
import loaderUrl from '@ui/_deprecated/Icon/icons/loader.svg';
import * as s from './Download.css';

export interface DownloadProps extends TestLocator {
  onDownload(): Promise<void>;
}

export const Download: React.FC<DownloadProps> = ({ onDownload, ...props }) => {
  const [loading, setLoading] = React.useState(false);

  const handleDownload = async () => {
    if (!loading) {
      setLoading(true);
      await onDownload();
      setLoading(false);
    }
  };

  return (
    <Button
      onClick={handleDownload}
      intent="text"
      size="xs"
      disabled={loading}
      data-testid={props['data-testid']}
      icon={
        loading ? (
          <img
            className={s.loader}
            alt={window.i18next.t('Download-string-4081-loading')}
            src={loaderUrl}
          />
        ) : (
          <Icon icon="download" size="24px" />
        )
      }
    />
  );
};
