import { PluginType } from '@components/Plugins/common/PluginTypes';
import { zapierImportContentPluginFragment_config } from '@components/Plugins/ZapierImportContentPlugin/@types/zapierImportContentPluginFragment';
import { IntegrationZapierPluginConfigView } from '@globals';

export type ZapierImportContentPluginConfig = zapierImportContentPluginFragment_config;

export const PLUGIN_TYPE = PluginType.integration_zapier_plugin;

export const PLUGIN_DEFAULT_CONFIG: Partial<ZapierImportContentPluginConfig> = {
  __typename: 'IntegrationZapierPluginConfig',
  view: IntegrationZapierPluginConfigView.full,
};

export const PLUGIN_INPUT_FIELD = 'zapierImportContentPluginConfig';
