import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import debounce from 'lodash-es/debounce';
import Cropper from 'react-cropper';
import cn from 'classnames';
import { config, getResizedBlobData } from './utils/cropper';
import { AspectRatioValue } from '../../ratio';
import { CenteredLoader } from '../../../../../modern-ui/Loader';
import { ServiceMessageType } from '../../../../../modern-ui/ServiceMessage2';
import { toaster, Messages } from '../../../../../services/MessageService';
import {
  getImageMIMETypeByExt,
  getExtFromFileName,
  ImageMIMEType,
} from '../../../../../utils/fileUtils';
import * as css from './ImageResize.css';
import 'cropperjs/dist/cropper.css';

export interface ImageResizeProps {
  image: File | string;
  ratio?: AspectRatioValue;
  onCrop(imageBlob: Blob): void;
}

export const ImageResize: React.FC<ImageResizeProps> = ({
  image,
  ratio,
  onCrop,
}) => {
  const [ready, setReady] = useState(false);
  const cropperRef = useRef<Cropper>();

  const handleReady = useCallback(() => !ready && setReady(true), [ready]);

  const setRef = useCallback((ref: Cropper | null) => {
    if (ref) {
      cropperRef.current = ref;
    }
  }, []);

  const handleCrop = useCallback(
    debounce(() => {
      const fileName = getExtFromFileName(
        typeof image === 'string' ? image : image.name,
      );
      const mimeType =
        (fileName && getImageMIMETypeByExt(fileName)) || ImageMIMEType.jpeg;

      if (cropperRef.current) {
        getResizedBlobData(cropperRef.current, mimeType)
          .then(onCrop)
          .catch(() => {
            toaster.show({
              type: ServiceMessageType.error,
              payload: {
                message: Messages.somethingWentWrong,
              },
            });
          });
      }
    }, 200),
    [cropperRef],
  );
  const imageUrl = useMemo(
    () => (typeof image === 'string' ? image : URL.createObjectURL(image)),
    [image],
  );

  useEffect(() => {
    return () => {
      if (typeof image !== 'string') {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [image, imageUrl]);

  return (
    <>
      {!ready && <CenteredLoader />}
      <Cropper
        className={cn(css.cropper, { [css.cropperHidden]: !ready })}
        ref={setRef}
        src={imageUrl}
        ready={handleReady}
        crop={handleCrop}
        aspectRatio={ratio}
        {...config}
      />
    </>
  );
};
