import {
  ExternalIntegrationEntryPointType,
  SendToMessengerColor,
  SendToMessengerSize,
} from '@globals';
import { popupEntryPointFragment_config as PopupEntryPointConfig } from './@types/popupEntryPointFragment';

import { PluginType } from '../common/PluginTypes';

export const PLUGIN_TYPE = PluginType.popup_entry_point;

export const DEFAULT_BUTTON_TEXT = 'GET_THIS_IN_MESSENGER';

export const PLUGIN_DEFAULT_CONFIG: PopupEntryPointConfig = {
  __typename: 'PopupEntryPointConfig',
  integration_type: ExternalIntegrationEntryPointType.shopify,
  block_id: null,
  separate_response_for_existing_users: false,
  existing_user_block_id: null,
  shopify_popup: {
    __typename: 'ShopifyIntegrationPopupOptions',
    enabled: true,
    title: 'Get exclusive offers',
    description: 'Click the button below to get exclusive access to our deals',
    button_text: DEFAULT_BUTTON_TEXT,
    color: SendToMessengerColor.blue,
    size: SendToMessengerSize.large,
    block_ids: [],
    included_pages: [],
    excluded_urls: [],
    trigger: {
      __typename: 'ShopifyIntegrationPopupTrigger',
      type: 'idle_timeout',
      idle_timeout_seconds: 0,
    },
  },
};

export const PLUGIN_INPUT_FIELD = 'popupEntryPointConfig';
