import React from 'react';
import cn from 'classnames';
import * as css from './PluginBox.css';

const PluginBoxInner: React.FC<React.HTMLProps<HTMLDivElement>> = (
  { className, ...props },
  ref,
) => <div className={cn(css.PluginBox, className)} {...props} ref={ref} />;
PluginBoxInner.displayName = 'PluginBox';

export const PluginBox = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(PluginBoxInner);
