import React from 'react';
import { compose, filter, propEq, complement, identity, pipe } from 'ramda';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Dialog, DialogHeading } from '../../modern-ui/Dialog';
import {
  FlexDialogContent,
  FormLine,
  SubmitRow,
  CloseButton,
} from '../../modern-ui/Dialog/ActionDialogParts';
import { Flex } from '../../modern-ui/Flex';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import { Input } from '../../modern-ui/Input';
import { ReactComponent as DefaultBotIcon } from '../../modern-ui/_deprecated/Icon/icons/default-bot.svg';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { ShimmerText } from '../../modern-ui/ShimmerText';
import { Autofocus } from '../../modern-ui/Autofocus';
import * as s from '../BlockActionsDialog/BlockActionsDialog.css';
import {
  GroupActionBots as Bots,
  GroupActionBots_bots as Bot,
} from './@types/GroupActionBots';
import { testAllowedPlatforms } from '@utils/Platform/testAllowedPlatforms';
import { Platform } from '@globals';

interface IGroupActionsDialogProps {
  onSubmit: (params: { botId: string }) => void;
  renderHeading: () => React.ReactNode;
  renderActionText: () => React.ReactNode;
  onDismiss?: () => void;
  currentBotId: string;
  excludeCurrentBot?: boolean;
}

interface IGroupActionsDialogState {
  [formValue: string]: string | null;
}

const botsQuery = gql`
  query GroupActionBots {
    bots {
      id
      allowedPlatforms
      title
      status {
        page_info {
          id
          picture
        }
      }
    }
  }
`;

const excludeBotById = pipe(propEq('id'), complement, filter as any) as (
  botId: string,
) => (bots: Bot[]) => Bot[];

export class GroupActionsDialog extends React.Component<
  IGroupActionsDialogProps,
  IGroupActionsDialogState
> {
  constructor(props: IGroupActionsDialogProps) {
    super(props);
    this.state = {
      botId: props.excludeCurrentBot ? null : props.currentBotId,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setFieldValue(name: string, value: string) {
    this.setState({ [name]: value });
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!event.currentTarget.checkValidity()) {
      return;
    }

    this.props.onSubmit(this.state as { botId: string });
  }

  render() {
    const {
      renderHeading,
      renderActionText,
      onDismiss,
      excludeCurrentBot,
      currentBotId,
    } = this.props;
    const { botId } = this.state;
    const botsFilter = excludeCurrentBot
      ? excludeBotById(currentBotId)
      : identity;
    return (
      <Dialog>
        <form onSubmit={this.handleSubmit}>
          <FlexDialogContent>
            <div>
              <DialogHeading>
                <Flex alignItems="baseline" justifyContent="space-between">
                  <div style={{ paddingRight: 8 }}>{renderHeading()}</div>
                  {onDismiss ? (
                    <CloseButton
                      aria-label="close"
                      type="button"
                      onClick={onDismiss}
                    />
                  ) : null}
                </Flex>
              </DialogHeading>
              <Query<Bots> query={botsQuery}>
                {({ error, loading, data }) => (
                  <React.Fragment>
                    {!error ? (
                      <React.Fragment>
                        <FormLine>
                          <label>
                            <div className={s.formLabel}>
                              {window.i18next.t(
                                'GroupActionsDialog-JSXText--150-to-the-bot',
                              )}
                            </div>
                            <SimpleCombobox
                              key={loading ? 'loading' : 'ready'}
                              initialSelectedItem={
                                loading || !data
                                  ? null
                                  : data.bots.find(
                                      (bot: any) => bot.id === botId,
                                    )
                              }
                              onChange={(item) =>
                                this.setFieldValue('botId', item!.id)
                              }
                              items={
                                data
                                  ? (botsFilter(data.bots) as Bot[]).filter(
                                      ({ allowedPlatforms }) =>
                                        !allowedPlatforms ||
                                        testAllowedPlatforms(
                                          [Platform.facebook],
                                          allowedPlatforms,
                                        ),
                                    )
                                  : []
                              }
                              renderInput={({
                                getInputProps,
                                openMenu,
                                selectedItem,
                                selectInputValue,
                                ref,
                              }) => (
                                <Autofocus
                                  shouldFocus={!loading}
                                  render={({ bind }) => (
                                    <Input
                                      {...getInputProps({
                                        ref: loading
                                          ? undefined
                                          : (n: HTMLInputElement) => {
                                              ref(n);
                                              bind.ref(n);
                                            },
                                        onFocus: compose(
                                          openMenu,
                                          selectInputValue,
                                        ),
                                      })}
                                      data-testid="group-actions__bot-input"
                                      name="botId"
                                      placeholder={window.i18next.t(
                                        'GroupActionsDialog-string--159-enter-bot-name',
                                      )}
                                      render={
                                        loading
                                          ? () => (
                                              <div>
                                                <ShimmerText
                                                  play
                                                  style={{
                                                    paddingLeft: 12,
                                                    fontSize: 15,
                                                    lineHeight:
                                                      'var(--control-decorator-min-height)',
                                                  }}
                                                >
                                                  {window.i18next.t(
                                                    'GroupActionsDialog-JSXText-1258-loading-bot-list',
                                                  )}
                                                </ShimmerText>
                                                <input
                                                  type="text"
                                                  name="botId"
                                                  // render invalid input
                                                  // so that the form cannot be submitted
                                                  // while loading
                                                  required
                                                  style={{
                                                    position: 'absolute',
                                                    opacity: 0,
                                                    width: 0,
                                                    height: 0,
                                                  }}
                                                />
                                              </div>
                                            )
                                          : undefined
                                      }
                                      renderIconEnd={() => {
                                        if (
                                          !selectedItem ||
                                          !selectedItem.status.page_info ||
                                          !selectedItem.status.page_info.picture
                                        ) {
                                          return <DefaultBotIcon />;
                                        }
                                        return (
                                          <img
                                            src={
                                              selectedItem.status.page_info
                                                .picture
                                            }
                                            width={24}
                                            height={24}
                                            alt="page status info"
                                          />
                                        );
                                      }}
                                      required
                                    />
                                  )}
                                />
                              )}
                            />
                          </label>
                        </FormLine>
                      </React.Fragment>
                    ) : null}
                    {error ? (
                      <p>
                        {window.i18next.t(
                          'GroupActionsDialog-JSXText--117-an-error-occured-while-loading-bot-list',
                        )}
                      </p>
                    ) : null}
                  </React.Fragment>
                )}
              </Query>
            </div>
            <SubmitRow>
              <Button
                data-testid="group-actions__action-button"
                type="submit"
                disabled={!botId}
                intent={ButtonIntent.primary}
              >
                {renderActionText()}
              </Button>
            </SubmitRow>
          </FlexDialogContent>
        </form>
      </Dialog>
    );
  }
}
