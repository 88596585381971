import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import liveChatPreview from './liveChatPreview.svg';
import peoplePreview from './peoplePreview.svg';
import reengagePreview from './reengagePreview.svg';
import { ReactComponent as SwitcherImage } from './switcher.svg';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { Translation } from '@translations';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useEntryPointTipsQuery } from '@utils/Data/MiniOnboarding/useEntryPointTipsQuery';
import {
  ServerStorageItemKeys,
  useServerStorageForBot,
} from '@utils/ServerStorage';
import { sendEvent } from '@utils/Analytics';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { CenteredLoader } from '@ui/Loader';
import css from './MiniOnboardingOverlay.css';

interface MiniOnboardingOverlayProps {
  page: BotTabs.liveChat | BotTabs.people | BotTabs.reEngage;
}

const data: Record<
  MiniOnboardingOverlayProps['page'],
  { image: string; path: Translation; mobileImageStyle?: React.CSSProperties }
> = {
  [BotTabs.liveChat]: {
    image: liveChatPreview,
    path: 'modernComponents.LeftNavigationPanel.liveChat',
  },
  [BotTabs.people]: {
    image: peoplePreview,
    path: 'modernComponents.LeftNavigationPanel.peopleTab',
  },
  [BotTabs.reEngage]: {
    image: reengagePreview,
    path: 'modernComponents.LeftNavigationPanel.reengageTab',
  },
};

export const MiniOnboardingOverlay: React.FC<MiniOnboardingOverlayProps> = ({
  page,
}) => {
  const botId = useCurrentBotId();
  const history = useHistory();
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const { allowed, loading: roleLoading } = useRolePermission({
    domain: 'flows',
    can: Permission.EDIT,
  });

  const [_, setClosed] = useServerStorageForBot<boolean>(
    ServerStorageItemKeys.miniOnboardingPopupClosed,
    botId,
  );

  useEffect(() => {
    sendEvent({
      category: 'mini onboarding overlay',
      action: 'show mini onbording overlay',
      propertyBag: {
        botId,
        page,
      },
    });
  }, [page, botId]);

  useEntryPointTipsQuery(botId);

  if (roleLoading) return <CenteredLoader />;

  return (
    <div className={css.container}>
      <div
        className={css.backgroudImage}
        style={{
          backgroundImage: `url(${data[page].image})`,
          ...(isSmallScreenSize && data[page].mobileImageStyle),
        }}
      />
      <div className={css.overlay}>
        <Flex
          flexDirection="column"
          alignItems="center"
          className={css.content}
        >
          <SwitcherImage className={css.switcherImage} />
          <Type size="24px" weight="bold" color="white" align="center">
            {t(
              allowed
                ? 'MiniOnboarding.overlay.activateYourBotBefore'
                : 'MiniOnboarding.overlay.askYourAdmin',
              {
                tab: t(data[page].path),
              },
            )}
          </Type>
          {allowed && (
            <>
              <Spacer factor={3} />
              <Type
                size={isSmallScreenSize ? '15px' : '24px'}
                color="white"
                align="center"
              >
                {t('MiniOnboarding.overlay.goToOnboardingGuide')}
              </Type>
              <Spacer factor={5} />
              <Button
                data-testid="mini-onboarding-overlay__open-onbording-guide-button"
                onClick={() => {
                  sendEvent({
                    category: 'mini onboarding overlay',
                    action: 'open onbording guide button click',
                    propertyBag: {
                      botId,
                      page,
                    },
                  });
                  setClosed(false);
                  history.push(getTabLink(BotTabs.home, botId));
                }}
              >
                {t('MiniOnboarding.overlay.openOnboardingGuide')}
              </Button>
            </>
          )}
        </Flex>
      </div>
    </div>
  );
};
