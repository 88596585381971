import React from 'react';
import { Modal } from '../../../../../modern-ui/Modal';
import { DefaultDialog } from '../../../../../modern-ui/Dialog/DefaultDialog';
import { Button } from '../../../../../modern-ui/Button';

export interface ImageSizeAlertProps {
  onDismiss(): void;
}

export const ImageSizeAlert: React.FC<ImageSizeAlertProps> = ({
  onDismiss,
}) => (
  <Modal onDismiss={onDismiss}>
    <DefaultDialog
      header={window.i18next.t('ImageSizeAlert-string-1655-file-is-too-large')}
      footer={
        <Button intent="primary" onClick={onDismiss}>
          {window.i18next.t('ImageSizeAlert-JSXText-3182-ok')}
        </Button>
      }
      onDismiss={onDismiss}
    >
      {window.i18next.t(
        'ImageSizeAlert-JSXText-9874-upload-an-image-thats-under-10-mb',
      )}
    </DefaultDialog>
  </Modal>
);
