import ng from 'angular';
import { react2angular } from 'react2angular';
import React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import { JsonPlugin, IJsonPluginProps } from './JsonPlugin';
import client from '../../../common/services/ApolloService';

const JsonPluginWrapper: React.FC<IJsonPluginProps> = (props) => (
  <ApolloProvider client={client}>
    <JsonPlugin {...props} />
  </ApolloProvider>
);

export const ngJsonPlugin = ng
  .module('app.plugins.jsonPlugin', [])
  .component(
    'jsonPlugin',
    react2angular(JsonPluginWrapper, ['id', 'blockId', 'position', 'botId'], []),
  ).name;
