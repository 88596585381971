import { JsonPluginRequestPostFormat } from './JsonPluginConst';
import { ATTR_SIGN_REG } from '../../../modern-ui/TextWithAttributesEditor';
import { isValidJSON } from '../../../utils/Validator';
import { getFullAttributesBoundaries } from '../../../modern-ui/TextWithAttributesEditor/attributesBoundariesUtils';
import { LSKey, LS } from 'cf-common/src/localStorage';

export const isNotShowJsonValidError = (body: string | null) => {
  if (!body) {
    return true;
  }
  return isValidJSON(body.replace(ATTR_SIGN_REG, 'null')); // replace attributes template for validation
};

export const isJsonEncoded = (postFormat: JsonPluginRequestPostFormat | null) =>
  !postFormat || postFormat === JsonPluginRequestPostFormat.json;

export const urlEncodedParamsFromBody = (body: string | null) => {
  if (!body) {
    return [];
  }

  return body.split('&').map((keyValue) => {
    const parsed = keyValue.split('=');

    return [decodeURIComponent(parsed[0]), decodeURIComponent(parsed[1])];
  });
};

/**
 * encode everything except boundaries
 */
const smartEncodeURIComponent = (s: string) => {
  const boundaries = getFullAttributesBoundaries(s);
  const nonEncodedIndexesSet = new Set<number>();

  boundaries.forEach(({ startOffset, endOffset }) => {
    for (let i = startOffset; i < endOffset; i++) {
      nonEncodedIndexesSet.add(i);
    }
  });

  let encodedS = '';
  for (let i = 0; i < s.length; i++) {
    const currentChar = s.charAt(i);
    encodedS += nonEncodedIndexesSet.has(i)
      ? currentChar
      : encodeURIComponent(currentChar);
  }

  return encodedS;
};

export const urlEncodedParamsToBody = (urlEncodedParams: string[][]) => {
  const s = urlEncodedParams
    .map((param) => [
      smartEncodeURIComponent(param[0]),
      smartEncodeURIComponent(param[1]),
    ])
    .map((param) => param.join('='))
    .join('&');

  return s;
};

const getSendToMessengerClickedStatusFromLocaleStore = () =>
  LS.getRaw(LSKey.sendToMessengerInJsonClicked) || '';

export const isSendToMessengerClickedFromLocaleStore = (botId: string) =>
  getSendToMessengerClickedStatusFromLocaleStore().split(',').includes(botId);

export const setSendToMessengerClickedToLocaleStore = (botId: string) =>
  isSendToMessengerClickedFromLocaleStore(botId) ||
  LS.setRaw(
    LSKey.sendToMessengerInJsonClicked,
    `${getSendToMessengerClickedStatusFromLocaleStore()}${botId},`,
  );
