import React, { useEffect, useMemo, useRef } from 'react';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Container } from './Container/Container';
import { useEntryPointTipsQuery } from '@utils/Data/MiniOnboarding/useEntryPointTipsQuery';
import { IconButton } from '@ui/IconButton';
import {
  ServerStorageItemKeys,
  useServerStorageForBot,
} from '@utils/ServerStorage';
import { Modal } from '@services/index';
import { CloseDialog } from './CloseDialog/CloseDialog';
import { useCurrentBotId } from '@utils/Routing';
import { sendEvent } from '@utils/Analytics';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { OnboardingTipsStatus } from '@globals';
import { OnBoardingSectionList } from './OnBoardingSectionList';
import css from './MiniOnboardingBanner.css';

interface MiniOnboardingBannerProps {
  className?: string;
}

export const MiniOnboardingBanner: React.FC<MiniOnboardingBannerProps> = ({
  className,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const sentEventRef = useRef(false);
  const { data: entryPointTipsData } = useEntryPointTipsQuery(botId, {
    fetchPolicy: 'cache-and-network',
  });
  const { isSmallScreenSize } = useDeviceMedia();
  const [closed, setClosed] = useServerStorageForBot<boolean>(
    ServerStorageItemKeys.miniOnboardingPopupClosed,
    botId,
  );

  const tips = entryPointTipsData?.entryPointTips;

  const tipsDisabled = useMemo(
    () =>
      tips?.sections
        .flatMap(({ entryPoints }) => entryPoints)
        .every(({ status }) => status === OnboardingTipsStatus.disabled),
    [tips],
  );
  /**
   * на мобильных экранах у нас зачастую меняется лейаут под который нужно менять шаги,
   * размеры мобильного экрана не позволяют показать подсказку онбординга
   * поэтому мы скрывает онбординг на мобильных устройствах
   */
  const bannerDisabled =
    !tips || tipsDisabled || closed || isSmallScreenSize || !tips.sections;

  useEffect(() => {
    if (bannerDisabled || sentEventRef.current) return;
    sentEventRef.current = true;
    sendEvent({
      category: 'mini onboarding banner',
      action: 'show banner',
      propertyBag: {
        botId,
      },
    });
  }, [botId, bannerDisabled]);

  if (bannerDisabled) return null;

  const { platform, sections } = tips;
  const entryPoints = sections.flatMap(({ entryPoints }) => entryPoints);

  return (
    <Container
      className={className}
      platform={platform}
      entryPoints={entryPoints}
    >
      <div className={css.title}>
        <Type
          size={isSmallScreenSize ? '24px' : '44px'}
          color="accent1Semilight"
          weight="extrabold"
        >
          {t('MiniOnboarding.banner.maximumImpact')}
        </Type>
      </div>

      <OnBoardingSectionList sections={sections} platform={platform} />

      <IconButton
        icon="close"
        color="accent1Secondary"
        className={css.closeAction}
        onClick={() =>
          Modal.show(({ close }) => (
            <CloseDialog
              onClose={close}
              onHidePopup={() => {
                close();
                setClosed(true);
                sendEvent({
                  category: 'mini onboarding banner',
                  action: 'close banner button click',
                  propertyBag: {
                    botId,
                  },
                });
              }}
            />
          ))
        }
      />
      {/* Пока не поддерживается мобилки в онбординге */}
      {/* {isSmallScreenSize && (
        <div className={css.weRecommendYouDesktop}>
          <Spacer factor={4} />
          <Type color="accent1Light" weight="medium" size="15px">
            {t('MiniOnboarding.banner.weRecommendYouDesktop')}
          </Type>
        </div>
      )} */}
    </Container>
  );
};
