import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment publicReplyEntryPointEntryPointFragment on PublicReplyEntryPoint {
    ...commonPluginFragment
    config {
      replies {
        attachment_id
        attachment_share_url
        attachment_url
        source
        message
      }
      enabled
      delay_ms
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
