import { PluginType } from '@components/Plugins/common/PluginTypes';
import {
  ExternalIntegrationEntryPointType,
  ProductListShopifyOrderButtonType,
  ProductListType,
} from '@globals';
import { productListPluginFragment_config } from './@types/productListPluginFragment';

export type ProductListPluginConfig = productListPluginFragment_config;

export const PLUGIN_TYPE = PluginType.product_list;

export const PLUGIN_DEFAULT_CONFIG: ProductListPluginConfig = {
  __typename: 'ProductListPluginConfig',
  list_type: 'static' as ProductListType,
  products: null,
  order: null,
  visited_products: null,
  back_in_stock: null,
};

export const PLUGIN_SHOPIFY_PRODUCT_LIST_DEFAULT_CONFIG: ProductListPluginConfig =
  {
    __typename: 'ProductListPluginConfig',
    list_type: 'order_content' as ProductListType,
    products: null,
    visited_products: null,
    back_in_stock: null,
    order: {
      __typename: 'ProductListOrderContentConfig',
      integration_type: 'shopify' as ExternalIntegrationEntryPointType,
      shopify_order: {
        __typename: 'ProductListShopifyOrderContentConfig',
        order_id: '{{ shopify order id }}',
        fulfillment_id: '{{ shopify order fulfillment id }}',
        buttons: [
          {
            __typename: 'ProductListShopifyOrderButtonConfig',
            type: 'order_page' as ProductListShopifyOrderButtonType,
            title: 'Go to order page',
            counter_id: null,
          },
        ],
      },
    },
  };

export const PLUGIN_SHOPIFY_PRODUCT_LIST_PRODUCT_VISIT_DEFAULT_CONFIG: ProductListPluginConfig =
  {
    __typename: 'ProductListPluginConfig',
    list_type: ProductListType.visited_products,
    products: null,
    order: null,
    back_in_stock: null,
    visited_products: {
      __typename: 'ProductListVisitedProductsConfig',
      recommendation_type: null,
      buttons: [
        {
          __typename: 'ProductListShopifyOrderButtonConfig',
          type: ProductListShopifyOrderButtonType.product_page,
          title: 'Shop now',
          counter_id: null,
        },
      ],
    },
  };

export const PLUGIN_INPUT_FIELD = 'productListPluginConfig';
