import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment customerChatEntryPointFragment on CustomerChatEntryPoint {
    ...commonPluginFragment
    config {
      domains
      logged_in_greeting
      logged_out_greeting
      theme_color
      greeting_dialog_display
      supported_devices
      greeting_dialog_delay
      pages_whitelist
      pages_blacklist
      block_id
      guest_block_id
      upgrade_block_id
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
