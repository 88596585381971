import React from 'react';
import debounce from 'lodash-es/debounce';
import { sendHoverEvent } from '@components/LeftNavigationPanel/utils';

export interface HoverTabEventBoxProps {
  send?: boolean;
  tabEvent: string;
}

export const HoverTabEventBox: React.FC<HoverTabEventBoxProps> = ({
  send,
  tabEvent,
  children,
}) => {
  const sendHoverEventDebounced = debounce(sendHoverEvent, 300);

  return (
    <div onMouseEnter={() => send && sendHoverEventDebounced(tabEvent)}>
      {children}
    </div>
  );
};
