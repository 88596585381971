import ng from 'angular';
import { react2angular } from 'react2angular';
import React from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-common';
import { AbtPlugin, AbtPluginProps } from './AbtPlugin';
import client from '../../../common/services/ApolloService';
import { abtPluginFragment_config_user_filter } from './@types/abtPluginFragment';
import { globalHistory } from '../../../utils/Routing';

interface AbtPluginWrapperProps extends AbtPluginProps {
  PeopleService: {
    navigatePeopleTabWithFilter: (
      filter: abtPluginFragment_config_user_filter,
    ) => void;
  };
}

const AbtPluginWrapper: React.FC<AbtPluginWrapperProps> = ({
  PeopleService,
  ...props
}) => (
  <ApolloProvider client={client}>
    <Router history={globalHistory}>
      <AbtPlugin
        {...props}
        goToPeopleTabRequest={(filter) => {
          PeopleService.navigatePeopleTabWithFilter(filter);
        }}
      />
    </Router>
  </ApolloProvider>
);

export const ngAbtPlugin = ng
  .module('app.plugins.abtPlugin', [])
  .component(
    'abtPlugin',
    react2angular(
      AbtPluginWrapper,
      ['id', 'blockId', 'position', 'botId', 'blockTitle'],
      ['PeopleService'],
    ),
  ).name;
