import { PluginType } from '../common/PluginTypes';
import { whatsappDirectEntryPointFragment_config as WhatsappDirectEntryPointConfig } from './@types/whatsappDirectEntryPointFragment';

export const PLUGIN_TYPE = PluginType.whatsapp_direct_entry_point;

export const PLUGIN_DEFAULT_CONFIG: WhatsappDirectEntryPointConfig = {
  __typename: 'WhatsappDirectEntryPointConfig',
  block_id: null,
};

export const PLUGIN_INPUT_FIELD = 'whatsappDirectEntryPointConfig';
