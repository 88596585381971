import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Type, TypeProps } from '@ui/Type';
import { BotSwitcherIcon, BotSwitcherIconProps } from '../BotSwitcherIcon';
import * as css from '../BotSwitcher.css';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Button, ButtonUnstyled } from '@ui/Button';

export interface BotSwitcherRegularProps extends BotSwitcherIconProps {
  size: TypeProps<HTMLDivElement>['size'];
  title: string;
  icon?: React.ReactElement;
  className?: string;
  menuDisabled?: boolean;
}

export const BotSwitcherRegular: React.FC<BotSwitcherRegularProps> = ({
  icon,
  title,
  children,
  className,
  size,
  screenSize,
  active,
  src,
  showProBadge,
  menuDisabled,
  ...buttonProps
}) => {
  const Container = menuDisabled ? React.Fragment : ButtonUnstyled;

  return (
    <Flex
      flexDirection="column"
      className={cn(css.wrapper, className)}
      data-testid="bot-switcher"
    >
      <Container {...(menuDisabled ? {} : buttonProps)}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex className={css.content}>
            {icon || (
              <BotSwitcherIcon
                screenSize={screenSize}
                active={active}
                src={src}
                showProBadge={showProBadge}
              />
            )}

            <Spacer factor={0} horizontalFactor={1.5} />

            <Type size={size} color="cobaltExtralight" className={css.text}>
              <TextEllipsis>{title}</TextEllipsis>
            </Type>
          </Flex>

          {!menuDisabled && (
            <Icon icon="arrow_right" size="16px" color="cobaltSemilight" />
          )}
        </Flex>
      </Container>

      {children && (
        <>
          <Spacer factor={2} />
          {children}
        </>
      )}
    </Flex>
  );
};

export interface BotSwitcherRegularLoadingProps {
  width?: string;
  height: number;
  className?: string;
}

export const BotSwitcherRegularLoading: React.FC<BotSwitcherRegularLoadingProps> =
  ({ height, width, className }) => (
    <Flex flexDirection="column" className={cn(css.wrapper, className)}>
      <LoadingPlaceholder color="cobaltSemilight" fullWidth height={height} />
      <Spacer factor={2} />
      <LoadingPlaceholder
        color="cobaltSemilight"
        width={width}
        height={height}
      />
      <Spacer factor={2} />
      {/* Workaround чтобы появились паддинги нжно чтобы был children */}
      <Button loading>_</Button>
    </Flex>
  );
