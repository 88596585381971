import { useLightBots } from '@utils/Data/Bot/useLightBots';
import React, { useMemo } from 'react';
import { useCreateBotFromHeader } from '../../hooks';
import { buildRegularComboboxItems, filterBotsToShow } from '../../utils';
import { BotHeaderBase, BotHeaderProps } from './BotHeader';

export const BotHeaderRegular = React.memo<BotHeaderProps>(
  ({ loading: outsideLoading, ...props }) => {
    const { lightBots, loading: lightBotsLoading } = useLightBots();
    const bots = { bots: lightBots || [] };
    const { onCreateBot, loading: createdBotLoading } =
      useCreateBotFromHeader(bots);

    const comboboxItems = useMemo(
      () => buildRegularComboboxItems(lightBots),
      [lightBots],
    );

    const loading = createdBotLoading || lightBotsLoading || outsideLoading;

    return (
      <BotHeaderBase
        loading={loading}
        onAddBotButtonClick={(platforms) => onCreateBot(undefined, platforms)}
        comboboxItems={comboboxItems}
        botsItemsLength={bots.bots.length}
        filter={filterBotsToShow}
        {...props}
      />
    );
  },
);
