import { useEffect, useRef, useState } from 'react';
import { LS } from 'cf-common/src/localStorage';
import { LSKey, useLocalStorage } from '@utils/LocalStorage';
import { EntryPointTipsQuery_entryPointTips_sections as Sections } from '@utils/Data/MiniOnboarding/@types/EntryPointTipsQuery';
import { OnboardingTipsStatus } from '@globals';
import { useWhatsappEnabled } from '@utils/Whatsapp';

export const usePreopenedTip = (sections: Sections[]) => {
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const oldValue = LS.get(LSKey.onboardingPreopenedTip);
  const [preopenedTip, setPreopenedTip] = useLocalStorage(
    LSKey.onboardingPreopenedTip,
    typeof oldValue !== 'boolean'
      ? sections[0].entryPoints[0].status !== OnboardingTipsStatus.done
      : undefined,
  );

  useEffect(() => {
    if (
      sections[0].entryPoints[0].status === OnboardingTipsStatus.done &&
      preopenedTip
    ) {
      setPreopenedTip(false);
    }
  }, [preopenedTip, sections, setPreopenedTip]);

  /**
   * Пока работает только для ВА бота, для фб/инсты реишили пока не включать, тк там всего 4 обучалки
   * и нет смысле "пред открывать" первую секцию
   */
  const shouldBePreopened = (index: number) =>
    isWhatsappEnabled ? preopenedTip && index === 0 : false;

  return { shouldBePreopened, setPreopenedTip, preopenedTip };
};

export const useHeightPreopenedTip = (halfOpened: boolean) => {
  const [_, setForceUpdate] = useState<HTMLDivElement | null>();
  const tipRef = useRef<HTMLDivElement | null>(null);
  const boxRef = useRef<HTMLDivElement | null>(null);

  const getHeight = () => {
    const tipTop = tipRef.current?.getBoundingClientRect().top;
    const boxTop = boxRef.current?.getBoundingClientRect().top;

    if (typeof tipTop === 'number' && typeof boxTop === 'number') {
      return tipTop - boxTop + 10;
    }

    return null;
  };

  const height = getHeight();
  const style = height && halfOpened ? { height } : undefined;

  return {
    boxRef,
    tipRef,
    style,
    setForceUpdate,
  };
};
