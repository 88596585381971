import gql from 'graphql-tag';
import { ZAP_FRAGMENT } from '@components/FlowBuilder/views/components/Zapier/query';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment zapierImportContentPluginFragment on IntegrationZapierPlugin {
    ...commonPluginFragment
    config {
      view
      connected_zaps {
        ...zapFragment
      }
      attributes
      preview {
        gallery_cards {
          title
          subtitle
          image_url
          item_url
          buttons {
            title
            url
            phone_number
          }
        }
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
  ${ZAP_FRAGMENT}
`;
