import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment shopifyEventEntryPointFragment on ShopifyEventEntryPoint {
    ...commonPluginFragment
    config {
      events {
        type
        abandoned_cart {
          actions {
            delay_in_minutes
            next_block_id
            counter_id
          }
        }
        order_confirmation {
          actions {
            delay_in_seconds
            next_block_id
            counter_id
          }
        }
        order_status_update {
          actions {
            delay_in_seconds
            next_block_id
            counter_id
          }
        }
        product_visit {
          actions {
            delay_in_minutes
            next_block_id
            counter_id
          }
        }
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
