import React from 'react';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { AttributesQuery_bot_variableSuggest as Attribute } from '@utils/AttributesUtils/@types/AttributesQuery';
import { useOneTimeState } from 'cf-common/src/utils/hooks';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  fromValueHolders,
  useStatefulArray,
} from '@utils/React/useStatefulArray';
import { jsonPluginFragment_config as JSONPluginConfig } from '../@types/jsonPluginFragment';
import {
  urlEncodedParamsFromBody,
  urlEncodedParamsToBody,
} from '../JsonPluginUtils';
import { UrlEncodedParam } from './UrlEncodedParam';
import * as originCss from '../JsonPlugin.css';

interface UrlEncodedDataProps {
  body: JSONPluginConfig['body'];
  botAttributes: Attribute[];
  onBodyChange(nextBody: string): void;
  onBodySave(nextBody: string): void;
}

const EMPTY_URL_ENCODED_PARAMETER: [string, string] = ['', ''];

export const UrlEncodedData: React.FC<UrlEncodedDataProps> = ({
  body,
  botAttributes,
  onBodyChange,
  onBodySave,
}) => {
  const { t } = useSafeTranslation();
  const [isParamAdded, setIsParamAdded] = useOneTimeState(false);
  const {
    valueHolders: urlEncodedParams,
    changeItem,
    deleteItem,
    addItem,
  } = useStatefulArray(urlEncodedParamsFromBody(body), (params) => {
    onBodyChange(urlEncodedParamsToBody(params));
  });

  return (
    <React.Fragment>
      {urlEncodedParams.length > 0 && (
        <div>
          {urlEncodedParams.map(
            ({ id, value: [paramName, paramValue] }, index) => (
              <UrlEncodedParam
                key={id}
                paramName={paramName}
                paramValue={paramValue}
                autoFocus={
                  isParamAdded && urlEncodedParams.length === index + 1
                }
                botAttributes={botAttributes}
                onParamNameChange={(paramNameUpdated) => {
                  changeItem(id, ([, paramValue]) => [
                    paramNameUpdated,
                    paramValue,
                  ]);
                }}
                onParamValueChange={(paramValueUpdated) => {
                  changeItem(id, ([paramName]) => [
                    paramName,
                    paramValueUpdated,
                  ]);
                }}
                onBlur={() =>
                  onBodySave(
                    urlEncodedParamsToBody(fromValueHolders(urlEncodedParams)),
                  )
                }
                onDelete={() => {
                  deleteItem(id, (params) => {
                    onBodySave(urlEncodedParamsToBody(params));
                  });
                }}
              />
            ),
          )}
        </div>
      )}
      <Spacer factor={1} />
      <Button
        intent="secondary"
        size="m"
        icon={<Icon icon="plus" />}
        className={originCss.addButton}
        onClick={() => {
          addItem(EMPTY_URL_ENCODED_PARAMETER, (params) => {
            onBodySave(urlEncodedParamsToBody(params));
            setIsParamAdded(true);
          });
        }}
      >
        {t('JSONPlugin-JSXText--717-add-parameter')}
      </Button>
    </React.Fragment>
  );
};
