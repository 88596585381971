import React from 'react';
import YouTube from 'react-youtube';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { PLAYER_OPTIONS } from '@components/YouTubeFramePlayer/constants';
import css from './VideoDialog.css';

interface VideoDialogProps {
  onDismiss: VoidFunction;
  onComplete: VoidFunction;
  buttonText: string;
  title: string;
  description: string;
  videoId: string;
}

export const VideoDialog: React.FC<VideoDialogProps> = ({
  onDismiss,
  onComplete,
  buttonText,
  title,
  description,
  videoId,
}) => {
  return (
    <DefaultDialog
      className={css.dialog}
      footer={
        <Flex justifyContent="center" fullWidth>
          <Button
            onClick={onComplete}
            data-testid="onbording-tour_video-dialog_start-button"
          >
            {buttonText}
          </Button>
        </Flex>
      }
      onDismiss={onDismiss}
    >
      <Flex flexDirection="column" alignItems="center">
        <Spacer factor={6} />
        <YouTube
          videoId={videoId}
          opts={{
            ...PLAYER_OPTIONS,
            width: '640px',
            height: '360px',
            playerVars: { ...PLAYER_OPTIONS.playerVars, autoplay: 0, fs: 1 },
          }}
        />
        <Spacer factor={6} />
        <Flex flexDirection="column" fullWidth>
          <Type size="24px" weight="semibold" align="left">
            {title}
          </Type>
          <Spacer factor={4} />
          <Type size="15px" align="left">
            {description}
          </Type>
        </Flex>
      </Flex>
    </DefaultDialog>
  );
};
