import React from 'react';
import { Type } from '@ui/Type';
import { BusinessVerificationStatus, OnboardingTipsStatus } from '@globals';
import { HeaderBadge } from './HeaderBadge';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';

export interface HeaderProps {
  verificationStatus: BusinessVerificationStatus | null;
  status: OnboardingTipsStatus;
  title: string;
}

export const Header: React.FC<HeaderProps> = ({
  verificationStatus,
  status,
  title,
}) => {
  return (
    <Flex alignItems="center">
      <Type
        size="18px"
        weight="medium"
        color={
          status === OnboardingTipsStatus.disabled
            ? 'baseTertiary'
            : 'accent1Light'
        }
        decoration={
          status === OnboardingTipsStatus.done ? 'line-through' : undefined
        }
        data-testid="onbording-tour_tips-title"
      >
        {title}
      </Type>
      {verificationStatus && (
        <>
          <Spacer factor={0} horizontalFactor={2} />
          <HeaderBadge verificationStatus={verificationStatus} />
        </>
      )}
    </Flex>
  );
};
