import { PluginType } from '../common/PluginTypes';
import { instagramMediaSharePluginFragment_config } from './@types/instagramMediaSharePluginFragment';

type InstagramMediaSharePluginConfig = instagramMediaSharePluginFragment_config;

export const PLUGIN_TYPE = PluginType.media_share;

export const PLUGIN_DEFAULT_CONFIG: InstagramMediaSharePluginConfig = {
  __typename: 'InstagramMediaSharePluginConfig',
  media_id: null,
  media_url: null,
  media_type: null,
  permalink: null,
};

export const PLUGIN_INPUT_FIELD = 'instagramMediaSharePluginConfig';
