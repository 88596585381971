import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment handoverPluginFragment on HandoverProtocolPlugin {
    ...commonPluginFragment
    config {
      application_id
      timeout_in_seconds
      stop_words
    }
    validation_details {
      fields {
        field
        error
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;

export const BOT_SECONDARY_APPS_QUERY = gql`
  query getBotSecondaryApps($botId: String!) {
    bot(id: $botId) {
      id
      pro {
        status
        manual
      }
      secondaryApps {
        id
        name
      }
      status {
        page
      }
    }
  }
`;
