import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment instagramMediaSharePluginFragment on InstagramMediaSharePlugin {
    ...commonPluginFragment
    config {
      media_id
      media_url
      media_type
      permalink
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
