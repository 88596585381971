import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { VideoDialog } from '../VideoDialog/VideoDialog';

interface WhatsappReengageProps {
  onDismiss: VoidFunction;
  onComplete: VoidFunction;
}

export const WhatsappReengage: React.FC<WhatsappReengageProps> = ({
  onDismiss,
  onComplete,
}) => {
  const { t } = useSafeTranslation();
  return (
    <VideoDialog
      buttonText={t('MiniOnboarding.WhatsappReengage.button')}
      title={t('MiniOnboarding.WhatsappReengage.title')}
      description={t('MiniOnboarding.WhatsappReengage.description')}
      videoId="zKG5JxQ9avA"
      onDismiss={onDismiss}
      onComplete={onComplete}
    />
  );
};
