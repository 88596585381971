export enum PluginType {
  add_tag = 'add_tag',
  remove_tag = 'remove_tag',
  add_attribute = 'add_attribute',
  remove_attribute = 'remove_attribute',
  open_url = 'open_url',
  phone_call = 'phone_call',
  text = 'text',
  typing = 'typing',
  quick_reply = 'quick_reply',
  date_picker = 'date_picker',
  image = 'image',
  gallery = 'gallery',
  go_to_block_plugin = 'go_to_block_plugin',
  add_to_sequence = 'add_to_sequence',
  list = 'list',
  video = 'video',
  audio = 'audio',
  document = 'document',
  comment = 'comment',
  form_v2 = 'form_v2',
  setup_variable_plugin = 'setup_variable_plugin',
  collect_phone = 'collect_phone',
  collect_email = 'collect_email',
  save_user_email = 'save_user_email',
  save_user_phone = 'save_user_phone',
  json_plugin = 'json_plugin',
  google_sheet = 'google_sheet',
  calendly = 'calendly',
  email = 'email',
  event_trigger = 'event_trigger',
  kommo_plugin = 'integration/kommo_plugin',
  integration_zapier_plugin = 'integration/zapier_plugin',
  autoposting_zapier_plugin = 'autoposting/zapier_plugin',
  remove_from_sequence = 'remove_from_sequence',
  communication = 'communication',
  handover = 'handover',
  search_google_plugin = 'search/google_plugin',
  search_bing_plugin = 'search/bing_plugin',
  search_swiftype_plugin = 'search/swiftype_plugin',
  search_rss_plugin = 'search/rss_plugin',
  subscribe_user_plugin = 'subscribe_user_plugin',
  subscriptions_manager_plugin = 'subscriptions_manager_plugin',
  subscription_broadcasting_plugin = 'subscription_broadcasting_plugin',
  facebook_analytics = 'facebook_analytics',
  facebook_ads = 'facebook_ads',
  abt = 'abt',
  abandoned_cart = 'abandoned_cart',
  delay = 'delay',
  split_traffic = 'split_traffic',
  otn_request = 'otn_request',
  custom_ai = 'custom_ai',
  notification_messages_request = 'notification_messages_request',
  nTNDaily = 'shortcut/nTNDaily',
  nTNWeekly = 'shortcut/nTNWeekly',
  nTNMonthly = 'shortcut/nTNMonthly',
  inbound_links_entry_point = 'entry_point/inbound_links',
  fb_page_entry_point = 'entry_point/fb_page',
  send_to_messenger_entry_point = 'entry_point/send_to_messenger',
  ref_link_entry_point = 'entry_point/ref_link',
  instagram_bot_link_entry_point = 'entry_point/instagram_bot_link',
  customer_chat_entry_point = 'entry_point/customer_chat',
  persistent_menu_entry_point = 'entry_point/persistent_menu',
  instagram_persistent_menu_entry_point = 'entry_point/instagram_persistent_menu',
  comments_autoreply_entry_point = 'entry_point/comments_autoreply',
  comments_like = 'comments_like',
  facebook_shops_entry_point = 'entry_point/facebook_shops',
  facebook_shops_product_list = 'facebook_shops_products',
  private_reply_entry_point = 'private_reply',
  public_reply_entry_point = 'public_reply',
  flowbuilder_livechat = 'flow_builder/livechat',
  date_and_time_shortcut = 'shortcut/date_and_time',
  datepicker_shortcut = 'shortcut/datepicker', // for adding several plugins for datepicker (datepicker_text_shortcut and datepicker_quick_reply_shortcut)
  datepicker_text_shortcut = 'text/datepicker',
  text_quick_replies_shortcut = 'shortcut/text_quick_replies',
  instagram_text_quick_replies_shortcut = 'shortcut/instagram_text_quick_replies',
  datepicker_quick_reply_shortcut = 'quick_reply/datepicker',
  ads_manager_ctm_entry_point = 'entry_point/ads_manager_ctm',
  ads_manager_ctm_json_entry_point = 'entry_point/ads_manager_ctm_json',
  ads_manager_sm_entry_point = 'entry_point/ads_manager_sm',
  external_integration_entry_point = 'entry_point/external_integration',
  instagram_ads_manager_ctm_entry_point = 'entry_point/instagram_ads_manager_ctm',
  popup_entry_point = 'entry_point/popup',
  shopify_back_in_stock = 'entry_point/shopify_back_in_stock',
  product_list_opt_in_confirmation_shortcut = 'shortcut/product_list_opt_in_confirmation',
  product_list_back_in_stock_message_shortcut = 'shortcut/product_list_back_in_stock_message',
  shopify_event_entry_point = 'entry_point/shopify_event',
  text_reminder_shortcut = 'shortcut/text_reminder',
  gallery_reminder_shortcut = 'shortcut/gallery_reminder',
  instagram_direct_entry_point = 'entry_point/instagram_direct',
  media_share = 'media_share',
  instagram_comments_autoreply_entry_point = 'entry_point/instagram_comments_autoreply',
  instagram_story_mention_entry_point = 'entry_point/instagram_story_mention',
  instagram_story_reply_entry_point = 'entry_point/instagram_story_reply',
  whatsapp_direct_entry_point = 'entry_point/whatsapp_direct',
  whatsapp_bot_link = 'entry_point/whatsapp_bot_link',
  order_receipt = 'order_receipt',
  order_confirmation_shortcut = 'shortcut/order_confirmation',
  order_status_update_shortcut = 'shortcut/order_status_update',
  product_list = 'product_list',
  product_list_shopify_order_shortcut = 'shortcut/product_list_shopify_order',
  product_visit_shortcut = 'shortcut/product_visit',
  shopify_customer_chat_entry_point = 'entry_point/customer_chat/shopify',
  product_list_product_visit_shortcut = 'shortcut/product_list_product_visit',
  save_user_phone_shortcut = 'shortcut/save_user_phone',
  save_user_email_shortcut = 'shortcut/save_user_email_shortcut',
  save_user_input_shortcut = 'shortcut/save_user_input_shortcut',
  collect_answers_shortcut = 'shortcut/collect_answers',
  collect_emails_shortcut = 'shortcut/collect_emails',
  collect_phones_shortcut = 'shortcut/collect_phones',
  collect_numbers_shortcut = 'shortcut/collect_numbers',
  date_picker_shortcut = 'shortcut/date_picker',
  comments_reply_shortcut = 'shortcut/comments_reply',
  instagram_comments_reply_shortcut = 'shortcut/instagram_comments_reply',
  ad_comments_autoreply_entry_point = 'entry_point/ad_comments_autoreply',
  open_ai = 'open_ai',
  whatsapp_template = 'whatsapp_template',
  whatsapp_list = 'whatsapp_list',
  whatsapp_widget_entry_point = 'entry_point/whatsapp_widget',
  whatsapp_location = 'whatsapp_location',
  conversation_assign_admin = 'conversation_assign_admin',
  whatsapp_popup_entry_point = 'entry_point/whatsapp_popup',
}

export const isShortcut = (pluginType: PluginType) => {
  return pluginType.indexOf('shortcut/') === 0;
};

/**
 * List of deprecated entry point we want to delete soon
 */
export const HIDDEN_DEPRECATED_ENTRY_POINTS = [
  PluginType.shopify_back_in_stock,
  PluginType.shopify_event_entry_point,
  PluginType.shopify_customer_chat_entry_point,
  PluginType.popup_entry_point,
];
