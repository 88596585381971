import i18next from 'i18next';
import { clamp } from 'ramda';
import Maybe from 'graphql/tsutils/Maybe';
import { Item } from '@ui/SimpleCombobox';
import { LightBotsQuery_lightBots } from '@utils/Data/Bot/@types/LightBotsQuery';
import { LightWorkspaceBotsQuery_lightWorkspaceBots } from '@utils/Data/Bot/@types/LightWorkspaceBotsQuery';
import { normalizedIncludes } from '@utils/normalizedIncludes';
import {
  MIN_BOTS_AMOUNT,
  MOBILE_ITEM_HEIGHT,
  ITEM_HEIGHT,
  SEARCH_INPUT_HEIGHT,
  NAVIGATION_HEIGHT,
  ALL_BOTS_BUTTON_HEIGHT,
} from './consts';
import {
  ComboboxBotsHeaderType,
  ComboboxHeaderType,
  ComboboxItemBotType,
  ComboboxItemType,
  ComboboxSpacerType,
  ComboboxType,
} from './types';

export interface GetDeviceRelatedProps {
  mobile: boolean;
  showAllBotsButton: boolean;
  showSearchField: boolean;
  pageHeight: number;
  itemsLength: number;
}

export const getDeviceRelatedProps = ({
  mobile,
  showAllBotsButton,
  showSearchField,
  pageHeight,
  itemsLength,
}: GetDeviceRelatedProps) => {
  const otherItemsHeight =
    (showSearchField ? SEARCH_INPUT_HEIGHT : 0) +
    (showAllBotsButton ? ALL_BOTS_BUTTON_HEIGHT : 0);

  if (mobile) {
    return {
      fixedSizeListHeight: pageHeight - otherItemsHeight - NAVIGATION_HEIGHT,
      autoSizerHeight: `calc(100% - ${otherItemsHeight}px)`,
      itemSize: MOBILE_ITEM_HEIGHT,
    };
  }

  const menuAvailableHeight = pageHeight - otherItemsHeight;
  const visibleItemsCount = Math.ceil(menuAvailableHeight / ITEM_HEIGHT);
  const allItemsHeightRate = (itemsLength * ITEM_HEIGHT) / pageHeight;

  let menuHeight =
    allItemsHeightRate > 0.7
      ? pageHeight - otherItemsHeight
      : clamp(1, visibleItemsCount, itemsLength - 1) * ITEM_HEIGHT;

  menuHeight =
    itemsLength < MIN_BOTS_AMOUNT
      ? ITEM_HEIGHT * MIN_BOTS_AMOUNT + otherItemsHeight
      : menuHeight;

  return {
    fixedSizeListHeight: menuHeight,
    autoSizerHeight: `${menuHeight}px`,
    itemSize: ITEM_HEIGHT,
  };
};

export const buildRegularComboboxItems = (
  lightBots: Maybe<LightBotsQuery_lightBots[]>,
): ComboboxItemType[] => {
  return (lightBots || []).map(
    (bot) =>
      ({
        type: ComboboxType.item,
        id: bot.id,
        title: bot.title,
        bot,
      } as ComboboxItemBotType),
  );
};

export const buildWorkspacesComboboxItems = (
  bots: Maybe<LightWorkspaceBotsQuery_lightWorkspaceBots>,
): ComboboxItemType[] => {
  const workspaceSections: ComboboxItemType[] | undefined =
    bots?.workspaces.flatMap(({ bots, workspace }) => {
      const header: ComboboxHeaderType = {
        type: ComboboxType.workspaceHeader,
        id: workspace.id,
        title: workspace.name,
        status: workspace.status,
      };

      const botsItems: Array<ComboboxItemBotType> = bots.map((bot) => ({
        type: ComboboxType.item,
        id: bot.id,
        title: bot.title,
        bot: { ...bot, workspace_id: workspace.id },
      }));

      const spacer: ComboboxSpacerType = {
        type: ComboboxType.spacer,
        id: '__spacer',
        // title нужен для комбобоса
        title: '',
      };

      return [header, ...botsItems, spacer];
    });

  const header: ComboboxBotsHeaderType = {
    type: ComboboxType.botsHeader,
    id: '__id',
    title: i18next.t('common.BotHeader.bots'),
  };

  const botsItems: Array<ComboboxItemBotType> | undefined =
    bots?.botsWithoutWorkspace.map((bot) => ({
      type: ComboboxType.item,
      id: bot.id,
      title: bot.title,
      bot: { ...bot, workspace_id: null },
    }));

  const botsSection: ComboboxItemType[] = [header, ...(botsItems || [])];

  return [...(workspaceSections || []), ...botsSection];
};

export const filterBotsToShow = (
  itemToString: (item: Item<ComboboxItemType>) => string,
  items: Item<ComboboxItemType>[],
  selectedItem: Item<ComboboxItemType>,
  inputValue: string | null,
) => {
  if (inputValue === itemToString(selectedItem)) {
    // display all items
    return items;
  }
  return items.filter(
    (item) =>
      (item.type !== ComboboxType.item ||
        normalizedIncludes(
          itemToString(item).toLowerCase(),
          (inputValue || '').toLowerCase(),
        )) &&
      item !== selectedItem,
  );
};

export const filterWorkspaceBotsToShow = (
  itemToString: (item: Item<ComboboxItemType>) => string,
  items: Item<ComboboxItemType>[],
  selectedItem: Item<ComboboxItemType>,
  inputValue: string | null,
) => {
  const botItems = inputValue
    ? items.filter(
        (item) =>
          ![
            ComboboxType.botsHeader,
            ComboboxType.workspaceHeader,
            ComboboxType.spacer,
          ].includes(item.type),
      )
    : items;

  return filterBotsToShow(itemToString, botItems, selectedItem, inputValue);
};
