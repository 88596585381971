import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';

import { sendEvent } from '@utils/Analytics';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';

import { ReactComponent as MorePlugins } from '@ui/_deprecated/Icon/icons/icon_more.svg';

import { getPluginButtonConfig } from './AddPluginButtonConfig';
import { AddPluginButton, AddPluginButtonMode } from './AddPluginButton';

import { PluginType } from '../common/PluginTypes';
import {
  AddPluginMenuProps,
  AddPluginProps,
  AddPluginTabType,
  PluginArray,
} from './types';

import * as css from './AddPlugin.css';

interface PluginButtonProps extends AddPluginProps {
  pluginType: PluginType;
  onHidePopupMenu?: () => void;
  pluginList?: boolean;
}

const AddPluginButtonWrapper: React.FC<PluginButtonProps> = (props) => {
  const { t } = useSafeTranslation();
  const buttonConfig = getPluginButtonConfig({
    pluginType: props.pluginType,
    botId: props.botId,
    t,
  });

  return buttonConfig ? (
    <>
      <AddPluginButton
        onClick={() => {
          if (props.onHidePopupMenu) {
            props.onHidePopupMenu();
          }

          sendEvent({
            category: 'chatfuel plugins panel',
            action: 'click',
            label: props.pluginType,
            propertyBag: {
              pluginType: props.pluginType,
            },
          });

          props.onAddPlugin(props.pluginType, props.index);
        }}
        mode={AddPluginButtonMode.menu}
        renderIcon={() => buttonConfig.img}
        className={buttonConfig.testClass}
      >
        {buttonConfig.title}
      </AddPluginButton>
      <span className={css.separator} />
    </>
  ) : null;
};

export const AddPluginMenu: React.FC<AddPluginMenuProps> = (props) => {
  const { t } = useSafeTranslation();
  const more = {
    title: t('modernComponents.plugins.AddPlugin.moreTitle'),
    img: <MorePlugins />,
    showPluginList: props.onShowPluginList,
    testClass: 'test-open-plugin-browser-button',
  };
  const { isAutomateEnabled } = useAutomateEnabled();

  const plugins: PluginArray = {
    [AddPluginTabType.STRUCTURE]: [
      PluginType.text,
      PluginType.typing,
      PluginType.image,
      PluginType.gallery,
      PluginType.quick_reply,
      PluginType.go_to_block_plugin,
    ],
    [AddPluginTabType.BROADCAST]: [
      PluginType.text,
      PluginType.typing,
      PluginType.image,
      PluginType.gallery,
      PluginType.quick_reply,
    ].concat(isAutomateEnabled ? PluginType.add_to_sequence : []),
    [AddPluginTabType.AUTOPOST]: [
      PluginType.text,
      PluginType.typing,
      PluginType.image,
      PluginType.gallery,
      PluginType.quick_reply,
    ].concat(isAutomateEnabled ? PluginType.add_to_sequence : []),
  };

  return (
    <div
      className={cn(css.container, {
        [css.containerNarrow]: !isAutomateEnabled,
      })}
    >
      {plugins[props.type].map((pluginType) => (
        <AddPluginButtonWrapper
          key={pluginType}
          botId={props.botId}
          onAddPlugin={props.onAddPlugin}
          onHidePopupMenu={props.onHidePopupMenu}
          pluginType={pluginType}
          index={props.index}
          type={props.type}
          mode={props.mode}
        />
      ))}
      <AddPluginButton
        onClick={() => {
          if (props.onHidePopupMenu) {
            props.onHidePopupMenu();
          }

          sendEvent({
            category: 'chatfuel plugins panel',
            action: 'click',
            label: 'more',
            propertyBag: {
              pluginType: 'more',
            },
          });

          more.showPluginList();
        }}
        mode={AddPluginButtonMode.menu}
        renderIcon={() => more.img}
        className={more.testClass}
      >
        {more.title}
      </AddPluginButton>
    </div>
  );
};
