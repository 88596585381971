import { mergeDeepWith } from 'ramda';
import { QuickReplyType } from '@globals';
import { PluginType } from '../common/PluginTypes';
import { datePickerPluginFragment_config } from './@types/datePickerPluginFragment';

export type DatePickerPluginConfig = datePickerPluginFragment_config;

export const PLUGIN_TYPE = PluginType.date_picker;

export const PLUGIN_DEFAULT_CONFIG: DatePickerPluginConfig = {
  __typename: 'DatePickerPluginConfig',
  type: QuickReplyType.date,
  allow_skip: false,
  property: null,
  date_button: {
    __typename: 'ButtonConfig',
    title: '',
    block_ids: [],
  },
  skip_button: {
    __typename: 'ButtonConfig',
    title: '',
    block_ids: [],
  },
  qr_card_id: null,
};

export const getDatePickerConfig = mergeDeepWith(
  (_, b) => b,
  PLUGIN_DEFAULT_CONFIG,
);

export const PLUGIN_INPUT_FIELD = 'datePickerPluginConfig';
