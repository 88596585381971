import { Rect } from '@components/Onboarding/types';
import { useEffect, useState } from 'react';

type Payload = {
  calloutRef: React.RefObject<HTMLDivElement>;
  text: React.ReactNode;
  button?: React.ReactNode | null;
};
/**
 * расчитанно на то callout имеет фиксированную ширину, и абсолютное позиционирование.
 * благодаря на его размер влияет только текст и кнопка внутри.
 */
export const useCalloutSize = ({ calloutRef, text, button }: Payload) => {
  const [rect, setRect] = useState<Rect | null>(null);

  useEffect(() => {
    const calloutRects = calloutRef.current?.getBoundingClientRect();
    setRect(calloutRects ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calloutRef, text, button]);

  return rect;
};
