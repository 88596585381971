import React, { CSSProperties } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { getComponentWithRefAndName } from '@utils/withRef';
import { sendEvent } from '@utils/Analytics';
import { ReactComponent as LogoSvg } from './images/logo.svg';
import { ReactComponent as LogoFullSvg } from './images/logo_full.svg';
import * as css from './ChatfuelLogo.css';
import { ButtonUnstyled } from '@ui/Button';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';

interface ChatfuelLogoProps extends TestLocator {
  full: boolean;
  buttonWrapper: boolean;
  className?: string;
  style?: CSSProperties;
  fullLogoClassName?: string;
}

export const ChatfuelLogo = getComponentWithRefAndName<
  HTMLAnchorElement,
  ChatfuelLogoProps
>(
  'ChatfuelLogo',
  (
    { buttonWrapper, className, fullLogoClassName, style, full, ...rest },
    ref,
  ) => {
    const { isLiveChatWebView } = useIsLiveChatWebView();
    const Wrapper = buttonWrapper ? ButtonUnstyled : Link;

    return (
      // @ts-expect-error
      <Wrapper
        to="/bots"
        className={cn(css.logo, className)}
        style={style}
        data-testid={rest['data-testid']}
        ref={ref}
        onClick={(
          event: React.BaseSyntheticEvent<unknown, unknown, unknown>,
        ) => {
          if (isLiveChatWebView) {
            event.preventDefault();
          }

          sendEvent({
            category: 'dashboard navigation panel',
            action: 'chatfuel logo click',
            propertyBag: { prevented: buttonWrapper },
          });
        }}
      >
        {full ? <LogoFullSvg className={fullLogoClassName} /> : <LogoSvg />}
      </Wrapper>
    );
  },
);
