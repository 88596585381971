import { PluginType } from '@components/Plugins/common/PluginTypes';
import { splitTrafficPluginFragment_config } from './@types/splitTrafficPluginFragment';

export type SplitTrafficPluginConfig = splitTrafficPluginFragment_config;

export const PLUGIN_TYPE = PluginType.split_traffic;

export const PLUGIN_DEFAULT_CONFIG: SplitTrafficPluginConfig = {
  __typename: 'SplitTrafficPluginConfig',
  random: false,
  variants: [
    {
      __typename: 'SplitTrafficPluginVariant',
      percent: 50,
      block_id: null,
    },
    {
      __typename: 'SplitTrafficPluginVariant',
      percent: 50,
      block_id: null,
    },
  ],
};

export const PLUGIN_INPUT_FIELD = 'splitTrafficPluginConfig';
