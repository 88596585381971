import { useCurrentBotId } from '@utils/Routing';
import { useMutation } from '@apollo/react-hooks';
import { SETUP_INTENT_MUTATION } from '../queries';

import {
  SetupIntentMutation,
  SetupIntentMutationVariables,
} from '../@types/SetupIntentMutation';

export const useIntentSetup = () => {
  const botId = useCurrentBotId();

  const [invokeSetupIntent, { data }] = useMutation<
    SetupIntentMutation,
    SetupIntentMutationVariables
  >(SETUP_INTENT_MUTATION, {
    variables: {
      botId: botId || '',
    },
  });

  const setupIntent = async () => {
    const { data } = await invokeSetupIntent();
    return {
      clientSecret: data?.setupIntent?.client_secret,
    };
  };

  return {
    setupIntent,
    clientSecret: data?.setupIntent?.client_secret,
  };
};
