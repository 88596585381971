import React, { useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import { Editor } from 'slate-react';
import debounce from 'lodash-es/debounce';
import { Input } from '@ui/Input';
import {
  Attributes,
  EditorModeEnum,
  ITextWithAttributesEditorProps,
  TextWithAttributesEditor,
} from '@ui/TextWithAttributesEditor';
import { insertText } from '@utils/Slite/insertText';
import { deserialize, serialize } from '@ui/BubbleEditor';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import * as css from './EditableField.css';

export interface EditableFieldProps
  extends Pick<ITextWithAttributesEditorProps, 'onManageAttributesClick'> {
  attributes: Attributes;
  placeholder: string;
  disabled: boolean;
  text?: string;
  onChange(value: string): void;
  maxTextLength?: number;
  textEditorClassName?: string;
  textEditorInactiveClassName?: string;
  controlDecoratorInactiveClassName?: string;
  hidePlaceholderWhenFocused?: boolean;
  'data-testid'?: string;
}

const MAX_TEXT_LENGTH = 638;

export const EditableField: React.FC<EditableFieldProps> = ({
  attributes,
  placeholder,
  text = '',
  disabled,
  onChange,
  maxTextLength = MAX_TEXT_LENGTH,
  textEditorClassName,
  textEditorInactiveClassName = css.textEditorInactive,
  controlDecoratorInactiveClassName = css.controlDecoratorInactive,
  hidePlaceholderWhenFocused,
  onManageAttributesClick,
  'data-testid': dataTestId,
}) => {
  const [localTextState, setLocalTextState] = useState(text);
  const editorContainerRef = useRef<HTMLDivElement>(null);

  const [isFocused, setFocused] = useState(false);

  const onChangeDebounced = useCallback(debounce(onChange, 200), [onChange]);

  return (
    <Input
      containerClassName={cn({
        [controlDecoratorInactiveClassName]: !isFocused,
      })}
      render={() => (
        <TextOutsideControls
          initialShow={false}
          onInsertRequest={(val, el) => {
            insertText(val, el as Editor);
          }}
          currentTextLimit={Math.max(0, maxTextLength - localTextState.length)}
          shouldShowOutsideControls={{
            emoji: true,
            attributes: true,
            symbolsLimit: true,
          }}
        >
          {({ ref, getInputProps }) => (
            <TextWithAttributesEditor
              data-testid={dataTestId}
              className={cn(css.textEditor, textEditorClassName, {
                [textEditorInactiveClassName]: !isFocused,
              })}
              placeholderContainerClassName={css.placeholderContainerClassName}
              editorRef={ref}
              editorContainerRef={editorContainerRef}
              defaultValue={deserialize(text ?? '')}
              maxLength={maxTextLength}
              onBlur={(event) => {
                setFocused(false);
                getInputProps().onBlur(event);
              }}
              onFocus={(event: any) => {
                setFocused(true);
                getInputProps().onFocus(event);
              }}
              disabled={disabled}
              onManageAttributesClick={onManageAttributesClick}
              onChange={(v) => {
                const value = serialize(v);
                setLocalTextState(value);
                onChangeDebounced(value);
              }}
              placeholder={
                hidePlaceholderWhenFocused && isFocused ? '' : placeholder
              }
              multiLinePlaceholder
              attributes={attributes}
              fixedEditorMode={EditorModeEnum.edit}
              shouldMoveCursorToEndOnFocus
              hasManageAttributes
            />
          )}
        </TextOutsideControls>
      )}
    />
  );
};
