import React, { useState } from 'react';
import cn from 'classnames';
import { ReactComponent as HelpIcon } from '../../../modern-ui/_deprecated/Icon/icons/question-mark_currentColor.svg';
import { TooltipPure } from '@ui/Tooltip2';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { sendEvent } from '@utils/Analytics';
import * as css from './PluginHeader.css';

interface IPluginHeader extends React.HTMLProps<HTMLDivElement> {
  title: string;
  icon: React.ReactNode;
  pluginType: string;
  helpUrl?: string;
  showHelpTooltip?: boolean;
}

export const sendPluginHelpOpenGaEvent = (pluginType: string) => {
  sendEvent({
    category: 'plugin',
    action: 'help open',
    label: pluginType,
    propertyBag: {
      pluginType,
    },
  });
};

export const PluginHeader: React.FC<IPluginHeader> = ({
  className,
  title,
  icon,
  helpUrl,
  showHelpTooltip,
  pluginType,
  ...props
}) => {
  const [isHelpButtonClicked, setIsHelpButtonClicked] = useState(false);

  return (
    <div
      className={cn(css.PluginHeader, className)}
      {...props}
      data-testid="plugin-title"
    >
      <div className={css.iconBox}>{icon}</div>
      <div className={css.title}>{title}</div>
      {helpUrl && !isWhiteLabelDomain() && (
        <TooltipPure
          content={window.i18next.t(
            'PluginHeader-string--150-need-help-read-our-docs',
          )}
          type="small"
          show={showHelpTooltip && !isHelpButtonClicked}
        >
          {(ref) => (
            <a
              className={css.helpLink}
              href={helpUrl}
              target="_blank"
              rel="noopener noreferrer"
              ref={ref}
              onClick={() => {
                setIsHelpButtonClicked(true);
                sendPluginHelpOpenGaEvent(pluginType);
              }}
            >
              <HelpIcon />
            </a>
          )}
        </TooltipPure>
      )}
    </div>
  );
};
