import { flowBuilderLiveChatPluginFragment_config as LiveChatPluginConfig } from './@types/flowBuilderLiveChatPluginFragment';
import { PluginType } from '../common/PluginTypes';

export const PLUGIN_TYPE = PluginType.flowbuilder_livechat;

export const PLUGIN_DEFAULT_CONFIG: LiveChatPluginConfig = {
  __typename: 'FlowBuilderLiveChatPluginConfig',
  dynamic_menu_title: null,
  timeout_config: {
    __typename: 'TimeoutConfig',
    time_period: null,
    time_value: null,
  },
  button_text: null,
  image_url: null,
  send_admin_notifications: null,
  stop_message: null,
  subtitle: null,
  title: null,
};

export const PLUGIN_INPUT_FIELD = 'flowBuilderLiveChatPluginConfig';
