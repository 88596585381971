import gql from 'graphql-tag';
import { PLUGIN_FRAGMENT as ADD_ATTRIBUTE_PLUGIN_FRAGMENT } from '../AddAttributePlugin/AddAttributePluginQuery';
import { PLUGIN_FRAGMENT as REMOVE_ATTRIBUTE_PLUGIN_FRAGMENT } from '../RemoveAttributePlugin/RemoveAttributePluginQuery';
import { PLUGIN_FRAGMENT as SETUP_ATTRIBUTE_PLUGIN_FRAGMENT } from '../SetupAttributePlugin/SetupAttributePluginQuery';
import { PLUGIN_FRAGMENT as JSON_PLUGIN_FRAGMENT } from '../JsonPlugin/JsonPluginQuery';
import { PLUGIN_FRAGMENT as GOTO_BLOCK_PLUGIN_FRAGMENT } from '../GoToBlockPlugin/GoToBlockPluginQuery';
import { PLUGIN_FRAGMENT as FACEBOOK_PLUGIN_FRAGMENT } from '../FacebookAnalyticsPlugin/FacebookAnalyticsPluginConst';
import {
  DEFAULT_COMMON_TYPE_PLUGIN_FRAGMENT,
  VALIDATION_FRAGMENT,
} from './PluginQuery';
import { PLUGIN_FRAGMENT as HANDOVER_PLUGIN_FRAGMENT } from '../HandoverPlugin/HandoverPluginQuery';
import { PLUGIN_FRAGMENT as ABT_PLUGIN_FRAGMENT } from '../AbtPlugin/AbtPluginConst';
import { PLUGIN_FRAGMENT as ABANDONED_CART_PLUGIN_FRAGMENT } from '../AbandonedCartPlugin/AbandonedCartPluginConst';
import { PLUGIN_FRAGMENT as EMAIL_PLUGIN_FRAGMENT } from '../EmailPlugin/EmailPluginQuery';
import { PLUGIN_FRAGMENT as FLOWBUILDER_LIVECHAT_PLUGIN_FRAGMENT } from '../FlowBuilderLiveChatPlugin/FlowBuilderLiveChatPluginQuery';
import { PLUGIN_FRAGMENT as COMMENTS_AUTOREPLY_ENTRY_POINT_FRAGMENT } from '../CommentsAutoreplyEntryPoint/CommentsAutoreplyEntryPointQuery';
import { PLUGIN_FRAGMENT as AD_COMMENTS_AUTOREPLY_ENTRY_POINT_FRAGMENT } from '../AdCommentsAutoreplyEntryPoint/AdCommenntsAutoreplyEntryPointQuery';
import { PLUGIN_FRAGMENT as INBOUND_LINKS_ENTRY_POINT_FRAGMENT } from '../InboundLinksEntryPoint/InboundLinksEntryPointQuery';
import { PLUGIN_FRAGMENT as CUSTOMER_CHAT_ENTRY_POINT_FRAGMENT } from '../CustomerChatEntryPoint/CustomerChatEntryPointQuery';
import { PLUGIN_FRAGMENT as SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_FRAGMENT } from '../ShopifyCustomerChatEntryPoint/ShopifyCustomerChatEntryPointQuery';
import { PLUGIN_FRAGMENT as PERSISTENT_MENU_ENTRY_POINT_FRAGMENT } from '../PersistentMenuEntryPoint/PersistentMenuEntryPointQuery';
import { PLUGIN_FRAGMENT as PRIVATE_REPLY_ENTRY_POINT_FRAGMENT } from '../PrivateReplyEntryPoint/PrivateReplyEntryPointQuery';
import { PLUGIN_FRAGMENT as PUBLIC_REPLY_ENTRY_POINT_FRAGMENT } from '../PublicReplyEntryPoint/PublicReplyEntryPointQuery';
import { PLUGIN_FRAGMENT as SEND_TO_MESSENGER_ENTRY_POINT_FRAGMENT } from '../SendToMessengerEntryPoint/SendToMessengerEntryPointQuery';
import { PLUGIN_FRAGMENT as FACEBOOK_SHOPS_ENTRY_POINT_FRAGMENT } from '../FacebookShopsEntryPoint/FacebookShopsEntryPointQuery';
import { PLUGIN_FRAGMENT as ZAPIER_EVENT_TRIGGER_FRAGMENT } from '../ZapierEventTriggerPlugin/ZapierEventTriggerPluginQuery';
import {
  PLUGIN_FRAGMENT as EXTERNAL_INTEGRATION_ENTRY_POINT_FRAGMENT,
  PLUGIN_FRAGMENT as EXTERNAL_INTEGRATION_TRIGGER_PLUGIN_FRAGMENT,
} from '../ExternalIntegrationEntryPoint/ExternalIntegrationEntryPointQuery';
import {
  PLUGIN_FRAGMENT as ZAPIER_IMPORT_CONTENT_FRAGMENT,
  PLUGIN_FRAGMENT as ZAPIER_IMPORT_CONTENT_PLUGIN_FRAGMENT,
} from '../ZapierImportContentPlugin/ZapierImportContentPluginQuery';
import { PLUGIN_FRAGMENT as GOOGLE_SHEET_PLUGIN_FRAGMENT } from '../GoogleSheetPlugin/GoogleSheetPluginQuery';
import { PLUGIN_FRAGMENT as FACEBOOK_ADS_ENTRY_POINT_FRAGMENT } from '../FacebookAdsEntryPoint/FacebookAdsEntryPointQuery';
import {
  PLUGIN_FRAGMENT as INSTAGRAM_ENTRY_POINT_FRAGMENT,
  PLUGIN_FRAGMENT as INSTAGRAM_ADS_ENTRY_POINT_FRAGMENT,
} from '../InstagramAdsEntryPoint/InstagramAdsEntryPointQuery';
import { PLUGIN_FRAGMENT as POPUP_ENTRY_POINT_FRAGMENT } from '../PopupEntryPoint/PopupEntryPointQuery';
import { PLUGIN_FRAGMENT as SHOPIFY_EVENT_ENTRY_POINT_FRAGMENT } from '../ShopifyEventEntryPoint/ShopifyEventEntryPointQuery';
import { PLUGIN_FRAGMENT as SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_FRAGMENT } from '../ShopifyBackInStockEntryPoint/ShopifyBackInStockEntryPointQuery';
import { PLUGIN_FRAGMENT as INSTAGRAM_MEDIA_SHARE_PLUGIN_FRAGMENT } from '../InstagramMediaSharePlugin/InstagramMediaSharePluginQuery';
import { PLUGIN_FRAGMENT as INSTAGRAM_DIRECT_ENTRY_POINT_FRAGMENT } from '../InstagramDirectEntryPoint/InstagramDirectEntryPointQuery';
import { PLUGIN_FRAGMENT as INSTAGRAM_COMMENTS_AUTOREPLY_ENTRY_POINT_FRAGMENT } from '../InstagramCommentsAutoreplyEntryPoint/InstagramCommentsAutoreplyEntryPointQuery';
import { PLUGIN_FRAGMENT as INSTAGRAM_STORY_MENTION_ENTRY_POINT_FRAGMENT } from '../InstagramStoryMentionEntryPoint/InstagramStoryMentionEntryPointQuery';
import { PLUGIN_FRAGMENT as INSTAGRAM_STORY_REPLY_ENTRY_POINT_FRAGMENT } from '../InstagramStoryReplyEntryPoint/InstagramStoryReplyEntryPointQuery';
import { PLUGIN_FRAGMENT as INSTAGRAM_BOT_LINK_ENTRY_POINT_FRAGMENT } from '../InstagramLinkEntryPoint/InstagramLinkEntryPointQuery';
import { PLUGIN_FRAGMENT as ORDER_RECEIPT_PLUGIN_FRAGMENT } from '../OrderReceiptPlugin/OrderReceiptPluginQuery';
import { PLUGIN_FRAGMENT as CALENDLY_PLUGIN_FRAGMENT } from '../CalendlyIntegration/CalendlyIntegrationQuery';
import { PLUGIN_FRAGMENT as AUDIO_PLUGIN_FRAGMENT } from '../AudioPlugin/AudioPluginQuery';
import { PLUGIN_FRAGMENT as QUICK_REPLY_PLUGIN_FRAGMENT } from '../QuickReplyPlugin/QuickReplyPluginQuery';
import { PLUGIN_FRAGMENT as DATE_PICKER_PLUGIN_FRAGMENT } from '../DatePickerPlugin/DatePickerPluginQuery';
import { PLUGIN_FRAGMENT as PRODUCT_LIST_PLUGIN_FRAGMENT } from '../ProductListPlugin/ProductListPluginQuery';
import { PLUGIN_FRAGMENT as BOT_LINK_PLUGIN_FRAGMENT } from '../BotLinkEntryPoint/BotLinkEntryPointQuery';
import { PLUGIN_FRAGMENT as TYPING_PLUGIN_FRAGMENT } from '../../FlowBuilder/modules/TypingPluginModule/TypingPluginQuery';
import { PLUGIN_FRAGMENT as ASSIGN_ADMIN_PLUGIN_FRAGMENT } from '../../FlowBuilder/modules/AssignAdminPluginModule/AssignAdminPluginQuery';
import { PLUGIN_FRAGMENT as N_TIME_NOTIFICATION_PLUGIN_FRAGMENT } from '../NTimeNotificationPlugin/NTimeNotificationPluginQuery';
import { PLUGIN_FRAGMENT as WHATSAPP_BOT_LINK_ENTRY_POINT } from '../WhatsappBotLinkEntryPoint/WhatsappBotLinkEntryPointQuery';
import { PLUGIN_FRAGMENT as COMMENTS_LIKE_PLUGIN_FRAGMENT } from '../CommentsLikePlugin/CommentsLikePluginQuery';
import { PLUGIN_FRAGMENT as OPEN_AI_PLUGIN_FRAGMENT } from '../OpenAiPlugin/OpenAiPluginQuery';
import { PLUGIN_FRAGMENT as CUSTOM_AI_PLUGIN_FRAGMENT } from '../CustomAiPlugin/CustomAiPluginQuery';
import { PLUGIN_FRAGMENT as WHATSAPP_TEMPLATE_PLUGIN_FRAGMENT } from '../WhatsappTemplatePlugin/WhatsappTemplatePluginQuery';
import { PLUGIN_FRAGMENT as SPLIT_TRAFFIC_PLUGIN_FRAGMENT } from '../SplitTrafficPlugin/SplitTrafficQuery';
import { PLUGIN_FRAGMENT as WHATSAPP_LIST_PLUGIN_FRAGMENT } from '../WhatsappListPlugin/WhatsappListPluginQuery';
import { PLUGIN_FRAGMENT as WHATSAPP_DIRECT_ENTRY_POING_FRAGMENT } from '../WhatsappDirectEntryPoint/WhatsappDirectEntryPointQuery';
import { PLUGIN_FRAGMENT as WHATSAPP_WIDGET_ENTRY_POINT_FRAGMENT } from '../WhatsappWidgetEntryPoint/WhatsappWidgetEntryPointQuery';
import { PLUGIN_FRAGMENT as WHATSAPP_POPUP_ENTRY_POINT_FRAGMENT } from '../WhatsappPopupEntryPoint/WhatsappPopupEntryPointQuery';
import { PLUGIN_FRAGMENT as DOCUMENT_PLUGIN_FRAGMENT } from '../DocumentPlugin/DocumentPluginQuery';
import { PLUGIN_FRAGMENT as VIDEO_PLUGIN_FRAGMENT } from '../VideoPlugin/VideoPluginQuery';
import { PLUGIN_FRAGMENT as WHATSAPP_LOCATION_PLUGIN_FRAGMENT } from '../WhatsappLocationPlugin/WhatsappLocationPluginQuery';
import { NG_PLUGINS_FRAGMENTS } from './ngPluginsFragments';
import { KOMMO_PLUGIN_FRAGMENT } from '../KommoPlugin/KommoPlugin';
import {
  GraphqlCacheManager,
  CacheManager,
} from '@utils/GQL/GraphqlCacheManager';
import {
  PluginDataQuery,
  PluginDataQueryVariables,
} from './@types/PluginDataQuery';
import client from '@common/services/ApolloService';
import { ApolloQueryResult, MutationUpdaterFn } from 'apollo-client';
import { Observable } from 'rxjs';
import { flowBuilderModule } from '@components/FlowBuilder';
import { PluginType } from './PluginTypes';
import {
  SavePluginMutationPluginData,
  SavePluginMutationPluginDataVariables,
} from './@types/SavePluginMutationPluginData';
import {
  SavePluginMutationCommon,
  SavePluginMutationCommonVariables,
} from './@types/SavePluginMutationCommon';
import {
  PluginTypeQuery,
  PluginTypeQueryVariables,
} from './@types/PluginTypeQuery';

export const PLUGINS_FRAGMENT = gql`
  fragment pluginsFragment on CardI {
    ...inboundLinksEntryPointFragment
    ...addAttributePluginFragment
    ...removeAttributePluginFragment
    ...setupAttributePluginFragment
    ...jsonPluginFragment
    ...defaultCommonTypePluginFragment
    ...facebookAnalyticsPluginFragment
    ...handoverPluginFragment
    ...abtPluginFragment
    ...abandonedCartPluginFragment
    ...goToBlockPluginFragment
    ...sendEmailPluginFragment
    ...commentsAutoreplyEntryPointFragment
    ...adCommentsAutoreplyEntryPointFragment
    ...commentsLikePluginConfigFragment
    ...customerChatEntryPointFragment
    ...shopifyCustomerChatEntryPointFragment
    ...persistentMenuEntryPointFragment
    ...privateReplyEntryPointEntryPointFragment
    ...publicReplyEntryPointEntryPointFragment
    ...sendToMessengerEntryPointFragment
    ...googleSheetPluginFragment
    ...instagramAdsEntryPointFragment
    ...instagramMediaSharePluginFragment
    ...instagramDirectEntryPointFragment
    ...instagramCommentsAutoreplyEntryPointFragment
    ...instagramStoryMentionEntryPointFragment
    ...botLinkEntryPointFragment
    ...instagramStoryReplyEntryPointFragment
    ...instagramLinkEntryPointFragment
    ...facebookShopsEntryPointFragment
    ...zapierEventTriggerPluginFragment
    ...externalIntegrationEntryPointFragment
    ...zapierImportContentPluginFragment
    ...facebookAdsEntryPointFragment
    ...facebookAdsEntryPointCTMFragment
    ...facebookAdsEntryPointCTMJsonFragment
    ...facebookAdsEntryPointSMFragment
    ...popupEntryPointFragment
    ...shopifyEventEntryPointFragment
    ...shopifyBackInStockEntryPointFragment
    ...calendlyIntegrationPluginFragment
    ...orderReceiptPluginFragment
    ...productListPluginFragment
    ...audioPluginFragment
    ...quickReplyPluginFragment
    ...datePickerPluginFragment
    ...whatsappDirectEntryPointFragment
    ...assignAdminPluginFragment
    # / - ng style plugins
    ...textPluginFragment
    ...typingPluginFragment
    ...imagePluginFragment
    ...galleryPluginFragment
    ...addToSequencePluginFragment
    ...videoPluginFragment
    ...commentPluginFragment
    ...userInputPluginFragment
    ...setupAttributePluginFragment
    ...collectPhonePluginFragment
    ...collectEmailPluginFragment
    ...exportViaZapierPluginFragment
    ...integrationZapierPluginFragment
    ...removeToSequencePluginFragment
    ...flowBuilderLiveChatPluginFragment
    ...googleSearchPluginFragment
    ...bindSearchPluginFragment
    ...swiftypeSearchPluginFragment
    ...rssSearchPluginFragment
    ...subscribeUserPluginFragment
    ...subscriptionsManagerPluginFragment
    ...subscriptionBroadcastingPluginFragment
    ...toDoPluginFragment
    ...autopostingZapierPluginFragment
    ...nTimeNotificationPluginFragment
    ...whatsappBotLinkEntryPointFragment
    ...whatsappPopupEntryPointFragment
    ...openAiPluginFragment
    ...customAiPluginFragment
    ...whatsappTemplatePluginFragment
    ...splitTrafficPluginFragment
    ...whatsappListPluginFragment
    ...whatsappWidgetEntryPointFragment
    ...documentPluginFragment
    ...videoPluginFragment
    ...whatsappLocationPluginFragment
    ...KommoPluginFragment
    ... on CardI {
      id
      source_card_id
      plugin_id
      is_valid
    }
  }
  ${INBOUND_LINKS_ENTRY_POINT_FRAGMENT}
  ${ADD_ATTRIBUTE_PLUGIN_FRAGMENT}
  ${REMOVE_ATTRIBUTE_PLUGIN_FRAGMENT}
  ${SETUP_ATTRIBUTE_PLUGIN_FRAGMENT}
  ${JSON_PLUGIN_FRAGMENT}
  ${FACEBOOK_PLUGIN_FRAGMENT}
  ${DEFAULT_COMMON_TYPE_PLUGIN_FRAGMENT}
  ${HANDOVER_PLUGIN_FRAGMENT}
  ${ABT_PLUGIN_FRAGMENT}
  ${ABANDONED_CART_PLUGIN_FRAGMENT}
  ${GOTO_BLOCK_PLUGIN_FRAGMENT}
  ${EMAIL_PLUGIN_FRAGMENT}
  ${FLOWBUILDER_LIVECHAT_PLUGIN_FRAGMENT}
  ${COMMENTS_AUTOREPLY_ENTRY_POINT_FRAGMENT}
  ${AD_COMMENTS_AUTOREPLY_ENTRY_POINT_FRAGMENT}
  ${CUSTOMER_CHAT_ENTRY_POINT_FRAGMENT}
  ${SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_FRAGMENT}
  ${PERSISTENT_MENU_ENTRY_POINT_FRAGMENT}
  ${PRIVATE_REPLY_ENTRY_POINT_FRAGMENT}
  ${PUBLIC_REPLY_ENTRY_POINT_FRAGMENT}
  ${SEND_TO_MESSENGER_ENTRY_POINT_FRAGMENT}
  ${FACEBOOK_SHOPS_ENTRY_POINT_FRAGMENT}
  ${ZAPIER_EVENT_TRIGGER_FRAGMENT}
  ${EXTERNAL_INTEGRATION_TRIGGER_PLUGIN_FRAGMENT}
  ${ZAPIER_IMPORT_CONTENT_PLUGIN_FRAGMENT}
  ${GOOGLE_SHEET_PLUGIN_FRAGMENT}
  ${FACEBOOK_ADS_ENTRY_POINT_FRAGMENT}
  ${INSTAGRAM_ADS_ENTRY_POINT_FRAGMENT}
  ${POPUP_ENTRY_POINT_FRAGMENT}
  ${SHOPIFY_EVENT_ENTRY_POINT_FRAGMENT}
  ${SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_FRAGMENT}
  ${INSTAGRAM_DIRECT_ENTRY_POINT_FRAGMENT}
  ${INSTAGRAM_MEDIA_SHARE_PLUGIN_FRAGMENT}
  ${INSTAGRAM_COMMENTS_AUTOREPLY_ENTRY_POINT_FRAGMENT}
  ${INSTAGRAM_STORY_MENTION_ENTRY_POINT_FRAGMENT}
  ${INSTAGRAM_STORY_REPLY_ENTRY_POINT_FRAGMENT}
  ${INSTAGRAM_BOT_LINK_ENTRY_POINT_FRAGMENT}
  ${ORDER_RECEIPT_PLUGIN_FRAGMENT}
  ${PRODUCT_LIST_PLUGIN_FRAGMENT}
  ${CALENDLY_PLUGIN_FRAGMENT}
  ${BOT_LINK_PLUGIN_FRAGMENT}
  ${AUDIO_PLUGIN_FRAGMENT}
  ${QUICK_REPLY_PLUGIN_FRAGMENT}
  ${DATE_PICKER_PLUGIN_FRAGMENT}
  ${TYPING_PLUGIN_FRAGMENT}
  ${NG_PLUGINS_FRAGMENTS}
  ${N_TIME_NOTIFICATION_PLUGIN_FRAGMENT}
  ${WHATSAPP_BOT_LINK_ENTRY_POINT}
  ${COMMENTS_LIKE_PLUGIN_FRAGMENT}
  ${OPEN_AI_PLUGIN_FRAGMENT}
  ${CUSTOM_AI_PLUGIN_FRAGMENT}
  ${WHATSAPP_TEMPLATE_PLUGIN_FRAGMENT}
  ${SPLIT_TRAFFIC_PLUGIN_FRAGMENT}
  ${WHATSAPP_LIST_PLUGIN_FRAGMENT}
  ${WHATSAPP_WIDGET_ENTRY_POINT_FRAGMENT}
  ${WHATSAPP_POPUP_ENTRY_POINT_FRAGMENT}
  ${DOCUMENT_PLUGIN_FRAGMENT}
  ${VIDEO_PLUGIN_FRAGMENT}
  ${WHATSAPP_LOCATION_PLUGIN_FRAGMENT}
  ${KOMMO_PLUGIN_FRAGMENT}
  ${WHATSAPP_DIRECT_ENTRY_POING_FRAGMENT}
  ${ASSIGN_ADMIN_PLUGIN_FRAGMENT}
`;

export const BLOCK_QUERY = gql`
  query BlockQuery($botId: String!, $blockId: ID!) {
    bot(id: $botId) {
      id
      builtin_blocks_status {
        default_message
        welcome_message
      }
      block(id: $blockId) {
        id
        title
        builtin
        referral
        referral_active
        removed
        message_tag
        notification_topic_id
        otn_purpose
        bot
        user_filter {
          operation
          valid
          parameters {
            type
            name
            operation
            values
          }
        }
        broadcast_options {
          time_period
          time_value
        }
        cards {
          ...pluginsFragment
        }
        jsonCards
        synced
      }
    }
  }
  ${PLUGINS_FRAGMENT}
`;

export const FLOW_BLOCK_FRAGMENT = gql`
  fragment FlowBlockFragment on FlowBlock {
    id
    source_block_id
    title
    display_title
    type
    subtype
    context_type
    parent_group
    builtin
    is_valid
    oneTimeRef
    broadcast_options {
      time_period
      time_value
    }
    referral
    referral_active
    user_filter {
      operation
      valid
      parameters {
        type
        name
        operation
        values
      }
    }
    message_tag
    otn_purpose
    notification_topic_id
    synced
    stats {
      sent
      seen
      clicked
      blocked
      attempted
      failed
    }
    non_unique_counter_stats {
      id
      counterId
      stats {
        sent
        seen
        clicked
        blocked
        attempted
        failed
      }
    }
    bot
    cards {
      ...pluginsFragment
    }
    jsonCards
    hide_add_skill
    position
    position_in_flow {
      x
      y
    }
    send_on_update
    next_block_ids
    flowBlock
    flowRootBlock
    blockFromFlow
    entryPoint
  }
  ${PLUGINS_FRAGMENT}
`;

export const CREATE_PLUGIN_MUTATION = gql`
  mutation SavePluginMutationCommon(
    $blockId: String!
    $pluginType: String!
    $position: Int
    $id: String
    $refresh: Boolean
    $enabled: Boolean
    $synced: Boolean
    $localization: [LocalizationInput!]
    $inboundLinksEntryPointConfig: InboundLinksEntryPointConfigInput
    $addAttributePluginConfig: AddAttributePluginConfigInput
    $removeAttributePluginConfig: RemoveAttributePluginConfigInput
    $setupAttributePluginConfig: SetupAttributePluginConfigInput
    $jsonPluginConfig: JsonPluginConfigInput
    $facebookAnalyticsPluginConfig: FacebookAnalyticsPluginConfigInput
    $handoverPluginConfig: HandoverProtocolConfigInput
    $abtPluginConfig: AbtPluginConfigInput
    $abandonedCartPluginConfig: AbandonedCartPluginConfigInput
    $goToBlockPluginConfig: GoToBlockPluginConfigInput
    $sendEmailPluginConfig: SendEmailPluginConfigInput
    $flowBuilderLiveChatPluginConfig: FlowBuilderLiveChatPluginConfigInput
    $googleSheetPluginConfig: GoogleSheetPluginConfigInput
    $externalIntegrationEntryPointConfig: ExternalIntegrationEntryPointConfigInput
    $zapierEventTriggerPluginConfig: ExportViaZapierPluginConfigInput
    $zapierImportContentPluginConfig: IntegrationZapierPluginConfigInput
    $instagramAdsEntryPointConfig: InstagramAdsEntryPointConfigInput
    $instagramMediaSharePluginConfig: InstagramMediaSharePluginConfigInput
    $instagramDirectEntryPointConfig: InstagramDirectEntryPointConfigInput
    $facebookAdsEntryPointConfig: FacebookAdsEntryPointConfigInput
    $instagramStoryMentionEntryPointConfig: InstagramStoryMentionEntryPointConfigInput
    $instagramCommentsAutoreplyEntryPointConfig: InstagramCommentsAutoreplyEntryPointConfigInput
    $commentsAutoreplyEntryPointConfig: CommentsAutoreplyEntryPointConfigInput
    $adCommentsAutoreplyEntryPointConfig: AdCommentsAutoreplyEntryPointConfigInput
    $commentsLikePluginConfig: CommentsLikePluginConfigInput
    $instagramStoryReplyEntryPointConfig: InstagramStoryReplyEntryPointConfigInput
    $instagramLinkEntryPointConfig: InstagramLinkEntryPointConfigInput
    $orderReceiptPluginConfig: OrderReceiptPluginConfigInput
    $calendlyIntegrationPluginConfig: CalendlyIntegrationPluginConfigInput
    $productListPluginConfig: ProductListPluginConfigInput
    $customerChatEntryPointConfig: CustomerChatEntryPointConfigInput
    $shopifyCustomerChatEntryPointConfig: ShopifyCustomerChatEntryPointConfigInput
    $audioPluginConfig: AudioPluginConfigInput
    $quickReplyPluginConfig: QuickReplyPluginConfigInput
    $datePickerPluginConfig: DatePickerPluginConfigInput
    $botLinkEntryPointConfig: BotLinkEntryPointConfigInput
    $nTimeNotificationPluginConfig: NTimeNotificationPluginConfigInput
    $whatsappBotLinkEntryPointConfig: WhatsappBotLinkEntryPointConfigInput
    $whatsappPopupEntryPointConfig: WhatsappPopupEntryPointConfigInput
    $openAiPluginConfig: OpenAiPluginConfigInput
    $customAiPluginConfig: CustomAiPluginConfigInput
    $whatsappTemplatePluginConfig: WhatsappTemplatePluginConfigInput
    $whatsappListPluginConfig: WhatsappListPluginConfigInput
    $documentPluginConfig: DocumentPluginConfigInput
    $videoPluginConfig: VideoPluginConfigInput
    $whatsappLocationPluginConfig: WhatsappLocationPluginConfigInput
    $kommoPluginConfig: KommoPluginConfigInput
  ) {
    saveCard(
      blockId: $blockId
      pluginType: $pluginType
      position: $position
      id: $id
      refresh: $refresh
      enabled: $enabled
      synced: $synced
      localization: $localization
      inboundLinksEntryPointConfig: $inboundLinksEntryPointConfig
      addAttributePluginConfig: $addAttributePluginConfig
      removeAttributePluginConfig: $removeAttributePluginConfig
      setupAttributePluginConfig: $setupAttributePluginConfig
      jsonPluginConfig: $jsonPluginConfig
      facebookAnalyticsPluginConfig: $facebookAnalyticsPluginConfig
      handoverPluginConfig: $handoverPluginConfig
      abtPluginConfig: $abtPluginConfig
      abandonedCartPluginConfig: $abandonedCartPluginConfig
      goToBlockPluginConfig: $goToBlockPluginConfig
      sendEmailPluginConfig: $sendEmailPluginConfig
      flowBuilderLiveChatPluginConfig: $flowBuilderLiveChatPluginConfig
      googleSheetPluginConfig: $googleSheetPluginConfig
      zapierImportContentPluginConfig: $zapierImportContentPluginConfig
      instagramAdsEntryPointConfig: $instagramAdsEntryPointConfig
      zapierEventTriggerPluginConfig: $zapierEventTriggerPluginConfig
      externalIntegrationEntryPointConfig: $externalIntegrationEntryPointConfig
      instagramMediaSharePluginConfig: $instagramMediaSharePluginConfig
      instagramDirectEntryPointConfig: $instagramDirectEntryPointConfig
      facebookAdsEntryPointConfig: $facebookAdsEntryPointConfig
      instagramStoryMentionEntryPointConfig: $instagramStoryMentionEntryPointConfig
      instagramCommentsAutoreplyEntryPointConfig: $instagramCommentsAutoreplyEntryPointConfig
      commentsAutoreplyEntryPointConfig: $commentsAutoreplyEntryPointConfig
      adCommentsAutoreplyEntryPointConfig: $adCommentsAutoreplyEntryPointConfig
      commentsLikePluginConfig: $commentsLikePluginConfig
      instagramStoryReplyEntryPointConfig: $instagramStoryReplyEntryPointConfig
      instagramLinkEntryPointConfig: $instagramLinkEntryPointConfig
      calendlyIntegrationPluginConfig: $calendlyIntegrationPluginConfig
      orderReceiptPluginConfig: $orderReceiptPluginConfig
      productListPluginConfig: $productListPluginConfig
      customerChatEntryPointConfig: $customerChatEntryPointConfig
      shopifyCustomerChatEntryPointConfig: $shopifyCustomerChatEntryPointConfig
      botLinkEntryPointConfig: $botLinkEntryPointConfig
      audioPluginConfig: $audioPluginConfig
      nTimeNotificationPluginConfig: $nTimeNotificationPluginConfig
      quickReplyPluginConfig: $quickReplyPluginConfig
      datePickerPluginConfig: $datePickerPluginConfig
      whatsappBotLinkEntryPointConfig: $whatsappBotLinkEntryPointConfig
      whatsappPopupEntryPointConfig: $whatsappPopupEntryPointConfig
      openAiPluginConfig: $openAiPluginConfig
      customAiPluginConfig: $customAiPluginConfig
      whatsappTemplatePluginConfig: $whatsappTemplatePluginConfig
      whatsappListPluginConfig: $whatsappListPluginConfig
      documentPluginConfig: $documentPluginConfig
      videoPluginConfig: $videoPluginConfig
      whatsappLocationPluginConfig: $whatsappLocationPluginConfig
      kommoPluginConfig: $kommoPluginConfig
    ) {
      ...pluginsFragment
    }
  }
  ${PLUGINS_FRAGMENT}
`;

export interface CreatePluginMutationParams {
  pluginType: PluginType;
  variables: SavePluginMutationCommonVariables;
  optimisticResponse: SavePluginMutationCommon;
  update?: MutationUpdaterFn<SavePluginMutationCommon>;
}

export const createPluginMutation = async ({
  pluginType,
  variables,
  optimisticResponse,
  update,
}: CreatePluginMutationParams) => {
  if (flowBuilderModule.hasPlugin(pluginType)) {
    return flowBuilderModule.getPlugin(pluginType).createPlugin({
      pluginType,
      optimisticResponse,
      variables,
      update,
    }) as Promise<ApolloQueryResult<SavePluginMutationCommon>>;
  }

  return client.mutate<
    SavePluginMutationCommon,
    SavePluginMutationCommonVariables
  >({
    optimisticResponse,
    mutation: CREATE_PLUGIN_MUTATION,
    variables,
    update,
  });
};

export const SAVE_PLUGIN_MUTATION = gql`
  mutation SavePluginMutationPluginData(
    $blockId: String!
    $pluginType: String!
    $position: Int
    $id: String
    $refresh: Boolean
    $enabled: Boolean
    $synced: Boolean
    $localization: [LocalizationInput!]
    $inboundLinksEntryPointConfig: InboundLinksEntryPointConfigInput
    $addAttributePluginConfig: AddAttributePluginConfigInput
    $removeAttributePluginConfig: RemoveAttributePluginConfigInput
    $jsonPluginConfig: JsonPluginConfigInput
    $facebookAnalyticsPluginConfig: FacebookAnalyticsPluginConfigInput
    $handoverPluginConfig: HandoverProtocolConfigInput
    $abtPluginConfig: AbtPluginConfigInput
    $abandonedCartPluginConfig: AbandonedCartPluginConfigInput
    $goToBlockPluginConfig: GoToBlockPluginConfigInput
    $sendEmailPluginConfig: SendEmailPluginConfigInput
    $sendToMessengerEntryPointConfig: SendToMessengerEntryPointConfigInput
    $customerChatEntryPointConfig: CustomerChatEntryPointConfigInput
    $shopifyCustomerChatEntryPointConfig: ShopifyCustomerChatEntryPointConfigInput
    $flowBuilderLiveChatPluginConfig: FlowBuilderLiveChatPluginConfigInput
    $googleSheetPluginConfig: GoogleSheetPluginConfigInput
    $externalIntegrationEntryPointConfig: ExternalIntegrationEntryPointConfigInput
    $zapierEventTriggerPluginConfig: ExportViaZapierPluginConfigInput
    $zapierImportContentPluginConfig: IntegrationZapierPluginConfigInput
    $instagramAdsEntryPointConfig: InstagramAdsEntryPointConfigInput
    $popupEntryPointConfig: PopupEntryPointConfigInput
    $shopifyEventEntryPointConfig: ShopifyEventEntryPointConfigInput
    $shopifyBackInStockEntryPointConfig: ShopifyBackInStockEntryPointConfigInput
    $instagramMediaSharePluginConfig: InstagramMediaSharePluginConfigInput
    $instagramDirectEntryPointConfig: InstagramDirectEntryPointConfigInput
    $facebookAdsEntryPointConfig: FacebookAdsEntryPointConfigInput
    $commentsAutoreplyEntryPointConfig: CommentsAutoreplyEntryPointConfigInput
    $adCommentsAutoreplyEntryPointConfig: AdCommentsAutoreplyEntryPointConfigInput
    $commentsLikePluginConfig: CommentsLikePluginConfigInput
    $instagramCommentsAutoreplyEntryPointConfig: InstagramCommentsAutoreplyEntryPointConfigInput
    $instagramStoryMentionEntryPointConfig: InstagramStoryMentionEntryPointConfigInput
    $instagramStoryReplyEntryPointConfig: InstagramStoryReplyEntryPointConfigInput
    $instagramLinkEntryPointConfig: InstagramLinkEntryPointConfigInput
    $orderReceiptPluginConfig: OrderReceiptPluginConfigInput
    $calendlyIntegrationPluginConfig: CalendlyIntegrationPluginConfigInput
    $botLinkEntryPointConfig: BotLinkEntryPointConfigInput
    $productListPluginConfig: ProductListPluginConfigInput
    $audioPluginConfig: AudioPluginConfigInput
    $quickReplyPluginConfig: QuickReplyPluginConfigInput
    $datePickerPluginConfig: DatePickerPluginConfigInput
    $nTimeNotificationPluginConfig: NTimeNotificationPluginConfigInput
    $whatsappBotLinkEntryPointConfig: WhatsappBotLinkEntryPointConfigInput
    $whatsappPopupEntryPointConfig: WhatsappPopupEntryPointConfigInput
    $openAiPluginConfig: OpenAiPluginConfigInput
    $customAiPluginConfig: CustomAiPluginConfigInput
    $whatsappTemplatePluginConfig: WhatsappTemplatePluginConfigInput
    $splitTrafficPluginConfig: SplitTrafficPluginConfigInput
    $whatsappListPluginConfig: WhatsappListPluginConfigInput
    $whatsappWidgetEntryPointConfig: WhatsappWidgetEntryPointConfigInput
    $documentPluginConfig: DocumentPluginConfigInput
    $videoPluginConfig: VideoPluginConfigInput
    $whatsappLocationPluginConfig: WhatsappLocationPluginConfigInput
    $kommoPluginConfig: KommoPluginConfigInput
  ) {
    saveCard(
      blockId: $blockId
      pluginType: $pluginType
      position: $position
      id: $id
      refresh: $refresh
      enabled: $enabled
      synced: $synced
      localization: $localization
      inboundLinksEntryPointConfig: $inboundLinksEntryPointConfig
      addAttributePluginConfig: $addAttributePluginConfig
      removeAttributePluginConfig: $removeAttributePluginConfig
      jsonPluginConfig: $jsonPluginConfig
      facebookAnalyticsPluginConfig: $facebookAnalyticsPluginConfig
      handoverPluginConfig: $handoverPluginConfig
      abtPluginConfig: $abtPluginConfig
      abandonedCartPluginConfig: $abandonedCartPluginConfig
      goToBlockPluginConfig: $goToBlockPluginConfig
      sendEmailPluginConfig: $sendEmailPluginConfig
      sendToMessengerEntryPointConfig: $sendToMessengerEntryPointConfig
      customerChatEntryPointConfig: $customerChatEntryPointConfig
      shopifyCustomerChatEntryPointConfig: $shopifyCustomerChatEntryPointConfig
      flowBuilderLiveChatPluginConfig: $flowBuilderLiveChatPluginConfig
      googleSheetPluginConfig: $googleSheetPluginConfig
      zapierImportContentPluginConfig: $zapierImportContentPluginConfig
      zapierEventTriggerPluginConfig: $zapierEventTriggerPluginConfig
      externalIntegrationEntryPointConfig: $externalIntegrationEntryPointConfig
      instagramAdsEntryPointConfig: $instagramAdsEntryPointConfig
      popupEntryPointConfig: $popupEntryPointConfig
      shopifyEventEntryPointConfig: $shopifyEventEntryPointConfig
      shopifyBackInStockEntryPointConfig: $shopifyBackInStockEntryPointConfig
      instagramMediaSharePluginConfig: $instagramMediaSharePluginConfig
      instagramDirectEntryPointConfig: $instagramDirectEntryPointConfig
      facebookAdsEntryPointConfig: $facebookAdsEntryPointConfig
      commentsLikePluginConfig: $commentsLikePluginConfig
      commentsAutoreplyEntryPointConfig: $commentsAutoreplyEntryPointConfig
      adCommentsAutoreplyEntryPointConfig: $adCommentsAutoreplyEntryPointConfig
      instagramCommentsAutoreplyEntryPointConfig: $instagramCommentsAutoreplyEntryPointConfig
      instagramStoryMentionEntryPointConfig: $instagramStoryMentionEntryPointConfig
      instagramStoryReplyEntryPointConfig: $instagramStoryReplyEntryPointConfig
      instagramLinkEntryPointConfig: $instagramLinkEntryPointConfig
      orderReceiptPluginConfig: $orderReceiptPluginConfig
      calendlyIntegrationPluginConfig: $calendlyIntegrationPluginConfig
      botLinkEntryPointConfig: $botLinkEntryPointConfig
      productListPluginConfig: $productListPluginConfig
      audioPluginConfig: $audioPluginConfig
      nTimeNotificationPluginConfig: $nTimeNotificationPluginConfig
      quickReplyPluginConfig: $quickReplyPluginConfig
      datePickerPluginConfig: $datePickerPluginConfig
      whatsappBotLinkEntryPointConfig: $whatsappBotLinkEntryPointConfig
      whatsappPopupEntryPointConfig: $whatsappPopupEntryPointConfig
      openAiPluginConfig: $openAiPluginConfig
      customAiPluginConfig: $customAiPluginConfig
      whatsappTemplatePluginConfig: $whatsappTemplatePluginConfig
      splitTrafficPluginConfig: $splitTrafficPluginConfig
      whatsappListPluginConfig: $whatsappListPluginConfig
      whatsappWidgetEntryPointConfig: $whatsappWidgetEntryPointConfig
      documentPluginConfig: $documentPluginConfig
      videoPluginConfig: $videoPluginConfig
      whatsappLocationPluginConfig: $whatsappLocationPluginConfig
      kommoPluginConfig: $kommoPluginConfig
    ) {
      ... on GoogleSheetPlugin {
        ...googleSheetPluginFragment
      }
      ... on InboundLinksEntryPoint {
        ...inboundLinksEntryPointFragment
      }
      ... on InstagramMediaSharePlugin {
        ...instagramMediaSharePluginFragment
      }
      ... on WhatsappListPlugin {
        ...whatsappListPluginFragment
      }
      ... on CardI {
        id
        plugin_id
        is_valid
      }
      ...validationFragment
    }
  }
  ${VALIDATION_FRAGMENT}
  ${GOOGLE_SHEET_PLUGIN_FRAGMENT}
  ${INBOUND_LINKS_ENTRY_POINT_FRAGMENT}
  ${INSTAGRAM_MEDIA_SHARE_PLUGIN_FRAGMENT}
  ${WHATSAPP_LIST_PLUGIN_FRAGMENT}
`;

export const savePluginMutation = async (
  pluginType: PluginType,
  variables: SavePluginMutationPluginDataVariables,
) => {
  if (flowBuilderModule.hasPlugin(pluginType)) {
    await flowBuilderModule.getPlugin(pluginType).savePlugin(variables);
  } else {
    await client.mutate<
      SavePluginMutationPluginData,
      SavePluginMutationPluginDataVariables
    >({
      mutation: SAVE_PLUGIN_MUTATION,
      variables,
    });
  }
};

export const PLUGIN_DATA_QUERY = gql`
  query PluginDataQuery($id: ID!) {
    card(id: $id) {
      ...inboundLinksEntryPointFragment
      ...addAttributePluginFragment
      ...removeAttributePluginFragment
      ...jsonPluginFragment
      ...facebookAnalyticsPluginFragment
      ...handoverPluginFragment
      ...abtPluginFragment
      ...abandonedCartPluginFragment
      ...goToBlockPluginFragment
      ...sendEmailPluginFragment
      ...sendToMessengerEntryPointFragment
      ...customerChatEntryPointFragment
      ...flowBuilderLiveChatPluginFragment
      ...googleSheetPluginFragment
      ...externalIntegrationEntryPointFragment
      ...zapierImportContentPluginFragment
      ...zapierEventTriggerPluginFragment
      ...instagramAdsEntryPointFragment
      ...instagramDirectEntryPointFragment
      ...instagramMediaSharePluginFragment
      ...instagramMediaSharePluginFragment
      ...facebookAdsEntryPointFragment
      ...facebookAdsEntryPointCTMFragment
      ...facebookAdsEntryPointCTMJsonFragment
      ...facebookAdsEntryPointSMFragment
      ...instagramCommentsAutoreplyEntryPointFragment
      ...commentsAutoreplyEntryPointFragment
      ...adCommentsAutoreplyEntryPointFragment
      ...commentsLikePluginConfigFragment
      ...instagramStoryMentionEntryPointFragment
      ...instagramStoryReplyEntryPointFragment
      ...instagramLinkEntryPointFragment
      ...defaultCommonTypePluginFragment
      ...popupEntryPointFragment
      ...shopifyEventEntryPointFragment
      ...shopifyBackInStockEntryPointFragment
      ...orderReceiptPluginFragment
      ...calendlyIntegrationPluginFragment
      ...productListPluginFragment
      ...shopifyCustomerChatEntryPointFragment
      ...audioPluginFragment
      ...quickReplyPluginFragment
      ...datePickerPluginFragment
      ...botLinkEntryPointFragment
      ...nTimeNotificationPluginFragment
      ...whatsappBotLinkEntryPointFragment
      ...whatsappPopupEntryPointFragment
      ...openAiPluginFragment
      ...customAiPluginFragment
      ...whatsappTemplatePluginFragment
      ...splitTrafficPluginFragment
      ...whatsappListPluginFragment
      ...whatsappWidgetEntryPointFragment
      ...documentPluginFragment
      ...videoPluginFragment
      ...whatsappLocationPluginFragment
      ...KommoPluginFragment
      ...whatsappDirectEntryPointFragment
    }
  }
  ${INBOUND_LINKS_ENTRY_POINT_FRAGMENT}
  ${ADD_ATTRIBUTE_PLUGIN_FRAGMENT}
  ${REMOVE_ATTRIBUTE_PLUGIN_FRAGMENT}
  ${JSON_PLUGIN_FRAGMENT}
  ${FACEBOOK_PLUGIN_FRAGMENT}
  ${HANDOVER_PLUGIN_FRAGMENT}
  ${ABT_PLUGIN_FRAGMENT}
  ${DEFAULT_COMMON_TYPE_PLUGIN_FRAGMENT}
  ${ABANDONED_CART_PLUGIN_FRAGMENT}
  ${GOTO_BLOCK_PLUGIN_FRAGMENT}
  ${EMAIL_PLUGIN_FRAGMENT}
  ${SEND_TO_MESSENGER_ENTRY_POINT_FRAGMENT}
  ${CUSTOMER_CHAT_ENTRY_POINT_FRAGMENT}
  ${FLOWBUILDER_LIVECHAT_PLUGIN_FRAGMENT}
  ${GOOGLE_SHEET_PLUGIN_FRAGMENT}
  ${EXTERNAL_INTEGRATION_ENTRY_POINT_FRAGMENT}
  ${ZAPIER_IMPORT_CONTENT_FRAGMENT}
  ${ZAPIER_EVENT_TRIGGER_FRAGMENT}
  ${INSTAGRAM_ENTRY_POINT_FRAGMENT}
  ${POPUP_ENTRY_POINT_FRAGMENT}
  ${SHOPIFY_EVENT_ENTRY_POINT_FRAGMENT}
  ${SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_FRAGMENT}
  ${INSTAGRAM_MEDIA_SHARE_PLUGIN_FRAGMENT}
  ${INSTAGRAM_DIRECT_ENTRY_POINT_FRAGMENT}
  ${FACEBOOK_ADS_ENTRY_POINT_FRAGMENT}
  ${INSTAGRAM_COMMENTS_AUTOREPLY_ENTRY_POINT_FRAGMENT}
  ${INSTAGRAM_STORY_MENTION_ENTRY_POINT_FRAGMENT}
  ${INSTAGRAM_STORY_REPLY_ENTRY_POINT_FRAGMENT}
  ${INSTAGRAM_BOT_LINK_ENTRY_POINT_FRAGMENT}
  ${ORDER_RECEIPT_PLUGIN_FRAGMENT}
  ${CALENDLY_PLUGIN_FRAGMENT}
  ${PRODUCT_LIST_PLUGIN_FRAGMENT}
  ${SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_FRAGMENT}
  ${AUDIO_PLUGIN_FRAGMENT}
  ${QUICK_REPLY_PLUGIN_FRAGMENT}
  ${DATE_PICKER_PLUGIN_FRAGMENT}
  ${BOT_LINK_PLUGIN_FRAGMENT}
  ${N_TIME_NOTIFICATION_PLUGIN_FRAGMENT}
  ${WHATSAPP_BOT_LINK_ENTRY_POINT}
  ${COMMENTS_AUTOREPLY_ENTRY_POINT_FRAGMENT}
  ${AD_COMMENTS_AUTOREPLY_ENTRY_POINT_FRAGMENT}
  ${COMMENTS_LIKE_PLUGIN_FRAGMENT}
  ${OPEN_AI_PLUGIN_FRAGMENT}
  ${CUSTOM_AI_PLUGIN_FRAGMENT}
  ${WHATSAPP_TEMPLATE_PLUGIN_FRAGMENT}
  ${SPLIT_TRAFFIC_PLUGIN_FRAGMENT}
  ${WHATSAPP_LIST_PLUGIN_FRAGMENT}
  ${WHATSAPP_WIDGET_ENTRY_POINT_FRAGMENT}
  ${WHATSAPP_POPUP_ENTRY_POINT_FRAGMENT}
  ${DOCUMENT_PLUGIN_FRAGMENT}
  ${VIDEO_PLUGIN_FRAGMENT}
  ${WHATSAPP_LOCATION_PLUGIN_FRAGMENT}
  ${KOMMO_PLUGIN_FRAGMENT}
  ${WHATSAPP_DIRECT_ENTRY_POING_FRAGMENT}
`;

export const pluginDataQuery = new GraphqlCacheManager<
  PluginDataQuery,
  PluginDataQueryVariables
>(client, PLUGIN_DATA_QUERY);

const PLUGIN_TYPE_QUERY = gql`
  query PluginTypeQuery($id: ID!) {
    card(id: $id) {
      id
      plugin_id
    }
  }
`;
const pluginTypeQuery = new GraphqlCacheManager<
  PluginTypeQuery,
  PluginTypeQueryVariables
>(client, PLUGIN_TYPE_QUERY);

interface TemporaryPluginVariables extends PluginDataQueryVariables {}

class TemporaryPluginDataQuery
  implements CacheManager<PluginDataQuery, TemporaryPluginVariables>
{
  /**
   * Почему то падает автотест AC_308 если не проверять ошибку. В шаред флоу
   * вызывается метод watch для Send to Messenger, хотя поидее не должен
   */
  private getPluginType(params: TemporaryPluginVariables) {
    let pluginType;

    try {
      pluginType = pluginTypeQuery.read({ id: params.id })?.card
        .plugin_id as PluginType;
    } catch {
      pluginType = null;
    }

    return pluginType;
  }

  read(params: TemporaryPluginVariables): PluginDataQuery | null {
    const pluginType = this.getPluginType(params);

    if (pluginType && flowBuilderModule.hasPlugin(pluginType)) {
      return flowBuilderModule.getPlugin(pluginType).cacheManager.read(params);
    }

    return pluginDataQuery.read(params);
  }

  write(data: PluginDataQuery, params: TemporaryPluginVariables): void {
    const pluginType = this.getPluginType(params);

    if (pluginType && flowBuilderModule.hasPlugin(pluginType)) {
      return (
        flowBuilderModule
          .getPlugin(pluginType)
          // @ts-expect-error
          .cacheManager.write(data, params)
      );
    }

    return pluginDataQuery.write(data, params);
  }

  watch(
    params: TemporaryPluginVariables,
  ): Observable<ApolloQueryResult<PluginDataQuery>> {
    const pluginType = this.getPluginType(params);

    if (pluginType && flowBuilderModule.hasPlugin(pluginType)) {
      return flowBuilderModule.getPlugin(pluginType).cacheManager.watch(params);
    }

    return pluginDataQuery.watch(params);
  }
}

export const temporaryPluginDataQuery = new TemporaryPluginDataQuery();
