import React from 'react';
import cn from 'classnames';
import { Platform } from '@globals';
import * as css from './VerticalLine.css';

export interface VerticalLineProps {
  platform: Platform;
  enabled: boolean;
}

export const VerticalLine: React.FC<VerticalLineProps> = ({
  platform,
  enabled,
}) => {
  return (
    <div
      className={cn(
        css.verticalLine,
        enabled && css.enabled,
        {
          [Platform.instagram]: css.instagram,
          [Platform.facebook]: css.facebook,
          [Platform.whatsapp]: css.whatsapp,
        }[platform],
      )}
      style={{ zIndex: enabled ? 100 : 0 }}
    />
  );
};
