import { PluginType } from '../common/PluginTypes';
import { calendlyIntegrationPluginFragment_config as CalendlyIntegrationPlugin } from './@types/calendlyIntegrationPluginFragment';

export const PLUGIN_TYPE = PluginType.calendly;

export const PLUGIN_DEFAULT_CONFIG: CalendlyIntegrationPlugin = {
  __typename: 'CalendlyIntegrationPluginConfig',
  account_id: null,
  event_type_uri: null,
  export_attributes: {
    __typename: 'CalendlyExportAttributes',
    name: '{{first name}} {{last name}}',
    email: '{{email}}',
  },
  booked_block_id: null,
  booked_counter_id: null,
  rescheduled_block_id: null,
  rescheduled_counter_id: null,
  cancelled_block_id: null,
  cancelled_counter_id: null,
  reminders: [],
};

export const PLUGIN_INPUT_FIELD = 'calendlyIntegrationPluginConfig';
