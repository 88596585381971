import React from 'react';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import {
  Avatar as AvatarComponent,
  AvatarSize,
} from '../../../../../modern-ui/Avatar';
import * as css from './Avatar.css';
import { TextEllipsis } from '@ui/TextEllipsis';

interface Props {
  title: string;
  url: string;
}

export const Avatar: React.FC<Props> = ({ title, url }) => (
  <div className={css.avatarContainer} title={title}>
    <AvatarComponent className={css.avatar} size={AvatarSize.small} src={url} />
    <Spacer horizontalFactor={1} factor={0} />
    <TextEllipsis>
      <Type as="p" size="12px" noWrap>
        {title}
      </Type>
    </TextEllipsis>
  </div>
);
