import { CustomerActionType, WelcomeMessageMediaType } from '@globals';
import { PluginType } from '../common/PluginTypes';
import { instagramAdsEntryPointFragment_config } from './@types/instagramAdsEntryPointFragment';

export type InstagramAdsEntryPointConfig =
  instagramAdsEntryPointFragment_config;

export const PLUGIN_TYPE = PluginType.instagram_ads_manager_ctm_entry_point;

export const PLUGIN_DEFAULT_CONFIG: InstagramAdsEntryPointConfig = {
  __typename: 'InstagramAdsEntryPointConfig',
  campaigns: [],
  greeting: {
    __typename: 'InstagramAdsGreeting',
    type: WelcomeMessageMediaType.text,
  },
  welcomeMessageType: WelcomeMessageMediaType.text,
  customer_action_type: CustomerActionType.ice_breakers,
};

export const PLUGIN_INPUT_FIELD = 'instagramAdsEntryPointConfig';
