import React, { useEffect } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { Callout } from '@ui/Callout';
import { CalloutProps } from '@ui/Callout/Callout';
import { Anchor } from '@ui/Links';
import { useHistory } from 'react-router-dom';
import { getPlansPageUrl } from '@utils/Routing';
import { usePlanLimitReached } from '@utils/Data/Pricing';
import { sendEvent } from '@utils/Analytics';
import { Translation } from '@translations';
import css from './PlanLimitReachedCallout.css';

export interface GetPlanLimitReachedTextPath {
  (dialogPricingEnabled: boolean, currentPlan?: string | null): Record<
    'title' | 'button',
    Translation
  >;
}

type UsePlanLimitReturnType = Exclude<
  ReturnType<typeof usePlanLimitReached>,
  null
>;

interface PlanLimitReachedCalloutProps
  extends UsePlanLimitReturnType,
    Pick<
      CalloutProps,
      'buttonNewLine' | 'iconDisabled' | 'className' | 'iconView'
    > {
  isAnchor?: boolean;
  getTextPath: GetPlanLimitReachedTextPath;
  onClick?: VoidFunction;
  sourcePage: string;
}

export const PlanLimitReachedCallout = ({
  isAnchor,
  getTextPath,
  onClick,
  botId,
  dialogsPricingEnabled,
  nextPlanText,
  nextPlanLimit,
  planLimit,
  currentPlanText,
  currentPlanType,
  botStatus,
  sourcePage,
  visible,
  buttonNewLine,
  className,
  iconView,
  iconDisabled,
}: PlanLimitReachedCalloutProps) => {
  const { t } = useSafeTranslation();
  const history = useHistory();

  const { title, button } = getTextPath(dialogsPricingEnabled, currentPlanType);
  const plansPageUrl = getPlansPageUrl(botId, dialogsPricingEnabled);

  const sendBillingEvent = () => {
    sendEvent({
      category: 'billing',
      action: 'go to plan update page',
      label: 'plan limit reached',
      propertyBag: {
        dialogsPricingEnabled,
        botId,
        botStatus,
        sourcePage,
        currentTier: currentPlanType,
      },
    });
  };

  useEffect(() => {
    if (visible)
      sendEvent({
        category: 'billing',
        action: 'show update message',
        label: 'plan limit reached',
        propertyBag: {
          dialogsPricingEnabled,
          botId,
          botStatus,
          sourcePage,
          currentTier: currentPlanType,
        },
      });
  }, [
    visible,
    dialogsPricingEnabled,
    botId,
    botStatus,
    sourcePage,
    currentPlanType,
  ]);

  return (
    <Callout
      flexDirection="row"
      type="gradient"
      text={t(title, {
        planLimit,
        currentPlan: currentPlanText,
        nextPlan: nextPlanText,
        nextPlanLimit,
      })}
      button={
        isAnchor ? (
          <div className={css.anchorContainer}>
            <Anchor
              href={`${plansPageUrl}${
                plansPageUrl.includes('?') ? '&' : '?'
              }planLimitModal=disabled`}
              target="_blank"
              intent="tooltip"
              size="regular"
              onMouseDown={sendBillingEvent}
            >
              {t(button)}
            </Anchor>
          </div>
        ) : (
          <Button
            intent="secondary"
            onClick={() => {
              sendBillingEvent();
              onClick?.();
              if (botId) history.push(plansPageUrl);
            }}
          >
            {t(button)}
          </Button>
        )
      }
      iconDisabled={iconDisabled}
      iconView={iconView}
      buttonNewLine={buttonNewLine}
      className={className}
    />
  );
};
