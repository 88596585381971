import React from 'react';
import { Modal } from '../../../modern-ui/Modal';
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogFooter,
  DialogHeading,
} from '../../../modern-ui/Dialog';
import * as css from './HandoverPlugin.css';
import { PLUGIN_HELP_URL } from './HandoverPluginConst';
import { Button, ButtonIntent } from '../../../modern-ui/_deprecated/Button';

interface HandoverPluginModalProps {
  onDismiss: () => void;
}

export const HandoverPluginSetupAlertModal: React.FC<HandoverPluginModalProps> = (
  props,
) => (
  <Modal onDismiss={props.onDismiss}>
    <Dialog className={css.dialogContainer}>
      <DialogContent>
        <DialogHeading className={css.dialogHeader}>
          {window.i18next.t(
            'HandoverPluginSetupAlertModal-JSXText-4592-activate-handover-protocol-in-faсebook-page-settings',
          )}
        </DialogHeading>
        <span>
          {window.i18next.t(
            'HandoverPluginSetupAlertModal-JSXText--359-in-page-settings',
          )}
          {'>'}
          {window.i18next.t(
            'HandoverPluginSetupAlertModal-JSXText--143-messenger-platform-assign-chatfuel-the-primary-receiver-role-and-the-other-app-the-secondary-receiver-role-see',
          )}
        </span>
        <a
          href={PLUGIN_HELP_URL}
          target="_blank"
          className={css.link}
          rel="noopener noreferrer"
        >
          {window.i18next.t(
            'HandoverPluginSetupAlertModal-JSXText-1805-the-complete-guide',
          )}
        </a>
      </DialogContent>
      <DialogFooter>
        <Button intent={ButtonIntent.primary} onClick={props.onDismiss}>
          {window.i18next.t('HandoverPluginSetupAlertModal-JSXText-1571-close')}
        </Button>
      </DialogFooter>
      <DialogCloseButton onClick={props.onDismiss} />
    </Dialog>
  </Modal>
);
