import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { AutomatedMessagesSource } from '../components/types';
import { useAutomatedMessages } from '../useAutomatedMessages';

interface ManageMessagesButtonProps {
  botId: string;
  source: AutomatedMessagesSource;
}

export const ManageMessagesButton: React.FC<ManageMessagesButtonProps> = ({
  botId,
  source,
}) => {
  const { showModal: showAutomatedMessagesModal } = useAutomatedMessages({
    botId,
    source,
  });
  const { t } = useSafeTranslation();
  return (
    <Button
      onClick={() => {
        showAutomatedMessagesModal();
      }}
    >
      {t('pages.LiveChat.PluginSettings.manageMessagesButton')}
    </Button>
  );
};
