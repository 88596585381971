import { FlowController } from '@components/FlowBuilder/flow/controller';
import { Rect } from '../../types';
import { getZoneId } from './getZoneId';
import {
  onboardingTourFragment_steps_zoneData_focus_OnboardingTourFlowBuilderZone,
  onboardingTourFragment_steps_zoneData_focus_OnboardingTourHTMLZone,
} from '@utils/Data/MiniOnboarding/@types/onboardingTourFragment';
import { getShapeName } from './getShapeName';

/**
 * @param flowController
 * @param zone shape name из flowBuilder
 * @returns кординаты zone относительно flowController если zone найдена
 */
export const getFlowBuilderZoneBounds = (
  flowController: Readonly<FlowController> | undefined | null,
  zone: onboardingTourFragment_steps_zoneData_focus_OnboardingTourFlowBuilderZone | null,
): Rect | undefined => {
  if (!flowController || !zone) return undefined;

  let focusView = null;
  const focusShapeName = getShapeName(zone);
  if (zone.blockId) {
    focusView = zone.cardId
      ? flowController?.viewInNodeByCardId(zone.blockId, zone.cardId)
      : flowController?.nodeById(zone.blockId).blockView;
    const focusShape = focusShapeName
      ? focusView?.shape().getChildByName(focusShapeName, true)
      : focusView?.shape();

    return focusShape?.getBounds();
  }

  /**
   * если не указан blockId то пробуем найти focusShape
   */
  return focusShapeName
    ? flowController
        .nodeById(flowController.getFlowId())
        .blockView.shape()
        .getChildByName(focusShapeName, true)
        ?.getBounds()
    : undefined;
};

/**
 * @param zone id htmlElement в доме
 * @returns кординаты zone относительно window если zone найдена
 */
export const getHTMLElementZoneBounds = (
  zone: onboardingTourFragment_steps_zoneData_focus_OnboardingTourHTMLZone | null,
  scrollIntoView: boolean,
): Rect | undefined => {
  if (!(zone && 'htmlElementId' in zone)) return undefined;

  const element = document.querySelector(`#${getZoneId(zone)}`);
  // TODO: вынести наверх
  if (scrollIntoView) {
    element?.scrollIntoView();
  }

  return element?.getBoundingClientRect();
};
