import React from 'react';
import {
  NavigationHeader,
  NavigationHeaderProps,
} from '../../NavigationHeader/NavigationHeader';
import css from './ChatListHeader.css';

export const ChatListHeader: React.FC<NavigationHeaderProps> = (props) => (
  <div className={css.root}>
    <NavigationHeader {...props} />
  </div>
);
