import React from 'react';
import { Flex } from '@ui/Flex';
import { ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './Footer.css';

export interface FooterProps {
  onAddBotButtonClick: VoidFunction;
  addBotButtonDisabled: boolean;
  onAllBotsButtonClick: VoidFunction;
}

export const Footer: React.FC<FooterProps> = ({
  onAddBotButtonClick,
  addBotButtonDisabled,
  onAllBotsButtonClick,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Flex justifyContent="space-between" className={css.footerButtonWrapper}>
      <ButtonUnstyled
        onClick={() => {
          onAddBotButtonClick();
        }}
        disabled={addBotButtonDisabled}
        className={css.addBotButton}
        data-testid="bot-list__add-new-bot"
      >
        <Flex flexDirection="row">
          <Icon icon="plus" size="20px" color="blue" />
          <Spacer factor={0} horizontalFactor={1} />
          <Type size="15px" color="blue" weight="medium">
            {t('common.BotHeader.addBot')}
          </Type>
        </Flex>
      </ButtonUnstyled>

      <ButtonUnstyled
        onClick={onAllBotsButtonClick}
        className={css.allBotsButton}
        data-testid="bot-list__all-bots-list"
      >
        <Type size="15px" color="blue" weight="medium">
          {t('common.BotHeader.allBotsList')}
        </Type>
      </ButtonUnstyled>
    </Flex>
  );
};
