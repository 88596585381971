import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';
import debounce from 'lodash-es/debounce';
import { DefaultDialog } from '@ui/Dialog';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { Tooltip2 } from '@ui/Tooltip2';
import { CenteredLoader } from '@ui/Loader';
import { Icon } from '@ui/Icon';
import { TimeIntervalsIds, TimeoutInput } from '@ui/TimeoutInput';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { AttributesData } from '@utils/AttributesUtils/AttributesData';
import { sendEvent } from '@utils/Analytics';
import { Input } from '@ui/Input';
import { useLivechatSettingsContext } from '@utils/Data/LiveChat/Settings/context';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { Platform } from '@globals';
import { EditableAnswer, EditableGalleryCard, Section } from './components';
import { AutomatedMessagesSource } from './components/types';
import { BOT_ON_PAUSE_KEY, CHAT_ALREADY_STOPPED_KEY } from './constants';
import { ReactComponent as DynamicMenuPreview } from './assets/dynamic-menu-preview.svg';
import * as css from './AutomatedMessages.css';
import { useAutomatedMessages } from './useAutomatedMessages';

const liveChatPluginIntervalIds = Object.values(TimeIntervalsIds).filter(
  (id) => TimeIntervalsIds.immediately !== id && TimeIntervalsIds.off !== id,
);

export interface AutomatedMessagesDialogProps {
  botId: string;
  source: AutomatedMessagesSource;
  onDismiss(): void;
  isSmallScreenSize?: boolean;
}

export const AutomatedMessagesDialog: React.FC<AutomatedMessagesDialogProps> =
  ({ botId, source, onDismiss, isSmallScreenSize }) => {
    const { t } = useSafeTranslation();
    const {
      livechatSettings,
      setConfig,
      setLocalizationKey,
      loading: livechatSettingsLoading,
    } = useLivechatSettingsContext();
    const { showModal: showAutomatedMessagesModal } = useAutomatedMessages({
      botId,
      source,
      isSmallScreenSize,
    });

    const { isWhatsappEnabled } = useWhatsappEnabled();

    const { time_period, time_value } =
      livechatSettings?.config.timeout_config || {};

    // for the fields except EditableFields, since their onChange is already debounced
    const onSetInputFieldConfigDebounced = useCallback(
      debounce(setConfig, 200),
      [setConfig],
    );

    const [
      isLivechatConfigurationRulesNoteHidden,
      setLivechatConfigurationRulesNoteHidden,
      { loading: serverStorageLoading },
    ] = useServerStorage<boolean>(
      ServerStorageItemKeys.isLivechatPluginNewConfigurationRulesNoteHidden,
    );

    const disabled =
      serverStorageLoading || !isLivechatConfigurationRulesNoteHidden;

    const tooltipPlacement = isSmallScreenSize ? 'bottom' : 'right';

    return (
      <DefaultDialog
        mobileAdaptive={isSmallScreenSize}
        dialogStyle={isSmallScreenSize ? { width: '100%' } : { width: 600 }}
        contentStyle={{
          maxHeight: isSmallScreenSize ? 'none' : 608,
          display: 'flex',
          flexDirection: 'column',
        }}
        header={
          <Flex alignItems="center">
            <Type size="24px" weight="semibold">
              {t('modernComponents.AutomatedMessagesDialog.title')}
            </Type>
            <Spacer factor={0} horizontalFactor={1} />
            <Tooltip2
              placement={tooltipPlacement}
              boundariesElement="viewport"
              content={
                <Type as="p" size="12px" color="white">
                  <Trans
                    t={t}
                    i18nKey="modernComponents.AutomatedMessagesDialog.tooltip"
                  />
                </Type>
              }
              type="small"
              positionFixed
            >
              {(ref, bind) => (
                <div
                  {...bind}
                  ref={ref}
                  style={{ position: 'relative', top: 1 }}
                >
                  <Icon icon="info" color="grey" />
                </div>
              )}
            </Tooltip2>
          </Flex>
        }
        onDismiss={onDismiss}
      >
        <AttributesData
          botId={botId}
          platform={isWhatsappEnabled ? Platform.whatsapp : undefined}
        >
          {({ attributes, loading: attributesLoading }) => {
            if (livechatSettingsLoading || attributesLoading) {
              return (
                <div className={css.loaderContainer}>
                  <CenteredLoader />
                </div>
              );
            }
            return (
              <div
                className={cn(!isSmallScreenSize && css.dialogContentContainer)}
              >
                {isLivechatConfigurationRulesNoteHidden &&
                  !livechatSettingsLoading &&
                  !livechatSettings?.has_livechat_plugins && (
                    <Flex
                      className={cn(css.banner, css.warning)}
                      alignItems="center"
                    >
                      <Icon
                        className={css.bannerIcon}
                        icon="info"
                        color="alloyOrange"
                      />
                      <Spacer factor={0} horizontalFactor={2} />
                      <Type size="15px" color="alloyOrange">
                        {t(
                          'modernComponents.AutomatedMessagesDialog.noLivechatPluginsWarning.text',
                        )}
                        <Anchor
                          href="https://docs.chatfuel.com/en/articles/849315-live-chat"
                          target="_blank"
                          style={{ color: 'var(--alloyOrange)' }}
                          data-testid="live-chat-automated-messages__no-livechat-plugins-link"
                          onMouseDown={() => {
                            sendEvent({
                              category: 'live chat',
                              label: 'automated messages',
                              action: 'open no livechat plugins link',
                            });
                          }}
                        >
                          {t(
                            'modernComponents.AutomatedMessagesDialog.noLivechatPluginsWarning.link',
                          )}
                        </Anchor>
                      </Type>
                    </Flex>
                  )}
                {!isLivechatConfigurationRulesNoteHidden &&
                  !serverStorageLoading && (
                    <Flex
                      className={cn(css.banner, css.info)}
                      alignItems="center"
                    >
                      <Icon
                        className={css.bannerIcon}
                        icon="info"
                        color="blue"
                      />
                      <Spacer factor={0} horizontalFactor={2} />
                      <Type size="15px">
                        {t(
                          'modernComponents.AutomatedMessagesDialog.livechatConfigurationRulesNote.text',
                        )}
                      </Type>
                      <Spacer factor={0} horizontalFactor={3} />
                      <Button
                        data-testid="live-chat-automated-messages__configuration-note-checked-button"
                        onClick={() => {
                          sendEvent({
                            category: 'live chat settings',
                            action: 'livechat configuration note checked',
                            propertyBag: {
                              botId,
                            },
                          });
                          setLivechatConfigurationRulesNoteHidden(true);
                        }}
                      >
                        {t(
                          'modernComponents.AutomatedMessagesDialog.livechatConfigurationRulesNote.button',
                        )}
                      </Button>
                    </Flex>
                  )}
                <Spacer factor={4} />
                <Flex flexDirection="column">
                  {!isWhatsappEnabled && (
                    <>
                      <Section
                        isSmallScreenSize={isSmallScreenSize}
                        title={
                          <Flex>
                            <Type weight="semibold" size="15px">
                              {t(
                                'modernComponents.AutomatedMessagesDialog.section.dynamicMenu',
                              )}
                            </Type>
                            <Spacer horizontalFactor={1} />
                            <Tooltip2
                              placement={tooltipPlacement}
                              boundariesElement="viewport"
                              content={
                                <div style={{ width: 214 }}>
                                  <DynamicMenuPreview />
                                  <br />
                                  <br />
                                  <Type as="p" size="12px" color="white">
                                    <Trans
                                      t={t}
                                      i18nKey="modernComponents.AutomatedMessagesDialog.section.dynamicMenuTooltip"
                                    />
                                  </Type>
                                </div>
                              }
                              type="small"
                              positionFixed
                            >
                              {(ref, bind) => (
                                <Icon
                                  ref={ref}
                                  {...bind}
                                  icon="info"
                                  color="grey"
                                />
                              )}
                            </Tooltip2>
                          </Flex>
                        }
                        content={
                          <Input
                            data-testid="live-chat-automated-messages__stop-button-name-input"
                            containerClassName={css.dynamicMenuTitleInput}
                            defaultValue={
                              livechatSettings?.config.dynamic_menu_title ?? ''
                            }
                            onChange={({ target: { value } }) => {
                              onSetInputFieldConfigDebounced({
                                dynamic_menu_title: value,
                              });
                            }}
                            disabled={disabled}
                            maxLength={30}
                          />
                        }
                      />
                      <Spacer />
                    </>
                  )}
                  <Section
                    isSmallScreenSize={isSmallScreenSize}
                    title={
                      <Flex>
                        <Type as="p" weight="semibold" size="15px">
                          {t(
                            'modernComponents.AutomatedMessagesDialog.section.pauseAutomationFor',
                          )}
                        </Type>
                        <Spacer horizontalFactor={1} />
                        <Tooltip2
                          placement={tooltipPlacement}
                          boundariesElement="viewport"
                          content={
                            <Type as="p" size="12px" color="white">
                              {t(
                                'modernComponents.AutomatedMessagesDialog.section.pauseAutomationTooltip',
                              )}
                            </Type>
                          }
                          type="small"
                          positionFixed
                        >
                          {(ref, bind) => (
                            <Icon
                              ref={ref}
                              {...bind}
                              icon="info"
                              color="grey"
                            />
                          )}
                        </Tooltip2>
                      </Flex>
                    }
                    content={
                      <TimeoutInput
                        data-testid="live-chat-automated-messages__pause-timeout"
                        value={time_value ?? undefined}
                        interval={time_period as TimeIntervalsIds}
                        intervalIds={liveChatPluginIntervalIds}
                        disabled={disabled}
                        onChange={({
                          value: time_value,
                          interval: time_period,
                        }) => {
                          sendEvent({
                            category: 'live chat plugin',
                            action: 'set timeout',
                            propertyBag: {
                              time_period,
                              time_value,
                            },
                          });
                          onSetInputFieldConfigDebounced({
                            timeout_config: {
                              __typename: 'TimeoutConfig',
                              time_period,
                              time_value,
                            },
                          });
                        }}
                      />
                    }
                  />
                  <Spacer />
                  <Section
                    isSmallScreenSize={isSmallScreenSize}
                    title={t(
                      'modernComponents.AutomatedMessagesDialog.section.livechatStarted',
                    )}
                    content={
                      <EditableGalleryCard
                        botId={botId}
                        source={source}
                        attributes={attributes}
                        onResizeImage={onDismiss}
                        disabled={disabled}
                      />
                    }
                  />
                  <Spacer />
                  <Section
                    isSmallScreenSize={isSmallScreenSize}
                    title={t(
                      'modernComponents.AutomatedMessagesDialog.section.livechatStopped',
                    )}
                    content={
                      <EditableAnswer
                        data-testid="live-chat-automated-messages__livechat-stopped-input"
                        onManageAttributesClick={({ modal }) => {
                          modal?.onClose(() => showAutomatedMessagesModal());
                          onDismiss?.();
                        }}
                        onChange={(stop_message) => {
                          setConfig({ stop_message });
                        }}
                        attributes={attributes}
                        text={livechatSettings?.config.stop_message ?? ''}
                        disabled={disabled}
                        maxTextLength={80}
                      />
                    }
                  />
                  <Spacer />
                  <Section
                    isSmallScreenSize={isSmallScreenSize}
                    title={t(
                      'modernComponents.AutomatedMessagesDialog.section.userClicksAfterLivechatStopped',
                    )}
                    content={
                      <EditableAnswer
                        data-testid="live-chat-automated-messages__after-livechat-stopped-input"
                        attributes={attributes}
                        onChange={(value) => {
                          setLocalizationKey(CHAT_ALREADY_STOPPED_KEY, value);
                        }}
                        text={
                          livechatSettings?.localization?.find(
                            ({ key }) => key === CHAT_ALREADY_STOPPED_KEY,
                          )?.value.default ?? ''
                        }
                        disabled={disabled}
                      />
                    }
                  />
                  <Spacer />
                  <Section
                    isSmallScreenSize={isSmallScreenSize}
                    title={t(
                      'modernComponents.AutomatedMessagesDialog.section.botInteractionDuringActiveSession',
                    )}
                    content={
                      <EditableAnswer
                        data-testid="live-chat-automated-messages__livechat-interaction-input"
                        onChange={(value) => {
                          setLocalizationKey(BOT_ON_PAUSE_KEY, value);
                        }}
                        attributes={attributes}
                        text={
                          livechatSettings?.localization?.find(
                            ({ key }) => key === BOT_ON_PAUSE_KEY,
                          )?.value.default ?? ''
                        }
                        disabled={disabled}
                      />
                    }
                  />
                </Flex>
              </div>
            );
          }}
        </AttributesData>
      </DefaultDialog>
    );
  };
