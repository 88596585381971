import React, { useState } from 'react';
import { clone } from 'ramda';
import cn from 'classnames';
import { Input } from '../../../../modern-ui/Input';
import { ReactComponent as ArrowIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_arrow.svg';
import { Flex } from '../../../../modern-ui/Flex';
import { LocalizationType } from '../types';
import * as css from './Localization.css';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface LocalizationProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'onChange'> {
  localization: LocalizationType;
  onChange: (localization: LocalizationType) => void;
  onBlur?: () => void;
}

export const Localization: React.FC<LocalizationProps> = ({
  className,
  localization,
  onChange,
  onBlur,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className} {...props}>
      <Flex>
        <button className={css.button} onClick={() => setIsOpen(!isOpen)}>
          <Flex alignItems="center" className={css.title}>
            <div>
              {window.i18next.t(
                'Localization-JSXText-1971-modify-default-plugin-phrases',
              )}
            </div>
            <ArrowIcon className={cn(css.icon, { [css.open]: isOpen })} />
          </Flex>
        </button>
      </Flex>
      {isOpen && (
        <div className={css.itemsBox}>
          <Flex alignItems="center" className={css.subheaders}>
            <div className={css.col1}>
              {window.i18next.t('Localization-JSXText-6268-default-phrase')}
            </div>
            <div className={css.col2}>
              {window.i18next.t('Localization-JSXText--835-change-to')}
            </div>
          </Flex>
          {localization.map((item, i) => (
            <Flex key={item.key} alignItems="center" className={css.item}>
              <div className={css.col1}>{item.key}</div>
              <div className={css.col2}>
                <Input
                  value={item.value.default}
                  onBlur={onBlur}
                  onChange={({ target }: React.FormEvent) => {
                    const localizationCopy = clone(localization);
                    localizationCopy[i].value.default = (
                      target as HTMLInputElement
                    ).value;
                    onChange(localizationCopy);
                  }}
                  placeholder={window.i18next.t(
                    'Localization-string--181-enter-caption',
                  )}
                />
              </div>
            </Flex>
          ))}
        </div>
      )}
    </div>
  );
};
