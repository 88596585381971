import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from 'react-apollo';
import {
  CheckWhatsappTemplateCreatedQuery,
  CheckWhatsappTemplateCreatedQueryVariables,
} from './@types/CheckWhatsappTemplateCreatedQuery';
import { useCurrentBotId } from '@utils/Routing';

const CHECK_WA_TEMPLATE_CREATED_QUERY = gql`
  query CheckWhatsappTemplateCreatedQuery($botId: String!) {
    checkWhatsappTemplateCreated(botId: $botId)
  }
`;

export const useCheckWhatsappTemplateCreated = (
  options?: QueryHookOptions<
    CheckWhatsappTemplateCreatedQuery,
    CheckWhatsappTemplateCreatedQueryVariables
  >,
) => {
  const botId = useCurrentBotId();

  return useQuery<
    CheckWhatsappTemplateCreatedQuery,
    CheckWhatsappTemplateCreatedQueryVariables
  >(CHECK_WA_TEMPLATE_CREATED_QUERY, {
    skip: !botId,
    variables: {
      botId: botId!,
    },
    pollInterval: 3000,
    ...options,
  });
};
