import React from 'react';
import { Modal } from '@services/index';
import { MiniOnboardingDialog } from './MiniOnboardingDialog';
import { Platform } from '@globals';

export const showMiniOnboardingModal = (payload: {
  pluginType: string;
  platform: Platform;
  videoId: string | null;
  flowId: string | null;
  title: string;
  templateFlowGroupId: string;
  hasFlowTour: boolean;
}) =>
  Modal.show(({ close }) => (
    <MiniOnboardingDialog {...payload} onClose={close} />
  ));
