import React from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { TFunction } from 'i18next';
import { NavLink } from 'react-router-dom';

import { sendEvent } from '@utils/Analytics';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { useAdminCountryAndCurrency } from '@utils/Data/Admin/Locale/useAdminCountryAndCurrency';

import { AgencyLinkWhiteLabel } from '../../../pages/WhiteLabel/AgencyWhiteLabel/AgencyLinkWhiteLabel';

import * as css from './PageHeaderNav.css';

export interface LinkComponentProps {
  title: string;
  url: string;
  sendEvent: () => void;
}

export const getLinks = (t: TFunction) =>
  isWhiteLabelDomain()
    ? []
    : [
        {
          // Warning! If change position, also change import here:
          // dashboard/src/pages/WhiteLabel/AgencyWhiteLabel/AgencyLinkWhiteLabel/ngAgencyLinkWhiteLabel.tsx
          title: t('modernUi.PageHeaderNav.agency'),
          url: '/agency',
          componentRender: (props: LinkComponentProps) => (
            <AgencyLinkWhiteLabel {...props} />
          ),
          sendEvent: () =>
            sendEvent({
              category: 'header button',
              action: 'click',
              label: 'agency',
            }),
        },
        {
          title: t('modernUi.PageHeaderNav.help'),
          url: 'https://docs.chatfuel.com/',
          sendEvent: () =>
            sendEvent({
              category: 'header button',
              action: 'click',
              label: 'help',
            }),
        },
        {
          title: t('modernUi.PageHeaderNav.blog'),
          url: 'https://blog.chatfuel.com/',
          sendEvent: () =>
            sendEvent({
              category: 'header button',
              action: 'click',
              label: 'blog',
            }),
        },
        {
          title: t('modernUi.PageHeaderNav.community'),
          url: 'https://fb.com/groups/chatfuel',
          sendEvent: () =>
            sendEvent({
              category: 'header button',
              action: 'click',
              label: 'community',
            }),
        },
        {
          title: t('modernUi.PageHeaderNav.youtube'),
          url: 'http://youtube.com/Chatfuel',
          sendEvent: () =>
            sendEvent({
              category: 'header button',
              action: 'click',
              label: 'youtube',
            }),
        },
      ];

const isInnerUrl = (url: string) => url.indexOf('/') === 0;

const BRAZIL_CODE = 'BR';
const RUSSIAN_CODE = 'RU';

export const PageHeaderNav: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const { countryCode, loading: countryCodeIsLoading } =
    useAdminCountryAndCurrency();
  // If admin has Russian or Brazil country code that means he has localized dashboard and pricing.
  // We decided to hide navigation links here for those users.
  // Because they either don't allow to use some link (e.g. Affiliate program)
  // or they lead to the English resources (e.g. Video, Community).
  const adminHasLocalizedDashboard =
    countryCode === BRAZIL_CODE || countryCode === RUSSIAN_CODE;

  if (countryCodeIsLoading || adminHasLocalizedDashboard) return null;

  return (
    <nav className={cn(css.linkBox, className)} {...props}>
      {getLinks(t).map(({ title, url, componentRender, sendEvent }) => (
        <React.Fragment key={title}>
          {componentRender ? (
            componentRender({ url, title, sendEvent })
          ) : isInnerUrl(url) ? (
            <NavLink
              to={url}
              activeClassName={css.active}
              className={css.link}
              title={title}
              onClick={sendEvent}
              // todo remove this when we use React router only
              isActive={() => false}
            >
              {title}
            </NavLink>
          ) : (
            <a
              href={url}
              title={title}
              className={css.link}
              onClick={sendEvent}
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </a>
          )}
        </React.Fragment>
      ))}
    </nav>
  );
};
