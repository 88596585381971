import React from 'react';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import {
  useBotSwitcherNavItemData,
  useSubscribersPricingStatistics,
} from '../../hooks/useBotSwitcherNavItemData';
import { UpgradeOldPricingButton } from '@pages/BotPage/HomeTab/components/BotSectionView/PlanButton/UpgradeOldPricingButton';
import { BotSwitcherProps } from './types';
import { BotSwitcherPricing } from './BotSwitcherPricing';
import * as css from './BotSwitcherPricing.css';

export const BotSwitcherOldPricing: React.FC<BotSwitcherProps> = ({
  onButtonClick,
  ...props
}) => {
  const { allowed, isBotPro } = useBotSwitcherNavItemData();
  const { isMediumScreenSize } = useDeviceMedia();
  const { statistics, loading: subscribersPricingStatisticsLoading } =
    useSubscribersPricingStatistics();
  const { isPaymentFailed, loading: botDataLoading } =
    useBotSwitcherNavItemData();

  return (
    <BotSwitcherPricing
      {...props}
      dialogsPricing={false}
      statistics={statistics}
      loading={subscribersPricingStatisticsLoading || botDataLoading}
      hideButton={isBotPro && !isPaymentFailed}
    >
      <UpgradeOldPricingButton
        onClick={onButtonClick}
        disabled={!allowed}
        className={css.button}
        small={isMediumScreenSize}
      />
    </BotSwitcherPricing>
  );
};
