import React from 'react';
import { Modal } from '../../modern-ui/Modal';
import {
  Dialog,
  DialogContent,
  DialogHeading,
  DialogCloseButton,
  DialogFooter,
} from '../../modern-ui/Dialog';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import * as css from './HandoverPluginPopup.css';

interface IHandoverPluginPopupProps {
  onDismiss: () => void;
}

export const HandoverPluginPopup: React.FC<IHandoverPluginPopupProps> = ({
  onDismiss,
}) => (
  <Modal onDismiss={onDismiss}>
    <Dialog className={css.dialogContainer}>
      <DialogContent>
        <DialogHeading className={css.dialogHeader}>
          {window.i18next.t(
            'HandoverPluginPopup-JSXText-4592-activate-handover-protocol-in-faсebook-page-settings',
          )}
        </DialogHeading>
        <div className={css.dialogText}>
          <span>
            {window.i18next.t(
              'HandoverPluginPopup-JSXText--359-in-page-settings',
            )}
            {'>'}
            {window.i18next.t(
              'HandoverPluginPopup-JSXText-3840-messenger-platform-assign-chatfuel-the-primary-receiver-role-and-the-other-app-the-secondary-receiver-role-see',
            )}{' '}
          </span>
          <a
            href="https://docs.chatfuel.com/plugins/plugin-documentation/conversation-handover"
            target="_blank"
            rel="noopener noreferrer"
            className={css.link}
          >
            {window.i18next.t(
              'HandoverPluginPopup-JSXText--271-the-complete-guide',
            )}
          </a>
          <span>.</span>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button intent={ButtonIntent.primary} onClick={onDismiss}>
          {window.i18next.t('HandoverPluginPopup-JSXText-3183-ok')}
        </Button>
      </DialogFooter>
      <DialogCloseButton onClick={onDismiss} />
    </Dialog>
  </Modal>
);
