import React from 'react';
import cn from 'classnames';
import * as css from './PluginVeil.css';

interface PluginVeilProps {
  show: boolean;
  children: JSX.Element | string;
  className?: string;
}

export const PluginVeil: React.FC<PluginVeilProps> = ({
  show,
  className,
  ...props
}) =>
  show ? (
    <div className={cn(css.pluginVeil, className)} {...props}>
      {props.children}
    </div>
  ) : (
    <div />
  );
