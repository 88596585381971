import React, { useMemo } from 'react';
import nanoid from 'nanoid';
import { Button } from '@ui/Button';
import { SimpleCombobox, SimpleComboboxProps } from '@ui/SimpleCombobox';
import { MenuItem } from '../../modern-ui/Menu';
import { ReactComponent as DropdownIcon } from '../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import * as css from './GroupingCombobox.css';

type ItemId = string;

interface GroupingCombobox_GroupItem {
  title: string;
  id: ItemId;
}

export interface GroupingCombobox_Group {
  title: string;
  items: GroupingCombobox_GroupItem[];
}

interface SimpleComboboxItem {
  type: 'item' | 'group' | 'divider';
  title: string;
  id: string;
}

export interface GroupingComboboxProps {
  groups: GroupingCombobox_Group[];
  ungroupedItems?: GroupingCombobox_GroupItem[];
  emptyPlaceholder?: string;
  disable?: boolean;
  selectedItemId: ItemId | null;
  onSelect(itemId: ItemId | null): void;
  simpleComboboxProps?: Partial<SimpleComboboxProps<SimpleComboboxItem>>;
  width?: number;
}

export const GroupingCombobox: React.FC<GroupingComboboxProps> = ({
  groups,
  ungroupedItems,
  emptyPlaceholder,
  disable,
  selectedItemId,
  onSelect,
  simpleComboboxProps,
  width,
}) => {
  const items: SimpleComboboxItem[] = useMemo(() => {
    const items: SimpleComboboxItem[] = [];

    if (ungroupedItems?.length) {
      items.push(
        ...ungroupedItems.map(
          ({ title, id }): SimpleComboboxItem => ({
            type: 'item',
            title,
            id,
          }),
        ),
      );
      if (groups.length) {
        items.push({
          type: 'divider',
          title: '',
          id: nanoid(),
        });
      }
    }

    for (let i = 0; i < groups.length; ++i) {
      const group = groups[i];
      items.push({
        type: 'group',
        title: group.title,
        id: nanoid(),
      });
      items.push(
        ...group.items.map(
          (i): SimpleComboboxItem => ({
            type: 'item',
            title: i.title,
            id: i.id,
          }),
        ),
      );
      if (i !== groups.length - 1) {
        items.push({
          type: 'divider',
          title: '',
          id: nanoid(),
        });
      }
    }
    return items;
  }, [groups, ungroupedItems]);

  const selectedItem = useMemo(() => {
    return items.find((i) => i.id === selectedItemId) ?? null;
  }, [items, selectedItemId]);

  return (
    <SimpleCombobox<SimpleComboboxItem>
      items={items}
      selectedItem={selectedItem}
      onChange={(item) => {
        onSelect(item ? item.id : null);
      }}
      renderInput={({ getToggleButtonProps, selectedItem }) => (
        <Button
          data-testid="grouping-combobox__select-item-button"
          intent="secondary"
          {...getToggleButtonProps()}
          iconRight={<DropdownIcon />}
          className={css.button}
          disabled={disable}
        >
          {selectedItem ? (
            selectedItem.title
          ) : (
            <span className={css.placeholder}>
              {emptyPlaceholder ??
                window.i18next.t('GroupingCombobox-string-6708-empty')}
            </span>
          )}
        </Button>
      )}
      renderItem={({ item, index, highlightedIndex, getItemProps }) => {
        const itemProps = {
          title: item.title,
        };
        if (item.type === 'item') {
          Object.assign(
            itemProps,
            getItemProps({
              index,
              item,
            }),
            {
              active: highlightedIndex === index,
            },
          );
        }

        return (
          <MenuItem
            {...itemProps}
            style={{ width }}
            type={item.type}
            key={item.id}
          />
        );
      }}
      {...simpleComboboxProps}
    />
  );
};
