import { Rect } from '../../types';

/**
 * Смещает кординаты Rect относительно offsetRect
 */
export const addOffsetToRect = (rect: Rect, offsetRect: Rect): Rect => ({
  ...rect,
  x: rect.x + offsetRect.x,
  y: rect.y + offsetRect.y,
});
