import { ShopifyEventType } from '@globals';
import {
  shopifyEventEntryPointFragment_config as ShopifyEventEntryPointConfig,
  shopifyEventEntryPointFragment_config_events,
} from './@types/shopifyEventEntryPointFragment';

import { PluginType } from '../common/PluginTypes';

export const PLUGIN_TYPE = PluginType.shopify_event_entry_point;

const ACTION_CONFIG = {
  next_block_id: null,
  counter_id: null,
};

export const SHOPIFY_EVENT_ENTRY_POINT_EVENT_DEFAULT_CONFIGS: Record<
  ShopifyEventType,
  shopifyEventEntryPointFragment_config_events
> = {
  [ShopifyEventType.abandoned_cart]: {
    __typename: 'ShopifyEventConfig',
    type: ShopifyEventType.abandoned_cart,
    order_confirmation: null,
    order_status_update: null,
    product_visit: null,
    abandoned_cart: {
      __typename: 'AbandonedCartEventConfig',
      actions: [
        {
          __typename: 'AbandonedCartAction',
          delay_in_minutes: 3,
          ...ACTION_CONFIG,
        },
      ],
    },
  },
  [ShopifyEventType.order_confirmation]: {
    __typename: 'ShopifyEventConfig',
    type: ShopifyEventType.order_confirmation,
    abandoned_cart: null,
    product_visit: null,
    order_status_update: null,
    order_confirmation: {
      __typename: 'OrderConfirmationEventConfig',
      actions: [
        {
          __typename: 'OrderConfirmationAction',
          delay_in_seconds: 0,
          ...ACTION_CONFIG,
        },
      ],
    },
  },
  [ShopifyEventType.order_status_update]: {
    __typename: 'ShopifyEventConfig',
    type: ShopifyEventType.order_status_update,
    abandoned_cart: null,
    order_confirmation: null,
    product_visit: null,
    order_status_update: {
      __typename: 'OrderStatusUpdateEventConfig',
      actions: [
        {
          __typename: 'OrderStatusUpdateAction',
          delay_in_seconds: 180,
          ...ACTION_CONFIG,
        },
      ],
    },
  },
  [ShopifyEventType.product_visit]: {
    __typename: 'ShopifyEventConfig',
    type: ShopifyEventType.product_visit,
    order_confirmation: null,
    abandoned_cart: null,
    order_status_update: null,
    product_visit: {
      __typename: 'ProductVisitEventConfig',
      actions: [
        {
          __typename: 'ProductVisitAction',
          delay_in_minutes: 3,
          next_block_id: null,
          counter_id: null,
        },
      ],
    },
  },
  [ShopifyEventType.order_status_update]: {
    __typename: 'ShopifyEventConfig',
    type: ShopifyEventType.order_status_update,
    abandoned_cart: null,
    order_confirmation: null,
    product_visit: null,
    order_status_update: {
      __typename: 'OrderStatusUpdateEventConfig',
      actions: [
        {
          __typename: 'OrderStatusUpdateAction',
          delay_in_seconds: 180,
          ...ACTION_CONFIG,
        },
      ],
    },
  },
  [ShopifyEventType.product_visit]: {
    __typename: 'ShopifyEventConfig',
    type: ShopifyEventType.product_visit,
    order_confirmation: null,
    abandoned_cart: null,
    order_status_update: null,
    product_visit: {
      __typename: 'ProductVisitEventConfig',
      actions: [
        {
          __typename: 'ProductVisitAction',
          delay_in_minutes: 3,
          next_block_id: null,
          counter_id: null,
        },
      ],
    },
  },
};

export const PLUGIN_DEFAULT_CONFIG: ShopifyEventEntryPointConfig = {
  __typename: 'ShopifyEventEntryPointConfig',
  events: [
    SHOPIFY_EVENT_ENTRY_POINT_EVENT_DEFAULT_CONFIGS[
      ShopifyEventType.abandoned_cart
    ],
  ],
};

export const PLUGIN_INPUT_FIELD = 'shopifyEventEntryPointConfig';
