import React from 'react';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { HEADER_VALUE_LIMIT } from '../JsonPluginConst';
import { jsonPluginFragment_config as JSONPluginConfig } from '../@types/jsonPluginFragment';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useOneTimeState } from 'cf-common/src/utils/hooks';
import {
  fromValueHolders,
  useStatefulArray,
} from '@utils/React/useStatefulArray';
import { AttributesQuery_bot_variableSuggest as Attribute } from '@utils/AttributesUtils/@types/AttributesQuery';
import { JsonRequestHeaderItem } from './JsonRequestHeaderItem';

interface JsonRequestHeadersProps {
  headers: JSONPluginConfig['headers'];
  botAttributes: Attribute[];
  onHeadersChange(nextHeaders: JSONPluginConfig['headers']): void;
  onHeadersSave(nextHeaders: JSONPluginConfig['headers']): void;
}

export const JsonRequestHeaders: React.FC<JsonRequestHeadersProps> = ({
  headers,
  botAttributes,
  onHeadersChange,
  onHeadersSave,
}) => {
  const { t } = useSafeTranslation();
  const [isHeaderAdded, setIsHeaderAdded] = useOneTimeState(false);
  const {
    valueHolders: headerValues,
    changeItem,
    deleteItem,
    addItem,
  } = useStatefulArray(headers ?? [], onHeadersChange);

  return (
    <React.Fragment>
      <Type as="h3" weight="semibold" size="15px_DEPRECATED">
        {t('JSONPlugin-JSXText--992-headers')}
      </Type>
      <Spacer factor={1} />
      {headerValues.length > 0 && (
        <div>
          {headerValues.map(({ value: header, id }, index) => (
            <JsonRequestHeaderItem
              key={id}
              header={header}
              autoFocus={isHeaderAdded && headerValues.length === index + 1}
              botAttributes={botAttributes}
              onHeaderKeyChange={(nextKey) => {
                changeItem(id, (prevHeader) => ({
                  ...prevHeader,
                  key: nextKey,
                }));
              }}
              onHeaderValueChange={(value) => {
                changeItem(id, (prevHeader) => ({
                  ...prevHeader,
                  value: value.substr(0, HEADER_VALUE_LIMIT),
                }));
              }}
              onDelete={() => {
                deleteItem(id, (nextHeaders) => {
                  onHeadersSave(nextHeaders);
                });
              }}
              onBlur={() => onHeadersSave(fromValueHolders(headerValues))}
            />
          ))}
        </div>
      )}
      {headerValues.length! > 0 && <Spacer factor={1} />}
      <Button
        intent="secondary"
        size="m"
        icon={<Icon icon="plus" />}
        onClick={() => {
          addItem(
            {
              __typename: 'JsonPluginJsonPluginHeader',
              key: '',
              value: '',
            },
            (nextHeaders) => {
              onHeadersSave(nextHeaders);
              setIsHeaderAdded(true);
            },
          );
        }}
      >
        {t('JSONPlugin-JSXText-1933-add-header')}
      </Button>
    </React.Fragment>
  );
};
