import { PluginType } from '../common/PluginTypes';
import { removeAttributePluginFragment_config as RemoveAttributeConfig } from './@types/removeAttributePluginFragment';

export const PLUGIN_TYPE = PluginType.remove_attribute;

export const PLUGIN_DEFAULT_CONFIG: RemoveAttributeConfig = {
  __typename: 'RemoveAttributePluginConfig',
  title: null,
};

export const PLUGIN_INPUT_FIELD = 'removeAttributePluginConfig';
