import React from 'react';
import * as css from './BotSwitcher.css';
import { ScreenSizes } from '@utils/DOM/useDeviceMedia';
import {
  BotSwitcherRegular,
  BotSwitcherRegularProps,
} from './BotSwitcherRegular';
import { BotSwitcherSmall } from './BotSwitcherSmall';

export interface BotSwitcherProps
  extends Omit<BotSwitcherRegularProps, 'size' | 'className'> {
  screenSize: ScreenSizes;
}

export const BotSwitcher: React.FC<BotSwitcherProps> = (props) => {
  return {
    [ScreenSizes.small]: (
      <BotSwitcherRegular
        {...props}
        size="18px"
        className={css.wrapperMobile}
      />
    ),
    [ScreenSizes.medium]: (
      <BotSwitcherSmall {...props} className={css.wrapperTablet} />
    ),
    [ScreenSizes.large]: (
      <BotSwitcherRegular
        {...props}
        size="12px"
        className={css.wrapperDesktop}
      />
    ),
  }[props.screenSize];
};
