import React, { useRef, useState } from 'react';
import { Router } from 'react-router-dom';
import { Query, Mutation } from '@apollo/react-components';
import { MutationFetchResult } from '@apollo/react-common';
import { ApolloError } from 'apollo-client';
import { pipe, map, pathOr, fromPairs, values, objOf, defaultTo } from 'ramda';
import debounce from 'lodash-es/debounce';
import { log } from 'cf-common/src/logger';
import { removeTypename } from '@utils/GQL/utils';
import { IPlugin } from './Plugin';
import { useCurrentBotId, globalHistory } from '../../../utils/Routing';
import {
  PluginDataQueryVariables,
  PluginDataQuery_card,
} from './@types/PluginDataQuery';
import { isNewPlugin } from './createPlugin';
import { getPluginsDefaultData } from './pluginConst';
import { updateBlockValidStatusInGqlCacheById } from '../../Aside/BlocksGroupsQueries';
import { BLOCK_LINKS_QUERY } from '../../BlockLinks/BlockLinks.queries';
import {
  SavePluginMutationPluginData,
  SavePluginMutationPluginDataVariables,
} from './@types/SavePluginMutationPluginData';
import {
  PLUGIN_DATA_QUERY,
  SAVE_PLUGIN_MUTATION,
  temporaryPluginDataQuery,
} from './PluginGQL';
import { PluginType } from './PluginTypes';
import { refetchJsonAdsDataIfNeed } from '@utils/Data/Marketing/getAdsJsonDataObservable';
import { Card, ValidationErrors, LocalizationType } from './types';

export { Card, ValidationErrors };

export type PluginDataType<TPluginConfig> = {
  card: Card<TPluginConfig>;
};

export interface ISetPluginStateParams<TPluginConfig> {
  plugin_id?: PluginType;
  config?: TPluginConfig;
  localization?: LocalizationType;
  is_valid?: boolean | null;
  refresh?: boolean | null;
  enabled?: boolean | null;
}

export interface PluginDataChildrenParams<TPluginConfig> {
  pluginConfig: TPluginConfig;
  pluginLocalization: LocalizationType;
  validationErrors: ValidationErrors;
  setPluginConfigState: (params: ISetPluginStateParams<TPluginConfig>) => void;
  loading: boolean;
  loadingError: ApolloError | undefined;
  savePlugin: () => void;
  savePluginAsync: (
    _pluginConfig: TPluginConfig,
  ) => Promise<void | MutationFetchResult<SavePluginMutationPluginData>>;
  savingError: ApolloError | undefined;
  saving: boolean;
  isNewPlugin: boolean;
}

export interface IPluginData<TPluginConfig> extends IPlugin {
  children: (
    params: PluginDataChildrenParams<TPluginConfig>,
  ) => JSX.Element | null;
  pluginType: string;
  onCompletedLoad?: (pluginConfig: TPluginConfig) => void;
  preventRefetchBlockLinksData?: boolean; // refetch block links data in automate tab only
}

const prepareConfig = pipe(defaultTo({} as any), removeTypename) as any;

export const getPreparedToSaveMutationVariables = <TPluginConfig extends {}>({
  pluginConfig,
  localization,
  restVariables: {
    id,
    blockId,
    pluginType,
    position,
    refresh,
    enabled,
    synced,
  },
}: {
  pluginConfig?: TPluginConfig;
  localization?: LocalizationType;
  restVariables: SavePluginMutationPluginDataVariables;
}) => {
  return {
    id,
    blockId,
    pluginType,
    position,
    refresh,
    enabled,
    synced,
    ...(localization && {
      localization: localization.length
        ? removeTypename(localization)
        : undefined,
    }),
    ...(objOf(
      Object.keys(getPluginsDefaultData()[pluginType])[0],
      prepareConfig(pluginConfig),
    ) as PluginDataType<TPluginConfig>),
  };
};

export const prepareValidationErrors = pipe(map(values), fromPairs as any);

export type PluginDataSetPluginConfigState<TPluginConfig> = (
  update: ISetPluginStateParams<TPluginConfig>,
) => void;

const PluginData = <TPluginConfig extends {}>({
  children,
  id,
  blockId,
  pluginType,
  position,
  onCompletedLoad,
  preventRefetchBlockLinksData,
}: React.PropsWithChildren<IPluginData<TPluginConfig>>) => {
  const setPluginConfigState: PluginDataSetPluginConfigState<TPluginConfig> = (
    update,
  ) => {
    const pluginData = temporaryPluginDataQuery.read({
      id,
    });
    if (pluginData) {
      temporaryPluginDataQuery.write(
        {
          card: {
            ...pluginData.card,
            ...(update as PluginDataQuery_card),
          },
        },
        { id },
      );
    }
  };
  const botId = useCurrentBotId();

  // container for debounced save plugin variables
  const savePluginVariablesRef = useRef(
    {} as SavePluginMutationPluginDataVariables,
  );

  /**
   * We need use local caching data for fixed blink plugin where Apollo return empty data
   * on update him cache (after optimistic update by create plugin)
   */
  const [pluginDataUpdateCache, setPluginDataUpdateCache] = useState<
    Card<TPluginConfig> | undefined
  >();
  return (
    <Query<PluginDataType<TPluginConfig>, PluginDataQueryVariables>
      query={PLUGIN_DATA_QUERY}
      variables={{
        id,
      }}
      fetchPolicy="cache-only"
      onCompleted={(data) => {
        const config = data?.card.config;
        if (config && onCompletedLoad) {
          onCompletedLoad(config);
        }
      }}
      onError={(error) => {
        log.warn({ error, msg: `Error load ${id} data` });
        // We don’t show the toaster because it can generate many displays
        // (if there are a lot of plugins and the error is common)
      }}
    >
      {({ data, loading, error: loadingError }) => {
        const pluginDataCard = data?.card;

        if (pluginDataCard && isNewPlugin(id)) {
          setPluginDataUpdateCache(pluginDataCard); // view comment on useState call
        }

        const card = pluginDataCard || pluginDataUpdateCache;

        if (!card) {
          log.warn({ msg: `There is no card in plugin ${pluginType}` });
          return null;
        }

        const validationErrors = prepareValidationErrors(
          pathOr([], ['validation_details', 'fields'], card),
        ) as ValidationErrors;
        const pluginConfig = card.config as TPluginConfig;
        const pluginLocalization = (card.localization ||
          []) as LocalizationType;

        savePluginVariablesRef.current = getPreparedToSaveMutationVariables({
          pluginConfig,
          localization: pluginLocalization,
          restVariables: {
            id,
            blockId,
            pluginType,
            position,
          },
        });
        return (
          <Mutation<
            SavePluginMutationPluginData,
            SavePluginMutationPluginDataVariables
          >
            // TODO
            mutation={SAVE_PLUGIN_MUTATION}
            refetchQueries={
              preventRefetchBlockLinksData
                ? undefined
                : [{ query: BLOCK_LINKS_QUERY, variables: { botId, blockId } }]
            }
            onCompleted={() => {
              refetchJsonAdsDataIfNeed(botId!, blockId);
              updateBlockValidStatusInGqlCacheById(blockId);
            }}
            onError={(error) => {
              log.warn({
                error,
                msg: 'Error load plugin data: save plugin mutation',
              });
            }}
          >
            {(savePluginMutation, { loading: saving, error: savingError }) => {
              const savePlugin = debounce(() => {
                const variables = savePluginVariablesRef.current;
                if (variables && variables.id && !isNewPlugin(variables.id)) {
                  savePluginMutation({ variables });
                }
              }, 100);

              // _pluginConfig is needed since savePluginVariablesRef.current might have obsolete data
              const savePluginAsync = async (_pluginConfig: TPluginConfig) => {
                const variables = savePluginVariablesRef.current;
                if (variables && variables.id && !isNewPlugin(variables.id)) {
                  return savePluginMutation({
                    variables: getPreparedToSaveMutationVariables({
                      pluginConfig: _pluginConfig,
                      restVariables: variables,
                    }),
                  });
                }
                return undefined;
              };

              return children({
                loading,
                loadingError,
                pluginConfig,
                pluginLocalization,
                validationErrors,
                setPluginConfigState,
                savePlugin,
                saving,
                savePluginAsync, // if need async control for plugin saving
                savingError,
                isNewPlugin: isNewPlugin(id),
              });
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

const PluginDataWithHistory: typeof PluginData = (props) => (
  <Router history={globalHistory}>
    <PluginData {...props} />
  </Router>
);

export { PluginDataWithHistory as PluginData };
