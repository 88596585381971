import React from 'react';
import cn from 'classnames';
import { Badge } from '@ui/Badge';
import { Type } from '@ui/Type';
import { BusinessVerificationStatus } from '@globals';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './HeaderBadge.css';

export interface HeaderBadgeProps {
  verificationStatus: BusinessVerificationStatus;
}

export const HeaderBadge: React.FC<HeaderBadgeProps> = ({
  verificationStatus,
}) => {
  const { t } = useSafeTranslation();

  // eslint-disable-next-line no-underscore-dangle
  if (verificationStatus === BusinessVerificationStatus._UNKNOWN_) {
    return null;
  }

  return (
    <Badge
      className={cn(
        css.badge,
        {
          [BusinessVerificationStatus.not_verified]: css.notVerified,
          [BusinessVerificationStatus.verified]: css.verified,
          [BusinessVerificationStatus.pending_need_more_info]: css.needMoreInfo,
          [BusinessVerificationStatus.pending_submission]: css.pending,
        }[verificationStatus],
      )}
      kind="primary"
      size="s"
    >
      <Type size="12px" weight="medium" color="white">
        {t(`MiniOnboarding.banner.virifiedStatus.${verificationStatus}`)}
      </Type>
    </Badge>
  );
};
