import React, { useEffect, useRef, useState } from 'react';
import { PLAYER_OPTIONS } from '@components/YouTubeFramePlayer/constants';
import { useCloneFlowGroup } from '@pages/BotPage/FlowTab/FlowList/FlowGroup/FlowGroupMutations';
import { Platform } from '@globals';
import cn from 'classnames';
import { CenteredLoader } from '@ui/Loader';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { useHistory } from 'react-router-dom';
import YouTube from 'react-youtube';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import {
  ServerStorageItemKeys,
  serverStorageItemSet,
} from '@utils/ServerStorage';
import { sendEvent } from '@utils/Analytics';
import { showSomethingWentWrongToaster } from '@services/MessageService';
import css from './MiniOnboardingDialog.css';

interface MiniOnboardingDialogProps {
  title: string;
  templateFlowGroupId: string;
  platform: Platform;
  videoId: string | null;
  flowId: string | null;
  onClose: VoidFunction;
  hasFlowTour: boolean;
}

export const MiniOnboardingDialog: React.FC<MiniOnboardingDialogProps> = ({
  title,
  templateFlowGroupId,
  platform,
  videoId,
  flowId,
  hasFlowTour,
  onClose,
}) => {
  const botId = useCurrentBotId();
  const history = useHistory();
  const initRef = useRef(false);
  const [youTubeLoading, setYouTubeLoading] = useState(true);

  const { cloneFlowGroup, loading: clonning } = useCloneFlowGroup({
    botId,
    groupId: templateFlowGroupId,
    onCompleted(data) {
      if (data) {
        const flowId = data.cloneFlowGroup?.flow_ids?.[0] ?? '';
        sendEvent({
          category: 'mini onboarding banner',
          action: 'create flow group',
          propertyBag: {
            templateFlowGroupId,
            botId,
            title,
            platform,
          },
        });
        history.push(
          getTabLink(BotTabs.flows, botId!, { flowId, onboardingTour: flowId }),
        );
        if (!videoId || hasFlowTour) onClose();
      }
    },
    onError() {
      onClose();
      showSomethingWentWrongToaster();
    },
  });

  useEffect(() => {
    if (!botId || initRef.current) return;

    initRef.current = true;

    if (flowId) {
      history.push(`/bot/${botId}/${BotTabs.flows}/${flowId}`);
      if (!videoId) onClose();
    } else {
      const item = {
        [Platform.facebook]: ServerStorageItemKeys.isClosedBasicFlowTutorial,
        [Platform.instagram]:
          ServerStorageItemKeys.isClosedBasicInstagramFlowTutorial,
        [Platform.whatsapp]: undefined,
      }[platform];
      if (item) serverStorageItemSet(item, true);
      serverStorageItemSet(
        ServerStorageItemKeys.isTemplatesModalForNewUserShowed,
        true,
      );

      cloneFlowGroup(botId);
    }
  }, [
    flowId,
    cloneFlowGroup,
    botId,
    platform,
    history,
    videoId,
    onClose,
    hasFlowTour,
  ]);

  const loading = youTubeLoading || clonning;

  return (
    <>
      {loading && <CenteredLoader />}
      {videoId && (
        <div className={cn(css.container, loading && css.loading)}>
          <Button
            intent="secondary"
            icon={<Icon icon="close" />}
            className={css.close}
            onClick={onClose}
          />
          <YouTube
            videoId={videoId}
            onReady={() => setYouTubeLoading(false)}
            onPlay={() =>
              sendEvent({
                category: 'mini onboarding banner',
                action: 'play youTube video',
                propertyBag: {
                  botId,
                  title,
                  templateFlowGroupId,
                  platform,
                  videoId,
                },
              })
            }
            onEnd={() =>
              sendEvent({
                category: 'mini onboarding banner',
                action: 'viewed youTube video',
                propertyBag: {
                  botId,
                  title,
                  templateFlowGroupId,
                  platform,
                  videoId,
                },
              })
            }
            opts={{
              ...PLAYER_OPTIONS,
              width: '620px',
              height: '350px',
              playerVars: { ...PLAYER_OPTIONS.playerVars, autoplay: 0 },
            }}
          />
        </div>
      )}
    </>
  );
};
