import React from 'react';

import { Anchor } from '../../../../../modern-ui/Links';
import { Type } from '../../../../../modern-ui/Type';

import { isWhiteLabelDomain } from '../../../../../utils/WhiteLabelUtils';
import { sendEvent } from '../../../../../utils/Analytics';
import { PluginBlock } from '../../../common/PluginBlock';

import { PLUGIN_HELP_URL } from '../../FacebookAnalyticsPluginConst';

export const FacebookAnalyticsPluginHeader: React.FC = () => (
  <PluginBlock>
    <Type size="15px_DEPRECATED">
      {window.i18next.t(
        'FacebookAnalyticsPluginHeader-JSXText--359-use-this-plugin-to-track-funnel-conversions-in-facebook-ads-manager-or-facebook-events-it-will-send-conversion-events-to-facebook-just-like-pixel-does-but-for-your-bot-instead-of-your-website',
      )}
    </Type>
    {!isWhiteLabelDomain() && (
      <Anchor
        hideArrow
        intent="external"
        href={PLUGIN_HELP_URL}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          sendEvent({
            category: 'events to fb',
            action: 'click',
            label: 'learn more',
          });
        }}
      >
        {window.i18next.t(
          'FacebookAnalyticsPluginHeader-JSXText--128-learn-more',
        )}
      </Anchor>
    )}
  </PluginBlock>
);
