import React, { useState } from 'react';
import { StripeElementBase } from '@stripe/stripe-js';
import { sendEvent, sendEventToGA } from '@utils/Analytics';
import { submitPaymentEvent } from 'cf-common/src/conversionTracking';
import { useCurrentBotId } from '@utils/Routing';

interface UsePaymentFormArgs {
  initialPayment: boolean;
  complete: boolean;
  onSubmit?(): void;
}

export const usePaymentForm = ({
  initialPayment,
  complete,
  onSubmit,
}: UsePaymentFormArgs) => {
  const [cardElementIsReady, setCardElementReady] = useState(false);
  const botId = useCurrentBotId();

  const handleCardReady = (cardElement: StripeElementBase) => {
    cardElement.focus();
    setCardElementReady(true);
  };

  const handlePayment = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!complete) return;

    if (initialPayment) {
      sendEvent({
        category: 'pro plan payments',
        action: 'subscribe',
        propertyBag: {
          paymentLabel: '3d secure',
        },
      });
      submitPaymentEvent(undefined, botId!);
      sendEventToGA({
        type: 'user',
        name: 'made',
        label: 'payment',
      });
    } else {
      sendEvent({
        category: 'pro plan payments',
        action: 'update',
        propertyBag: {
          paymentLabel: '3d secure',
        },
      });
    }

    onSubmit?.();
  };

  return {
    cardElementIsReady,
    handleCardReady,
    handlePayment,
  };
};
