import React, { useCallback, useRef } from 'react';
import cn from 'classnames';
import { CollapsibleSection } from '@ui/CollapsibleSection';
import { sendEvent } from '@utils/Analytics';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { BotTabs, useCurrentBotId, useCurrentTab } from '@utils/Routing';
import { useBotFeatures } from '@utils/Data/Bot';
import { useBotWorkspaceId } from '@utils/Data/Bot/useBotWorkspaceId';
import { HomeNavItem } from '../Tabs/GrowNavItem';
import { LiveChatNavItem } from '../Tabs/LiveChatNavItem';
import { PeopleNavItem } from '../Tabs/PeopleNavItem';
import { ReEngageNavItem } from '../Tabs/ReEngageNavItem';
import { ConfigureNavItem } from '../Tabs/ConfigureNavItem';
import { AnalyzeNavItem } from '../Tabs/AnalyzeNavItem';
import { UpgradeNavItem } from '../Tabs/UpgradeNavItem';
import { BillingNavItem } from '../Tabs/BillingNavItem';
import { AutomationNavItem } from '../Tabs/AutomationNavItem';
import { AutomationNavSubItems } from '../Tabs/AutomationNavSubItems';
import { MessageTemplatesNavSubItems } from '../Tabs/MessageTemplatesNavSubItems';
import { FlowsNavItem } from '../Tabs/FlowsNavItem';
import { AiNavItem } from '../Tabs/AiNavItem';
import { BillingNavSubItems } from '../Tabs/BillingNavSubItems';
import * as commonCSS from '../../common.css';
import * as css from './NavigationItemsList.css';
import { useTimeLimitedTrial } from '@utils/Data/Pricing/useTimeLimitedTrial';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import { MessagesTemplatesNavItem } from '../Tabs/MessagesTemplatesNavItem';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';

const AUTOMATION_TAB_SUB_ITEMS: string[] = [
  BotTabs.automate,
  BotTabs.flows,
  BotTabs.keywords,
];

const BILLING_TAB_SUB_ITEMS: string[] = [BotTabs.plans, BotTabs.billing];

const MESSAGES_TEMPLATES_TAB_SUB_ITEMS: string[] = [
  BotTabs.reEngage,
  BotTabs.messagesTemplates,
];

interface NavigationItemsListProps {
  onItemClick: () => void;
}

export const NavigationItemsList: React.FC<NavigationItemsListProps> = ({
  onItemClick,
}) => {
  const { isMediumScreenSize } = useDeviceMedia();
  const changeExtendedRef = useRef<(() => void) | undefined>();
  const currentTab = useCurrentTab()!;
  const isAutomation = AUTOMATION_TAB_SUB_ITEMS.includes(currentTab);
  const isBilling = BILLING_TAB_SUB_ITEMS.includes(currentTab);
  const isMessagingTempaltes =
    MESSAGES_TEMPLATES_TAB_SUB_ITEMS.includes(currentTab);
  const botId = useCurrentBotId()!;
  const { botFeatures } = useBotFeatures(botId);
  const [workspaceId] = useBotWorkspaceId(botId);
  const { isAutomateEnabled } = useAutomateEnabled();
  const { currentTierTimeLimitedTrial, trialExpired, timeLimitedTrialLoading } =
    useTimeLimitedTrial();
  const { isCreateWhatsappTemplateInCfEnable } =
    useCreateWhatsappTemplateInCfEnable();
  const { account, loading: accountLoading } = useShopifyAccount();

  const isShowUpgradeTab =
    !botFeatures?.dialogs_pricing_enabled &&
    !workspaceId &&
    !account &&
    !accountLoading;

  const isShowBillingTab = botFeatures?.dialogs_pricing_enabled && !workspaceId;

  const disabledByTimeLimitedTrial = Boolean(
    currentTierTimeLimitedTrial && trialExpired && !timeLimitedTrialLoading,
  );

  const showBlocksTab = isAutomateEnabled;

  const handleClick = useCallback(() => {
    changeExtendedRef.current?.();
    onItemClick?.();
  }, [onItemClick]);

  const { isLiveChatWebView } = useIsLiveChatWebView();
  if (isLiveChatWebView) {
    return null;
  }

  return (
    <nav data-testid="test-navigation-panel" className={css.flexColumn}>
      <ul className={cn(css.flexColumn, css.resetListStyles)}>
        <HomeNavItem
          small={isMediumScreenSize}
          onClick={handleClick}
          disabled={disabledByTimeLimitedTrial}
        />

        {showBlocksTab &&
          (isMediumScreenSize ? (
            <AutomationNavItem
              small={isMediumScreenSize}
              className={cn({
                [commonCSS.activeLink]: isAutomation,
              })}
              disabled={disabledByTimeLimitedTrial}
            />
          ) : (
            <div>
              <CollapsibleSection
                controllable
                defaultExtended={isAutomation}
                anchor={({ changeExtended, extended }) => {
                  changeExtendedRef.current = () =>
                    extended && changeExtended();

                  return (
                    <AutomationNavItem
                      small={isMediumScreenSize}
                      onClick={() => {
                        if (extended) {
                          return;
                        }

                        sendEvent({
                          category: 'tab',
                          action: 'click',
                          label: 'expand automation',
                        });
                        changeExtended();
                      }}
                      className={cn({
                        [commonCSS.activeLink]: isAutomation,
                      })}
                      disabled={disabledByTimeLimitedTrial}
                    />
                  );
                }}
              >
                {({ bind, extended }) => (
                  <div
                    {...bind}
                    className={cn({
                      [commonCSS.activeLink]: extended,
                      [commonCSS.extended]: extended,
                    })}
                  >
                    <AutomationNavSubItems
                      onClick={onItemClick}
                      className={css.linkCollapsibleContent}
                      small={isMediumScreenSize}
                      disabled={disabledByTimeLimitedTrial}
                    />
                  </div>
                )}
              </CollapsibleSection>
            </div>
          ))}
        {!showBlocksTab && (
          <>
            <FlowsNavItem
              small={isMediumScreenSize}
              onClick={handleClick}
              disabled={disabledByTimeLimitedTrial}
            />
            <AiNavItem
              small={isMediumScreenSize}
              onClick={handleClick}
              disabled={disabledByTimeLimitedTrial}
            />
          </>
        )}
        {botFeatures?.livechat_v2 && (
          <LiveChatNavItem
            small={isMediumScreenSize}
            onClick={handleClick}
            disabled={disabledByTimeLimitedTrial}
          />
        )}
        <PeopleNavItem
          small={isMediumScreenSize}
          onClick={handleClick}
          disabled={disabledByTimeLimitedTrial}
        />
        {!isCreateWhatsappTemplateInCfEnable && (
          <ReEngageNavItem
            small={isMediumScreenSize}
            onClick={handleClick}
            disabled={disabledByTimeLimitedTrial}
          />
        )}
        {isCreateWhatsappTemplateInCfEnable &&
          (isMediumScreenSize ? (
            <MessagesTemplatesNavItem
              small={isMediumScreenSize}
              className={cn({
                [commonCSS.activeLink]: isMessagingTempaltes,
              })}
              disabled={disabledByTimeLimitedTrial}
            />
          ) : (
            <div>
              <CollapsibleSection
                controllable
                defaultExtended={isMessagingTempaltes}
                anchor={({ changeExtended, extended }) => {
                  changeExtendedRef.current = () =>
                    extended && changeExtended();

                  return (
                    <MessagesTemplatesNavItem
                      small={isMediumScreenSize}
                      onClick={() => {
                        if (extended) {
                          return;
                        }

                        sendEvent({
                          category: 'tab',
                          action: 'click',
                          label: 'expand broadcasts',
                        });
                        changeExtended();
                      }}
                      className={cn({
                        [commonCSS.activeLink]: isMessagingTempaltes,
                      })}
                      disabled={disabledByTimeLimitedTrial}
                    />
                  );
                }}
              >
                {({ bind, extended }) => (
                  <div
                    {...bind}
                    className={cn({
                      [commonCSS.activeLink]: extended,
                      [commonCSS.extended]: extended,
                    })}
                  >
                    <MessageTemplatesNavSubItems
                      onClick={onItemClick}
                      className={css.linkCollapsibleContent}
                      small={isMediumScreenSize}
                      disabled={disabledByTimeLimitedTrial}
                    />
                  </div>
                )}
              </CollapsibleSection>
            </div>
          ))}
        <ConfigureNavItem
          small={isMediumScreenSize}
          onClick={handleClick}
          disabled={disabledByTimeLimitedTrial}
        />
        {botFeatures?.enable_analyze && (
          <AnalyzeNavItem
            small={isMediumScreenSize}
            onClick={handleClick}
            disabled={disabledByTimeLimitedTrial}
          />
        )}
        {isShowUpgradeTab && (
          <UpgradeNavItem
            small={isMediumScreenSize}
            onClick={handleClick}
            disabled={disabledByTimeLimitedTrial}
          />
        )}
        {isShowBillingTab &&
          (isMediumScreenSize ? (
            <BillingNavItem
              small={isMediumScreenSize}
              className={cn({
                [commonCSS.activeLink]: isBilling,
              })}
            />
          ) : (
            <div>
              <CollapsibleSection
                controllable
                defaultExtended={isBilling}
                anchor={({ changeExtended, extended }) => {
                  changeExtendedRef.current = () =>
                    extended && changeExtended();

                  return (
                    <BillingNavItem
                      small={isMediumScreenSize}
                      onClick={() => {
                        if (extended) {
                          return;
                        }

                        sendEvent({
                          category: 'tab',
                          action: 'click',
                          label: 'expand billing',
                        });
                        changeExtended();
                      }}
                      className={cn({
                        [commonCSS.activeLink]: isBilling,
                      })}
                    />
                  );
                }}
              >
                {({ bind, extended }) => (
                  <div
                    {...bind}
                    className={cn({
                      [commonCSS.activeLink]: extended,
                      [commonCSS.extended]: extended,
                    })}
                  >
                    <BillingNavSubItems
                      onClick={onItemClick}
                      className={css.linkCollapsibleContent}
                      small={isMediumScreenSize}
                    />
                  </div>
                )}
              </CollapsibleSection>
            </div>
          ))}
        <div
          data-testid="test-navigation-panel-inner"
          className={cn(css.clickerForTestDropFocus)}
        />
      </ul>
    </nav>
  );
};
