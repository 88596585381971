import React from 'react';
import { ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { NavigationCompanyIcon } from '../NavigationCompanyIcon/NavigationCompanyIcon';
import { useIsFirstSessionTab } from '@utils/Routing';
import { LocaleMenu } from '@common/user/UserNavigationItem/LocaleMenu/LocaleMenu';
import * as css from './NavigationHeader.css';

export interface NavigationHeaderProps {
  buttonWrapper: boolean;
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
}

const HamburgerMenu = ({ onClick }: { onClick: VoidFunction }) => (
  <ButtonUnstyled className={css.hamburgerMenu} onClick={onClick}>
    <Icon icon="hamburger" size="24px" color="white" />
  </ButtonUnstyled>
);

export const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  isMenuOpen,
  setIsMenuOpen,
  buttonWrapper,
}) => {
  const { isSmallScreenSize, isMediumScreenSize } = useDeviceMedia();
  const isFirstSessionTab = useIsFirstSessionTab();
  const specialTab = isFirstSessionTab;

  if (isMenuOpen && !specialTab) {
    return (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className={css.openedMenu}
      >
        <NavigationCompanyIcon
          buttonWrapper={buttonWrapper}
          fullLogo={!isMediumScreenSize}
          big={isSmallScreenSize}
        />
        <ButtonUnstyled
          onClick={() => {
            setIsMenuOpen(false);
          }}
        >
          <Icon icon="close" size="24px" color="white" />
        </ButtonUnstyled>
      </Flex>
    );
  }

  return (
    <Flex
      justifyContent={specialTab ? 'space-between' : 'center'}
      className={css.closedMenu}
    >
      {!specialTab && isSmallScreenSize && (
        <div className={css.noSpecialTabMenu}>
          <HamburgerMenu onClick={() => setIsMenuOpen(!isMenuOpen)} />
        </div>
      )}
      <div />
      <NavigationCompanyIcon
        buttonWrapper={buttonWrapper}
        fullLogo={!isMediumScreenSize}
        small={isMediumScreenSize}
        wide
      />
      {specialTab && !isSmallScreenSize && (
        <>
          <LocaleMenu className={css.localeMenu} />
        </>
      )}
      {specialTab && isSmallScreenSize && (
        <HamburgerMenu onClick={() => setIsMenuOpen(!isMenuOpen)} />
      )}
    </Flex>
  );
};
