import React from 'react';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { BotSwitcherNewPricing } from './BotSwitcherNewPricing';
import { BotSwitcherOldPricing } from './BotSwitcherOldPricing';
import { BotSwitcherWhiteLabel } from './BotSwitcherWhiteLabel';
import { BotSwitcherWorkspace } from './BotSwitcherWorkspace';
import { BotSwitcherProps } from './types';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';

export interface BotSwitcherFactoryProps extends BotSwitcherProps {
  dialogsPricing: boolean;
  isWorkspace: boolean;
}

export const BotSwitcherFactory: React.FC<BotSwitcherFactoryProps> = ({
  dialogsPricing,
  isWorkspace,
  ...props
}) => {
  const { isLiveChatWebView } = useIsLiveChatWebView();

  if (isLiveChatWebView) {
    return <BotSwitcherWhiteLabel {...props} />;
  }

  if (isWorkspace) {
    return <BotSwitcherWorkspace {...props} />;
  }

  if (isWhiteLabelDomain()) {
    return <BotSwitcherWhiteLabel {...props} />;
  }

  if (dialogsPricing) {
    return <BotSwitcherNewPricing {...props} />;
  }

  if (!dialogsPricing) {
    return <BotSwitcherOldPricing {...props} />;
  }

  return null;
};
