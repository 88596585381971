import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment splitTrafficPluginFragment on SplitTrafficPlugin {
    ...commonPluginFragment
    config {
      random
      variants {
        percent
        block_id
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
