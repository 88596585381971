import cn from 'classnames';
import { Avatar } from '@ui/Avatar';
import { ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { IconButton } from '@ui/IconButton';
import { Type } from '@ui/Type';
import React from 'react';
import { Loader } from '@ui/Loader';
import css from './UserListItem.css';
import { TextEllipsis } from '@ui/TextEllipsis';
import Maybe from 'graphql/tsutils/Maybe';
import { PopperMenu } from '@ui/NestedMenu';
import { PopperMenuProps } from '@ui/NestedMenu/PopperMenu/PopperMenu';

interface User {
  id: string;
  name: Maybe<string> | React.ReactNode;
  pictureUrl?: Maybe<string>;
  subtitle: Maybe<string>;
}

export interface UserListItemProps {
  className?: string;
  onRemove?: (user: User) => void;
  user: User;
  pending?: boolean;
  menuItems?: PopperMenuProps['items'];
  onMenuItemSelect?: PopperMenuProps['onItemSelect'];
}

export const UserListItem: React.FC<UserListItemProps> = ({
  user,
  className,
  onMenuItemSelect,
  onRemove,
  pending,
  menuItems,
}) => {
  const { pictureUrl, name, subtitle } = user;

  return (
    <Flex className={cn(css.userListItem, className)}>
      {pending ? (
        <Loader className={css.avatar} />
      ) : (
        <Avatar src={pictureUrl} className={css.avatar} />
      )}
      <Flex flexDirection="column" className={css.titleContainer}>
        <TextEllipsis>
          <Type size="15px">{name}</Type>
        </TextEllipsis>
        {!menuItems ? (
          <Type size="15px" color="greyDark">
            {subtitle}
          </Type>
        ) : (
          <PopperMenu
            placement="bottom-start"
            modifiers={{
              preventOverflow: {
                boundariesElement: 'viewport',
              },
            }}
            menuStopPropagation
            items={menuItems}
            onItemSelect={(item) => onMenuItemSelect?.(item)}
            className={css.popperMenu}
          >
            {(ref, { setOpen }) => (
              <ButtonUnstyled
                ref={ref}
                className={css.roleButton}
                onClick={() => {
                  setOpen(true);
                }}
              >
                {subtitle}
                <Icon
                  icon="triangle"
                  color="blue"
                  className={css.triangleIcon}
                />
              </ButtonUnstyled>
            )}
          </PopperMenu>
        )}
      </Flex>
      {onRemove && !pending && (
        <IconButton
          icon="delete"
          color="greyDark"
          className={css.deleteButton}
          onClick={() => onRemove(user)}
        />
      )}
    </Flex>
  );
};
