import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import noop from 'lodash-es/noop';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { ProBadge } from '@components/ProBadge';
import { Tooltip2 } from '@ui/Tooltip2';
import * as css from './ProInformer.css';

interface ProInformerProps {
  botIsPro: boolean;
  botInDebt: boolean;
  daysLeft: number | undefined;
  handlePaymentProblem?: () => void;
}

export const ProInformer = React.memo<ProInformerProps>(
  ({ botIsPro, botInDebt, daysLeft, handlePaymentProblem }) => {
    const needToShowProIcon = botIsPro;
    const needToShowDebtIcon = botInDebt;

    const { t } = useSafeTranslation();

    return (
      <>
        {(needToShowProIcon || needToShowDebtIcon) && (
          <Spacer factor={1} horizontalFactor={2} />
        )}
        <div className={css.proInformer}>
          {needToShowProIcon && (
            <ProBadge size="12px" data-testid="pro-informer__label" />
          )}
          {needToShowDebtIcon && (
            <Tooltip2
              content={t(
                'modernUi.PageHeader.BotHeader.ProInformer.paymentProblem',
                { count: daysLeft },
              )}
              positionFixed
              placement="bottom"
              hoverable
            >
              {(ref, bind) => (
                <div
                  className={css.warningIconWrapper}
                  data-testid="pro-informer__warning-label"
                  {...bind}
                  ref={ref}
                  onClick={handlePaymentProblem}
                  onKeyUp={noop}
                >
                  <Icon icon="warning" size="28px" color="red" />
                </div>
              )}
            </Tooltip2>
          )}
        </div>
      </>
    );
  },
);
