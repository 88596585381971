import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import * as css from './TabLinkSkeleton.css';
import { NavItemViewProps } from '../Tabs/types';

export const TabLinkSkeleton: React.FC<NavItemViewProps> = ({ small }) =>
  small ? (
    <Flex
      className={css.wrapperSmall}
      alignItems="center"
      justifyContent="center"
    >
      <LoadingPlaceholder
        width={16}
        height={16}
        color="grey"
        className={css.circle}
      />
    </Flex>
  ) : (
    <Flex alignItems="center" className={css.wrapper}>
      <LoadingPlaceholder
        width={14}
        height={14}
        color="grey"
        className={cn(css.circle, css.circleLayout)}
      />
      <LoadingPlaceholder
        width={68}
        height={8}
        color="grey"
        className={css.block}
      />
    </Flex>
  );
