import React from 'react';
import { Flex } from '@ui/Flex';
import { IconButton } from '@ui/IconButton';
import css from './AttributesHeader.css';
import { Type } from '@ui/Type';
import { LiveChatMobilePage } from '@pages/LiveChat/useLiveChatMobilePageNavigation';
import { LivechatSubscriberQuery_livechatConversation_subscriber } from '@pages/LiveChat/common/@types/LivechatSubscriberQuery';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface AttributesHeaderProps {
  setActivePage(page: LiveChatMobilePage): void;
  subscriber?: LivechatSubscriberQuery_livechatConversation_subscriber;
}

export const AttributesHeader: React.FC<AttributesHeaderProps> = ({
  setActivePage,
  subscriber,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Flex className={css.root} alignItems="center">
      <IconButton
        icon="leftArrow"
        color="white"
        onClick={() => {
          setActivePage(LiveChatMobilePage.chat);
        }}
      />
      <Type
        className={css.chatWith}
        size="15px"
        weight="medium"
        color="white"
        whiteSpace="nowrap"
      >
        {`${t('pages.LiveChat.pageHeader.chatWith')} ${subscriber?.name}`}
      </Type>
    </Flex>
  );
};
