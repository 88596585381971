import React from 'react';
import { Icon, IconProps } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import { Category } from '../types';
import * as css from './CategoryIcon.css';

export interface PlatformIconProps extends Omit<IconProps, 'icon' | 'size'> {
  category: Category;
}

const iconMap: Record<Category, IconProps['icon']> = {
  [Category.facebook]: 'messengerLogo',
  [Category.instagram]: 'directLogo',
  [Category.website]: 'websiteLogo',
  [Category.shopify]: 'shopifyLogo',
  [Category.whatsapp]: 'whatsappLogo',
};

export const CategoryIcon: React.FC<PlatformIconProps> = ({
  category,
  ...iconProps
}) => {
  const icon = iconMap[category];
  if (!icon) {
    throw new Error(`Icon "${category}" was not found`);
  }
  return (
    <Flex
      {...iconProps}
      className={css.iconWrapper}
      justifyContent="center"
      alignItems="center"
    >
      <Icon icon={icon} size="12px" />
    </Flex>
  );
};
