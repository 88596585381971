import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment audioPluginFragment on AudioPlugin {
    ...commonPluginFragment
    config {
      url
      filename
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
