import { OnboardingTourEventType } from '@globals';
import { EventEmitter } from '@utils/EventEmitter';
import { OnboardingEmitterPayload } from '../../types';

/**
 * События для тригера перехода на следующий шаг онбординга или выполнения условий текущего шага
 */
export const OnboardingEmitter = new EventEmitter<
  void,
  OnboardingTourEventType,
  OnboardingEmitterPayload
>();

export enum OnboardingState {
  abort = 'abort',
}

/**
 * Событие для ручного выключения онбординга
 */
export const OnboardingManualStateEmitter = new EventEmitter<
  void,
  OnboardingState,
  void
>();
