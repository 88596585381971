import React from 'react';
import { OnboardingTipsStatus, Platform } from '@globals';
import { EntryPointTipsQuery_entryPointTips_sections as Sections } from '@utils/Data/MiniOnboarding/@types/EntryPointTipsQuery';
import { useOnboardingOpenSectionState } from './hooks';
import { OnboardingSection } from './OnboardingSection';
import css from './OnBoardingSectionList.css';
import { usePreopenedTip } from './features/PreopenedTip/usePreopenedTip';
import { FadeInOutOpacity } from '@components/Onboarding/OnboardingTour/FadeInOutOpacity/FadeInOutOpacity';
import { VerticalLine } from './Container/Line/VerticalLine';
import { useCurrentBotId } from '@utils/Routing';

export const getCommonInitialState = (sections: Sections[]) =>
  sections.reduce((prev, curr) => {
    const isSectionCompleted = curr.entryPoints.every(
      ({ status }) => status === OnboardingTipsStatus.done,
    );
    const isOneofPreviousSectionsCompleted = Object.values(prev).some(Boolean);
    const openSection =
      !isSectionCompleted && !isOneofPreviousSectionsCompleted;

    return {
      ...prev,
      [curr.id]: openSection,
    };
  }, {});

interface OnBoardingSectionsProps {
  platform: Platform;
  sections: Sections[];
}

export const OnBoardingSectionList: React.FC<OnBoardingSectionsProps> = ({
  platform,
  sections,
}) => {
  const botId = useCurrentBotId()!;
  const initialState = getCommonInitialState(sections);
  const { getState, toggleState, setState } = useOnboardingOpenSectionState(
    botId,
    initialState,
  );
  const { shouldBePreopened, setPreopenedTip } = usePreopenedTip(sections);

  return (
    <div className={css.sectionsWrapper}>
      {sections.map((section, index) => (
        <OnboardingSection
          index={index}
          key={section.id}
          platform={platform}
          title={section.title}
          entryPoints={section.entryPoints}
          opened={getState(section.id) || shouldBePreopened(index)}
          halfOpened={shouldBePreopened(index)}
          onAnchorClick={() => {
            if (shouldBePreopened(index)) {
              setState(section.id, true);
            } else {
              toggleState(section.id);
            }

            setPreopenedTip(false);
          }}
          line={(show) => {
            const nextSections = sections
              .slice(index + 1)
              .flatMap((section) => section.entryPoints);
            const isNextComplited = nextSections.some(
              ({ status }) => status === OnboardingTipsStatus.done,
            );
            if (index === sections.length - 1) {
              return null;
            }

            return (
              <FadeInOutOpacity show={show}>
                <VerticalLine platform={platform} enabled={isNextComplited} />
              </FadeInOutOpacity>
            );
          }}
        />
      ))}
    </div>
  );
};
