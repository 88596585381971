import { PluginType } from '../common/PluginTypes';
import { botLinkEntryPointFragment_config as BotLinkEntryPointConfig } from './@types/botLinkEntryPointFragment';

export const PLUGIN_TYPE = PluginType.ref_link_entry_point;

export const PLUGIN_DEFAULT_CONFIG: BotLinkEntryPointConfig = {
  __typename: 'BotLinkEntryPointConfig',
  block_id: null,
  referral: '',
};

export const PLUGIN_INPUT_FIELD = 'botLinkEntryPointConfig';
