import { PluginType } from '../common/PluginTypes';
import { adCommentsAutoreplyEntryPointFragment_config } from './@types/adCommentsAutoreplyEntryPointFragment';

export type AdCommentsAutoreplyEntryPointConfig =
  adCommentsAutoreplyEntryPointFragment_config;

export const PLUGIN_TYPE = PluginType.ad_comments_autoreply_entry_point;

export const PLUGIN_DEFAULT_CONFIG: AdCommentsAutoreplyEntryPointConfig = {
  __typename: 'AdCommentsAutoreplyEntryPointConfig',
  adAccount: null,
  posts: [],
  apply_for_all_posts: null,
  already_subscribed_setup_enabled: false,
  keywords_setup: {
    __typename: 'KeywordsSetup',
    intents: [],
    default_block_id: null,
  },
  already_subscribed_keywords_setup: {
    __typename: 'KeywordsSetup',
    intents: [],
    default_block_id: null,
  },
};

export const PLUGIN_INPUT_FIELD = 'adCommentsAutoreplyEntryPointConfig';
