import { Rect } from '@components/Onboarding/types';
import { usePrevious } from 'cf-common/src/utils/hooks';
import { useMemo } from 'react';

const CALLOUT_OFFSET = 25;
const CALLOUT_WIDTH = 300;

type Payload = {
  focusZoneBounds: Rect | undefined | null;
  windowSize: { width: number; height: number };
  calloutSize: { height: number } | null;
};

const getCalloutCoord = ({
  focusZoneBounds,
  windowSize,
  calloutSize,
}: Payload) => {
  if (!focusZoneBounds) return null;
  const isFitsLeft = focusZoneBounds.x - CALLOUT_OFFSET >= CALLOUT_WIDTH;
  const isFitsRight =
    focusZoneBounds.x +
      focusZoneBounds.width +
      CALLOUT_OFFSET +
      CALLOUT_WIDTH <=
    windowSize.width;
  const isFitsBottom =
    calloutSize &&
    focusZoneBounds.y +
      focusZoneBounds.height +
      CALLOUT_OFFSET +
      calloutSize.height <=
      windowSize.height;
  const isFitsTop =
    calloutSize && focusZoneBounds.y - CALLOUT_OFFSET >= calloutSize.height;
  if (isFitsLeft) {
    return {
      left: focusZoneBounds.x - CALLOUT_OFFSET - CALLOUT_WIDTH,
      top: focusZoneBounds.y,
    };
  }
  if (isFitsRight) {
    return {
      left: focusZoneBounds.x + focusZoneBounds.width + CALLOUT_OFFSET,
      top: focusZoneBounds.y,
    };
  }
  const left =
    focusZoneBounds.x + CALLOUT_WIDTH <= windowSize.width
      ? focusZoneBounds.x
      : windowSize.width - CALLOUT_WIDTH;
  if (isFitsTop) {
    return {
      left,
      top: focusZoneBounds.y - CALLOUT_OFFSET - calloutSize.height,
    };
  }
  if (isFitsBottom) {
    return {
      left,
      top: focusZoneBounds.y + focusZoneBounds.height + CALLOUT_OFFSET,
    };
  }
  return {
    left: windowSize.width - CALLOUT_WIDTH,
    top: focusZoneBounds.y,
  };
};

export const useBaseCoord = ({
  focusZoneBounds,
  windowSize,
  calloutSize,
}: Payload) => {
  const baseCoord = useMemo(
    () => getCalloutCoord({ focusZoneBounds, windowSize, calloutSize }),
    [focusZoneBounds, windowSize, calloutSize],
  );
  const previousBaseCoord = usePrevious(baseCoord) ?? null;

  return { baseCoord, previousBaseCoord };
};
