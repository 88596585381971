import { usePrevious } from 'cf-common/src/utils/hooks';
import { useEffect } from 'react';
import { useCurrentTab } from '@utils/Routing';

export const useOnTabChange = (cb: Function) => {
  const currentTab = useCurrentTab();
  const prevTab = usePrevious(currentTab, currentTab);

  useEffect(() => {
    if (!currentTab && currentTab === prevTab) {
      return;
    }

    cb();
  }, [currentTab, prevTab, cb]);
};
