import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useParams } from 'react-router-dom';
import { BotPageRouteParams, BotTabs, getTabLink } from '@utils/Routing';
import { TabNavLinkWithRole } from '../TabLink/TabLink';
import { BillingNavSubItems } from './BillingNavSubItems';
import { NavItemProps, NavItemViewProps } from './types';
import { NavItemView } from '../NavItems/NavItemView';
import * as css from '../../common.css';

const BillingNavItemView: React.FC<NavItemViewProps> = ({ small }) => {
  const { t } = useSafeTranslation();

  return (
    <NavItemView
      title={t('modernComponents.LeftNavigationPanel.billingTab')}
      small={small}
      icon="tabs_upgrade"
    />
  );
};

export const BillingNavItem: React.FC<NavItemProps> = ({
  small,
  onClick,
  className,
}) => {
  const { botId } = useParams<BotPageRouteParams>();

  return (
    <TabNavLinkWithRole
      data-testid="test-nav-to-billing"
      tabUrl={getTabLink(BotTabs.billing, botId)}
      tabEvent="billing"
      domain="pro"
      className={className}
      onClick={onClick}
      small={small}
      contentClassName={css.linkTooltip}
      activeClassName={css.activeLink}
      isContentLink={false}
      submenuStyles={{ top: -32 }}
      submenu={
        small ? (
          <BillingNavSubItems className={css.linkTooltip} small={small} />
        ) : null
      }
    >
      <BillingNavItemView small={small} />
    </TabNavLinkWithRole>
  );
};
