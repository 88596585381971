import React from 'react';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import css from './Section.css';

interface SectionProps {
  title: React.ReactNode;
  content: React.ReactNode;
  isSmallScreenSize?: boolean;
}

export const Section: React.FC<SectionProps> = ({
  content,
  title,
  isSmallScreenSize,
}) => (
  <Flex flexDirection={isSmallScreenSize ? 'column' : 'row'}>
    <Flex className={cn(!isSmallScreenSize && css.title)}>
      <Type size="15px" weight="semibold">
        {title}
      </Type>
    </Flex>
    <Spacer
      factor={isSmallScreenSize ? 1.5 : 0}
      horizontalFactor={isSmallScreenSize ? 0 : 4}
    />
    <Flex className={cn(!isSmallScreenSize && css.content)}>{content}</Flex>
  </Flex>
);
