import { useMutation } from '@apollo/react-hooks';
import { useCurrentBotId } from '@utils/Routing';
import {
  BILLING_PAYMENT_HISTORY_QUERY,
  PAYMENT_HISTORY_QUERY,
} from '@utils/Data/Pricing/queries';
import { SETUP_PAYMENT_METHOD } from '../queries';

import {
  SetupPaymentMethod,
  SetupPaymentMethodVariables,
} from '../@types/SetupPaymentMethod';
import { useCurrentBot } from '@utils/Data/Bot';
import { PaymentFlowError, PaymentFlowErrorKind } from 'cf-common/src/utils/Stripe/errors';

export const usePaymentMethodSetup = () => {
  const botId = useCurrentBotId();
  const { bot } = useCurrentBot();

  const [invokeSetupPaymentMethod] = useMutation<
    SetupPaymentMethod,
    SetupPaymentMethodVariables
  >(SETUP_PAYMENT_METHOD, {
    refetchQueries: [
      {
        query: BILLING_PAYMENT_HISTORY_QUERY,
        variables: { subscriptionId: bot?.status?.page || '' },
      },
      {
        query: PAYMENT_HISTORY_QUERY,
        variables: { botId },
      },
    ],
  });

  const setupPaymentMethod = async (paymentMethodId: string) => {
    const { data } = await invokeSetupPaymentMethod({
      variables: {
        botId: botId!,
        paymentMethodId,
      },
    });

    const stripeError = data?.setupPaymentMethod?.stripeError;

    if (stripeError) {
      throw new PaymentFlowError(stripeError.description, {
        kind: PaymentFlowErrorKind.stripeDecline,
        declineCode: stripeError.decline_code || stripeError.code,
      });
    }
  };

  return { setupPaymentMethod };
};
