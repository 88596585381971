import React from 'react';
import gql from 'graphql-tag';
import cn from 'classnames';
import { useQuery } from 'react-apollo';
import { WhiteLabelAgencyLogoQuery } from './@types/WhiteLabelAgencyLogoQuery';
import { getCurrentDomain } from '../../../utils/UrlUtils';
import * as css from './WhiteLabelLogo.css';

export enum LogoSize {
  default = 'default',
  big = 'big',
}

export interface IWhiteLabelLogoProps extends React.HTMLProps<HTMLDivElement> {
  logoSize?: LogoSize;
}

const WHITE_LABEL_AGENCY_LOGO_QUERY = gql`
  query WhiteLabelAgencyLogoQuery($domain: String) {
    whiteLabelAgency(domain: $domain) {
      id
      domain
      logo
    }
    me {
      id
      premiumAccount {
        id
        name
      }
    }
  }
`;

export const WhiteLabelLogo: React.FC<IWhiteLabelLogoProps> = ({
  logoSize,
  className,
  ...props
}) => {
  const { data, loading } = useQuery<WhiteLabelAgencyLogoQuery>(
    WHITE_LABEL_AGENCY_LOGO_QUERY,
    {
      variables: {
        domain: getCurrentDomain(),
      },
    },
  );

  if (loading || !data) {
    return null;
  }

  const name = data.me.premiumAccount?.name || '';
  const logo = data.whiteLabelAgency?.logo || '';
  return (
    <div title={name} className={cn(css.link, className)} {...props}>
      <div
        className={cn(css.picture, {
          [css.big]: logoSize === LogoSize.big,
        })}
        style={{ backgroundImage: `url("${logo}")` }}
      />
    </div>
  );
};
