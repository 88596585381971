import { useCallback, useState } from 'react';
import { useIsCreateBotByPlatform } from '@utils/Data/Admin/AdminFeatures/useIsCreateBotByPlatform';

export const useTopPanel = () => {
  const { isCreateBotByPlatform: isShowTopPanel } = useIsCreateBotByPlatform();
  const [isShowPlatformSelector, setIsShowPlatformSelector] =
    useState<boolean>(false);
  const toggleTopPanelPlatformSelector = useCallback(() => {
    setIsShowPlatformSelector((value) => !value);
  }, []);

  const hideTopPanelPlatformSelector = useCallback(() => {
    setIsShowPlatformSelector(false);
  }, []);

  return {
    isShowTopPanel,
    isShowPlatformSelector,
    toggleTopPanelPlatformSelector,
    hideTopPanelPlatformSelector,
  };
};
