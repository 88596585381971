import React from 'react';
import { TierType } from '@globals';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Flex } from '@ui/Flex';
import cn from 'classnames';
import { Spacer } from '@ui/Spacer';
import { TierPriceSizeProvider } from '@pages/Plans/hooks/useTierPriceSize';
import { PaywallTierCard } from '@pages/Plans/components/TierCard/PaywallTierCard';
import { PricingPlanTiersQuery_bot_pricingPlanTiers as PlanTier } from '@utils/Data/Pricing/@types/PricingPlanTiersQuery';
import { paywallPlansLoader } from './helpers';
import * as css from './PaywallPlansBlock.css';

interface PaywallPlansBlockProps {
  currency: string | undefined;
  tiers: PlanTier[];
  onUpgrade(tier: TierType, isFreeMonthByPromoCode?: boolean): void;
  isBlockLoading: boolean;
  isCardsLoading: boolean;
  checkAvailableFreeMonth?: (tierType: TierType) => boolean;
}

export const PaywallPlansBlock: React.FC<PaywallPlansBlockProps> = ({
  currency,
  tiers,
  onUpgrade,
  isBlockLoading,
  isCardsLoading,
  checkAvailableFreeMonth,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <Flex className={cn({ [css.narrowTiersContainer]: isSmallScreenSize })}>
      {isBlockLoading ? (
        paywallPlansLoader
      ) : (
        <TierPriceSizeProvider
          maxPrice={Math.max(...tiers.map((v) => v.tier_price))}
        >
          {tiers.map((tier, index) => (
            <React.Fragment key={tier.type}>
              {index !== 0 && <Spacer horizontalFactor={4} />}
              <PaywallTierCard
                tier={tier}
                currency={currency!}
                onStartFreeTrial={() => {
                  onUpgrade(tier.type, checkAvailableFreeMonth?.(tier.type));
                }}
                loading={isCardsLoading}
                checkAvailableFreeMonth={checkAvailableFreeMonth}
              />
            </React.Fragment>
          ))}
        </TierPriceSizeProvider>
      )}
    </Flex>
  );
};
