import React from 'react';
import cn from 'classnames';
import * as css from './ItemsList.css';

interface ItemsListProps<T> {
  itemsToShow: T[];
  renderItem: (item: T) => React.ReactNode;
  short?: boolean;
  renderBeforeItems?: () => React.ReactNode[];
  renderAfterItem?: () => React.ReactNode;
  callout?: React.ReactNode;
}

export function ItemsList<T extends { id: string }>({
  itemsToShow,
  renderBeforeItems,
  renderAfterItem,
  renderItem,
  callout,
  short,
}: ItemsListProps<T>) {
  return (
    <div>
      {callout && <div className={css.callout}>{callout}</div>}
      <div className={css.rightColumn}>
        <ul
          className={cn(
            css.templatesListModalItems,
            css.ul,
            short && css.short,
          )}
        >
          {renderBeforeItems?.().map((item) => (
            <li className={css.li}>{item}</li>
          ))}
          {itemsToShow.map((item) => (
            <li className={css.li} key={item.id}>
              {renderItem(item)}
            </li>
          ))}
          {renderAfterItem && <li className={css.li}>{renderAfterItem()}</li>}
        </ul>
      </div>
    </div>
  );
}
