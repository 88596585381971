import React from 'react';
import { CSSTransition } from 'react-transition-group';
import css from './FadeInOutOpacity.css';

interface FadeInOutOpacityProps {
  show: boolean;
}

export const FadeInOutOpacity: React.FC<FadeInOutOpacityProps> = ({
  children,
  show,
}) => {
  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames={{
        enter: css.enter,
        enterActive: css.enterActive,
        exit: css.exit,
        exitActive: css.exitActive,
      }}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
};
