import { PluginType } from '../common/PluginTypes';
import { commentsAutoreplyEntryPointFragment_config } from './@types/commentsAutoreplyEntryPointFragment';

export type CommentsAutoreplyEntryPointConfig = commentsAutoreplyEntryPointFragment_config;

export const PLUGIN_TYPE = PluginType.comments_autoreply_entry_point;

export const PLUGIN_DEFAULT_CONFIG: CommentsAutoreplyEntryPointConfig = {
  __typename: 'CommentsAutoreplyEntryPointConfig',
  already_subscribed_setup_enabled: false,
  posts: [],
  apply_for_all_posts: true,
  keywords_setup: {
    __typename: 'KeywordsSetup',
    intents: [],
    default_block_id: null,
  },
  already_subscribed_keywords_setup: {
    __typename: 'KeywordsSetup',
    intents: [],
    default_block_id: null,
  },
};

export const PLUGIN_INPUT_FIELD = 'commentsAutoreplyEntryPointConfig';
