import React from 'react';
import { TFunction } from 'i18next';
import * as css from './AddPlugin.css';
import { PluginType } from '../common/PluginTypes';
import { IPluginButton } from '../common/Plugin';

import { ReactComponent as TextPlugin } from '../common/icons/ic_textcard.svg';
import { ReactComponent as TypingPlugin } from '../common/icons/ic_youtube_round.svg';
import { ReactComponent as QuickReplyPlugin } from '../common/icons/ic_quickreply.svg';
import { ReactComponent as ImagePlugin } from '../common/icons/ic_imagecard.svg';
import { ReactComponent as GalleryPlugin } from '../common/icons/ic_gallery_round.svg';
import { ReactComponent as GoToBlockPlugin } from '../common/icons/ic_gotoblock.svg';
import { ReactComponent as SubscribeToSequence } from '../common/icons/ic_to-sequence-round.svg';
import { ReactComponent as ListPlugin } from '../common/icons/ic_list_round.svg';
import { ReactComponent as VideoPlugin } from '../common/icons/ic_video_round.svg';
import { ReactComponent as AudioPlugin } from '../common/icons/ic_audio_round.svg';
import { ReactComponent as CommentsPlugin } from '../common/icons/ic_comment.svg';
import { ReactComponent as UserInput } from '../common/icons/icon_Save_User_Input.svg';
import { ReactComponent as UserAttribute } from '../common/icons/ic_parameter_round.svg';
import { ReactComponent as UserPhone } from '../common/icons/blue_phone.svg';
import { ReactComponent as UserEmail } from '../common/icons/blue_email.svg';
import { ReactComponent as GoogleSheets } from '../common/icons/ic_gsheets_round.svg';
import { ReactComponent as NotifyAdmin } from '../common/icons/icon_Notify_Admin_via_Email.svg';
import { ReactComponent as Zapier } from '../common/icons/zapier.svg';
import { ReactComponent as UnsubscribeFromSequence } from '../common/icons/rm-fr-sequences.svg';
import { ReactComponent as LiveChat } from '../common/icons/icon_Live_Chat.svg';
import { ReactComponent as ConversationHandover } from '../common/icons/ic_handover_round.svg';
import { ReactComponent as GoogleSearch } from '../common/icons/google_search.svg';
import { ReactComponent as BingSearch } from '../common/icons/bing_search.svg';
import { ReactComponent as SwifttypeSearch } from '../common/icons/ic_r_swiftype.svg';
import { ReactComponent as RssImport } from '../common/icons/icon_RSS_Import.svg';
import { ReactComponent as Subscriber } from '../common/icons/ic_r_subscriber.svg';
import { ReactComponent as SuscriptionList } from '../common/icons/icon_Subscription_List.svg';
import { ReactComponent as Digest } from '../common/icons/icon_Digest.svg';
import { ReactComponent as JSONPlugin } from '../common/icons/icon_JSON_API.svg';
import { PLUGIN_NAME as JSON_PLUGIN_NAME } from '../JsonPlugin/JsonPluginConst';
import { getPluginButtonConfig as getFacebookAnalyticsPluginButtonConfig } from '../FacebookAnalyticsPlugin/FacebookAnalyticsPluginConst';
import { PLUGIN_BUTTON_CONFIG as ABT_PLUGIN_BUTTON_CONFIG } from '../AbtPlugin/AbtPluginConst';
import { PLUGIN_BUTTON_CONFIG as ABANDONED_CART_PLUGIN_BUTTON_CONFIG } from '../AbandonedCartPlugin/AbandonedCartPluginConst';
import { createTooltip } from './createTooltip';

type getPluginButtonConfigType = (params: {
  pluginType: PluginType;
  botId: string;
  pluginList?: boolean;
  t: TFunction;
}) => IPluginButton | null;

export const getPluginButtonConfig: getPluginButtonConfigType = ({
  pluginType,
  botId,
  pluginList,
  t,
}) => {
  switch (pluginType) {
    case PluginType.typing:
      return {
        title: t('modernComponents.plugins.AddPlugin.pluginTyping'),
        img: <TypingPlugin />,
      };
    case PluginType.text:
      return {
        title: t('modernComponents.plugins.AddPlugin.pluginText'),
        img: <TextPlugin />,
        testClass: 'test-text-plugin',
      };
    case PluginType.add_to_sequence:
      return {
        title: pluginList
          ? t('modernComponents.plugins.AddPlugin.subscribeToSequence')
          : t('modernComponents.plugins.AddPlugin.sequence'),
        img: <SubscribeToSequence />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string-1036-subscribe-users-entering-this-block-to-a-sequence-based-on-their-attributes',
            ),
            'https://docs.chatfuel.com/broadcasting/broadcasting-documentation/sequences',
          ),
        },
        testClass: 'test-add-to-sequence-plugin-button',
      };
    case PluginType.audio:
      return {
        title: t('AddPluginButtonConfig-string-6361-audio'),
        img: <AudioPlugin />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string--218-send-an-audio-file-in-the-chat',
            ),
            t('common.helpDocLinks.media_assets'),
          ),
        },
      };
    case PluginType.autoposting_zapier_plugin:
      return {
        title: t(
          'AddPluginButtonConfig-string-1270-auto-trigger-a-message-via-zapier',
        ),
        img: <Zapier />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string-9905-use-this-plugin-to-send-a-gallery-with-data-from-3rd-party-systems-crm-google-sheets-etc-to-chatfuel',
            ),
            'https://docs.chatfuel.com/tips-and-tricks/integrations/chatfuel-zapier-integration',
          ),
        },
      };
    case PluginType.collect_email:
      return {
        title: t('AddPluginButtonConfig-string--917-save-user-email'),
        img: <UserEmail />,
        tooltip: {
          free: t(
            'AddPluginButtonConfig-string--460-collect-your-bot-users-emails-the-plugin-will-send-a-quick-reply-button-automatically-pre-filled-with-the-email-address-from-the-users-facebook-profile-if-the-profile-does-not-have-an-email-address-the-quick-reply-will-not-be-shown-but-the-user-will-still-be-able-to-type-in-their-email',
          ),
        },
      };
    case PluginType.collect_phone:
      return {
        title: t('AddPluginButtonConfig-string-2114-save-user-phone-number'),
        img: <UserPhone />,
        tooltip: {
          free: t(
            'AddPluginButtonConfig-string--160-collect-your-bot-users-phone-numbers-the-plugin-will-send-a-quick-reply-button-automatically-pre-filled-with-the-phone-number-from-the-users-facebook-profile-if-the-profile-does-not-have-a-phone-number-the-quick-reply-will-not-be-shown-but-the-user-will-still-be-able-to-type-in-their-number',
          ),
        },
      };
    case PluginType.comment:
      return {
        title: t('AddPluginButtonConfig-string--167-comment'),
        img: <CommentsPlugin />,
      };
    case PluginType.communication:
      return {
        title: t('AddPluginButtonConfig-string--134-live-chat'),
        img: <LiveChat />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string--718-start-a-chat-between-a-user-and-a-bot-administrator-you-can-view-active-conversations-and-reply-to-users-in-the-live-chat-tab',
            ),
            'https://docs.chatfuel.com/plugins/connect-users-to-a-human/live-chat',
          ),
        },
      };
    case PluginType.email:
      return {
        title: t('AddPluginButtonConfig-string--451-notify-admin-via-email'),
        img: <NotifyAdmin />,
        tooltip: {
          free: t(
            'AddPluginButtonConfig-string-1858-use-this-plugin-to-solicit-user-feedback-send-notifications-to-bot-admins-or-export-data-from-your-chatbot-via-email-note-that-you-cant-send-emails-to-the-bot-users-with-this-plugin',
          ),
        },
      };
    case PluginType.event_trigger:
      return {
        title: t('AddPluginButtonConfig-string-1827-export-via-zapier'),
        img: <Zapier />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string--571-use-this-plugin-to-export-user-data-attributes-or-events-button-clicks-etc-from-chatfuel-to-3rd-party-systems-crm-google-sheets-etc',
            ),
            'https://docs.chatfuel.com/tips-and-tricks/integrations/chatfuel-zapier-integration',
          ),
        },
      };
    case PluginType.form_v2:
      return {
        title: t('AddPluginButtonConfig-string--913-save-user-input'),
        img: <UserInput />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string--207-ask-bot-users-questions-and-save-their-responses-to-user-attributes-you-can-then-utilize-attributes-in-broadcasting-user-filters-redirect-to-block-plugin-or-export-them-via-notify-admin-via-email-or-json-api-plugins',
            ),
            'https://docs.chatfuel.com/plugins/collect-user-data/save-user-input',
          ),
        },
      };
    case PluginType.gallery:
      return {
        title: t('modernComponents.plugins.AddPlugin.pluginGallery'),
        img: <GalleryPlugin />,
      };
    case PluginType.go_to_block_plugin:
      return {
        title: pluginList
          ? t('modernComponents.plugins.AddPlugin.redirectToBlockOrFlow')
          : t('modernComponents.plugins.AddPlugin.redirect'),
        img: <GoToBlockPlugin />,
        testClass: 'test-add-go-to-block-plugin-button',
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string-1549-redirect-users-to-another-block-once-redirected-the-user-will-not-receive-any-further-content-from-the-current-block-the-flow-will-continue-in-the-destination-block',
            ),
            'https://docs.chatfuel.com/plugins/redirect-users/redirect-to-block',
          ),
        },
      };
    case PluginType.google_sheet:
      return {
        title: t('AddPluginButtonConfig-string-1446-save-to-google-sheets'),
        img: <GoogleSheets />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string--171-export-user-data-to-a-google-sheet-connect-your-google-account-to-create-a-new-spreadsheet-then-select-attributes-to-save-into-the-spreadsheet',
            ),
            'https://docs.chatfuel.com/plugins/export-and-import/save-to-google-sheets',
          ),
        },
      };
    case PluginType.handover:
      return {
        title: t('AddPluginButtonConfig-string-1018-conversation-handover'),
        img: <ConversationHandover />,
        tooltip: {
          pro: createTooltip(
            t(
              'AddPluginButtonConfig-string-1989-use-this-plugin-to-pass-control-of-the-messenger-conversation-to-other-facebook-apps-such-as-social-media-management-or-customer-support-platforms',
            ),
            'https://docs.chatfuel.com/plugins/connect-users-to-a-human/conversation-handover',
          ),
          free: (
            <p style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
              {t(
                'AddPluginButtonConfig-JSXText-1635-use-this-plugin-to-pass-control-of-the-messenger-conversation-to-other-facebook-apps-such-as-social-media-management-or-customer-support-platforms',
              )}{' '}
              <a href={`/bot/${botId}/settings/`} className={css.tooltipLink}>
                {t('AddPluginButtonConfig-JSXText-1227-upgrade-to-pro')}
              </a>{' '}
              {t('AddPluginButtonConfig-JSXText--203-to-activate-it')}
            </p>
          ),
        },
        pro: true,
      };
    case PluginType.image:
      return {
        title: t('modernComponents.plugins.AddPlugin.pluginImage'),
        img: <ImagePlugin />,
        testClass: 'test-add-image',
      };
    case PluginType.integration_zapier_plugin:
      return {
        title: t('AddPluginButtonConfig-string--757-import-content-via-zapier'),
        img: <Zapier />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string-9905-use-this-plugin-to-send-a-gallery-with-data-from-3rd-party-systems-crm-google-sheets-etc-to-chatfuel',
            ),
            'https://docs.chatfuel.com/tips-and-tricks/integrations/chatfuel-zapier-integration',
          ),
        },
      };
    case PluginType.list:
      return {
        title: t('AddPluginButtonConfig-string-2368-list'),
        img: <ListPlugin />,
      };
    case PluginType.quick_reply:
      return {
        title: t('modernComponents.plugins.AddPlugin.pluginQuickReply'),
        img: <QuickReplyPlugin />,
        testClass: 'test-quick-reply',
      };
    case PluginType.remove_from_sequence:
      return {
        title: t('AddPluginButtonConfig-string--241-unsubscribe-from-sequence'),
        img: <UnsubscribeFromSequence />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string-3557-unsubscribe-users-entering-this-block-from-a-sequence-based-on-their-attributes',
            ),
            'https://docs.chatfuel.com/broadcasting/broadcasting-documentation/sequences',
          ),
        },
      };
    case PluginType.search_bing_plugin:
      return {
        title: t('AddPluginButtonConfig-string-1178-bing-search'),
        img: <BingSearch />,
        tooltip: {
          free: t(
            'AddPluginButtonConfig-string-1180-use-this-plugin-to-display-search-results-for-a-request-you-can-use-a-specific-request-or-a-user-attribute-saved-using-save-user-input-quick-reply-or-set-user-attribute-plugins',
          ),
        },
      };
    case PluginType.search_google_plugin:
      return {
        title: t('AddPluginButtonConfig-string--160-google-site-search'),
        img: <GoogleSearch />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string-1180-use-this-plugin-to-display-search-results-for-a-request-you-can-use-a-specific-request-or-a-user-attribute-saved-using-save-user-input-quick-reply-or-set-user-attribute-plugins',
            ),
            'https://docs.chatfuel.com/plugins/plugins-with-subscriptions/google-site-search',
          ),
        },
      };
    case PluginType.search_rss_plugin:
      return {
        title: t('AddPluginButtonConfig-string-5246-rss-import'),
        img: <RssImport />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string--148-use-this-plugin-to-show-rss-content-as-a-card-gallery-you-can-use-a-static-url-or-a-url-with-a-user-attribute-saved-using-save-user-input-quick-reply-or-set-user-attribute-plugins',
            ),
            'https://docs.chatfuel.com/plugins/plugins-with-subscriptions/rss-import',
          ),
        },
      };
    case PluginType.search_swiftype_plugin:
      return {
        title: t('AddPluginButtonConfig-string--498-swiftype-search'),
        img: <SwifttypeSearch />,
        tooltip: {
          free: t(
            'AddPluginButtonConfig-string--206-use-this-plugin-to-display-search-results-for-a-request-you-can-use-a-specific-request-or-a-user-attribute-saved-using-save-user-input-quick-reply-or-set-user-attribute-plugins',
          ),
        },
      };
    case PluginType.setup_variable_plugin:
      return {
        title: t('AddPluginButtonConfig-string-2074-set-user-attribute'),
        img: <UserAttribute />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string-1330-set-a-value-for-an-existing-user-attribute-or-add-a-new-one-use-it-to-segment-users-for-broadcast-subscriptions-to-define-bot-flow-scenarios-or-to-analyze-user-activity-note-that-you-can-use-arithmetic-expressions-and-attributes-in-the-value-field',
            ),
            'https://docs.chatfuel.com/plugins/collect-user-data/set-user-attribute',
          ),
        },
        testClass: 'test-add-setup-attribute-plugin',
      };
    case PluginType.subscribe_user_plugin:
      return {
        title: t('AddPluginButtonConfig-string-7736-subscriber'),
        img: <Subscriber />,
        tooltip: {
          free: t(
            'AddPluginButtonConfig-string-1316-use-this-plugin-to-subscribe-a-user-to-a-google-site-search-bing-search-or-rss-import-plugins-result-specified-in-another-block',
          ),
        },
      };
    case PluginType.subscription_broadcasting_plugin:
      return {
        title: t('AddPluginButtonConfig-string-2046-digest'),
        img: <Digest />,
        tooltip: {
          free: t(
            'AddPluginButtonConfig-string--203-use-this-plugin-to-display-last-unseen-updates-based-on-user-subscriptions-the-updates-are-shown-as-a-gallery',
          ),
        },
      };
    case PluginType.subscriptions_manager_plugin:
      return {
        title: t('AddPluginButtonConfig-string-7865-subscription-list'),
        img: <SuscriptionList />,
        tooltip: {
          free: t(
            'AddPluginButtonConfig-string-1402-use-this-plugin-to-display-a-persons-subscriptions-allows-people-to-unsubscribe-or-get-stories-from-rss-feeds-or-search-result',
          ),
        },
      };
    case PluginType.video:
      return {
        title: t('AddPluginButtonConfig-string-8265-video'),
        img: <VideoPlugin />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string-1206-send-a-video-file-in-the-chat',
            ),
            t('common.helpDocLinks.media_assets'),
          ),
        },
      };
    case PluginType.json_plugin:
      return {
        title: JSON_PLUGIN_NAME,
        img: <JSONPlugin />,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string-2765-use-this-plugin-to-create-various-integrations-with-your-server-generate-dynamic-content-or-send-data',
            ),
            'https://docs.chatfuel.com/en/articles/735122-json-api',
          ),
        },
      };
    case PluginType.facebook_analytics:
      return getFacebookAnalyticsPluginButtonConfig(botId);
    case PluginType.abt:
      return ABT_PLUGIN_BUTTON_CONFIG;
    case PluginType.abandoned_cart:
      return {
        ...ABANDONED_CART_PLUGIN_BUTTON_CONFIG,
        tooltip: {
          free: createTooltip(
            t(
              'AddPluginButtonConfig-string--107-the-user-will-receive-a-gallery-where-each-card-is-an-item-from-their-abandoned-cart',
            ),
            t('common.helpDocLinks.media_assets'),
          ),
          pro: createTooltip(
            t(
              'AddPluginButtonConfig-string--107-the-user-will-receive-a-gallery-where-each-card-is-an-item-from-their-abandoned-cart',
            ),
            t('common.helpDocLinks.media_assets'),
          ),
        },
        pro: true,
      };
    default:
      return null;
  }
};
