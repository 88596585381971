import { Badge, Kind } from '@ui/Badge';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React from 'react';
import css from './TrialBadge.css';

interface TrialBadgeProps {}

export const TrialBadge: React.FC<TrialBadgeProps> = () => {
  const { t } = useSafeTranslation();

  return (
    <Badge size="s" className={css.trialBadge} kind={Kind.alert}>
      {t('pages.Pricing.tier.trial')}
    </Badge>
  );
};
