import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { Anchor } from '@ui/Links';
import { ButtonUnstyled } from '@ui/Button';
import * as css from '../../common.css';
import { ChecekTabRoleProps, CheckTabRole } from '../CheckTabRole/CheckTabRole';
import {
  TabContainerType,
  TabViewWithEvent,
  TabViewWithEventProps,
} from '../TabView/TabView';
import { sendClickEvent } from '../../utils';

interface TabProps
  extends Omit<TabViewWithEventProps, 'container'>,
    TestLocator {
  tabEvent: string;
  onClick?: () => void;
}

interface TabAnchorProps extends TabProps {
  href: string;
}

export const TabButton: React.FC<TabProps> = ({
  tabEvent,
  onClick,
  ...params
}) => {
  const Container: TabContainerType = ({ children, className }) => (
    <ButtonUnstyled
      data-testid={params['data-testid']}
      className={className}
      style={{ width: '100%' }}
      onClick={() => {
        sendClickEvent(tabEvent);
        onClick?.();
      }}
    >
      {children}
    </ButtonUnstyled>
  );

  return (
    <TabViewWithEvent {...params} tabEvent={tabEvent} container={Container} />
  );
};

export const TabAnchor: React.FC<TabAnchorProps> = ({
  href,
  onClick,
  tabEvent,
  ...rest
}) => {
  const Container: TabContainerType = ({ children, className }) => (
    <Anchor
      data-testid={rest['data-testid']}
      className={cn(css.link, className)}
      intent="external"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        sendClickEvent(tabEvent);
        onClick?.();
      }}
      noUnderline
      hideArrow
    >
      {children}
    </Anchor>
  );

  return (
    <TabViewWithEvent {...rest} tabEvent={tabEvent} container={Container} />
  );
};

interface TabNavLinkProps
  extends Omit<TabViewWithEventProps, 'container'>,
    TestLocator {
  tabUrl: string;
  tabEvent: string;
  activeClassName: string;
  onClick?: () => void;
  id?: string;
}

const TabNavLink: React.FC<TabNavLinkProps> = ({
  tabUrl,
  tabEvent,
  activeClassName,
  onClick,
  id,
  ...rest
}) => {
  const LinkHelper: TabContainerType = ({ children, className }) => (
    <NavLink
      id={id}
      data-testid={rest['data-testid']}
      to={tabUrl}
      className={cn(css.link, className)}
      activeClassName={activeClassName}
      onClick={() => {
        sendClickEvent(tabEvent);
        onClick?.();
      }}
    >
      {children}
    </NavLink>
  );

  return (
    <TabViewWithEvent {...rest} tabEvent={tabEvent} container={LinkHelper} />
  );
};

interface TabNavLinkWithRoleProps
  extends Omit<TabNavLinkProps, 'send'>,
    ChecekTabRoleProps {}

export const TabNavLinkWithRole: React.FC<TabNavLinkWithRoleProps> = ({
  domain,
  small,
  ...rest
}) => (
  <CheckTabRole domain={domain} small={small}>
    <TabNavLink {...rest} send={small} />
  </CheckTabRole>
);
