import React from 'react';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { PlanButtonView } from '@pages/BotPage/HomeTab/components/BotSectionView/PlanButton/PlanButtonView';
import { usePlanButtonType } from '@pages/BotPage/HomeTab/components/BotSectionView/PlanButton/hooks';
import {
  useBotSwitcherNavItemData,
  useDialogsPricingStatistics,
} from '../../hooks/useBotSwitcherNavItemData';
import { BotSwitcherProps } from './types';
import { BotSwitcherPricing } from './BotSwitcherPricing';
import * as css from './BotSwitcherPricing.css';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';

interface BotSwitcherNewPricingProps extends BotSwitcherProps {}

export const BotSwitcherNewPricing: React.FC<BotSwitcherNewPricingProps> = ({
  onButtonClick,
  ...props
}) => {
  const { allowed } = useBotSwitcherNavItemData();
  const planButtonType = usePlanButtonType();
  const { statistics, loading } = useDialogsPricingStatistics();
  const { isMediumScreenSize, isSmallScreenSize } = useDeviceMedia();
  const { hasShopifyAccount } = useShopifyAccount();

  return (
    <BotSwitcherPricing
      {...props}
      dialogsPricing
      statistics={statistics}
      loading={loading}
    >
      {hasShopifyAccount === false && (
        <PlanButtonView
          type={planButtonType}
          disabled={!allowed}
          className={css.button}
          shortText={!isSmallScreenSize}
          small={isMediumScreenSize}
          onClick={onButtonClick}
        />
      )}
    </BotSwitcherPricing>
  );
};
