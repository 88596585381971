import React from 'react';
import * as css from './BotSwitcher.css';
import { ScreenSizes } from '@utils/DOM/useDeviceMedia';
import { BotSwitcherRegularLoading } from './BotSwitcherRegular';
import { BotSwitcherSmallLoading } from './BotSwitcherSmall';

export interface BotSwitcherLoadingProps {
  screenSize: ScreenSizes;
}

export const BotSwitcherLoading: React.FC<BotSwitcherLoadingProps> = (
  props,
) => {
  return {
    [ScreenSizes.small]: (
      <BotSwitcherRegularLoading
        height={24}
        width="50%"
        className={css.wrapperMobile}
      />
    ),
    [ScreenSizes.medium]: (
      <BotSwitcherSmallLoading className={css.wrapperTablet} />
    ),
    [ScreenSizes.large]: (
      <BotSwitcherRegularLoading
        height={16}
        width="50%"
        className={css.wrapperDesktop}
      />
    ),
  }[props.screenSize];
};
