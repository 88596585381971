import { PluginType } from '../common/PluginTypes';
import { setupAttributePluginFragment_config as SetupAttributeConfig } from './@types/setupAttributePluginFragment';

export const PLUGIN_TYPE = PluginType.setup_variable_plugin;

export const PLUGIN_DEFAULT_CONFIG: SetupAttributeConfig = {
  __typename: 'SetupAttributePluginConfig',
  items: [
    {
      __typename: 'SetupAttributePluginItem',
      variable_name: '',
      value: '',
    },
  ],
};

export const PLUGIN_INPUT_FIELD = 'setupAttributePluginConfig';
