import ng from 'angular';
import { ngJsonPlugin } from './JsonPlugin';
import { ngFacebookAnalyticsPlugin } from './FacebookAnalyticsPlugin';
import { ngHandoverPlugin } from './HandoverPlugin';
import { ngAbtPlugin } from './AbtPlugin';
import { ngAddPlugin } from './AddPlugin/ngAddPlugin';
import { ngConvertToGalleryPlugin } from '../../common/plugins/card-list-plugin/ConvertToGalleryButton';
import { ngAbandonedCartPlugin } from './AbandonedCartPlugin';

export const ngPlugins = ng.module('app.plugins', [
  ngJsonPlugin,
  ngFacebookAnalyticsPlugin,
  ngHandoverPlugin,
  ngAbtPlugin,
  ngAddPlugin,
  ngConvertToGalleryPlugin,
  ngAbandonedCartPlugin,
]).name;
