import React, { useState } from 'react';
import { Query } from '@apollo/react-components';
import { pathOr } from 'ramda';
import { ApolloError } from 'apollo-client';
import { IPlugin } from '../common/Plugin';
import { handoverPluginFragment_config as IHandoverPluginConfig } from './@types/handoverPluginFragment';
import { PluginBox, PluginData } from '../common';
import {
  PLUGIN_HELP_URL,
  PLUGIN_ICON,
  PLUGIN_NAME,
  PLUGIN_TYPE,
  HandoverPluginErrors,
  NO_APP_SELECTED,
} from './HandoverPluginConst';
import { PluginHeader } from '../common/PluginHeader';
import { PluginBlock } from '../common/PluginBlock';
import { BOT_SECONDARY_APPS_QUERY } from './HandoverPluginQuery';
import { HandoverTimeoutSetter } from './HandoverTimeoutSetter';
import { PluginControlLabel } from '../common/PluginControlLabel';
import { Input } from '@ui/Input';
import { BubbleEditor } from '@ui/BubbleEditor';
import {
  getBotSecondaryApps as BotSecondaryApps,
  getBotSecondaryApps_bot_secondaryApps as BotSecondaryApp,
  getBotSecondaryAppsVariables as BotSecondaryAppsVariables,
} from './@types/getBotSecondaryApps';
import { defaultFilterItemsToShow, SimpleCombobox } from '@ui/SimpleCombobox';
import { PluginVeil } from '../common/PluginVeil';
import { HandoverPluginSetupAlertModal } from './HandoverPluginSetupAlertModal';
import { Loader } from '@ui/Loader';
import { ReactComponent as ErrorIcon } from '@ui/_deprecated/Icon/icons/ic_error.svg';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import * as css from './HandoverPlugin.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { MenuItem } from '@ui/Menu';
import { Spacer } from '@ui/Spacer';

export interface HandoverPluginProps extends IPlugin {
  botId: string;
}

const H3: React.FC = ({ children }) => (
  <Type as="h3" size="15px" weight="semibold" className={css.sectionTitle}>
    {children}
  </Type>
);

const ADD_NEW_ITEM = 'new';

export const HandoverPlugin: React.FC<HandoverPluginProps> = (props) => {
  const { t } = useSafeTranslation();
  const [showDisableWarning, setShowDisableWarning] = useState(false);

  const setupError = (error?: ApolloError) =>
    error &&
    !!error.graphQLErrors.find(
      (item) => item.message === HandoverPluginErrors.HANDOVER_SETUP_ERROR,
    );

  const isPluginDisabled = (error?: ApolloError, data?: BotSecondaryApps) =>
    !data ||
    pathOr([''], ['bot', 'secondaryApps'], data).length === 0 ||
    setupError(error);

  const generateSecondaryAppsItems = (data?: BotSecondaryApps) => {
    const apps = (data?.bot?.secondaryApps || []).map(
      (item: BotSecondaryApp) => ({
        id: item.id,
        title: item.name,
      }),
    );
    return [NO_APP_SELECTED(), ...apps];
  };

  const botDisconnected = (data?: BotSecondaryApps) => !data?.bot?.status?.page;

  return (
    <PluginData<IHandoverPluginConfig>
      pluginType={PLUGIN_TYPE}
      id={props.id}
      blockId={props.blockId}
      position={props.position}
    >
      {({
        loading: pluginDataLoading,
        setPluginConfigState,
        savePlugin,
        pluginConfig,
      }) => {
        if (pluginDataLoading) {
          return null;
        }

        return (
          <PluginBox>
            <Query<BotSecondaryApps, BotSecondaryAppsVariables>
              query={BOT_SECONDARY_APPS_QUERY}
              variables={{ botId: props.botId }}
              fetchPolicy="cache-and-network"
              errorPolicy="all"
            >
              {({ loading, error, data }) => {
                const disabledInputs = isPluginDisabled(error, data);
                const items = generateSecondaryAppsItems(data);

                const { application_id } = pluginConfig;
                const defaultItem = application_id
                  ? {
                      id: application_id,
                      title: application_id,
                    }
                  : null;
                const selectedItem =
                  items.find((item) => item.id === application_id) ||
                  defaultItem;

                return (
                  <>
                    <PluginVeil show={loading && !botDisconnected(data)}>
                      <Loader />
                    </PluginVeil>

                    <PluginHeader
                      title={PLUGIN_NAME}
                      icon={PLUGIN_ICON}
                      helpUrl={PLUGIN_HELP_URL}
                      pluginType={PLUGIN_TYPE}
                    />
                    <PluginBlock>
                      <span>
                        {t(
                          'modernComponents.plugins.HandoverPlugin.pluginDescription',
                        )}
                      </span>
                      {!isWhiteLabelDomain() && (
                        <a
                          href={PLUGIN_HELP_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t(
                            'modernComponents.plugins.HandoverPlugin.learnMore',
                          )}
                        </a>
                      )}
                      {botDisconnected(data) ? (
                        <div className={css.errorMessage}>
                          <ErrorIcon />
                          <span className={css.errorText}>
                            {t(
                              'modernComponents.plugins.HandoverPlugin.errorText',
                            )}
                          </span>
                        </div>
                      ) : (
                        <>
                          <H3>
                            {t(
                              'modernComponents.plugins.HandoverPlugin.activateHandover',
                            )}
                          </H3>
                          <span>
                            {t(
                              'modernComponents.plugins.HandoverPlugin.pageSettings',
                            )}
                            {'>'}
                            {t(
                              'modernComponents.plugins.HandoverPlugin.platformAssign',
                            )}
                          </span>
                          <a
                            href={PLUGIN_HELP_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t(
                              'modernComponents.plugins.HandoverPlugin.completeGuide',
                            )}
                          </a>

                          <H3>
                            {t(
                              'modernComponents.plugins.HandoverPlugin.chooseSecondaryReceiver',
                            )}
                          </H3>

                          <span>
                            {t(
                              'modernComponents.plugins.HandoverPlugin.chooseAppHint',
                            )}
                          </span>

                          <Spacer factor={1.5} />

                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                          <div
                            className={css.inputContainer}
                            onClick={() =>
                              disabledInputs && setShowDisableWarning(true)
                            }
                          >
                            <SimpleCombobox
                              itemToString={(item) => (item ? item.title : '')}
                              items={items}
                              onChange={(item) => {
                                if (item) {
                                  const config = {
                                    ...pluginConfig,
                                    application_id:
                                      item.id === ADD_NEW_ITEM
                                        ? item.title.trim()
                                        : item.id,
                                  };
                                  setPluginConfigState({ config });
                                  savePlugin();
                                }
                              }}
                              selectedItem={selectedItem}
                              filter={(
                                itemToString,
                                items,
                                selectedItem,
                                inputValue,
                              ) => {
                                const newItems = defaultFilterItemsToShow(
                                  itemToString,
                                  items,
                                  selectedItem,
                                  inputValue,
                                );

                                if (!newItems.length) {
                                  return [
                                    {
                                      id: ADD_NEW_ITEM,
                                      title: inputValue || '',
                                    },
                                  ];
                                }

                                return newItems;
                              }}
                              renderItem={({
                                item,
                                index,
                                highlightedIndex,
                                getItemProps,
                              }) => (
                                <MenuItem
                                  key={item.id}
                                  {...getItemProps({ item })}
                                  active={index === highlightedIndex}
                                  title={
                                    item.id === ADD_NEW_ITEM
                                      ? `+ ${item.title}`
                                      : item.title
                                  }
                                />
                              )}
                              renderInput={({ getInputProps, openMenu }) => (
                                <Input
                                  disabled={disabledInputs}
                                  className={css.input}
                                  {...getInputProps({ onFocus: openMenu })}
                                />
                              )}
                            />

                            <H3>
                              {t(
                                'modernComponents.plugins.HandoverPlugin.setTimeout',
                              )}
                            </H3>
                            <HandoverTimeoutSetter
                              defaultValue={
                                pluginConfig.timeout_in_seconds || undefined
                              }
                              disabled={disabledInputs}
                              onChange={(timeout) => {
                                const config = {
                                  ...pluginConfig,
                                  timeout_in_seconds: timeout || -1,
                                };
                                setPluginConfigState({ config });
                              }}
                              onSave={savePlugin}
                            />
                          </div>

                          <H3>
                            {t(
                              'modernComponents.plugins.HandoverPlugin.graphApi',
                            )}
                          </H3>
                          <span>
                            {t(
                              'modernComponents.plugins.HandoverPlugin.graphApiDescription',
                            )}
                          </span>
                          <a
                            href="https://developers.facebook.com/docs/messenger-platform/handover-protocol/pass-thread-control"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t(
                              'modernComponents.plugins.HandoverPlugin.graphApi',
                            )}
                          </a>
                          <span>
                            {t(
                              'modernComponents.plugins.HandoverPlugin.returnControl',
                            )}
                          </span>
                          <span className={css.escapedText}>
                            {window.CHATFUEL_CONFIG.APP_ID}
                          </span>
                          <span>
                            {t('modernComponents.plugins.HandoverPlugin.as')}
                          </span>
                          <span className={css.escapedText}>target_app_id</span>

                          <H3>
                            {t(
                              'modernComponents.plugins.HandoverPlugin.stopHandover',
                            )}
                          </H3>
                          <span>
                            {t(
                              'modernComponents.plugins.HandoverPlugin.stopHandoverDescription',
                            )}
                          </span>

                          <PluginControlLabel
                            className={css.stopWordTitle}
                            label={t(
                              'modernComponents.plugins.HandoverPlugin.passConversationBack',
                            )}
                          >
                            {({ id }) => (
                              <Input
                                render={() => (
                                  <BubbleEditor
                                    showControlsPlaceholder
                                    onStringChange={(line) => {
                                      const config = {
                                        ...pluginConfig,
                                        stop_words: line.split('\n'),
                                      };
                                      setPluginConfigState({ config });
                                      savePlugin();
                                    }}
                                    id={id}
                                    defaultValue={
                                      pluginConfig.stop_words
                                        ? pluginConfig.stop_words.join('\n')
                                        : undefined
                                    }
                                    example={['stop chat', 'return to bot']}
                                  />
                                )}
                              />
                            )}
                          </PluginControlLabel>
                        </>
                      )}
                    </PluginBlock>
                    {showDisableWarning && (
                      <HandoverPluginSetupAlertModal
                        onDismiss={() => setShowDisableWarning(false)}
                      />
                    )}
                  </>
                );
              }}
            </Query>
          </PluginBox>
        );
      }}
    </PluginData>
  );
};
