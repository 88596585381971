import ng from 'angular';
import { react2angular } from 'react2angular';
import React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import {
  AbandonedCartPlugin,
  AbandonedCartPluginProps,
} from './AbandonedCartPlugin';
import client from '../../../common/services/ApolloService';

const AbandonedCartPluginWrapper: React.FC<AbandonedCartPluginProps> = (
  props,
) => {
  return (
    <ApolloProvider client={client}>
      <AbandonedCartPlugin {...props} />
    </ApolloProvider>
  );
};

export const ngAbandonedCartPlugin = ng
  .module('app.plugins.abandonedCartPlugin', [])
  .component(
    'abandonedCartPlugin',
    react2angular(
      AbandonedCartPluginWrapper,
      ['id', 'blockId', 'position', 'botId'],
      [],
    ),
  ).name;
