import React, { useMemo } from 'react';
import {
  CallendlyDialog,
  CallendlyDialogProps,
} from '@pages/BotPage/KeywordsPage/FirstSessionPage/components/ProductTour/BookingDemo/CallendlyDialog';
import { useCurrentBotId } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useWhatsappMigrationStatusMutation } from '@utils/Whatsapp/hooks/useWhatsappMigrationStatusMutation';
import { WhatsappMigrationStatus } from '@globals';
import { ENTRY_POINT_TIPS_QUERY } from '@utils/Data/MiniOnboarding/queries';

interface WABookingDemoProps extends Pick<CallendlyDialogProps, 'close'> {}

export const WABookingDemo: React.FC<WABookingDemoProps> = (props) => {
  const botId = useCurrentBotId();
  const [setWhatsappMeetingBooked] = useWhatsappMigrationStatusMutation({
    variables: {
      botId: botId!,
      status: WhatsappMigrationStatus.meeting_booked,
    },
    refetchQueries: [
      { query: ENTRY_POINT_TIPS_QUERY, variables: { botId: botId! } },
    ],
  });
  const { i18n } = useSafeTranslation();
  const { language } = i18n;
  const callendlyUrl = useMemo(() => {
    switch (language) {
      case 'pt':
        return 'https://calendly.com/cfedgar/30min-1';
      case 'es':
        return 'https://calendly.com/laura-0823/30min';
      default:
        return 'https://calendly.com/d/2cd-xsj-3gy/call-with-chatfuel';
    }
  }, [language]);

  return (
    <CallendlyDialog
      {...props}
      callendlyUrl={callendlyUrl}
      onEventScheduled={setWhatsappMeetingBooked}
    />
  );
};
