import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  BotSwitcherStatisticsWithData,
  BotSwitcherStatisticsWithDataProps,
} from './BotSwitcherStatisticsWithData';
import { TimeLimitedTrialStatistics } from './TimeLimitedTrialStatistics/TimeLimitedTrialStatistics';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { useWhatsappConnected } from '@utils/Data/Whatsapp';
import { useCurrentBotId } from '@utils/Routing';

export interface BotSwitcherStatisticsFactoryProps
  extends Omit<BotSwitcherStatisticsWithDataProps, 'icon' | 'label'> {
  dialogsPricing: boolean;
  currentTierTimeLimitedTrial?: boolean;
  trialRemainingDays?: number | null;
  trialEndDate?: number | null;
}

export const BotSwitcherStatisticsFactory: React.FC<BotSwitcherStatisticsFactoryProps> =
  ({
    dialogsPricing,
    currentTierTimeLimitedTrial,
    trialRemainingDays,
    trialEndDate,
    ...props
  }) => {
    const { t } = useSafeTranslation();
    const botId = useCurrentBotId();
    const { isConnected, loading: whatsappConnectedLoading } =
      useWhatsappConnected(botId);
    const { hasShopifyAccount, loading: accountLoading } = useShopifyAccount();
    const hideTimeLimitedTrialStatistics =
      whatsappConnectedLoading ||
      accountLoading ||
      (isConnected && hasShopifyAccount);

    if (currentTierTimeLimitedTrial && !hideTimeLimitedTrialStatistics)
      return (
        <TimeLimitedTrialStatistics
          trialRemainingDays={trialRemainingDays}
          trialEndDate={trialEndDate}
        />
      );

    if (dialogsPricing) {
      return (
        <BotSwitcherStatisticsWithData
          {...props}
          icon="chat"
          label={t('modernComponents.BotSwitcher.conversations')}
        />
      );
    }

    return (
      <BotSwitcherStatisticsWithData
        {...props}
        icon="tabs_people"
        label={t('modernComponents.BotSwitcher.subscribers')}
      />
    );
  };
