import { customerChatEntryPointFragment_config as CustomerChatEntryPointConfig } from './@types/customerChatEntryPointFragment';
import { PluginType } from '../common/PluginTypes';
import { HEXColors } from '@ui/_common/colors';

export const PLUGIN_TYPE = PluginType.customer_chat_entry_point;

export const DEFAULT_GREETING = 'Hi! How can we help you?';
export const DEFAULT_THEME_COLOR = HEXColors.white;

export const PLUGIN_DEFAULT_CONFIG: CustomerChatEntryPointConfig = {
  __typename: 'CustomerChatEntryPointConfig',
  domains: [],
  logged_in_greeting: '',
  logged_out_greeting: '',
  theme_color: null,
  greeting_dialog_display: null,
  greeting_dialog_delay: null,
  supported_devices: null,
  pages_whitelist: ['/*'],
  pages_blacklist: [],
  block_id: null,
  guest_block_id: null,
  upgrade_block_id: null,
};

export const PLUGIN_INPUT_FIELD = 'customerChatEntryPointConfig';
