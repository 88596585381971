import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment whatsappWidgetEntryPointFragment on WhatsappWidgetEntryPoint {
    ...commonPluginFragment
    config {
      prefilledMessage
      welcomeMessageDelay
      welcomeMessage
      displayVariant
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
