import React, { useMemo } from 'react';
import { useWorkspacesBots } from '@utils/Data/Workspaces/useWorkspacesBots';
import { useLightWorkspaceBots } from '@utils/Data/Bot/useLightBots';
import { useCreateWorkspaceBotFromHeader } from '../../hooks';
import {
  buildWorkspacesComboboxItems,
  filterWorkspaceBotsToShow,
} from '../../utils';
import { BotHeaderBase, BotHeaderProps } from './BotHeader';
import { ComboboxType } from '../../types';
import { BotsHeader, WorkspaceHeader } from '../ComboboxItems';
import { getWorkspaceDataByStatus } from '@components/Workspace/workspaceStatus';

export const BotHeaderWorkspaces = React.memo<BotHeaderProps>(
  ({ loading: outsideLoading, ...props }) => {
    const [workspaces, workspacesLoading] = useWorkspacesBots();
    const {
      lightWorkspaceBots,
      loading: lightBotsLoading,
      flatBots,
    } = useLightWorkspaceBots();
    const workspaceId = workspaces?.workspaces[0]?.workspace?.id;
    const {
      onCreateBot: onCreateWorkspaceBot,
      loading: createdWorkspaceBotLoading,
    } = useCreateWorkspaceBotFromHeader();

    const comboboxItems = useMemo(
      () => buildWorkspacesComboboxItems(lightWorkspaceBots),
      [lightWorkspaceBots],
    );

    const loading =
      createdWorkspaceBotLoading ||
      workspacesLoading ||
      lightBotsLoading ||
      outsideLoading;

    return (
      <BotHeaderBase
        loading={loading}
        onAddBotButtonClick={(platforms) =>
          onCreateWorkspaceBot(workspaceId, platforms)
        }
        comboboxItems={comboboxItems}
        botsItemsLength={flatBots.length}
        filter={filterWorkspaceBotsToShow}
        renderCustomItem={(item, { itemSize }) => {
          if (item.type === ComboboxType.spacer) {
            return <div style={{ height: itemSize }} />;
          }

          if (item.type === ComboboxType.botsHeader) {
            return <BotsHeader title={item.title} />;
          }

          if (item.type === ComboboxType.workspaceHeader) {
            const status = getWorkspaceDataByStatus(item.status);

            return <WorkspaceHeader title={item.title} status={status} />;
          }

          return null;
        }}
        {...props}
      />
    );
  },
);
