import React from 'react';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { BotSwitcher, BotSwitcherProps } from '../../BotSwitcher';
import { useBotSwitcherNavItemData } from '../../hooks/useBotSwitcherNavItemData';

type BotSwitcherWithDataProps = Omit<
  BotSwitcherProps,
  'screenSize' | 'title' | 'active' | 'showProBadge' | 'src'
>;

export const BotSwitcherWithData: React.FC<BotSwitcherWithDataProps> = ({
  children,
  ...props
}) => {
  const { screenSize } = useDeviceMedia();
  const { bot, active, isBotPro } = useBotSwitcherNavItemData();

  return (
    <BotSwitcher
      screenSize={screenSize}
      title={bot?.title || ''}
      active={active}
      showProBadge={isBotPro}
      src={bot?.status?.page_info?.picture}
      {...props}
    >
      {children}
    </BotSwitcher>
  );
};
