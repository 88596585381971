import React from 'react';
import range from 'lodash-es/range';
import { Spacer } from '@ui/Spacer';
import { PaywallTierCardLoader } from '@pages/Plans/components/TierCard/PaywallTierCard';

export const paywallPlansLoader = range(3).map((tierIndex) => (
  <React.Fragment
    // eslint-disable-next-line react/no-array-index-key
    key={tierIndex}
  >
    {tierIndex !== 0 && <Spacer horizontalFactor={4} />}
    <PaywallTierCardLoader />
  </React.Fragment>
));
