import { PluginType } from '../common/PluginTypes';
import { addAttributePluginFragment_config as AddAttributeConfig } from './@types/addAttributePluginFragment';

export const PLUGIN_TYPE = PluginType.add_attribute;

export const PLUGIN_DEFAULT_CONFIG: AddAttributeConfig = {
  __typename: 'AddAttributePluginConfig',
  title: null,
  value: null,
};

export const PLUGIN_INPUT_FIELD = 'addAttributePluginConfig';
