import { formatPrice } from '@utils/Format/priceFormatter';
import Maybe from 'graphql/tsutils/Maybe';
import React from 'react';

interface Props {
  amount: Maybe<number>;
  currency?: Maybe<string>;
}

export const Price: React.FC<Props> = ({ amount, currency }) => (
  <div data-testid="payment-history-price-amount">
    {typeof amount === 'number' ? formatPrice(amount, { currency }) : '-'}
  </div>
);
