import { OnboardingTourEventType } from '@globals';
import { InteractionData, OnboardingEvent } from '../../types';

export const matchingEvent = (
  event: OnboardingEvent,
  interactionData: InteractionData,
  currentElementID: string | null,
) => {
  const eventMatch =
    interactionData.event && event.type === interactionData.event;
  const eventValueMatch =
    event.validateValue === null ||
    event.validateValue === interactionData.value;
  const eventElementMatch =
    currentElementID !== null &&
    'element' in interactionData &&
    interactionData.element === currentElementID;
  const eventWithoutElementMatch =
    event.type === OnboardingTourEventType.connectWhatsapp;

  return Boolean(
    eventMatch &&
      eventValueMatch &&
      (eventElementMatch || eventWithoutElementMatch),
  );
};
