import React from 'react';
import cn from 'classnames';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as Tint } from './tint.svg';
import { OnboardingTipsStatus, Platform } from '@globals';
import { EnabledItems } from '../../types';
import css from './Bottle.css';

interface BottleProps {
  platform: Platform;
  entryPoints: EnabledItems;
}

export const Bottle: React.FC<BottleProps> = ({ entryPoints, platform }) => {
  const enabledPoints = entryPoints.filter(
    (item) => item.status === OnboardingTipsStatus.done,
  );
  const percent = enabledPoints.length
    ? `${(1 - enabledPoints.length / entryPoints.length) * 100}%`
    : '120%';
  const platformClassName = {
    [Platform.instagram]: css.instagram,
    [Platform.facebook]: css.facebook,
    [Platform.whatsapp]: css.whatsapp,
  }[platform];

  return (
    <div role="img" className={css.container}>
      <div className={css.isolationContainer}>
        <div className={css.bottle} />
        <Tint className={css.tint} />
        <Logo className={css.logo} />
        <div
          className={cn(css.water, css.darkWater, platformClassName)}
          style={{ top: percent }}
        />
        <div
          className={cn(css.water, platformClassName)}
          style={{ top: percent }}
        />
      </div>
    </div>
  );
};
