import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment privateReplyEntryPointEntryPointFragment on PrivateReplyEntryPoint {
    ...commonPluginFragment
    config {
      enabled
      delay_ms
      content_type
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
