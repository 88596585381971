import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { BotPageRouteParams, BotTabs, getTabLink } from '@utils/Routing';
import { TabNavLinkWithRole } from '../TabLink/TabLink';
import { NavItemProps } from './types';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { OnboardingTourEventType, OnboardingTourHTMLElementId } from '@globals';
import * as css from '../../LeftNavigationPanel.css';
import * as commonCSS from '../../common.css';

export const MessageTemplatesNavSubItems: React.FC<NavItemProps> = ({
  small,
  onClick,
  className,
  disabled,
}) => {
  const { botId } = useParams<BotPageRouteParams>();
  const { t } = useSafeTranslation();

  const handleClick = () => {
    onClick?.();
    OnboardingEmitter.emit(OnboardingTourEventType.click, {
      element: OnboardingTourHTMLElementId.NavLinkToBroadcast,
      value: null,
    });
  };

  return (
    <div
      className={cn({
        [css.extendedContent]: !small,
      })}
    >
      <TabNavLinkWithRole
        data-testid="test-nav-to-messages-templates"
        tabUrl={getTabLink(BotTabs.messagesTemplates, botId)}
        tabEvent="messages-templates"
        domain="broadcasting"
        className={className}
        disabled={disabled}
        small={small}
        onClick={onClick}
        activeClassName={cn(commonCSS.activeLinkSubMenu)}
      >
        {!small && <Spacer factor={1} horizontalFactor={5} />}
        <Type weight="medium" size="12px" color="grey">
          {t('modernComponents.LeftNavigationPanel.templatesSubTab')}
        </Type>
      </TabNavLinkWithRole>

      <TabNavLinkWithRole
        data-testid="test-nav-to-campaigns"
        id={OnboardingTourHTMLElementId.NavLinkToBroadcast}
        tabUrl={getTabLink(BotTabs.reEngage, botId)}
        tabEvent="broadcast"
        domain="broadcasting"
        disabled={disabled}
        className={className}
        small={small}
        onClick={handleClick}
        activeClassName={cn(commonCSS.activeLinkSubMenu)}
      >
        {!small && <Spacer factor={1} horizontalFactor={5} />}
        <Type weight="medium" size="12px" color="grey">
          {t('modernComponents.LeftNavigationPanel.campaignsSubTab')}
        </Type>
      </TabNavLinkWithRole>
    </div>
  );
};
