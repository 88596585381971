import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';
import { PluginType } from '../common/PluginTypes';
import { KommoPluginFragment_config } from './@types/KommoPluginFragment';
import { KommoActionType } from '@globals';

export const KOMMO_PLUGIN_TYPE = PluginType.kommo_plugin;

export type KommoPluginConfig = KommoPluginFragment_config;

export const KOMMO_PLUGIN_INPUT_FIELD = 'kommoPluginConfig';

export const KOMMO_PLUGIN_FRAGMENT = gql`
  fragment KommoPluginFragment on KommoPlugin {
    ...commonPluginFragment
    config {
      actionType
      pipelineId
      pipelineName
      statusId
      statusName
      assigneeId
      assigneeName
      whatsappName
      whatsappPhone
      deadline_ms
      text
      customFields {
        attribute
        fieldId
        fieldName
        fieldType
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;

export const KOMMO_PLUGIN_DEFAULT_CONFIG: KommoPluginConfig = {
  __typename: 'KommoPluginConfig',
  actionType: KommoActionType.create_lead,
  pipelineId: null,
  pipelineName: null,
  statusId: null,
  statusName: null,
  assigneeId: null,
  assigneeName: null,
  whatsappName: null,
  whatsappPhone: null,
  deadline_ms: 1000 * 60 * 60,
  text: null,
  customFields: [],
};
