import React, { useState } from 'react';
import cn from 'classnames';
import {
  Button,
  ButtonIntent,
  ButtonSize,
} from '../../../../modern-ui/_deprecated/Button';
import { ReactComponent as AddIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_add_small.svg';
import * as css from './InsertPlugin.css';
import { TooltipPure } from '../../../../modern-ui/Tooltip2';
import { Dialog } from '../../../../modern-ui/Dialog';
import { Modal } from '../../../../modern-ui/Modal';
import { AddPluginMode, AddPluginMenuProps } from '../types';
import { HoverDisclosure } from '../../../../modern-ui/HoverDisclosure';
import { AddPluginMenu } from '../AddPluginMenu';

const ADD_PLUGIN_MENU_OFFSET = {
  top: -12,
  left: 40,
};

export const InsertPluginPopup: React.FC<AddPluginMenuProps> = (props) => {
  const [hovered, setHovered] = useState(false);

  const [dialogLeft, setDialogLeft] = useState(0);
  const [dialogTop, setDialogTop] = useState(0);
  const [showMenuPopup, setShowMenuPopup] = useState(
    props.mode === AddPluginMode.STATIC,
  );

  const addButtonRef = React.createRef<HTMLDivElement>();

  const onClick = () => {
    const { top, left } = addButtonRef.current
      ? addButtonRef.current.getBoundingClientRect()
      : { top: 0, left: 0 };

    setDialogLeft(left + ADD_PLUGIN_MENU_OFFSET.left);
    setDialogTop(top + ADD_PLUGIN_MENU_OFFSET.top);
    setShowMenuPopup(true);
  };

  return (
    <HoverDisclosure
      render={({ bind, isVisible }) => (
        <div
          className={cn(
            css.insertPlugin,
            isVisible ? css.insertPluginHovered : css.insertPluginHide,
          )}
          {...bind}
        >
          <>
            <div ref={addButtonRef} />
            <TooltipPure
              show={hovered}
              content={window.i18next.t(
                'InsertPlugin-string-5422-add-new-card-here',
              )}
              positionFixed
              type="small"
              placement="right"
            >
              {(ref) => (
                <div
                  className={css.insertPluginButton}
                  ref={ref}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >
                  <Button
                    tall
                    size={ButtonSize.s}
                    intent={ButtonIntent.default}
                    renderIcon={() => <AddIcon />}
                    onClick={onClick}
                  />
                </div>
              )}
            </TooltipPure>
            {showMenuPopup ? (
              <Modal onDismiss={() => setShowMenuPopup(false)}>
                <Dialog
                  className={css.dialog}
                  style={{ left: dialogLeft, top: dialogTop }}
                >
                  <AddPluginMenu
                    {...props}
                    onHidePopupMenu={() => setShowMenuPopup(false)}
                  />
                </Dialog>
              </Modal>
            ) : null}
          </>
        </div>
      )}
    />
  );
};
