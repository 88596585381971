import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { CollapsibleSection } from '@ui/CollapsibleSection';
import { ListItem } from './ListItem/ListItem';
import { OnboardingListAnchor } from './OnboardingListAnchor';
import { EntryPointTipsQuery_entryPointTips_sections_entryPoints as EntryPoints } from '@utils/Data/MiniOnboarding/@types/EntryPointTipsQuery';
import { Platform, OnboardingTipsStatus } from '@globals';
import { useCurrentBotId } from '@utils/Routing';
import { LSKey, useLocalStorage } from '@utils/LocalStorage';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import * as css from './OnboardingSection.css';
import { Line } from './Container/Line/Line';
import { useHeightPreopenedTip } from './features/PreopenedTip/usePreopenedTip';

export interface OnboardingSectionProps {
  entryPoints: EntryPoints[];
  title: string;
  platform: Platform;
  opened: boolean;
  onAnchorClick: VoidFunction;
  halfOpened: boolean;
  line: (extended: boolean) => React.ReactNode;
  index: number;
}

export const OnboardingSection: React.FC<OnboardingSectionProps> = ({
  entryPoints,
  platform,
  title,
  opened,
  onAnchorClick,
  halfOpened,
  line,
  index: sectionIndex,
}) => {
  const botId = useCurrentBotId()!;
  const { boxRef, tipRef, style, setForceUpdate } =
    useHeightPreopenedTip(halfOpened);
  const { isSmallScreenSize } = useDeviceMedia();
  const [showFlowBuilderOnMobile, setShowFlowBuilderOnMobile] = useLocalStorage(
    LSKey.showFlowBuilderOnMobile,
  );

  const tipsDone = entryPoints.filter(
    (entryPoint) => entryPoint.status === OnboardingTipsStatus.done,
  ).length;
  const stats = `${tipsDone}/${entryPoints.length}`;

  return (
    <Flex
      ref={boxRef}
      flexDirection="column"
      className={cn(css.wrapper, {
        [css.opened]: opened,
        [css.closed]: !opened,
      })}
      style={style}
    >
      <CollapsibleSection
        defaultExtended={opened}
        unmountHiddenSection={false}
        ignoreTransitionClasses
        controllable
        anchor={({ extended }) => (
          <OnboardingListAnchor
            stats={stats}
            title={title}
            opened={halfOpened ? false : extended}
            onClick={onAnchorClick}
          />
        )}
      >
        {({ bind }) => (
          <>
            <Flex
              {...bind}
              flexDirection="column"
              gapFactor={1}
              className={css.tipsWrapper}
            >
              {entryPoints.map((entryPoint, index) => (
                <div
                  style={{
                    zIndex:
                      entryPoint.status === OnboardingTipsStatus.done
                        ? entryPoints.length - index
                        : 0,
                  }}
                  ref={(ref) => {
                    if (index === 1) {
                      tipRef.current = ref;
                      setForceUpdate(ref);
                    }
                  }}
                >
                  <ListItem
                    key={entryPoint.title}
                    {...entryPoint}
                    platform={platform}
                    botId={botId}
                    showFlowBuilderOnMobile={showFlowBuilderOnMobile}
                    setShowFlowBuilderOnMobile={setShowFlowBuilderOnMobile}
                    isSmallScreenSize={isSmallScreenSize}
                    isButtonActive={
                      sectionIndex === 0 &&
                      index === 0 &&
                      entryPoint.status !== OnboardingTipsStatus.done
                    }
                    lineToBottle={
                      <Line
                        extended={opened}
                        platform={platform}
                        enabled={
                          entryPoint.status === OnboardingTipsStatus.done
                        }
                      />
                    }
                  />
                </div>
              ))}
            </Flex>

            {line(opened)}
          </>
        )}
      </CollapsibleSection>
    </Flex>
  );
};
