import React from 'react';
import ng from 'angular';
import { react2angular } from 'react2angular';
import { ImageResizeDialog, ImageResizeDialogProps } from './ImageResizeDialog';
import { Modal } from '../../../../../modern-ui/Modal';
import * as css from './ImageResize.css';

const ImageResizeDialogWrapper: React.FC<ImageResizeDialogProps> = (props) => (
  <Modal overlayClassName={css.overlay}>
    <ImageResizeDialog {...props} />
  </Modal>
);

export const ngImageResizeDialog = ng
  .module('app.components.imageResizeDialog', [])
  .component(
    'imageResizeDialog',
    react2angular(
      ImageResizeDialogWrapper,
      ['image', 'ratio', 'onDismiss', 'onSave'],
      [],
    ),
  ).name;
