import React from 'react';

import { useSafeTranslation } from '@utils/useSafeTranslation';

import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';

import * as css from './ExistingCard.css';

interface ExistingCardProps {
  onUpdate: () => void;
  cardLastNumbers: string;
  withoutPrefix?: boolean;
  error?: boolean;
}
export const ExistingCard: React.FC<ExistingCardProps> = ({
  onUpdate,
  error,
  cardLastNumbers,
}) => {
  const { t } = useSafeTranslation();
  return (
    <div className={css.root}>
      <Flex justifyContent="space-between" alignItems="center">
        <Type size="15px">
          {t('ExistingCard-JSXText-1189-current-card')}
          {` ${cardLastNumbers}`}
        </Type>
        <Button intent={error ? 'red' : 'secondary'} onClick={onUpdate}>
          {t('ExistingCard-JSXText-1177-update-card')}
        </Button>
      </Flex>
    </div>
  );
};
