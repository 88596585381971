import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment whatsappDirectEntryPointFragment on WhatsappDirectEntryPoint {
    ...commonPluginFragment
    config {
      block_id
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
