import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Platform } from '@globals';
import {
  clone,
  pathOr,
  sum,
  map,
  pipe,
  prop,
  last,
  sortBy,
  equals,
} from 'ramda';
import gql from 'graphql-tag';
import cn from 'classnames';
import { Hover } from 'react-powerplug';
import { log } from 'cf-common/src/logger';
import { removeTypename } from '@utils/GQL/utils';
import * as css from './AbtPlugin.css';
import { PluginBox, PluginData } from '../common';
import { IPlugin } from '../common/Plugin';
import {
  PLUGIN_HELP_URL,
  PLUGIN_ICON,
  PLUGIN_NAME,
  PLUGIN_TYPE,
} from './AbtPluginConst';
import { PluginHeader } from '../common/PluginHeader';
import { PluginBlock } from '../common/PluginBlock';
import {
  abtPluginFragment_config,
  abtPluginFragment_config as AbtPluginConfig,
  abtPluginFragment_config_user_filter as UserFilterData,
  abtPluginFragment_config_user_filter_parameters,
} from './@types/abtPluginFragment';
import { PluginControlLabel } from '../common/PluginControlLabel';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { Range } from '@ui/Range';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
} from '../../../modern-ui/_deprecated/Button';
import { ButtonUnstyled } from '@ui/Button';
import { BlocksSelector } from '@pages/AiSetupPage/BlocksSelector';
import { BlockWithPermissions } from '@pages/AiSetupPage';
import { BlocksSelectorData } from '../../BlocksSelector2';
import { ReactComponent as AddIcon } from '../../../modern-ui/_deprecated/Icon/icons/ic_add_small.svg';
import { FocusWithin } from '@ui/FocusWithin';
import { OptionPopupMenu } from './OptionPopupMenu';
import { UserFilter, UserFilterColorWay } from '../../UserFilter';
import {
  AbtStatsQuery,
  AbtStatsQuery_abtStats_options as StatOption,
  AbtStatsQueryVariables,
} from './@types/AbtStatsQuery';
import { Loader } from '@ui/Loader';
import { ReactComponent as FlagIcon } from './images/flag.svg';
import { Anchor } from '@ui/Links';
import { TooltipPure, Tooltip2 } from '@ui/Tooltip2';
import client from '../../../common/services/ApolloService';
import OneElementQueue from '../../../common/services/OneElementQueueService';

const oneElementQueue = new OneElementQueue();

export interface AbtPluginProps extends IPlugin {
  botId: string;
  blockTitle: string;
  goToPeopleTabRequest: (filter: UserFilterData) => void;
}

type AbtStatsType = {
  options: StatOption[];
  maxConversionsOption: StatOption | undefined;
  shouldShowGreenMark: boolean;
  usersTotal: number;
};

const OPTIONS_LIMIT = 10;
const OPTION_SYMBOLS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const SHOW_MARK_CONVERSIONS_LIMIT = 10;

const calcSumPercents = pipe(map(prop('percent')), sum);

const sortByConversions = sortBy(prop('conversions'));

const getMaxConversionsOption = pipe<StatOption[], StatOption[], StatOption>(
  sortByConversions,
  last,
);

const isUserFilterEmpty = (filter: UserFilterData | null) => {
  if (!filter || !filter.parameters || filter.parameters.length === 0) {
    return true;
  }
  return filter.parameters.every(
    (parameter: abtPluginFragment_config_user_filter_parameters | null) =>
      !parameter ||
      !parameter.name ||
      !parameter.values ||
      parameter.values.filter(Boolean).length === 0,
  );
};

const emptyFilter = {
  __typename: 'Segmentation',
  parameters: [],
  operation: 'and',
  valid: null,
} as UserFilterData;

const ABT_STATS_QUERY = gql`
  query AbtStatsQuery(
    $botId: String!
    $attributeName: String!
    $optionIds: [String!]!
    $goal: SegmentationInput!
  ) {
    abtStats(
      botId: $botId
      attributeName: $attributeName
      optionIds: $optionIds
      goal: $goal
    ) {
      options {
        name
        total
        conversions
      }
    }
  }
`;

export const AbtPlugin: React.FC<AbtPluginProps> = ({
  id,
  blockId,
  position,
  botId,
  blockTitle,
  goToPeopleTabRequest,
}) => {
  const [isOptionWasAdded, setIsOptionWasAdded] = useState<boolean>(false);
  const [touchedInputs, setTouchedInputs] = useState<{
    [index: string]: boolean;
  }>({}); // rewrite to Formic

  useEffect(() => {
    setIsOptionWasAdded(false);
  }, [isOptionWasAdded]);

  useEffect(() => {
    return () => {
      oneElementQueue.removeQueue(id);
    };
  }, [id]);

  const goToPeopleTab = useCallback(
    (optionName: string, filter: UserFilterData = emptyFilter) => {
      goToPeopleTabRequest({
        ...filter,
        parameters: filter.parameters.concat([
          {
            __typename: 'SegmentationParameter',
            type: 'custom',
            name: `ABT in ${blockTitle}`,
            operation: 'is',
            values: [optionName],
          } as abtPluginFragment_config_user_filter_parameters,
        ]),
      });
    },
    [blockTitle, goToPeopleTabRequest],
  );

  const [shouldShowTooltips, setShouldShowTooltips] = useState<boolean>(false);
  const [abtStats, setAbtStats] = useState<AbtStatsType>({
    shouldShowGreenMark: false,
    maxConversionsOption: undefined,
    usersTotal: 0,
    options: [],
  });
  const [loadingAbtStats, setLoadingAbtStats] = useState<boolean>(false);

  // We use a queue of one element. It is necessary to exclude multiple
  // ABt statistics requests when updating the Goal filter state.
  const loadAbtStatsQueueOperator = useMemo(
    () =>
      oneElementQueue.getQueue(
        id,
        async (goal: UserFilterData, optionIds: string[]) => {
          try {
            setLoadingAbtStats(true);
            const {
              data: { abtStats: stats },
            } = await client.query<AbtStatsQuery, AbtStatsQueryVariables>({
              query: ABT_STATS_QUERY,
              variables: {
                botId,
                goal,
                optionIds,
                attributeName: `${window.i18next.t(
                  'AbtPlugin-Template--473-abt-in',
                )}${blockTitle}`,
              },
              fetchPolicy: 'no-cache',
            });
            if (stats) {
              const { options } = stats;
              const conversionsTotal = options.reduce(
                (count, option) => count + option.conversions,
                0,
              );
              const maxConversionsOption = getMaxConversionsOption(options);
              const shouldShowGreenMark =
                conversionsTotal > SHOW_MARK_CONVERSIONS_LIMIT;
              const usersTotal = options.reduce(
                (count, option) => count + option.total,
                0,
              );
              setShouldShowTooltips(usersTotal > 0);
              setAbtStats({
                shouldShowGreenMark,
                maxConversionsOption,
                usersTotal,
                options: stats.options,
              });
            }
          } catch (error) {
            log.warn({ error, msg: 'Error loading abt stat' });
          } finally {
            setLoadingAbtStats(false);
          }
        },
      ),
    [blockTitle, botId, id],
  );

  return (
    <PluginData<AbtPluginConfig>
      id={id}
      blockId={blockId}
      pluginType={PLUGIN_TYPE}
      position={position}
      onCompletedLoad={({ user_filter: userFilter, options }) => {
        if (userFilter && options) {
          loadAbtStatsQueueOperator.queue(
            removeTypename(userFilter),
            options.map(prop('name')),
          );
        }
      }}
    >
      {({ savePlugin, pluginConfig, loading, setPluginConfigState }) => {
        const sumPercents = calcSumPercents(pluginConfig.options || []);
        const isSumPercentsNotEq100 = sumPercents !== 100;

        if (loading) {
          return null;
        }

        return (
          <PluginBox>
            <PluginHeader
              title={PLUGIN_NAME}
              icon={PLUGIN_ICON}
              helpUrl={PLUGIN_HELP_URL}
              pluginType={PLUGIN_TYPE}
            />

            <PluginBlock>
              <span>
                {window.i18next.t(
                  'AbtPlugin-JSXText-1639-use-this-plugin-to-test-different-bot-flow-versions',
                )}
              </span>
              <a
                href={PLUGIN_HELP_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {window.i18next.t('AbtPlugin-JSXText-1076-learn-more')}
              </a>
            </PluginBlock>
            <div>
              <BlocksSelectorData botId={botId}>
                {({ loading, blocksGroups, flowsGroups }) => {
                  if (!pluginConfig.options) {
                    return null;
                  }
                  return (
                    <>
                      {pluginConfig.options.map((option, optionIndex) => {
                        const onRangeChange = ({
                          currentTarget: { value },
                        }: React.FormEvent<HTMLInputElement>) => {
                          const config = clone(pluginConfig);
                          config.options![optionIndex].percent = Math.min(
                            100,
                            Math.max(0, Number(value)),
                          );

                          if (config.options!.length === 2) {
                            const otherOptionIndex = 1 - optionIndex;
                            config.options![otherOptionIndex].percent =
                              100 - config.options![optionIndex].percent;
                          }

                          setPluginConfigState({ config });
                        };

                        return (
                          <Hover
                            key={option.name}
                            render={({ bind, hovered }) => (
                              <PluginBlock className={css.option} {...bind}>
                                <Flex
                                  alignItems="center"
                                  className={css.spacing}
                                >
                                  <div className={css.optionName}>
                                    {window.i18next.t(
                                      'AbtPlugin-JSXText--859-variant',
                                    )}
                                    {option.name}
                                  </div>
                                  {hovered &&
                                    (pluginConfig.options || []).length > 2 && (
                                      <OptionPopupMenu
                                        showTooltip={shouldShowTooltips}
                                        onDeleteRequest={() => {
                                          const config = clone(pluginConfig);
                                          if (config.options) {
                                            config.options.splice(
                                              optionIndex,
                                              1,
                                            );
                                          }
                                          setPluginConfigState({ config });
                                          savePlugin();
                                        }}
                                      />
                                    )}
                                </Flex>
                                <PluginControlLabel
                                  label={window.i18next.t(
                                    'AbtPlugin-string--159-user-distribution',
                                  )}
                                  className={css.spacing}
                                >
                                  {({ id: domId }) => (
                                    <FocusWithin
                                      render={({ bind, focusedWithin }) => (
                                        <TooltipPure
                                          content={window.i18next.t(
                                            'AbtPlugin-string-2554-changing-the-distribution-may-affect-test-results',
                                          )}
                                          show={
                                            focusedWithin && shouldShowTooltips
                                          }
                                          placement="right"
                                          type="small"
                                        >
                                          {(ref) => (
                                            <Flex alignItems="center" {...bind}>
                                              <Flex
                                                alignItems="center"
                                                ref={ref}
                                                className={css.percentBox}
                                              >
                                                <div className={css.rangeBox}>
                                                  <Range
                                                    value={option.percent}
                                                    onChange={onRangeChange}
                                                    onMouseUp={savePlugin}
                                                    onBlur={savePlugin}
                                                    min={0}
                                                    max={100}
                                                  />
                                                </div>
                                                <Input
                                                  id={domId}
                                                  value={option.percent}
                                                  type="number"
                                                  renderIconEnd={() => (
                                                    <div
                                                      className={
                                                        css.percentSymbol
                                                      }
                                                    >
                                                      %
                                                    </div>
                                                  )}
                                                  className={css.percentInput}
                                                  step={1}
                                                  onChange={onRangeChange}
                                                  onBlur={savePlugin}
                                                  error={isSumPercentsNotEq100}
                                                />
                                                {focusedWithin &&
                                                  isSumPercentsNotEq100 && (
                                                    <div
                                                      className={
                                                        css.percentWarring
                                                      }
                                                    >
                                                      {window.i18next.t(
                                                        'AbtPlugin-JSXText--765-user-distribution-should-total-100-current',
                                                      )}{' '}
                                                      {sumPercents}
                                                      %)
                                                    </div>
                                                  )}
                                              </Flex>
                                            </Flex>
                                          )}
                                        </TooltipPure>
                                      )}
                                    />
                                  )}
                                </PluginControlLabel>

                                <PluginControlLabel
                                  label={window.i18next.t(
                                    'AbtPlugin-string--656-redirect-to',
                                  )}
                                >
                                  {() => (
                                    <FocusWithin
                                      render={({ bind, focusedWithin }) => (
                                        <TooltipPure
                                          content={window.i18next.t(
                                            'AbtPlugin-string--123-changing-this-may-affect-test-results',
                                          )}
                                          placement="right"
                                          type="small"
                                          show={
                                            shouldShowTooltips &&
                                            focusedWithin &&
                                            !!option.blocks &&
                                            !!option.blocks.length
                                          }
                                        >
                                          {(ref) => (
                                            <div
                                              className={css.blocksSelectorBox}
                                              ref={ref}
                                            >
                                              <div {...bind}>
                                                <BlocksSelector
                                                  platform={Platform.facebook}
                                                  autofocus={
                                                    optionIndex ===
                                                      pluginConfig.options!
                                                        .length -
                                                        1 && isOptionWasAdded
                                                  }
                                                  placeholder={
                                                    loading
                                                      ? window.i18next.t(
                                                          'AbtPlugin-string--189-loading',
                                                        )
                                                      : window.i18next.t(
                                                          'AbtPlugin-string-1806-add-block-name',
                                                        )
                                                  }
                                                  blocksSelected={
                                                    option.blocks || []
                                                  }
                                                  blocksGroups={
                                                    blocksGroups as BlockWithPermissions[]
                                                  }
                                                  flowsGroups={flowsGroups}
                                                  onChange={(blocks) => {
                                                    if (
                                                      equals(
                                                        blocks || [],
                                                        pluginConfig?.options![
                                                          optionIndex
                                                        ].blocks || [],
                                                      )
                                                    ) {
                                                      return;
                                                    }
                                                    const config =
                                                      clone(pluginConfig);
                                                    config.options![
                                                      optionIndex
                                                    ].blocks = blocks;
                                                    setPluginConfigState({
                                                      config,
                                                    });
                                                    savePlugin();
                                                  }}
                                                  error={
                                                    touchedInputs[
                                                      option.name
                                                    ] &&
                                                    (!option.blocks ||
                                                      option.blocks.length ===
                                                        0)
                                                  }
                                                  currentBotId={botId}
                                                  onBlur={() =>
                                                    setTouchedInputs({
                                                      ...touchedInputs,
                                                      [option.name]: true,
                                                    })
                                                  }
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </TooltipPure>
                                      )}
                                    />
                                  )}
                                </PluginControlLabel>
                              </PluginBlock>
                            )}
                          />
                        );
                      })}
                    </>
                  );
                }}
              </BlocksSelectorData>
            </div>
            {pathOr<number>(0, ['options', 'length'], pluginConfig) <
              OPTIONS_LIMIT && (
              <Tooltip2
                content={window.i18next.t(
                  'AbtPlugin-string--206-adding-a-variant-may-affect-test-results',
                )}
                placement="right"
                type="small"
                hoverable={shouldShowTooltips}
              >
                {(ref, bind) => (
                  <Button
                    colorWay={ButtonColorWay.blue}
                    intent={ButtonIntent.primary}
                    className={css.add}
                    renderIcon={() => <AddIcon />}
                    onClick={() => {
                      const config = clone(pluginConfig);
                      const options = config.options || [];
                      const newOptionName =
                        OPTION_SYMBOLS.split(
                          (last(options) || { name: '' }).name,
                        )[1][0] || OPTION_SYMBOLS[0];
                      config.options = options.concat({
                        __typename: 'AbtPluginOption',
                        name: newOptionName,
                        percent: 0,
                        blocks: [],
                      });
                      setIsOptionWasAdded(true);
                      setPluginConfigState({ config });
                      savePlugin();
                    }}
                    innerRef={ref}
                    {...bind}
                  >
                    {window.i18next.t('AbtPlugin-JSXText-1946-add-variant')}
                  </Button>
                )}
              </Tooltip2>
            )}
            <PluginBlock className={css.footer}>
              {pluginConfig.user_filter ? (
                <>
                  <div className={css.goalTitle}>
                    {window.i18next.t('AbtPlugin-JSXText-2224-goal')}
                  </div>
                  <UserFilter
                    filter={pluginConfig.user_filter}
                    colorWay={UserFilterColorWay.white}
                    onChange={(filter) => {
                      const config = clone(pluginConfig);
                      if (filter.parameters.length === 0) {
                        config.user_filter!.parameters = [];
                      } else {
                        config.user_filter = filter;
                      }
                      setPluginConfigState({ config });
                      loadAbtStatsQueueOperator.queue(
                        removeTypename(config.user_filter!),
                        config.options!.map(prop('name')),
                      );
                    }}
                    onBlur={savePlugin}
                    onFilterItemRemove={savePlugin}
                    includeOnlyParameters={['attribute']}
                  />
                  <div className={css.statTitle}>
                    {window.i18next.t('AbtPlugin-JSXText-8020-stats')}
                  </div>
                  <div className={css.tableBox}>
                    <table className={css.statTable}>
                      <thead>
                        <tr>
                          <th>
                            {window.i18next.t('AbtPlugin-JSXText-1901-variant')}
                          </th>
                          <th>
                            {window.i18next.t(
                              'AbtPlugin-JSXText--556-total-users',
                            )}
                          </th>
                          <th>
                            {window.i18next.t(
                              'AbtPlugin-JSXText-5835-conversions',
                            )}
                          </th>
                          <th>
                            {window.i18next.t(
                              'AbtPlugin-JSXText--431-conversion-rate',
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {pluginConfig.options &&
                          pluginConfig.options.map(({ name }, optionIndex) => {
                            const { total, conversions } = abtStats.options[
                              optionIndex
                            ] || {
                              total: 0,
                              conversions: 0,
                            };

                            return (
                              <tr key={name}>
                                <td>
                                  <span className={css.statOptionName}>
                                    {window.i18next.t(
                                      'AbtPlugin-JSXText--859-variant',
                                    )}
                                    {name}
                                  </span>
                                  {abtStats.shouldShowGreenMark &&
                                    abtStats.maxConversionsOption &&
                                    abtStats.maxConversionsOption.name ===
                                      name && <FlagIcon className={css.flag} />}
                                </td>
                                <td>
                                  {total === 0 ? (
                                    <span>0</span>
                                  ) : (
                                    <Anchor
                                      intent="internal"
                                      onClick={() => {
                                        goToPeopleTab(name);
                                      }}
                                    >
                                      {total.toLocaleString('en-EN')}
                                    </Anchor>
                                  )}
                                </td>
                                <td>
                                  {isUserFilterEmpty(
                                    pluginConfig.user_filter,
                                  ) ? (
                                    <div className={css.conversionsNote}>
                                      {window.i18next.t(
                                        'AbtPlugin-JSXText--145-set-a-goal-to-see-conversions',
                                      )}
                                    </div>
                                  ) : conversions === 0 ? (
                                    <span>0</span>
                                  ) : (
                                    <ButtonUnstyled
                                      onClick={() => {
                                        goToPeopleTab(
                                          name,
                                          pluginConfig.user_filter || undefined,
                                        );
                                      }}
                                    >
                                      <Anchor intent="internal">
                                        {conversions.toLocaleString('en-EN')}
                                      </Anchor>
                                    </ButtonUnstyled>
                                  )}
                                </td>

                                <td>
                                  {total === 0 ? (
                                    '—'
                                  ) : (
                                    <span
                                      className={cn({
                                        [css.mark]:
                                          abtStats.shouldShowGreenMark,
                                      })}
                                    >
                                      {`${(
                                        (conversions / total) *
                                        100
                                      ).toLocaleString()}%`}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {loadingAbtStats && (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        className={css.loader}
                      >
                        <Loader />
                      </Flex>
                    )}
                  </div>
                  <div className={css.note}>
                    {window.i18next.t(
                      'AbtPlugin-JSXText--677-each-user-in-the-experiment-automatically-gets-the',
                    )}
                    <span className={css.noWrap}>{`${window.i18next.t(
                      'AbtPlugin-Template--182-abt-in',
                    )}${blockTitle}}} `}</span>
                    {window.i18next.t('AbtPlugin-JSXText-8867-attribute')}
                  </div>
                </>
              ) : (
                <Button
                  intent={ButtonIntent.primary}
                  colorWay={ButtonColorWay.white}
                  onClick={() => {
                    setPluginConfigState({
                      config: {
                        ...pluginConfig,
                        user_filter: {
                          __typename: 'Segmentation',
                          parameters: [],
                          operation: 'and',
                          valid: null,
                        },
                      } as abtPluginFragment_config,
                    });
                    savePlugin();
                  }}
                >
                  {window.i18next.t(
                    'AbtPlugin-JSXText--198-set-goal-and-see-stats',
                  )}
                </Button>
              )}
            </PluginBlock>
          </PluginBox>
        );
      }}
    </PluginData>
  );
};
