import React from 'react';
import cn from 'classnames';
import * as css from './AddPlugin.css';

export enum AddPluginButtonMode {
  menu = 'menu',
  list = 'list',
  listPro = 'listPro',
}

interface AddPluginButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  renderIcon: () => React.ReactNode;
  onClick: () => void;
  mode: AddPluginButtonMode;
  innerRef?: React.Ref<HTMLButtonElement>;
  className?: string;
}

export const AddPluginButton: React.FC<AddPluginButtonProps> = ({
  mode,
  children,
  renderIcon,
  innerRef,
  className,
  ...props
}) => (
  <button
    data-testid="plugins__add-plugin-button"
    className={cn(css.pluginAddButton, className, {
      [css.pluginAddMenu]: mode === AddPluginButtonMode.menu,
      [css.pluginAddList]:
        mode === AddPluginButtonMode.list ||
        mode === AddPluginButtonMode.listPro,
      [css.pluginAddListPro]: mode === AddPluginButtonMode.listPro,
    })}
    ref={innerRef}
    {...props}
  >
    <span className={css.label}>
      {renderIcon ? (
        <span className={css.iconContainer}>{renderIcon()}</span>
      ) : null}
      {children && <div className={css.labelText}>{children}</div>}
    </span>
  </button>
);
