import React from 'react';
import { Flex } from '@ui/Flex';
import { Icon, IconProps } from '@ui/Icon';
import * as css from './NavItem.css';

export interface NavItemSmallViewProps
  extends Pick<IconProps, 'color' | 'icon'> {}

export const NavItemSmallView: React.FC<NavItemSmallViewProps> = ({
  color,
  icon,
  children,
}) => (
  <Flex justifyContent="center" alignItems="center" className={css.wrapper}>
    <Icon
      className={css.icon}
      size="20px"
      icon={icon}
      color={color || 'grey'}
    />
    {children}
  </Flex>
);
