import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment commentsLikePluginConfigFragment on CommentsLikePlugin {
    ...commonPluginFragment
    config {
      likeComment
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
