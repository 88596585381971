import ng from 'angular';
import { react2angular } from 'react2angular';
import { ManageMessagesButton } from './ManageMessagesButton';

export const ngManageMessagesButton = ng
  .module('app.components.manageMessagesButton', [])
  .component(
    'manageMessagesButton',
    react2angular(ManageMessagesButton, ['botId', 'source'], []),
  ).name;
