import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { DefaultDialog } from '@ui/Dialog';
import { useWorkspace } from '@utils/Data/Workspaces/useWorkspace';
import { CenteredLoader } from '@ui/Loader';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { usePreventEvents } from '@utils/Event/usePreventEvents';
import {
  CreateNewWorkspaceBotContent,
  CreateNewWorkspaceBotContentProps,
} from './CreateNewWorkspaceBotContent';

export interface CreateNewWorkspaceBotModalProps
  extends Omit<CreateNewWorkspaceBotContentProps, 'name'> {
  workspaceId: Maybe<string>;
}

export const CreateNewWorkspaceBotModal: React.FC<CreateNewWorkspaceBotModalProps> =
  ({ workspaceId, onSubmit, onClose }) => {
    const { isSmallScreenSize } = useDeviceMedia();
    const { t } = useSafeTranslation();
    const { workspace } = useWorkspace(workspaceId);
    const preventEventsRef = usePreventEvents([
      'mousedown',
      'touchstart',
      'mouseup',
    ]);

    return (
      <div ref={preventEventsRef}>
        <DefaultDialog
          mobileAdaptive={isSmallScreenSize}
          header={t('modernComponents.BotSwitcher.createNewBotModal.header')}
          onDismiss={onClose}
        >
          {workspace ? (
            <CreateNewWorkspaceBotContent
              onSubmit={onSubmit}
              onClose={onClose}
              name={workspace.name}
            />
          ) : (
            <CenteredLoader style={{ height: '151px' }} />
          )}
        </DefaultDialog>
      </div>
    );
  };
