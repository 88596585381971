import React from 'react';
import { BotPageRouteParams, BotTabs, getTabLink } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useParams } from 'react-router-dom';
import { TabNavLinkWithRole } from '../TabLink/TabLink';
import { NavItemProps, NavItemViewProps } from './types';
import { NavItemView } from '../NavItems';
import * as css from '../../common.css';
import { useAdminFeatures } from '@utils/Data/Admin';

export const AiNavItemView: React.FC<NavItemViewProps> = ({ small }) => {
  const { t } = useSafeTranslation();
  const { adminFeatures } = useAdminFeatures();

  return (
    <NavItemView
      title={t(
        adminFeatures?.show_keywords_tab
          ? 'modernComponents.LeftNavigationPanel.keywords'
          : 'modernComponents.LeftNavigationPanel.aiTab',
      )}
      small={small}
      icon="tabs_aiTab"
    />
  );
};

export const AiNavItem: React.FC<NavItemProps> = ({
  small,
  className,
  onClick,
  disabled,
}) => {
  const { botId } = useParams<BotPageRouteParams>();

  return (
    <TabNavLinkWithRole
      data-testid="test-nav-to-ai-setup"
      tabUrl={getTabLink(BotTabs.keywords, botId)}
      tabEvent="ai"
      domain="ai"
      small={small}
      disabled={disabled}
      onClick={onClick}
      className={className}
      contentClassName={css.linkTooltip}
      activeClassName={css.activeLink}
    >
      <AiNavItemView small={small} />
    </TabNavLinkWithRole>
  );
};
