import { PluginType } from '../common/PluginTypes';
import { videoPluginFragment_config } from './@types/videoPluginFragment';

export type VideoPluginConfig = videoPluginFragment_config;

export const PLUGIN_TYPE = PluginType.video;

export const PLUGIN_DEFAULT_CONFIG: VideoPluginConfig = {
  __typename: 'VideoPluginConfig',
  url: '',
  filename: '',
};

export const PLUGIN_INPUT_FIELD = 'videoPluginConfig';
