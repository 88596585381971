import React, { useEffect } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { sendEvent } from '@utils/Analytics';
import { getErrorDescription, getErrorHeader } from './messages';
import { PaymentFlowErrorData } from 'cf-common/src/utils/Stripe/errors';

export interface PaymentErrorProps {
  error?: PaymentFlowErrorData;
  onDismiss: () => void;
  onTryAgain: () => void;
}

export const PaymentError: React.FC<PaymentErrorProps> = ({
  error,
  onDismiss,
  onTryAgain,
}) => {
  const { t } = useSafeTranslation();
  const header = <div style={{ width: 350 }}>{getErrorHeader(error)}</div>;
  const description = getErrorDescription(error);

  useEffect(() => {
    sendEvent({
      category: 'pro plan payments',
      action: 'show error popup',
      label: '3d secure',
      propertyBag: {
        declineCode: error?.declineCode,
      },
    });
  }, [error]);

  return (
    <DefaultDialog
      header={header}
      onDismiss={onDismiss}
      footer={() => (
        <Flex justifyContent="flex-end">
          <Button intent="primary" onClick={onTryAgain}>
            {t('modernComponents.Payments.PaymentDialog.tryAgain')}
          </Button>
        </Flex>
      )}
    >
      <Type size="15px_DEPRECATED">{description}</Type>
    </DefaultDialog>
  );
};
