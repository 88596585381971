import React from 'react';

interface Props {
  count: number;
}

export const Users: React.FC<Props> = ({ count = 0 }) => (
  <div data-testid="payment-history-price-users-count">
    {count.toLocaleString('en-EN')}
    {window.i18next.t('Users-JSXText-1027-users')}
  </div>
);
