import React, { useState, useCallback, useRef, useEffect } from 'react';
import cn from 'classnames';
import { prop } from 'ramda';
import httpStatuses from 'http-status';
import { TestRequestMutation_testJsonPluginRequest as JsonPluginRequestResult } from '../@types/TestRequestMutation';
import { PluginBlock } from '../../common/PluginBlock';
import { Flex } from '../../../../modern-ui/Flex';
import * as css from './JsonPluginRequestTestResult.css';

interface IJsonPluginRequestTestResultProps
  extends React.HTMLProps<HTMLDivElement> {
  result: JsonPluginRequestResult;
}

interface IRequestTabProps extends React.HTMLProps<HTMLDivElement> {
  active: boolean;
  disabled?: boolean;
}

const RequestTab: React.FC<IRequestTabProps> = ({
  className,
  active,
  disabled,
  ...props
}) => (
  <div
    className={cn(className, css.RequestTab, {
      [css.disabled]: disabled,
      [css.active]: active,
    })}
    role="tab"
    aria-selected={active}
    {...props}
  />
);

const REQUEST_TEST_TABS = [
  {
    name: 'Response Headers',
    field: 'responseHeaders',
  },
  {
    name: 'Response Body',
    field: 'response',
  },
  {
    name: 'Request Headers',
    field: 'requestHeaders',
  },
  {
    name: 'Request Body',
    field: 'body',
  },
];

export const JsonPluginRequestTestResult: React.FC<IJsonPluginRequestTestResultProps> = ({
  result,
  className,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(REQUEST_TEST_TABS[1].field);
  const selectAlByHotKey = useCallback((event: React.KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.which === 65) {
      const range = document.createRange();
      range.selectNode(event.currentTarget);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
      event.preventDefault();
    }
  }, []);
  const tabOutRef = useRef(null as HTMLPreElement | null);
  const tabData = prop(activeTab as any, result);

  useEffect(() => {
    if (tabOutRef.current) {
      tabOutRef.current.focus();
    }
  }, []);

  let tabOut = '';

  if (Array.isArray(tabData)) {
    tabOut = tabData.map(({ key, value }) => `${key}: ${value}`).join('\n');
  } else {
    try {
      tabOut = JSON.stringify(JSON.parse(tabData), null, 2);
    } catch (_) {
      tabOut = tabData;
    }
  }

  const statusText =
    (httpStatuses as any)[result.responseCode] ||
    window.i18next.t('JsonPluginRequestTestResult-string-1004-unknown-error');

  return (
    <PluginBlock
      className={cn(css.JsonPluginRequestTestResult, className)}
      {...props}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className={css.header}
      >
        <div className={css.title}>
          {window.i18next.t(
            'JsonPluginRequestTestResult-JSXText--275-response',
          )}
        </div>
        <div>
          {result.responseCode && (
            <div className={css.code}>
              <span>{result.responseCode}</span>
              <span>&nbsp;</span>
              <span>{statusText.toUpperCase()}</span>
            </div>
          )}
          {result.error && <div className={css.error}>{result.error}</div>}
        </div>
      </Flex>
      <Flex>
        <div role="tablist" className={css.tabslist}>
          {REQUEST_TEST_TABS.map((tab) => (
            <RequestTab
              key={tab.field}
              disabled={!prop(tab.field as any, result)}
              active={tab.field === activeTab}
              onClick={() => {
                setActiveTab(tab.field);
                const selection = window.getSelection();
                if (selection) {
                  selection.removeAllRanges();
                }

                if (tabOutRef.current) {
                  tabOutRef.current.focus();
                }
              }}
            >
              {tab.name}
            </RequestTab>
          ))}
        </div>
        <div className={css.viewBox}>
          <pre
            ref={tabOutRef}
            className={css.view}
            tabIndex={-1}
            onKeyDown={selectAlByHotKey}
          >
            {tabOut}
          </pre>
        </div>
      </Flex>
    </PluginBlock>
  );
};
