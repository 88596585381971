import React from 'react';
import cn from 'classnames';
import * as css from './PluginBlock.css';

interface PluginBlock extends React.HTMLProps<HTMLDivElement> {
  useCols?: boolean;
}

export const PluginBlock: React.FC<PluginBlock> = ({ className, useCols, ...props }) => (
  <div className={cn(css.PluginBlock, className, { [css.cols]: useCols })} {...props} />
);
