/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import { useSendToMessengerRenderWatchDog } from './useSendToMessengerRenderWatchDog';

export const useJsonPluginState = (botId: string) => {
  const [jsonBody, setJsonBody] = useState('');
  const [urlEncodedBody, setUrlEncodedBody] = useState('');
  const [testRequestSend, setTestRequestSend] = useState(false);
  const {
    dontSendToMessenger,
    setDontShowSendToMessenger,
    sendToMessengerRenderWatchDog,
  } = useSendToMessengerRenderWatchDog(botId);

  return {
    jsonBody,
    setJsonBody,
    urlEncodedBody,
    setUrlEncodedBody,
    testRequestSend,
    setTestRequestSend,
    dontSendToMessenger,
    setDontShowSendToMessenger,
    sendToMessengerRenderWatchDog,
  };
};
