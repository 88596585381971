import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment productListPluginFragment on ProductListPlugin {
    ...commonPluginFragment
    config {
      list_type
      products {
        integration_type
        shopify_product {
          product_id
          variant_id
          buttons {
            type
            title
            counter_id
          }
        }
      }
      order {
        integration_type
        shopify_order {
          order_id
          fulfillment_id
          buttons {
            type
            title
            counter_id
          }
        }
      }
      visited_products {
        recommendation_type
        buttons {
          type
          title
          counter_id
        }
      }
      back_in_stock {
        integration_type
        description
        buttons {
          type
          title
          counter_id
        }
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
