import React from 'react';
import { Flex } from '@ui/Flex';
import { Icon, IconProps } from '@ui/Icon';
import { Type } from '@ui/Type';
import * as css from './NavItem.css';

export interface NavItemBigViewProps extends Pick<IconProps, 'color' | 'icon'> {
  title: string;
}

export const NavItemBigView: React.FC<NavItemBigViewProps> = ({
  color,
  icon,
  title,
  children,
}) => (
  <Flex alignItems="center" className={css.wrapper}>
    <Icon
      className={css.icon}
      size="16px"
      icon={icon}
      color={color || 'grey'}
    />
    <Type as="span" weight="medium" size="12px" color={color || 'grey'}>
      {title}
    </Type>
    {children}
  </Flex>
);
