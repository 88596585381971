import React from 'react';
import cn from 'classnames';
import { Platform } from '@globals';
import { Bottle } from './Bottle/Bottle';
import { EnabledItems } from '../types';
import css from './Container.css';

interface ContainerProps {
  platform: Platform;
  entryPoints: EnabledItems;
  className?: string;
}

export const Container: React.FC<ContainerProps> = ({
  platform,
  children,
  entryPoints,
  className,
}) => {
  return (
    <div className={cn(css.container, className)}>
      <div className={css.imageContainer}>
        <Bottle entryPoints={entryPoints} platform={platform} />
      </div>
      <div className={css.content}>{children}</div>
    </div>
  );
};
