import { googleSheetPluginFragment_config as GoogleSheetPluginConfig } from './@types/googleSheetPluginFragment';
import { PluginType } from '../common/PluginTypes';
import { Timezone } from '@globals';

export const PLUGIN_TYPE = PluginType.google_sheet;

export const PLUGIN_DEFAULT_CONFIG: GoogleSheetPluginConfig = {
  __typename: 'GoogleSheetPluginConfig',
  spreadsheet_id: null,
  spreadsheet_title: null,
  owner_id: null,
  user_attributes: [],
  use_all_attributes: null,
  timezone: Timezone.bot,
};

export const PLUGIN_INPUT_FIELD = 'googleSheetPluginConfig';
