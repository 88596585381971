import React from 'react';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useBotSwitcherNavItemData } from '../../hooks/useBotSwitcherNavItemData';
import { BotSwitcherWithData } from './BotSwitcherWithData';
import { BotSwitcherLoading } from '../../BotSwitcherLoading';
import { BotSwitcherStatisticsFactory } from './BotSwitcherStatisticsFactory';
import { BotSwitcherErrorWithData } from '../../BotSwitcherError';
import { BotSwitcherProps } from './types';
import { useTimeLimitedTrial } from '@utils/Data/Pricing/useTimeLimitedTrial';
import { WarningBotImage } from './WarningBotImage/WarningBotImage';

export interface BotSwitcherPricingProps extends BotSwitcherProps {
  dialogsPricing: boolean;
  statistics: readonly [number, number];
  loading: boolean;
  hideButton?: boolean;
}

export const BotSwitcherPricing: React.FC<BotSwitcherPricingProps> = ({
  children,
  dialogsPricing,
  loading,
  statistics,
  hideButton,
  ...props
}) => {
  const { screenSize } = useDeviceMedia();
  const { bot, isPaymentFailed } = useBotSwitcherNavItemData();
  const {
    currentTierTimeLimitedTrial,
    trialRemainingDays,
    trialExpired,
    trialEndDate,
    timeLimitedTrialLoading,
  } = useTimeLimitedTrial();

  if (loading || timeLimitedTrialLoading) {
    return <BotSwitcherLoading screenSize={screenSize} />;
  }

  const button = hideButton || bot?.workspace_id ? null : children;

  if (isPaymentFailed) {
    return (
      <BotSwitcherErrorWithData {...props}>{button}</BotSwitcherErrorWithData>
    );
  }

  const [numerator, denominator] = statistics;

  const isTimeLimitedTrial = Boolean(
    currentTierTimeLimitedTrial && trialExpired,
  );

  return (
    <BotSwitcherWithData
      {...props}
      icon={isTimeLimitedTrial ? <WarningBotImage /> : undefined}
    >
      <BotSwitcherStatisticsFactory
        dialogsPricing={dialogsPricing}
        numerator={numerator}
        denominator={denominator}
        trialEndDate={trialEndDate}
        currentTierTimeLimitedTrial={currentTierTimeLimitedTrial}
        trialRemainingDays={trialRemainingDays}
        hideDenominator={Boolean(bot?.pro?.manual || bot?.workspace_id)}
      />
      {button}
    </BotSwitcherWithData>
  );
};
