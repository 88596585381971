import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import * as css from './SkipPaymentBlock.css';

interface SkipPaymentBlockProps {
  onSkip(): void;
  loading: boolean;
}

export const SkipPaymentBlock: React.FC<SkipPaymentBlockProps> = ({
  onSkip,
  loading,
}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <>
      <Spacer factor={8} />
      <Flex
        alignItems="center"
        justifyContent={isSmallScreenSize ? 'center' : 'flex-end'}
        flexDirection={isSmallScreenSize ? 'column-reverse' : 'row'}
      >
        <Type
          className={css.skipPaymentText}
          size="15px"
          color="white"
          align={isSmallScreenSize ? 'center' : 'start'}
        >
          {t('pages.FirstSession.PlansPaywall.skipPaymentText')}
        </Type>
        <Spacer factor={4} horizontalFactor={3} />
        <Button
          intent="transparent"
          style={{ width: isSmallScreenSize ? '100%' : '' }}
          onClick={onSkip}
          loading={loading}
          disabled={loading}
          data-testid="paywall__skip-btn"
        >
          {t('pages.FirstSession.PlansPaywall.skipPaymentButton')}
        </Button>
      </Flex>
    </>
  );
};
