import React from 'react';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import css from './WarningBotImage.css';

interface WarningBotImageProps {}

export const WarningBotImage: React.FC<WarningBotImageProps> = () => (
  <Flex alignItems="center" justifyContent="center" className={css.container}>
    <Icon icon="warning" color="accent4Normal" className={css.icon} />
  </Flex>
);
