import { PluginType } from '../common/PluginTypes';
import { whatsappLocationPluginFragment_config as WhatsappLocationPluginConfig } from './@types/whatsappLocationPluginFragment';

export const PLUGIN_TYPE = PluginType.whatsapp_location;

export const PLUGIN_DEFAULT_CONFIG: WhatsappLocationPluginConfig = {
  __typename: 'WhatsappLocationPluginConfig',
  longitude: null,
  latitude: null,
  name: null,
  address: null,
};

export const PLUGIN_INPUT_FIELD = 'whatsappLocationPluginConfig';
