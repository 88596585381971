import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment quickReplyPluginFragment on QuickReplyPlugin {
    ...commonPluginFragment
    config {
      process_text_by_ai
      type
      allow_skip
      property
      input_timeout_millis
      error_messages {
        text
        number
        phone
        email
        date
        datetime
      }
      phone {
        facebook_suggest
        title
        block_ids
      }
      email {
        facebook_suggest
        title
        block_ids
      }
      skip_button {
        title
        block_ids
      }
      buttons {
        title
        block_ids
        counter_id
      }
      follow_ups {
        id
        text
        delay_ms
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
