import {
  ExternalIntegrationEntryPointType,
  ProductListShopifyOrderButtonType,
  ProductListType,
  SendToMessengerColor,
  SendToMessengerSize,
} from '@globals';
import { shopifyBackInStockEntryPointFragment_config } from './@types/shopifyBackInStockEntryPointFragment';
import { SEND_TO_MESSENGER_TEXTS_NORMALIZED } from '../../../pages/GrowPage/AbandonedCart/AbandonedCartPage/sendToMessengerTexts';
import { productListPluginFragment_config } from '../ProductListPlugin/@types/productListPluginFragment';

type ShopifyBackInStockConfig = Omit<
  shopifyBackInStockEntryPointFragment_config,
  'on_back_in_stock_counter_id' | 'on_back_in_stock_unreachable_counter_id'
>;

export const PLUGIN_DEFAULT_CONFIG: ShopifyBackInStockConfig = {
  __typename: 'ShopifyBackInStockEntryPointConfig',
  title: 'This item is out of stock',
  description:
    'Tap the button below, and we’ll let you know when it’s back in stock.',
  button_size: SendToMessengerSize.xlarge,
  button_text: SEND_TO_MESSENGER_TEXTS_NORMALIZED.LET_ME_KNOW.id,
  button_color: SendToMessengerColor.blue,
  on_subscribe_block_id: null,
  on_back_in_stock_block_id: null,
  notify_unreachable: false,
  on_back_in_stock_unreachable_block_id: null,
};

export const PLUGIN_OPT_IN_CONFIRMATION_PRODUCT_LIST_DEFAULT_CONFIG = {
  __typename: 'ProductListPluginConfig',
  list_type: ProductListType.back_in_stock,
  products: null,
  visited_products: null,
  order: null,
  back_in_stock: {
    __typename: 'ProductListBackInStockConfig',
    integration_type: ExternalIntegrationEntryPointType.shopify,
    description: 'Awesome! We’ll keep you posted.',
    buttons: [],
  },
} as productListPluginFragment_config;

export const PLUGIN_BACK_IN_STOCK_MESSAGE_PRODUCT_LIST_DEFAULT_CONFIG = {
  __typename: 'ProductListPluginConfig',
  list_type: ProductListType.back_in_stock,
  products: null,
  visited_products: null,
  order: null,
  back_in_stock: {
    __typename: 'ProductListBackInStockConfig',
    integration_type: ExternalIntegrationEntryPointType.shopify,
    description: '',
    buttons: [
      {
        __typename: 'ProductListShopifyOrderButtonConfig',
        type: ProductListShopifyOrderButtonType.product_page,
        title: 'Buy now',
      },
    ],
  },
} as productListPluginFragment_config;

export const BACK_IN_STOCK_DEFAULT_CONFIG = {
  __typename: 'ProductListPluginConfig',
  list_type: ProductListType.back_in_stock,
  products: null,
  visited_products: null,
  order: null,
  back_in_stock: {
    __typename: 'ProductListBackInStockConfig',
    integration_type: ExternalIntegrationEntryPointType.shopify,
    description: '',
    buttons: [
      {
        type: ProductListShopifyOrderButtonType.product_page,
        title: '',
        counter_id: null,
        __typename: 'ProductListShopifyOrderButtonConfig',
      },
    ],
  },
} as productListPluginFragment_config;

export const PLUGIN_INPUT_FIELD = 'shopifyBackInStockEntryPointConfig';

export const PLUGIN_TYPE = 'entry_point/shopify_back_in_stock';

export const OTN_LINE_ID = 'otn_line_id';

export const BACK_IN_STOCK_OTN_PURPOSE =
  'chatfuel internal topic: back in stock reminder';
