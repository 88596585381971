import { useMutation } from '@apollo/react-hooks';
import { SETUP_PREMIUM_INTENT_MUTATION } from '../queries';
import { SetupPremiumIntentMutation } from '../@types/SetupPremiumIntentMutation';

export const usePremiumIntentSetup = () => {
  const [invokeSetupIntent, { data }] = useMutation<SetupPremiumIntentMutation>(
    SETUP_PREMIUM_INTENT_MUTATION,
  );

  const setupIntent = async () => {
    const { data } = await invokeSetupIntent();
    return {
      clientSecret: data?.setupPremiumIntent?.client_secret,
    };
  };

  return {
    setupIntent,
    clientSecret: data?.setupPremiumIntent?.client_secret,
  };
};
