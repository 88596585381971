import React from 'react';
import { sendEvent } from '../../../utils/Analytics';
import * as css from './AddPlugin.css';

export const createTooltip = (
  text: string | JSX.Element,
  href: string,
  link: string = window.i18next.t('createTooltip-string--138-learn-more'),
  onClick?: () => void,
) => (
  <p style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
    {text}{' '}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      className={css.tooltipLink}
      onClick={() => {
        sendEvent({
          category: 'chatfuel plugins dialog',
          action: 'click on learn more',
          propertyBag: {
            href,
          },
        });

        if (onClick) {
          onClick();
        }
      }}
    >
      {link}
    </a>
  </p>
);
