import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDialoguesPricing, useTiersData } from '@utils/Data/Pricing';
import { useCurrentBotId } from '@utils/Routing';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { TierType } from '@globals';

interface PaywallPaymentDialogProps {
  onDismiss?: () => void;
}

export const PaywallPaymentDialog: React.FC<PaywallPaymentDialogProps> = ({
  onDismiss,
  children,
}) => {
  const botId = useCurrentBotId();
  const { isSmallScreenSize } = useDeviceMedia();
  const { pricing, pricingLoading } = useDialoguesPricing();
  const { tiers, tiersLoading } = useTiersData(
    botId,
    pricing?.pricing_plan_id,
    pricing?.currency,
  );
  const { t } = useSafeTranslation();

  return (
    <DefaultDialog
      mobileAdaptive={isSmallScreenSize}
      header={t('modernComponents.Payments.PaymentDialog.enterCardInfo')}
      onDismiss={onDismiss}
    >
      {pricingLoading || tiersLoading ? (
        <LoadingPlaceholder />
      ) : (
        <Type size="15px">
          {t('modernComponents.Payments.PaymentDialog.paywallInfo', {
            dialogsCount: tiers.find((tier) => tier.type === TierType.trial)
              ?.tier_dialogs,
          })}
          .
        </Type>
      )}
      <Spacer factor={5} />
      {children}
    </DefaultDialog>
  );
};
