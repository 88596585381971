import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_KEY_QUERY } from './queries';
import { PaymentFlow } from './PaymentFlow';
import { PaymentDialogProps } from './types';
import { StripeKeyQuery } from './@types/StripeKeyQuery';
import { getStripePromise } from 'cf-common/src/utils/Stripe/getStripePromise';

export const PaymentDialog: React.FC<PaymentDialogProps> = ({
  paymentIntent,
  initialPayment = false,
  onClose,
  onSuccess,
  renderPaymentError,
  setupPayment,
}) => {
  const { data, loading } = useQuery<StripeKeyQuery>(STRIPE_KEY_QUERY);

  const stripePromise = loading ? null : getStripePromise(data?.stripeKey.key);

  return (
    <Elements stripe={stripePromise}>
      <PaymentFlow
        paymentIntent={paymentIntent}
        initialPayment={initialPayment}
        onSuccess={onSuccess}
        onDismiss={onClose}
        renderPaymentError={renderPaymentError}
        setupPayment={setupPayment}
      />
    </Elements>
  );
};
