import React from 'react';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { BotSwitcherError, BotSwitcherErrorProps } from './BotSwitcherError';
import { useBotSwitcherNavItemData } from '../hooks/useBotSwitcherNavItemData';
import { BotSwitcherBaseProps } from '../BotSwitcherNavItem/components/types';

type SpecifiedProps = 'screenSize' | 'title';

export interface BotSwitcherErrorWithDataProps
  extends Omit<BotSwitcherErrorProps, SpecifiedProps>,
    BotSwitcherBaseProps {}

export const BotSwitcherErrorWithData: React.FC<BotSwitcherErrorWithDataProps> =
  (props) => {
    const { screenSize } = useDeviceMedia();
    const { bot } = useBotSwitcherNavItemData();

    return (
      <BotSwitcherError
        {...props}
        screenSize={screenSize}
        title={bot?.title || ''}
      />
    );
  };
