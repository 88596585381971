import React, { useCallback } from 'react';
import { sendEvent } from '@utils/Analytics';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
} from '../../modern-ui/_deprecated/Button';
import * as css from './PeopleTabNoPage.css';
import { WhiteLabelUserDataWithApolloProvider } from '@utils/WhiteLabelUtils';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Router, useHistory } from 'react-router-dom';
import {
  BotTabs,
  getTabLink,
  globalHistory,
  useCurrentBotId,
} from '@utils/Routing';
import client from '@common/services/ApolloService';
import { ApolloProvider } from '@apollo/react-common';
import { DeepLinksMode } from '@pages/DeepLinks/types';

interface MessageProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  buttonTitle: string;
}

const Message: React.FC<MessageProps> = ({
  onClick,
  children,
  buttonTitle,
}) => (
  <>
    <div className={css.message}>{children}</div>
    <Button
      intent={ButtonIntent.primary}
      colorWay={ButtonColorWay.blue}
      onClick={onClick}
    >
      {buttonTitle}
    </Button>
  </>
);

interface WhatsappMessageProps
  extends Omit<MessageProps, 'buttonTitle' | 'onClick'> {}

const WhatsappMessage: React.FC<WhatsappMessageProps> = () => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const botId = useCurrentBotId();

  const onClick = useCallback(() => {
    sendEvent({
      category: 'people tab not connected',
      action: 'connect whatsapp button click',
    });
    history.push(
      getTabLink(BotTabs.home, botId, {
        dlMode: DeepLinksMode.connectWhatsapp,
      }),
    );
  }, [botId, history]);

  return (
    <Message
      onClick={onClick}
      buttonTitle={t('PeopleTabNoPage-JSXText--147-connect-to-whatsapp')}
    >
      {t('PeopleTabNoPage-JSXText-5593-connect-your-bot-to-a-whatsapp')}
      <br />
      {t('PeopleTabNoPage-JSXText-2019-page-to-see-bot-subscribers-whatsapp')}
    </Message>
  );
};

interface FacebookMessageProps extends Omit<MessageProps, 'buttonTitle'> {}

const FacebookMessage: React.FC<FacebookMessageProps> = ({ onClick }) => {
  const { t } = useSafeTranslation();

  return (
    <Message
      onClick={onClick}
      buttonTitle={t('PeopleTabNoPage-JSXText--147-connect-to-page')}
    >
      {t('PeopleTabNoPage-JSXText-5593-connect-your-bot-to-a-facebook')}
      <br />
      {t('PeopleTabNoPage-JSXText-2019-page-to-see-bot-subscribers')}
    </Message>
  );
};

interface IPeopleTabNoPageProps {
  onConnectPageRequest: () => void;
}

const PeopleTabNoPage: React.FC<IPeopleTabNoPageProps> = ({
  onConnectPageRequest,
}) => {
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { t } = useSafeTranslation();

  return (
    <div>
      <div className={css.blur} />
      <div className={css.veil}>
        <div className={css.content}>
          <WhiteLabelUserDataWithApolloProvider>
            {({ isWhiteLabelUser, loading }) =>
              loading ? null : isWhiteLabelUser ? (
                <div className={css.message}>
                  {t(
                    'PeopleTabNoPage-JSXText-7631-ask-us-to-connect-the-facebook',
                  )}
                  <br />
                  {t(
                    'PeopleTabNoPage-JSXText-2019-page-to-see-bot-subscribers',
                  )}
                </div>
              ) : (
                <>
                  {isWhatsappEnabled ? (
                    <WhatsappMessage />
                  ) : (
                    <FacebookMessage
                      onClick={() => {
                        sendEvent({
                          category: 'people tab not connected',
                          action: 'connect facebook button click',
                        });
                        onConnectPageRequest();
                      }}
                    />
                  )}
                </>
              )
            }
          </WhiteLabelUserDataWithApolloProvider>
        </div>
      </div>
    </div>
  );
};

export const PeopleTabNoPageWrapper = (params: IPeopleTabNoPageProps) => (
  <ApolloProvider client={client}>
    <Router history={globalHistory}>
      <PeopleTabNoPage {...params} />
    </Router>
  </ApolloProvider>
);
