import React, { useState } from 'react';
import { AddPluginList } from './AddPluginList';
import { InsertPluginPopup } from './InsertPlugin';
import { AddPluginMode, AddPluginProps } from './types';
import { AddPluginMenu } from './AddPluginMenu';

export const AddPlugin: React.FC<AddPluginProps> = (props) => {
  const [listVisibility, setListVisibility] = useState(false);

  return (
    <>
      {props.mode === AddPluginMode.POPUP ? (
        <InsertPluginPopup
          {...props}
          onShowPluginList={() => setListVisibility(true)}
        />
      ) : (
        <AddPluginMenu
          {...props}
          onShowPluginList={() => setListVisibility(true)}
        />
      )}
      <AddPluginList
        {...props}
        isVisible={listVisibility}
        onDismiss={() => setListVisibility(false)}
      />
    </>
  );
};
