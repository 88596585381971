import React from 'react';
import { Attributes } from '@ui/TextWithAttributesEditor';
import { EditableField, EditableFieldProps } from '../EditableField';
import * as css from './EditableButton.css';

export interface EditableButtonProps {
  attributes: Attributes;
  placeholder: string;
  onChange: EditableFieldProps['onChange'];
  maxTextLength: number;
  disabled: boolean;
  text?: string;
  'data-testid'?: string;
}

export const EditableButton: React.FC<EditableButtonProps> = ({
  attributes,
  placeholder,
  text = '',
  onChange,
  disabled,
  maxTextLength,
  ...props
}) => (
  <EditableField
    attributes={attributes}
    text={text}
    onChange={onChange}
    maxTextLength={maxTextLength}
    placeholder={placeholder}
    controlDecoratorInactiveClassName={css.controlDecoratorInactiveClassName}
    textEditorClassName={css.textEditorClassName}
    textEditorInactiveClassName={css.textEditorInactiveClassName}
    disabled={disabled}
    hidePlaceholderWhenFocused
    {...props}
  />
);
