import React from 'react';
import css from './Overlay.css';
import { ONBORDING_CONTAINER_ID } from './consts';
import { Rect } from '@components/Onboarding/types';

/**
 * TODO: new overlay 
 * <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1342 910" fill-rule="evenodd" 
 * class="deel-ui__modal-walkthrough__backdrop-shape" style="width: 
 * 1342px; color: rgb(21, 53, 122);"><path d="M0 0 h1342 v910 h-1342z 
   M567.25,412 h456.5 a8,8 0 0 1 8,8 
   v188 a8,8 0 0 1 -8,8 h-456.5 
   a8,8 0 0 1 -8,-8 v-188 a8,8 0 0 1 8,-8 z" fill="rgba(0, 0, 0, 0.3)"></path><path d="M567.25,412 h456.5 a8,8 0 0 1 8,8 
   v188 a8,8 0 0 1 -8,8 h-456.5 
   a8,8 0 0 1 -8,-8 v-188 a8,8 0 0 1 8,-8 z" stroke="currentColor" fill="transparent" stroke-width="2"></path></svg>
 */

interface OverlayProps {
  activeZoneBounds: Rect | undefined;
  focusZoneBounds: Rect | undefined;
  callout(): React.ReactNode;
}

export const Overlay: React.FC<OverlayProps> = ({
  callout,
  activeZoneBounds,
  focusZoneBounds,
}) => {
  const cutoutBounds = activeZoneBounds || focusZoneBounds;

  return (
    <div id={ONBORDING_CONTAINER_ID} className={css.container}>
      {cutoutBounds ? (
        <>
          <div className={css.topOverlay} style={{ height: cutoutBounds.y }} />
          <div className={css.leftOverlay} style={{ width: cutoutBounds.x }} />
          <div
            className={css.bottomOverlay}
            style={{
              height: `calc(100% - ${cutoutBounds.y + cutoutBounds.height}px)`,
            }}
          />
          <div
            className={css.rightOverlay}
            style={{
              width: `calc(100% - ${cutoutBounds.x + cutoutBounds.width}px)`,
            }}
          />
          {focusZoneBounds && (
            <div
              className={css.focusZone}
              style={{
                width: focusZoneBounds.width,
                height: focusZoneBounds.height,
                left: focusZoneBounds.x,
                top: focusZoneBounds.y,
                pointerEvents: activeZoneBounds ? 'none' : 'all',
              }}
            />
          )}
          {focusZoneBounds && activeZoneBounds && (
            <div
              className={css.activeZone}
              style={{
                width: activeZoneBounds.width,
                height: activeZoneBounds.height,
                left: activeZoneBounds.x,
                top: activeZoneBounds.y,
              }}
            />
          )}
        </>
      ) : (
        <div className={css.fullOverlay} />
      )}
      {callout()}
    </div>
  );
};
