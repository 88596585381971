import React, { useState } from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { ToasterProvider } from '@ui/Toaster';
import { ButtonUnstyled } from '@ui/Button';
import { Avatar, AvatarSize } from '@ui/Avatar';
import { TextEllipsis } from '@ui/TextEllipsis';
import { UserNavigationItemWithData } from '@common/user/UserNavigationItem/UserNavigationItemWithData';
import { ReactComponent as NotificationIcon } from '@ui/_deprecated/Icon/icons/notification.svg';
import * as css from './PageHeaderUserItem.css';

export const PageHeaderUserItem = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <ToasterProvider>
      <UserNavigationItemWithData
        offset="0, 4"
        placement="bottom-end"
        small={false}
        isOpen={isOpen}
        setOpen={setOpen}
      >
        {({ user, hasNotification }) => (
          <Flex
            flexDirection="column"
            fullHeight
            alignItems="center"
            justifyContent="center"
            inline
          >
            <ButtonUnstyled
              onClick={() => setOpen((open) => !open)}
              data-testid="page_header-username"
            >
              <div className={css.userData}>
                {hasNotification && (
                  <NotificationIcon className={css.notification} />
                )}
                <Avatar src={user.picture} size={AvatarSize.xMedium} />
                <div>
                  <TextEllipsis width={300}>
                    <Type size="12px" color="black" weight="regular">
                      {user.name || 'User'}
                    </Type>
                  </TextEllipsis>
                </div>
              </div>
            </ButtonUnstyled>
          </Flex>
        )}
      </UserNavigationItemWithData>
    </ToasterProvider>
  );
};
