import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment orderReceiptPluginFragment on OrderReceiptPlugin {
    ...commonPluginFragment
    config {
      integration_type
      shopify_order {
        order_id
      }
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
