import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash-es/debounce';
import { isSendToMessengerClickedFromLocaleStore } from './JsonPluginUtils';
import { SEND_TO_MESSENGER_RENDER_TIMEOUT } from './JsonPluginConst';

export const useSendToMessengerRenderWatchDog = (botId: string) => {
  const [dontSendToMessenger, setDontShowSendToMessenger] = useState(
    isSendToMessengerClickedFromLocaleStore(botId),
  );

  const sendToMessengerRenderWatchDog = useCallback(
    debounce(
      () => setDontShowSendToMessenger(true),
      SEND_TO_MESSENGER_RENDER_TIMEOUT,
    ),
    [],
  );

  useEffect(() => {
    sendToMessengerRenderWatchDog();
    return () => sendToMessengerRenderWatchDog.cancel();
  }, [sendToMessengerRenderWatchDog]);

  return {
    dontSendToMessenger,
    setDontShowSendToMessenger,
    sendToMessengerRenderWatchDog,
  };
};
