import gql from 'graphql-tag';
import { COMMON_PLUGIN_FRAGMENT } from '../common/PluginQuery';

export const PLUGIN_FRAGMENT = gql`
  fragment documentPluginFragment on DocumentPlugin {
    ...commonPluginFragment
    config {
      url
      filename
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;
