import React from 'react';
import { OnboardingTipsStatus } from '@globals';
import { showMiniOnboardingModal } from '../MiniOnboardingDialog/showMiniOnboardingModal';
import { sendEvent, sendHitOnce } from '@utils/Analytics';
import { showSwitchingDesktopDialog } from '../SwitchingDesktopDialog/showSwitchingDesktopDialog';
import { onboardingTourFragment } from '@utils/Data/MiniOnboarding/@types/onboardingTourFragment';
import { useOnbordingContext } from '@components/Onboarding/OnboardingContext';
import {
  OnboardingListItemView,
  OnboardingListItemViewProps,
} from './OnboardingListItemView';
import { useOnboardingStepDone } from './hooks';

type SpecifiedProps = 'handleOnbordingSetUpClick' | 'active';
interface ListItemProps
  extends Omit<OnboardingListItemViewProps, SpecifiedProps> {
  templateFlowGroupId: string | null;
  onboardingTour: onboardingTourFragment | null;
  type: string | null;
  blockId: string | null;
  flowId: string | null;
  youTubeUrl: string | null;
  hasFlowTour: boolean | null;
  botId: string;
  showFlowBuilderOnMobile: boolean;
  setShowFlowBuilderOnMobile: (value: boolean) => void;
  isSmallScreenSize: boolean;
  isTipsDialog?: boolean;
  isButtonActive: boolean;
}

export const ListItem: React.FC<ListItemProps> = (props) => {
  const {
    templateFlowGroupId,
    title,
    description,
    hasButton,
    onboardingTour,
    status,
    type,
    platform,
    flowId,
    youTubeUrl,
    botId,
    showFlowBuilderOnMobile,
    setShowFlowBuilderOnMobile,
    isSmallScreenSize,
    hasFlowTour,
    isTipsDialog,
    lineToBottle,
    containerClassName,
    verificationStatus,
    tooltip,
    isButtonActive,
  } = props;
  const { setTipsOnboardingTour } = useOnbordingContext();
  const { onDone } = useOnboardingStepDone(botId);

  const handleOnbordingSetUpClick = () => {
    sendHitOnce('onboadring_clicked');
    if (status !== OnboardingTipsStatus.available) return;
    sendEvent({
      category: isTipsDialog ? 'onbording-tour' : 'mini onboarding banner',
      action: isTipsDialog
        ? 'tips dialog next template button click'
        : 'set up button click',
      propertyBag: {
        title,
        templateFlowGroupId,
        botId,
        pluginType: type,
        platform,
      },
    });

    setTipsOnboardingTour(onboardingTour);

    if (templateFlowGroupId && type) {
      const showMiniOnboarding = () =>
        showMiniOnboardingModal({
          pluginType: type,
          templateFlowGroupId,
          title,
          platform,
          videoId: youTubeUrl && youTubeUrl.split('?v=')[1],
          flowId,
          hasFlowTour: Boolean(hasFlowTour),
        });
      if (isSmallScreenSize && !showFlowBuilderOnMobile)
        showSwitchingDesktopDialog(() =>
          setShowFlowBuilderOnMobile(true),
        )?.then(showMiniOnboarding);
      else showMiniOnboarding();
    } else {
      onDone();
    }
  };

  return (
    <OnboardingListItemView
      active={isButtonActive}
      hasButton={hasButton}
      status={status}
      handleOnbordingSetUpClick={handleOnbordingSetUpClick}
      containerClassName={containerClassName}
      platform={platform}
      title={title}
      description={description}
      verificationStatus={verificationStatus}
      tooltip={tooltip}
      lineToBottle={lineToBottle}
    />
  );
};
