import React from 'react';
import { ReactComponent as Icon } from '../common/icons/ic_handover_round.svg';
import { handoverPluginFragment_config as HandoverPluginConfig } from './@types/handoverPluginFragment';

export const PLUGIN_TYPE = 'handover';
export const PLUGIN_NAME = 'Conversation Handover';
export const PLUGIN_ICON = <Icon />;

export const PLUGIN_HELP_URL =
  'https://docs.chatfuel.com/articles/2912153-conversation-handover';

export const PLUGIN_INPUT_FIELD = 'handoverPluginConfig';

export const PLUGIN_DEFAULT_CONFIG: HandoverPluginConfig = {
  __typename: 'HandoverProtocolConfig',
  application_id: null,
  timeout_in_seconds: 60,
  stop_words: [],
};

export const NO_APP_SELECTED = () => ({
  id: '',
  title: window.i18next.t(
    'HandoverPluginConst-string--562-no-app-selected',
  ) as string,
});

export enum HandoverPluginErrors {
  HANDOVER_SETUP_ERROR = 'HANDOVER_SETUP_ERROR',
  BOT_NOT_CONNECTED_ERROR = 'BOT_NOT_CONNECTED_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}
