import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import cn from 'classnames';
import { IconProps } from '@ui/Icon';
import { BotPicture } from '@components/PageHeader/BotHeader/components/BotPicture/BotPicture';
import { Circle, CircleProps } from '@components/BotLivelinessStatus/Circle';
import { ProBadge } from '@components/ProBadge';
import * as css from './BotSwitcherIcon.css';
import { ScreenSizes } from '@utils/DOM/useDeviceMedia';

interface CommonProps extends Pick<CircleProps, 'active'> {
  iconSize: string;
  src: Maybe<string>;
  size?: IconProps['size'];
  className?: string;
}

interface BotSwitcherIconRegularProps extends CommonProps {}

const BotSwitcherIconRegular: React.FC<BotSwitcherIconRegularProps> = ({
  active,
  iconSize,
  size,
  src,
  className,
}) => (
  <div className={css.wrapper}>
    <BotPicture
      imageSrc={src}
      size={size}
      style={{ width: iconSize, height: iconSize }}
    />
    <Circle active={active} className={cn(className, css.status)} />
  </div>
);

interface BotSwitcherIconSmallProps extends CommonProps {
  showProBadge: boolean;
}

const BotSwitcherIconSmall: React.FC<BotSwitcherIconSmallProps> = ({
  active,
  showProBadge,
  iconSize,
  size,
  src,
  className,
}) => (
  <div className={css.wrapper}>
    <BotPicture
      imageSrc={src}
      size={size}
      style={{ width: iconSize, height: iconSize }}
    />
    <Circle active={active} className={cn(className, css.status)} />
    {showProBadge && (
      <div className={css.proBadge}>
        <ProBadge size="9px" data-testid="bot-switcher__pro-badge" />
      </div>
    )}
  </div>
);

export interface BotSwitcherIconProps
  extends Omit<
    BotSwitcherIconRegularProps & BotSwitcherIconSmallProps,
    'iconSize' | 'size'
  > {
  screenSize: ScreenSizes;
}

export const BotSwitcherIcon: React.FC<BotSwitcherIconProps> = (props) => {
  return {
    [ScreenSizes.small]: (
      <BotSwitcherIconRegular
        {...props}
        size="16px"
        iconSize="24px"
        className={css.statusMobile}
      />
    ),
    [ScreenSizes.medium]: (
      <BotSwitcherIconSmall
        {...props}
        size="32px"
        iconSize="44px"
        className={css.statusTablet}
      />
    ),
    [ScreenSizes.large]: (
      <BotSwitcherIconRegular
        {...props}
        size="16px"
        iconSize="16px"
        className={css.statusDesktop}
      />
    ),
  }[props.screenSize];
};
