import {
  PLUGIN_DEFAULT_CONFIG as ADD_ATTRIBUTE_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as ADD_ATTRIBUTE_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as ADD_ATTRIBUTE_PLUGIN_TYPE,
} from '../AddAttributePlugin/AddAttributePluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as REMOVE_ATTRIBUTE_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as REMOVE_ATTRIBUTE_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as REMOVE_ATTRIBUTE_PLUGIN_TYPE,
} from '../RemoveAttributePlugin/RemoveAttributePluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as SETUP_ATTRIBUTE_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as SETUP_ATTRIBUTE_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as SETUP_ATTRIBUTE_PLUGIN_TYPE,
} from '../SetupAttributePlugin/SetupAttributePluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as JSON_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as JSON_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as JSON_PLUGIN_TYPE,
} from '../JsonPlugin/JsonPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as FACEBOOK_ANALYTICS_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as FACEBOOK_ANALYTICS_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as FACEBOOK_ANALYTICS_PLUGIN_TYPE,
} from '../FacebookAnalyticsPlugin/FacebookAnalyticsPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as HANDOVER_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as HANDOVER_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as HANDOVER_PLUGIN_TYPE,
} from '../HandoverPlugin/HandoverPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as ABT_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as ABT_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as ABT_PLUGIN_TYPE,
} from '../AbtPlugin/AbtPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as ABANDONED_CART_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as ABANDONED_CART_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as ABANDONED_CART_PLUGIN_TYPE,
} from '../AbandonedCartPlugin/AbandonedCartPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as GOTO_BLOCK_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as GOTO_BLOCK_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as GOTO_BLOCK_PLUGIN_TYPE,
} from '../GoToBlockPlugin/GoToBlockPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as EMAIL_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as EMAIL_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as EMAIL_PLUGIN_TYPE,
} from '../EmailPlugin/EmailPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as FLOWBUILDER_LIVECHAT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as FLOWBUILDER_LIVECHAT_INPUT_FIELD,
  PLUGIN_TYPE as FLOWBUILDER_LIVECHAT_TYPE,
} from '../FlowBuilderLiveChatPlugin/FlowBuilderLiveChatPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as INBOUND_LINKS_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as INBOUND_LINKS_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as INBOUND_LINKS_ENTRY_POINT_TYPE,
} from '../InboundLinksEntryPoint/InboundLinksEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as COMMENTS_AUTOREPLY_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as COMMENTS_AUTOREPLY_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as COMMENTS_AUTOREPLY_ENTRY_POINT_TYPE,
} from '../CommentsAutoreplyEntryPoint/CommentsAutoreplyEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as CUSTOMER_CHAT_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as CUSTOMER_CHAT_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as CUSTOMER_CHAT_ENTRY_POINT_TYPE,
} from '../CustomerChatEntryPoint/CustomerChatEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_TYPE,
} from '../ShopifyCustomerChatEntryPoint/ShopifyCustomerChatEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as PERSISTENT_MENU_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as PERSISTENT_MENU_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as PERSISTENT_MENU_ENTRY_POINT_TYPE,
} from '../PersistentMenuEntryPoint/PersistentMenuEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as PRIVATE_REPLY_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as PRIVATE_REPLY_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as PRIVATE_REPLY_ENTRY_POINT_TYPE,
} from '../PrivateReplyEntryPoint/PrivateReplyEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as PUBLIC_REPLY_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as PUBLIC_REPLY_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as PUBLIC_REPLY_ENTRY_POINT_TYPE,
} from '../PublicReplyEntryPoint/PublicReplyEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as SEND_TO_MESSENGER_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as SEND_TO_MESSENGER_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as SEND_TO_MESSENGER_ENTRY_POINT_TYPE,
} from '../SendToMessengerEntryPoint/SendToMessengerEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as GOOGLE_SHEET_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as GOOGLE_SHEET_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as GOOGLE_SHEET_PLUGIN_TYPE,
} from '../GoogleSheetPlugin/GoogleSheetPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as ZAPIER_IMPORT_CONTENT_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as ZAPIER_IMPORT_CONTENT_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as ZAPIER_IMPORT_CONTENT_PLUGIN_TYPE,
} from '../ZapierImportContentPlugin/ZapierImportContentPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as ZAPIER_EVENT_TRIGGER_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as ZAPIER_EVENT_TRIGGER_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as ZAPIER_EVENT_TRIGGER_PLUGIN_TYPE,
} from '../ZapierEventTriggerPlugin/ZapierEventTriggerPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as INSTAGRAM_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as INSTAGRAM_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as INSTAGRAM_ENTRY_POINT_PLUGIN_TYPE,
} from '../InstagramAdsEntryPoint/InstagramAdsEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as INSTAGRAM_BOT_LINK_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as INSTAGRAM_BOT_LINK_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as INSTAGRAM_BOT_LINK_ENTRY_POINT_PLUGIN_TYPE,
} from '../InstagramLinkEntryPoint/InstagramLinkEntryPointConst';
import {
  PLUGIN_DEFAULT_CTM_CONFIG as FACEBOOK_ADS_CTM_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_DEFAULT_CTM_JSON_CONFIG as FACEBOOK_ADS_CTM_JSON_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_DEFAULT_SM_CONFIG as FACEBOOK_ADS_SM_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as FACEBOOK_ADS_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE_CTM_JSON as FACEBOOK_ADS_CTM_JSON_ENTRY_POINT_PLUGIN_TYPE,
  PLUGIN_TYPE_CTM as FACEBOOK_ADS_CTM_ENTRY_POINT_PLUGIN_TYPE,
  PLUGIN_TYPE_SM as FACEBOOK_ADS_SM_ENTRY_POINT_PLUGIN_TYPE,
} from '../FacebookAdsEntryPoint/FacebookAdsEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as POPUP_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as POPUP_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as POPUP_ENTRY_POINT_TYPE,
} from '../PopupEntryPoint/PopupEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as SHOPIFY_EVENT_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as SHOPIFY_EVENT_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as SHOPIFY_EVENT_ENTRY_POINT_TYPE,
} from '../ShopifyEventEntryPoint/ShopifyEventEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_TYPE,
} from '../ShopifyBackInStockEntryPoint/ShopifyBackInStockEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as INSTAGRAM_MEDIA_SHARE_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as INSTAGRAM_MEDIA_SHARE_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as INSTAGRAM_MEDIA_SHARE_PLUGIN_TYPE,
} from '../InstagramMediaSharePlugin/InstagramMediaSharePluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as INSTAGRAM_DIRECT_ENTRY_POINT_CONFIG,
  PLUGIN_INPUT_FIELD as INSTAGRAM_DIRECT_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as INSTAGRAM_DIRECT_ENTRY_POINT_TYPE,
} from '../InstagramDirectEntryPoint/InstagramDirectEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as INSTAGRAM_COMMENTS_AUTOREPLY_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as INSTAGRAM_COMMENTS_AUTOREPLY_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as INSTAGRAM_COMMENTS_AUTOREPLY_ENTRY_POINT_TYPE,
} from '../InstagramCommentsAutoreplyEntryPoint/InstagramCommentsAutoreplyEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as INSTAGRAM_STORY_MENTION_ENTRY_POINT_CONFIG,
  PLUGIN_INPUT_FIELD as INSTAGRAM_STORY_MENTION_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as INSTAGRAM_STORY_MENTION_ENTRY_POINT_TYPE,
} from '../InstagramStoryMentionEntryPoint/InstagramStoryMentionEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as INSTAGRAM_STORY_REPLY_ENTRY_POINT_CONFIG,
  PLUGIN_INPUT_FIELD as INSTAGRAM_STORY_REPLY_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as INSTAGRAM_STORY_REPLY_ENTRY_POINT_TYPE,
} from '../InstagramStoryReplyEntryPoint/InstagramStoryReplyEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as ORDER_RECEIPT_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as ORDER_RECEIPT_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as ORDER_RECEIPT_PLUGIN_TYPE,
} from '../OrderReceiptPlugin/OrderReceiptPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as CALENDLY_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as CALENDLY_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as CALENDLY_PLUGIN_TYPE,
} from '../CalendlyIntegration/CalendlyIntegrationConst';
import {
  PLUGIN_DEFAULT_CONFIG as PRODUCT_LIST_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as PRODUCT_LIST_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as PRODUCT_LIST_PLUGIN_TYPE,
} from '../ProductListPlugin/ProductListPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as BOT_LINK_ENTRY_POINT_CONFIG,
  PLUGIN_INPUT_FIELD as BOT_LINK_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as BOT_LINK_ENTRY_POINT_TYPE,
} from '../BotLinkEntryPoint/BotLinkEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as AUDIO_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as AUDIO_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as AUDIO_PLUGIN_TYPE,
} from '../AudioPlugin/AudioPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as QUICK_REPLY_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as QUICK_REPLY_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as QUICK_REPLY_PLUGIN_TYPE,
} from '../QuickReplyPlugin/QuickReplyPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as DATE_PICKER_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as DATE_PICKER_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as DATE_PICKER_PLUGIN_TYPE,
} from '../DatePickerPlugin/DatePickerPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as N_TIME_NOTIFICATION_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as N_TIME_NOTIFICATION_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as N_TIME_NOTIFICATION_PLUGIN_TYPE,
} from '../NTimeNotificationPlugin/NTimeNotificationPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as WHATSAPP_DIRECT_ENTRY_POINT_CONFIG,
  PLUGIN_INPUT_FIELD as WHATSAPP_DIRECT_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as WHATSAPP_DIRECT_ENTRY_POINT_TYPE,
} from '../WhatsappDirectEntryPoint/WhatsappDirectEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as WHATSAPP_BOT_LINK_ENTRY_POINT_CONFIG,
  PLUGIN_INPUT_FIELD as WHATSAPP_BOT_LINK_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as WHATSAPP_BOT_LINK_ENTRY_POINT_TYPE,
} from '../WhatsappBotLinkEntryPoint/WhatsappBotLinkEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as AD_COMMENTS_AUTOREPLY_ENTRY_POINT_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as AD_COMMENTS_AUTOREPLY_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as AD_COMMENTS_AUTOREPLY_ENTRY_POINT_TYPE,
} from '../AdCommentsAutoreplyEntryPoint/AdCommenntsAutoreplyEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as COMMENTS_LIKE_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as COMMENTS_LIKE_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as COMMENTS_LIKE_PLUGIN_TYPE,
} from '../CommentsLikePlugin/CommentsLikePluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as OPEN_AI_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as OPEN_AI_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as OPEN_AI_PLUGIN_TYPE,
} from '../OpenAiPlugin/OpenAiPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as WHATSAPP_TEMPLATE_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as WHATSAPP_TEMPLATE_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as WHATSAPP_TEMPLATE_PLUGIN_TYPE,
} from '../WhatsappTemplatePlugin/WhatsappTemplatePluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as SPLIT_TRAFFIC_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as SPLIT_TRAFFIC_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as SPLIT_TRAFFIC_PLUGIN_TYPE,
} from '../SplitTrafficPlugin/SplitTrafficPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as WHATSAPP_LIST_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as WHATSAPP_LIST_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as WHATSAPP_LIST_PLUGIN_TYPE,
} from '../WhatsappListPlugin/WhatsappListPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as WHATSAPP_WIDGET_ENTRY_POINT_CONFIG,
  PLUGIN_INPUT_FIELD as WHATSAPP_WIDGET_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as WHATSAPP_WIDGET_ENTRY_POINT_TYPE,
} from '../WhatsappWidgetEntryPoint/WhatsappWidgetEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as WHATSAPP_POPUP_ENTRY_POINT_CONFIG,
  PLUGIN_INPUT_FIELD as WHATSAPP_POPUP_ENTRY_POINT_INPUT_FIELD,
  PLUGIN_TYPE as WHATSAPP_POPUP_ENTRY_POINT_TYPE,
} from '../WhatsappPopupEntryPoint/WhatsappPopupEntryPointConst';
import {
  PLUGIN_DEFAULT_CONFIG as DOCUMENT_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as DOCUMENT_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as DOCUMENT_PLUGIN_TYPE,
} from '../DocumentPlugin/DocumentPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as VIDEO_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as VIDEO_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as VIDEO_PLUGIN_TYPE,
} from '../VideoPlugin/VideoPluginConst';
import {
  PLUGIN_DEFAULT_CONFIG as WHATSAPP_LOCATION_PLUGIN_CONFIG,
  PLUGIN_INPUT_FIELD as WHATSAPP_LOCATION_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as WHATSAPP_LOCATION_PLUGIN_TYPE,
} from '../WhatsappLocationPlugin/WhatsappLocationPluginConst';
import {
  KOMMO_PLUGIN_DEFAULT_CONFIG,
  KOMMO_PLUGIN_INPUT_FIELD,
  KOMMO_PLUGIN_TYPE,
} from '../KommoPlugin/KommoPlugin';
import {
  PLUGIN_DEFAULT_CONFIG as CUSTOM_AI_PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD as CUSTOM_AI_PLUGIN_INPUT_FIELD,
  PLUGIN_TYPE as CUSTOM_AI_PLUGIN_TYPE,
} from '../CustomAiPlugin/CustomAiPluginConst';
import { flowBuilderModule } from '@components/FlowBuilder';

type PluginDefaultDataType = {
  [pluginType: string]: { [pluginInputField: string]: any };
};

const PLUGINS_DEFAULT_DATA: PluginDefaultDataType = {
  [ADD_ATTRIBUTE_PLUGIN_TYPE]: {
    [ADD_ATTRIBUTE_PLUGIN_INPUT_FIELD]: ADD_ATTRIBUTE_PLUGIN_DEFAULT_CONFIG,
  },
  [REMOVE_ATTRIBUTE_PLUGIN_TYPE]: {
    [REMOVE_ATTRIBUTE_PLUGIN_INPUT_FIELD]:
      REMOVE_ATTRIBUTE_PLUGIN_DEFAULT_CONFIG,
  },
  [SETUP_ATTRIBUTE_PLUGIN_TYPE]: {
    [SETUP_ATTRIBUTE_PLUGIN_INPUT_FIELD]: SETUP_ATTRIBUTE_PLUGIN_DEFAULT_CONFIG,
  },
  [JSON_PLUGIN_TYPE]: {
    [JSON_PLUGIN_INPUT_FIELD]: JSON_PLUGIN_DEFAULT_CONFIG,
  },
  [FACEBOOK_ANALYTICS_PLUGIN_TYPE]: {
    [FACEBOOK_ANALYTICS_PLUGIN_INPUT_FIELD]:
      FACEBOOK_ANALYTICS_PLUGIN_DEFAULT_CONFIG,
  },
  [HANDOVER_PLUGIN_TYPE]: {
    [HANDOVER_PLUGIN_INPUT_FIELD]: HANDOVER_PLUGIN_DEFAULT_CONFIG,
  },
  [ABT_PLUGIN_TYPE]: {
    [ABT_PLUGIN_INPUT_FIELD]: ABT_PLUGIN_DEFAULT_CONFIG,
  },
  [ABANDONED_CART_PLUGIN_TYPE]: {
    [ABANDONED_CART_PLUGIN_INPUT_FIELD]: ABANDONED_CART_PLUGIN_DEFAULT_CONFIG,
  },
  [GOTO_BLOCK_PLUGIN_TYPE]: {
    [GOTO_BLOCK_PLUGIN_INPUT_FIELD]: GOTO_BLOCK_PLUGIN_DEFAULT_CONFIG,
  },
  [EMAIL_PLUGIN_TYPE]: {
    [EMAIL_PLUGIN_INPUT_FIELD]: EMAIL_PLUGIN_DEFAULT_CONFIG,
  },
  [FLOWBUILDER_LIVECHAT_TYPE]: {
    [FLOWBUILDER_LIVECHAT_INPUT_FIELD]: FLOWBUILDER_LIVECHAT_DEFAULT_CONFIG,
  },
  [INBOUND_LINKS_ENTRY_POINT_TYPE]: {
    [INBOUND_LINKS_ENTRY_POINT_INPUT_FIELD]:
      INBOUND_LINKS_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [COMMENTS_AUTOREPLY_ENTRY_POINT_TYPE]: {
    [COMMENTS_AUTOREPLY_ENTRY_POINT_INPUT_FIELD]:
      COMMENTS_AUTOREPLY_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [CUSTOMER_CHAT_ENTRY_POINT_TYPE]: {
    [CUSTOMER_CHAT_ENTRY_POINT_INPUT_FIELD]:
      CUSTOMER_CHAT_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_TYPE]: {
    [SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_INPUT_FIELD]:
      SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [PERSISTENT_MENU_ENTRY_POINT_TYPE]: {
    [PERSISTENT_MENU_ENTRY_POINT_INPUT_FIELD]:
      PERSISTENT_MENU_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [PRIVATE_REPLY_ENTRY_POINT_TYPE]: {
    [PRIVATE_REPLY_ENTRY_POINT_INPUT_FIELD]:
      PRIVATE_REPLY_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [PUBLIC_REPLY_ENTRY_POINT_TYPE]: {
    [PUBLIC_REPLY_ENTRY_POINT_INPUT_FIELD]:
      PUBLIC_REPLY_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [SEND_TO_MESSENGER_ENTRY_POINT_TYPE]: {
    [SEND_TO_MESSENGER_ENTRY_POINT_INPUT_FIELD]:
      SEND_TO_MESSENGER_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [GOOGLE_SHEET_PLUGIN_TYPE]: {
    [GOOGLE_SHEET_PLUGIN_INPUT_FIELD]: GOOGLE_SHEET_PLUGIN_DEFAULT_CONFIG,
  },
  [ZAPIER_IMPORT_CONTENT_PLUGIN_TYPE]: {
    [ZAPIER_IMPORT_CONTENT_PLUGIN_INPUT_FIELD]:
      ZAPIER_IMPORT_CONTENT_PLUGIN_DEFAULT_CONFIG,
  },
  [ZAPIER_EVENT_TRIGGER_PLUGIN_TYPE]: {
    [ZAPIER_EVENT_TRIGGER_PLUGIN_INPUT_FIELD]:
      ZAPIER_EVENT_TRIGGER_PLUGIN_DEFAULT_CONFIG,
  },
  [INSTAGRAM_ENTRY_POINT_PLUGIN_TYPE]: {
    [INSTAGRAM_ENTRY_POINT_INPUT_FIELD]: INSTAGRAM_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [FACEBOOK_ADS_CTM_ENTRY_POINT_PLUGIN_TYPE]: {
    [FACEBOOK_ADS_ENTRY_POINT_INPUT_FIELD]:
      FACEBOOK_ADS_CTM_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [FACEBOOK_ADS_CTM_JSON_ENTRY_POINT_PLUGIN_TYPE]: {
    [FACEBOOK_ADS_ENTRY_POINT_INPUT_FIELD]:
      FACEBOOK_ADS_CTM_JSON_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [FACEBOOK_ADS_SM_ENTRY_POINT_PLUGIN_TYPE]: {
    [FACEBOOK_ADS_ENTRY_POINT_INPUT_FIELD]:
      FACEBOOK_ADS_SM_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [POPUP_ENTRY_POINT_TYPE]: {
    [POPUP_ENTRY_POINT_INPUT_FIELD]: POPUP_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [SHOPIFY_EVENT_ENTRY_POINT_TYPE]: {
    [SHOPIFY_EVENT_ENTRY_POINT_INPUT_FIELD]:
      SHOPIFY_EVENT_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [INSTAGRAM_MEDIA_SHARE_PLUGIN_TYPE]: {
    [INSTAGRAM_MEDIA_SHARE_PLUGIN_INPUT_FIELD]:
      INSTAGRAM_MEDIA_SHARE_PLUGIN_DEFAULT_CONFIG,
  },
  [INSTAGRAM_DIRECT_ENTRY_POINT_TYPE]: {
    [INSTAGRAM_DIRECT_ENTRY_POINT_INPUT_FIELD]:
      INSTAGRAM_DIRECT_ENTRY_POINT_CONFIG,
  },
  [INSTAGRAM_COMMENTS_AUTOREPLY_ENTRY_POINT_TYPE]: {
    [INSTAGRAM_COMMENTS_AUTOREPLY_ENTRY_POINT_INPUT_FIELD]:
      INSTAGRAM_COMMENTS_AUTOREPLY_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [INSTAGRAM_STORY_MENTION_ENTRY_POINT_TYPE]: {
    [INSTAGRAM_STORY_MENTION_ENTRY_POINT_INPUT_FIELD]:
      INSTAGRAM_STORY_MENTION_ENTRY_POINT_CONFIG,
  },
  [INSTAGRAM_STORY_REPLY_ENTRY_POINT_TYPE]: {
    [INSTAGRAM_STORY_REPLY_ENTRY_POINT_INPUT_FIELD]:
      INSTAGRAM_STORY_REPLY_ENTRY_POINT_CONFIG,
  },
  [INSTAGRAM_BOT_LINK_ENTRY_POINT_PLUGIN_TYPE]: {
    [INSTAGRAM_BOT_LINK_ENTRY_POINT_INPUT_FIELD]:
      INSTAGRAM_BOT_LINK_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [ORDER_RECEIPT_PLUGIN_TYPE]: {
    [ORDER_RECEIPT_PLUGIN_INPUT_FIELD]: ORDER_RECEIPT_PLUGIN_CONFIG,
  },
  [CALENDLY_PLUGIN_TYPE]: {
    [CALENDLY_PLUGIN_INPUT_FIELD]: CALENDLY_PLUGIN_DEFAULT_CONFIG,
  },
  [PRODUCT_LIST_PLUGIN_TYPE]: {
    [PRODUCT_LIST_PLUGIN_INPUT_FIELD]: PRODUCT_LIST_PLUGIN_CONFIG,
  },
  [BOT_LINK_ENTRY_POINT_TYPE]: {
    [BOT_LINK_ENTRY_POINT_INPUT_FIELD]: BOT_LINK_ENTRY_POINT_CONFIG,
  },
  [SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_TYPE]: {
    [SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_INPUT_FIELD]:
      SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [AUDIO_PLUGIN_TYPE]: {
    [AUDIO_PLUGIN_INPUT_FIELD]: AUDIO_PLUGIN_CONFIG,
  },
  [DATE_PICKER_PLUGIN_TYPE]: {
    [DATE_PICKER_PLUGIN_INPUT_FIELD]: DATE_PICKER_PLUGIN_CONFIG,
  },
  [QUICK_REPLY_PLUGIN_TYPE]: {
    [QUICK_REPLY_PLUGIN_INPUT_FIELD]: QUICK_REPLY_PLUGIN_CONFIG,
  },
  [N_TIME_NOTIFICATION_PLUGIN_TYPE]: {
    [N_TIME_NOTIFICATION_PLUGIN_INPUT_FIELD]: N_TIME_NOTIFICATION_PLUGIN_CONFIG,
  },
  [KOMMO_PLUGIN_TYPE]: {
    [KOMMO_PLUGIN_INPUT_FIELD]: KOMMO_PLUGIN_DEFAULT_CONFIG,
  },
  [WHATSAPP_DIRECT_ENTRY_POINT_TYPE]: {
    [WHATSAPP_DIRECT_ENTRY_POINT_INPUT_FIELD]:
      WHATSAPP_DIRECT_ENTRY_POINT_CONFIG,
  },
  [WHATSAPP_BOT_LINK_ENTRY_POINT_TYPE]: {
    [WHATSAPP_BOT_LINK_ENTRY_POINT_INPUT_FIELD]:
      WHATSAPP_BOT_LINK_ENTRY_POINT_CONFIG,
  },
  [WHATSAPP_POPUP_ENTRY_POINT_TYPE]: {
    [WHATSAPP_POPUP_ENTRY_POINT_INPUT_FIELD]: WHATSAPP_POPUP_ENTRY_POINT_CONFIG,
  },
  [AD_COMMENTS_AUTOREPLY_ENTRY_POINT_TYPE]: {
    [AD_COMMENTS_AUTOREPLY_ENTRY_POINT_INPUT_FIELD]:
      AD_COMMENTS_AUTOREPLY_ENTRY_POINT_DEFAULT_CONFIG,
  },
  [COMMENTS_LIKE_PLUGIN_TYPE]: {
    [COMMENTS_LIKE_PLUGIN_INPUT_FIELD]: COMMENTS_LIKE_PLUGIN_CONFIG,
  },
  [OPEN_AI_PLUGIN_TYPE]: {
    [OPEN_AI_PLUGIN_INPUT_FIELD]: OPEN_AI_PLUGIN_CONFIG,
  },
  [WHATSAPP_TEMPLATE_PLUGIN_TYPE]: {
    [WHATSAPP_TEMPLATE_PLUGIN_INPUT_FIELD]: WHATSAPP_TEMPLATE_PLUGIN_CONFIG,
  },
  [SPLIT_TRAFFIC_PLUGIN_TYPE]: {
    [SPLIT_TRAFFIC_PLUGIN_INPUT_FIELD]: SPLIT_TRAFFIC_PLUGIN_CONFIG,
  },
  [WHATSAPP_LIST_PLUGIN_TYPE]: {
    [WHATSAPP_LIST_PLUGIN_INPUT_FIELD]: WHATSAPP_LIST_PLUGIN_CONFIG,
  },
  [WHATSAPP_WIDGET_ENTRY_POINT_TYPE]: {
    [WHATSAPP_WIDGET_ENTRY_POINT_INPUT_FIELD]:
      WHATSAPP_WIDGET_ENTRY_POINT_CONFIG,
  },
  [DOCUMENT_PLUGIN_TYPE]: {
    [DOCUMENT_PLUGIN_INPUT_FIELD]: DOCUMENT_PLUGIN_CONFIG,
  },
  [VIDEO_PLUGIN_TYPE]: {
    [VIDEO_PLUGIN_INPUT_FIELD]: VIDEO_PLUGIN_CONFIG,
  },
  [WHATSAPP_LOCATION_PLUGIN_TYPE]: {
    [WHATSAPP_LOCATION_PLUGIN_INPUT_FIELD]: WHATSAPP_LOCATION_PLUGIN_CONFIG,
  },
  [CUSTOM_AI_PLUGIN_TYPE]: {
    [CUSTOM_AI_PLUGIN_INPUT_FIELD]: CUSTOM_AI_PLUGIN_DEFAULT_CONFIG,
  }
};

export const getPluginsDefaultData = (): PluginDefaultDataType => {
  const config = flowBuilderModule.getPlugins().reduce(
    (curr, p) => ({
      ...curr,
      [p.id]: { [p.inputField]: p.getDefaultConfig() },
    }),
    {},
  );

  return {
    ...config,
    ...PLUGINS_DEFAULT_DATA,
  };
};
