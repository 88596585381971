import React from 'react';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { BotPageRouteParams, BotTabs, getTabLink } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useParams } from 'react-router-dom';
import { TabNavLinkWithRole } from '../TabLink/TabLink';
import * as css from '../../common.css';
import { NavItemProps, NavItemViewProps } from './types';
import { NavItemView } from '../NavItems/NavItemView';

export const HomeNavItemView: React.FC<NavItemViewProps> = ({ small }) => {
  const { t } = useSafeTranslation();

  return (
    <NavItemView
      title={t('modernComponents.LeftNavigationPanel.homeTab')}
      small={small}
      icon="tabs_home"
    />
  );
};

export const HomeNavItem: React.FC<NavItemProps> = ({
  small,
  onClick,
  className,
  disabled,
}) => {
  const { botId } = useParams<BotPageRouteParams>();
  const { t } = useSafeTranslation();

  return (
    <TabNavLinkWithRole
      data-testid="test-nav-to-grow"
      tabUrl={getTabLink(BotTabs.home, botId)}
      tabEvent="grow"
      domain="grow"
      small={small}
      onClick={onClick}
      disabled={disabled}
      className={className}
      contentClassName={css.linkTooltip}
      activeClassName={cn(css.activeLink)}
      submenu={
        small ? (
          <Type weight="medium" size="12px" color="grey">
            {t('modernComponents.LeftNavigationPanel.homeTab')}
          </Type>
        ) : null
      }
    >
      <HomeNavItemView small={small} />
    </TabNavLinkWithRole>
  );
};
