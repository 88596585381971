import React from 'react';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';

export const PaywallPaymentLoader: React.FC = () => {
  return (
    <div>
      <LoadingPlaceholder height={36} fullWidth />
      <Spacer factor={5} />
      <Flex>
        <LoadingPlaceholder height={36} fullWidth />
        <Spacer horizontalFactor={5} />
        <LoadingPlaceholder height={36} fullWidth />
      </Flex>
      <Spacer />
      <Flex justifyContent="flex-end">
        <Button intent="primary" loading>
          _
        </Button>
      </Flex>
    </div>
  );
};
