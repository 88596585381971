import React, { useRef } from 'react';
import cn from 'classnames';
import * as css from './PluginControlLabel.css';
import { Type } from '../../../modern-ui/Type';
import { Spacer } from '../../../modern-ui/Spacer';

const nanoid = require('nanoid');

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface PluginControlLabelChildrenProps {
  id: string;
}

export interface PluginControlLabel
  extends Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'label'> {
  label?: React.ReactNode;
  required?: boolean;
  description?: React.ReactNode;
  notTranslateFocus?: boolean;
  light?: boolean;
  spacerFactor?: number;
  children: (params: PluginControlLabelChildrenProps) => React.ReactNode;
}

export const PluginControlLabel: React.FC<PluginControlLabel> = ({
  className,
  required,
  children,
  label,
  description,
  light,
  spacerFactor = 1,
  ...props
}) => {
  const idRef = useRef(`id_${nanoid()}`);
  return (
    <div className={cn(css.PluginControlLabel, className)} {...props}>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      {label && (
        <label htmlFor={idRef.current}>
          <Type
            weight="semibold"
            size={light ? '12px' : '15px_DEPRECATED'}
            color={light ? 'cobaltTertiary' : 'black'}
          >
            <span className={cn(css.label, { [css.require]: required })}>
              {label}
            </span>
          </Type>
          <Spacer factor={spacerFactor} />
        </label>
      )}
      <div className={css.control}>{children({ id: idRef.current })}</div>
      {description && (
        <div className={cn(css.description, { [css.descriptionLight]: light })}>
          {description}
        </div>
      )}
    </div>
  );
};
