import { PluginType } from '../common/PluginTypes';
import { audioPluginFragment_config } from './@types/audioPluginFragment';

export type AudioPluginConfig = audioPluginFragment_config;

export const PLUGIN_TYPE = PluginType.audio;

export const PLUGIN_DEFAULT_CONFIG: AudioPluginConfig = {
  __typename: 'AudioPluginConfig',
  url: '',
  filename: '',
};

export const PLUGIN_INPUT_FIELD = 'audioPluginConfig';
