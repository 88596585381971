import { PluginType } from '../common/PluginTypes';
import { whatsappPopupEntryPointFragment_config as WhatsappPopupEntryPointFragmentConfig } from './@types/whatsappPopupEntryPointFragment';

export const PLUGIN_TYPE = PluginType.whatsapp_popup_entry_point;

export const PLUGIN_DEFAULT_CONFIG: WhatsappPopupEntryPointFragmentConfig = {
  __typename: 'WhatsappPopupEntryPointConfig',
  header: null,
  text: null,
  image: null,
  buttonText: 'Open WhatsApp',
  prefilledMessage: 'Hi! I want to claim my code.',
  delay_s: 99,
};

export const PLUGIN_INPUT_FIELD = 'whatsappPopupEntryPointConfig';
