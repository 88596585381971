import ng from 'angular';
import { react2angular } from 'react2angular';
import { HandoverPluginPopup } from './HandoverPluginPopup';

export const ngHandoverPluginPopup = ng
  .module('app.modernComponents.handoverPluginPopup', [])
  .component(
    'handoverPluginPopup',
    react2angular(HandoverPluginPopup, ['onDismiss'], []),
  ).name;
