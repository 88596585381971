import React from 'react';
import ng from 'angular';
import { react2angular } from 'react2angular';
import { ImageSizeAlert, ImageSizeAlertProps } from './ImageSizeAlert';

const ImageSizeAlertWrapper: React.FC<ImageSizeAlertProps> = (props) => (
  <ImageSizeAlert {...props} />
);

export const ngImageSizeAlert = ng
  .module('app.components.imageSizeAlert', [])
  .component(
    'imageSizeAlert',
    react2angular(ImageSizeAlertWrapper, ['onDismiss'], []),
  ).name;
