import React from 'react';
import { Modal } from '@services/index';
import { SwitchingDesktopDialog } from './SwitchingDesktopDialog';

export const showSwitchingDesktopDialog = (
  setShowFlowBuilderOnMobile: VoidFunction,
) =>
  Modal.show(
    ({ close, resolve }) => (
      <SwitchingDesktopDialog
        close={close}
        resolve={() => {
          resolve();
          setShowFlowBuilderOnMobile();
        }}
      />
    ),
    { mobileAdaptive: true },
  );
