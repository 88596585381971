import React from 'react';
import cn from 'classnames';
import { ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import { OnboardingTipsStatus } from '@globals';
import * as css from './ActionButton.css';

export const OnboardingButtonBox: React.FC<{ active: boolean }> = ({
  children,
  active,
}) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    className={cn(css.button, { [css.buttonActive]: active })}
  >
    {children}
  </Flex>
);

export interface ActionButtonProps {
  hasButton: boolean;
  status: OnboardingTipsStatus;
  handleOnbordingSetUpClick: VoidFunction;
  active: boolean;
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  active,
  status,
  hasButton,
  handleOnbordingSetUpClick,
}) => {
  if (!hasButton) {
    return null;
  }

  const button = (disabled: boolean = false) => {
    const getColor = () => {
      if (active) {
        return 'white';
      }

      if (disabled) {
        return 'cobaltSemilight';
      }

      return 'accent1Secondary';
    };

    return (
      <ButtonUnstyled
        onClick={handleOnbordingSetUpClick}
        data-testid="onbording_set-up_button"
        disabled={disabled}
      >
        <OnboardingButtonBox active={Boolean(active)}>
          <Icon icon="arrowBack" color={getColor()} className={css.icon} />
        </OnboardingButtonBox>
      </ButtonUnstyled>
    );
  };

  return {
    [OnboardingTipsStatus.available]: button(),
    [OnboardingTipsStatus.disabled]: button(true),
    [OnboardingTipsStatus.done]: null,
  }[status];
};
