import React from 'react';
import cn from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { IPlugin } from '../common/Plugin';
import { PluginBox, PluginData } from '../common';
import { abandonedCartPluginFragment_config as AbandonedCartPluginConfigPlugin } from './@types/abandonedCartPluginFragment';
import {
  AbandonedCartType,
  PLUGIN_HELP_URL,
  PLUGIN_ICON,
  PLUGIN_NAME,
  PLUGIN_TYPE,
} from './AbandonedCartPluginConst';
import { PluginHeader } from '../common/PluginHeader';
import { PluginBlock } from '../common/PluginBlock';
import * as css from './AbandonedCartPlugin.css';
import {
  Button,
  ButtonIntent,
  ButtonSize,
} from '../../../modern-ui/_deprecated/Button';
import { SegmentedGroupTrack } from '@ui/SegmentedGroup';
import { SegmentedGroupTrackColorWay } from '@ui/SegmentedGroup/SegmentedGroupTrack';
import { ReactComponent as ItemImage } from './images/clock_example.svg';
import { PluginVeil } from '../common/PluginVeil';
import { AbandonedCartPluginText } from './AbandonedCartPluginText';
import { sendEvent } from '@utils/Analytics';
import { Loader } from '@ui/Loader';
import { Flex } from '@ui/Flex';
import {
  ConnectedShopConfigQuery,
  ConnectedShopConfigQueryVariables,
} from './@types/ConnectedShopConfigQuery';

export interface AbandonedCartPluginProps extends IPlugin {
  botId: string;
}

export const CONNECTED_SHOP_CONFIG_QUERY = gql`
  query ConnectedShopConfigQuery($botId: String!) {
    bot(id: $botId) {
      id
      connectedShop {
        id
        myshopify_domain
        domain
      }
    }
  }
`;

export const AbandonedCartPlugin: React.FC<AbandonedCartPluginProps> = (
  props,
) => {
  const { data: connectedShopData, loading: connectedShopLoading } = useQuery<
    ConnectedShopConfigQuery,
    ConnectedShopConfigQueryVariables
  >(CONNECTED_SHOP_CONFIG_QUERY, {
    variables: { botId: props.botId },
    fetchPolicy: 'network-only',
  });

  const domains = connectedShopData && connectedShopData.bot.connectedShop;

  return (
    <PluginData<AbandonedCartPluginConfigPlugin>
      pluginType={PLUGIN_TYPE}
      id={props.id}
      blockId={props.blockId}
      position={props.position}
    >
      {({
        loading: pluginDataLoading,
        setPluginConfigState,
        savePlugin: savePluginInner,
        pluginConfig,
      }) => {
        if (pluginDataLoading || !pluginConfig) {
          return null;
        }

        const savePlugin = () => {
          sendEvent({
            category: 'abandoned cart',
            action: 'set up reminders',
            label: 'save',
          });
          savePluginInner();
        };

        return (
          <PluginBox>
            <PluginVeil show={!pluginConfig.enabled}>
              <div className={css.pluginVeil}>
                {window.i18next.t(
                  'AbandonedCartPlugin-string--371-youve-disabled-the-abandoned-cart-plugin-for-your-store-analytics-for-its-performance-will-no-longer-display-accurately',
                )}
              </div>
            </PluginVeil>
            <PluginVeil show={connectedShopLoading}>
              <Loader />
            </PluginVeil>
            <PluginHeader
              title={PLUGIN_NAME}
              icon={PLUGIN_ICON}
              helpUrl={PLUGIN_HELP_URL}
              pluginType={PLUGIN_TYPE}
              className={css.abandonedCartPluginTitle}
            />
            <PluginBlock>
              <div className={css.sectionEnd}>
                {window.i18next.t(
                  'AbandonedCartPlugin-JSXText--165-this-plugin-brings-people-back-to-their-abandoned-cart-after-clicking-on-a-specially-generated-button-the-user-will-be-redirected-to-the-checkout-page-for-items-they-left-behind',
                )}
              </div>
              <div className={css.sectionTitle}>
                {window.i18next.t(
                  'AbandonedCartPlugin-JSXText-1235-link-to-your-shopify-store',
                )}
              </div>
              <div className={css.sectionEnd}>
                {!connectedShopData?.bot.connectedShop ? (
                  connectedShopLoading ? (
                    'chatfuel.myshopify.com'
                  ) : (
                    <Flex alignItems="center">
                      <a href={`/bot/${props.botId}/grow`}>
                        <Button intent={ButtonIntent.primary}>
                          {window.i18next.t(
                            'AbandonedCartPlugin-JSXText--167-connect',
                          )}
                        </Button>
                      </a>
                      <span className={css.connectionError}>
                        {window.i18next.t(
                          'AbandonedCartPlugin-JSXText--131-please-connect-your-store-in-grow-tab',
                        )}
                      </span>
                    </Flex>
                  )
                ) : connectedShopLoading ? (
                  <Loader />
                ) : (
                  domains && (domains.domain || domains.myshopify_domain)
                )}
              </div>

              <div className={css.pluginSetupPart}>
                {!connectedShopData?.bot.connectedShop && (
                  <div className={css.pluginSetupVeil} />
                )}
                <div className={css.sectionTitle}>
                  {window.i18next.t(
                    'AbandonedCartPlugin-JSXText--582-which-reminder-style-do-you-want-to-use',
                  )}
                </div>
                <div className={css.typePickerWrapper}>
                  <SegmentedGroupTrack
                    role="tablist"
                    colorWay={SegmentedGroupTrackColorWay.grey}
                  >
                    <Button
                      role="tab"
                      intent={ButtonIntent.toggle}
                      size={ButtonSize.s}
                      aria-selected={
                        pluginConfig.reminder_type === AbandonedCartType.gallery
                      }
                      onClick={() => {
                        const config = {
                          ...pluginConfig,
                          reminder_type: AbandonedCartType.gallery,
                        };
                        sendEvent({
                          category: 'abandoned cart',
                          action: 'set up reminders',
                          label: 'click',
                        });
                        setPluginConfigState({ config });
                        savePlugin();
                      }}
                    >
                      {window.i18next.t(
                        'AbandonedCartPlugin-JSXText--459-gallery',
                      )}
                    </Button>
                    <Button
                      role="tab"
                      intent={ButtonIntent.toggle}
                      size={ButtonSize.s}
                      aria-selected={
                        pluginConfig.reminder_type === AbandonedCartType.text
                      }
                      onClick={() => {
                        const config = {
                          ...pluginConfig,
                          reminder_type: AbandonedCartType.text,
                        };
                        sendEvent({
                          category: 'abandoned cart',
                          action: 'set up reminders',
                          label: 'click',
                        });
                        setPluginConfigState({ config });
                        savePlugin();
                      }}
                    >
                      {window.i18next.t(
                        'AbandonedCartPlugin-JSXText-1622-text-card',
                      )}
                    </Button>
                  </SegmentedGroupTrack>
                </div>
                <div className={css.sectionEnd}>
                  {pluginConfig.reminder_type === AbandonedCartType.text
                    ? window.i18next.t(
                        'AbandonedCartPlugin-string--142-the-user-will-be-taken-directly-to-the-checkout-page-if-they-click-the-button-under-the-text-message-works-great-as-a-second-reminder',
                      )
                    : window.i18next.t(
                        'AbandonedCartPlugin-string--225-the-user-will-receive-a-gallery-where-each-card-is-an-item-from-their-abandoned-cart-works-great-as-a-first-reminder',
                      )}
                </div>
                <div className={css.pluginPreview}>
                  <div className={css.pluginPreviewBox}>
                    <div className={css.pluginPreviewBoxHeader}>
                      <span>
                        {window.i18next.t(
                          'AbandonedCartPlugin-JSXText-2119-name-of-your-page',
                        )}
                      </span>
                    </div>
                    <div className={css.pluginPreviewBoxContent}>
                      {pluginConfig.reminder_type === AbandonedCartType.text ? (
                        <>
                          <div className={css.textPreviewFirstMessage}>
                            <div />
                            <div />
                          </div>
                          <div className={css.textPreviewSecondMessage}>
                            <div />
                          </div>
                          <div className={css.textPreviewMainMessage}>
                            <div className={css.textPreviewMainMessageText}>
                              <AbandonedCartPluginText
                                text={pluginConfig.text_plugin.text}
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>,
                                ) => {
                                  const config = {
                                    ...pluginConfig,
                                    text_plugin: {
                                      ...pluginConfig.text_plugin,
                                      text: e.currentTarget.value,
                                    },
                                  };
                                  setPluginConfigState({ config });
                                }}
                                savePlugin={savePlugin}
                              />
                            </div>
                            <div className={css.textPreviewMainMessageButton}>
                              <AbandonedCartPluginText
                                text={pluginConfig.text_plugin.button_title}
                                button
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>,
                                ) => {
                                  const config = {
                                    ...pluginConfig,
                                    text_plugin: {
                                      ...pluginConfig.text_plugin,
                                      button_title: e.currentTarget.value,
                                    },
                                  };
                                  setPluginConfigState({ config });
                                }}
                                savePlugin={savePlugin}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={css.galleryPreviewFirstMessage}>
                            <div />
                          </div>
                          <div className={css.galleryPreviewGallery}>
                            <div className={css.galleryPreviewCard}>
                              <div
                                className={cn(
                                  css.cardPreview,
                                  css.firstCardPreview,
                                )}
                              >
                                <div className={css.cardPreviewText}>
                                  {window.i18next.t(
                                    'AbandonedCartPlugin-JSXText-1482-product-picture-01',
                                  )}
                                </div>
                                <ItemImage />
                              </div>
                              <div className={css.productInfo}>
                                <div className={css.productInfoFirstLine}>
                                  {window.i18next.t(
                                    'AbandonedCartPlugin-JSXText-6313-product-title-01',
                                  )}
                                </div>
                                <div className={css.productInfoSecondLine}>
                                  1X — 14$
                                </div>
                              </div>
                              <div className={css.cardButton}>
                                <AbandonedCartPluginText
                                  text={
                                    pluginConfig.gallery_plugin.button_title
                                  }
                                  button
                                  onChange={(
                                    e: React.FormEvent<HTMLInputElement>,
                                  ) => {
                                    const config = {
                                      ...pluginConfig,
                                      gallery_plugin: {
                                        ...pluginConfig.gallery_plugin,
                                        button_title: e.currentTarget.value,
                                      },
                                    };
                                    setPluginConfigState({ config });
                                  }}
                                  savePlugin={savePlugin}
                                />
                              </div>
                            </div>
                            <div className={css.galleryPreviewCard}>
                              <div
                                className={cn(
                                  css.cardPreview,
                                  css.secondCardPreview,
                                )}
                              >
                                <div className={css.cardPreviewText}>
                                  {window.i18next.t(
                                    'AbandonedCartPlugin-JSXText-1482-product-picture-02',
                                  )}
                                </div>
                              </div>
                              <div className={css.productInfo}>
                                <div className={css.productInfoFirstLine}>
                                  {window.i18next.t(
                                    'AbandonedCartPlugin-JSXText-6313-product-title-02',
                                  )}
                                </div>
                                <div className={css.productInfoSecondLine}>
                                  1X — 14$
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </PluginBlock>
          </PluginBox>
        );
      }}
    </PluginData>
  );
};
