import ng from 'angular';
import { react2angular } from 'react2angular';
import React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import { HandoverPlugin, HandoverPluginProps } from './HandoverPlugin';
import client from '../../../common/services/ApolloService';

const HandoverPluginWrapper: React.FC<HandoverPluginProps> = (props) => (
  <ApolloProvider client={client}>
    <HandoverPlugin {...props} />
  </ApolloProvider>
);

export const ngHandoverPlugin = ng
  .module('app.plugins.handoverPlugin', [])
  .component(
    'handoverPlugin',
    react2angular(
      HandoverPluginWrapper,
      ['id', 'botId', 'blockId', 'position'],
      [],
    ),
  ).name;
